import { PageInterface, PagesStateInterface } from 'store/reducers/projectPages/types';
import { IdInterface } from 'types/store';

export const initialPagesStoreState: PagesStateInterface = {
  projectPages: [],
  serverStateOfProjectPages: null,
  firstPageId: null,
  activePageId: '',
  pagesLoading: false,
};

export const defaultPageSizes = {
  height: 1200,
  width: 1600,
};

export const getInitialPages: (params: IdInterface) => PageInterface = ({ id }) => ({
  id,
  name: 'Страница',
  isHidden: false,
  boardSettings: {
    sizes: defaultPageSizes,
    viewState: 'show',
  },
});
