import React from 'react';
import { Button, Select } from 'modules/ui';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  addUserSourcesAction,
  createUserSourcesAction,
  updateUserSourceAction,
  updateUserSourcesAction,
} from 'store/reducers/adminUsers/actions';
import { getActiveUser } from 'store/reducers/adminUsers/getters';
import { UserSourcesListInterface } from 'store/reducers/adminUsers/types';
import { Form } from './styles';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { accessItems, defaultSelectedAccess } from 'components/admin/groups/constants';
import { ModalComponentType } from 'store/reducers/modals/types';
import { UserSourceFormType } from 'components/admin/usersPage/elemets/Sources/types';
import { SelectItemInterface } from 'components/admin/groups/types';
import { ModeForm } from 'types/store';

interface SourceModalProps {
  selectData?: SelectItemInterface[];
  data?: UserSourcesListInterface;
  mode: ModeForm;
}

export const SourceModal: ModalComponentType<SourceModalProps> = ({ onClose, selectData, data, mode }) => {
  const dispatch = useAppDispatch();

  const isAdd = mode === 'add';

  const activeUser = useSelector(getActiveUser);
  const activeUserId = activeUser?.id;

  const sourceId = data?.id;
  const sourceName = data?.name;
  const sourceGroupName = data?.groupName || '';

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<UserSourceFormType>({
    defaultValues: {
      access: data?.sourceGroupType || defaultSelectedAccess,
      ...(selectData && { source: selectData[0].value }),
    },
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<UserSourceFormType> = async ({ access, source }) => {
    try {
      if (!isDirty || !activeUserId || isSubmitting) {
        return null;
      }

      const params = {
        sourceId: source || sourceId || '',
        type: access,
        userId: activeUserId,
      };

      const localParams = {
        sourceGroupType: access,
        groupName: sourceGroupName,
      };

      if (isAdd) {
        const response = await dispatch(createUserSourcesAction(params));
        const selectedSource = selectData?.find((el) => el.value === source);

        if (response && selectedSource) {
          dispatch(addUserSourcesAction({ name: selectedSource.name, id: source, ...localParams }));
        }
      }

      if (!isAdd && sourceId && sourceName) {
        const response = await dispatch(updateUserSourceAction(params)).unwrap();

        if (response) {
          dispatch(
            updateUserSourcesAction({
              source: { id: sourceId, name: sourceName, ...localParams },
            }),
          );
        }
      }

      onClose();
    } catch (e) {
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      {isAdd && (
        <Controller
          render={({ field }) => (
            <Select title="К подключению" options={selectData} width="100%" {...field} value={field.value} />
          )}
          name="source"
          control={control}
        />
      )}

      <Controller
        render={({ field }) => <Select title="Права" options={accessItems} width="100%" {...field} value={field.value} />}
        name="access"
        control={control}
      />

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
