import { Alias, Columns, DB, IsHeadInterface, LinkRelationType, Table } from 'store/reducers/models/types';
import { Relation, RelationInitialInterface } from 'models/model/Relation';
import { isEqual } from 'lodash';
import { PositionConfigInterface } from 'types/store';
import { defaultPositionConfig } from 'constants/defaults';

interface ModelItemInitialInterface extends Table, Alias, Partial<DB>, Partial<IsHeadInterface> {
  relations?: Relation[] | null;
  columns: Columns;
  config?: PositionConfigInterface;
}

export class ModelItem {
  readonly table: string;
  readonly db: string | null;
  readonly columns: Columns;
  /* isHead for Head of Model */
  public isHead: boolean;
  public config: PositionConfigInterface;
  public alias: string;

  private _relations: Relation[] | null;

  constructor({
    alias,
    table,
    db = null,
    relations = null,
    columns,
    isHead = false,
    config = defaultPositionConfig,
  }: ModelItemInitialInterface) {
    this.alias = alias;
    this.table = table;
    this.db = db;
    this.columns = columns;
    this.isHead = isHead;
    this.config = config;

    this._relations = relations === null ? null : this.getVerifiedRelations(relations);
  }

  hasColumn(column: string) {
    return !!this.columns[column];
  }

  private isCorrectRelation(relation: Relation): boolean {
    const { left, right } = relation.link;

    if (left.table === this.alias) {
      return this.hasColumn(left.column);
    }

    if (right.table === this.alias) {
      return this.hasColumn(right.column);
    }

    return false;
  }

  private isCorrectHeadRelation(relation: Relation): boolean {
    const { left, right } = relation.link;

    return [left, right].every(({ table, column }) => table === this.alias && this.hasColumn(column));
  }

  private isCorrectRelations(relations: Relation[]): boolean {
    return relations.every((relation) => (this.isHead ? this.isCorrectHeadRelation(relation) : this.isCorrectRelation(relation)));
  }

  private getVerifiedRelations(relations: Relation[]) {
    if (this.isCorrectRelations(relations)) {
      return relations;
    }

    throw Error('Relations incorrect');
  }

  get columnsAsArray() {
    return Object.keys(this.columns);
  }

  get relations() {
    return this._relations;
  }

  set relations(relations) {
    if (relations === null) {
      this._relations = null;
      return;
    }

    this._relations = this.getVerifiedRelations(relations);
  }

  addRelation(params: RelationInitialInterface) {
    const [relation] = this.getVerifiedRelations([new Relation(params)]);

    if (relation) {
      this.relations = [...(this.relations || []), relation];
    }
  }

  deleteRelationByIndex(index: number) {
    if (this.relations) {
      this.relations.splice(index, 1);
    }
  }

  deleteRelationByLink(link: LinkRelationType) {
    if (this.relations) {
      this.relations = this.relations.filter((relation) => !isEqual(relation.link, link));
    }
  }
}
