import { AxisSettingsInterface, AxisSettingsNameType, AxisType } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import {
  axisPositionOptions,
  axisRotatedPositionOptions,
  labelsOrderOptions,
  labelsOrientationOptions,
  namePositionOptions,
  tickLabelOrientationOption,
} from 'modules/settingsContainer/common/view/AxisSettings/constants';
import { AxisTypeGeneric } from 'modules/settingsContainer/common/view/AxisSettings/types';
import TextField from 'modules/ui/TextField';
import { AutoOrManualGroupValue } from 'modules/settingsContainer/common/AutoOrManualGroupValue';
import { OnValueChange } from 'types/global';
import { typeOptions } from 'modules/settingsContainer/common/view/ExtendedSettings/constants';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { pxUnit } from 'constants/global';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';

interface AxisSettingsProps<Type extends AxisType> extends OnValueChange<AxisSettingsInterface<Type>> {
  type: AxisTypeGeneric<Type>;
  titleText: string;
  isRotated?: boolean;
  disabledLabelSize?: boolean;
  disabledOrderValue?: boolean;
  disabled?: { positionAxis?: boolean };
  isAxisX?: boolean;
}

export const AxisSettings = <Type extends AxisType = 'incision'>({
  titleText,
  value,
  onChange,
  type,
  isRotated = false,
  disabledLabelSize = false,
  disabledOrderValue = false,
  isAxisX = false,
  disabled = { positionAxis: false },
}: AxisSettingsProps<Type>) => {
  const { positionAxis } = disabled;

  const {
    isShow,
    showAxis,
    position,
    name: {
      isShow: nameIsShow,
      type: nameType,
      position: { type: positionType, value: positionValue },
    },
    label,
    stepSize,
    showGrid,
  } = value;

  const isIncision = type === 'incision',
    axisPositionOptionsValue = isRotated ? axisRotatedPositionOptions(isIncision) : axisPositionOptions(isIncision),
    namePositionOptionsValue = namePositionOptions(isRotated ? !isIncision : isIncision);

  const activePositionValue = getActiveRadioValue(axisPositionOptionsValue, position);

  const activeNamePositionValue = getActiveRadioValue(namePositionOptionsValue, positionType);

  const incisionValue: AxisSettingsInterface | undefined = isIncision ? (value as AxisSettingsInterface) : undefined;

  return (
    <MainContainerSettings
      titleText={titleText}
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <GroupContainerSettings
        titleText="Показать ось"
        switcherState={showAxis}
        switcherChange={() => onChange({ ...value, showAxis: !showAxis })}
      />

      {!positionAxis && (
        <GroupContainerSettings titleText="Расположение оси">
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: position }) => onChange({ ...value, position })}
              activeValue={activePositionValue}
              options={axisPositionOptionsValue}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      )}
      <GroupContainerSettings
        titleText="Показать название оси"
        switcherState={nameIsShow}
        switcherChange={() => onChange({ ...value, name: { ...value.name, isShow: !nameIsShow } })}
      >
        {!isIncision && (
          <ElementContainerSettings>
            <TextField
              onChange={(e) => onChange({ ...value, name: { ...value.name, text: e.target.value } })}
              value={(value.name as AxisSettingsNameType<'indicator'>).text}
              type="text"
              name="width"
              needBackground={false}
              needBorderBottom={false}
              width="100%"
            />
          </ElementContainerSettings>
        )}
      </GroupContainerSettings>
      <GroupContainerSettings titleText="Расположение названия">
        <ElementContainerSettings>
          <SettingsRadio
            onChange={({ value: type }) => onChange({ ...value, name: { ...value.name, type } })}
            activeValue={getActiveRadioValue(typeOptions, nameType)}
            options={typeOptions}
          />
        </ElementContainerSettings>
        <>
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: type }) =>
                onChange({
                  ...value,
                  name: { ...value.name, type: nameType, position: { ...value.name.position, type, value: positionValue } },
                })
              }
              activeValue={activeNamePositionValue}
              options={namePositionOptionsValue}
            />
          </ElementContainerSettings>
          {value.name.type === 'manual' && (
            <ElementContainerSettings>
              <NumberPropertiesBlock
                properties="Отступ"
                name="width"
                unit={pxUnit}
                value={positionValue}
                onChange={(positionValue) =>
                  onChange({
                    ...value,
                    name: { ...value.name, position: { type: positionType, value: positionValue } },
                  })
                }
              />
            </ElementContainerSettings>
          )}
        </>
      </GroupContainerSettings>
      {isIncision && !disabledOrderValue && (
        <GroupContainerSettings titleText="Порядок значений">
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: labelOrderValues }) => onChange({ ...value, labelOrderValues })}
              activeValue={getActiveRadioValue(labelsOrderOptions(isAxisX), incisionValue?.labelOrderValues || '')}
              options={labelsOrderOptions(isAxisX)}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      )}
      <>
        <GroupContainerSettings
          titleText="Показать метки"
          switcherState={label.isActive}
          switcherChange={() =>
            onChange({ ...value, label: { properties: label.properties, isActive: !label.isActive, value: label.value } })
          }
        >
          <ElementContainerSettings>
            <NumberPropertiesBlock
              properties="Отступ от оси"
              name="padding"
              unit={pxUnit}
              value={label.value}
              onChange={(labelValue) =>
                onChange({ ...value, label: { properties: label.properties, isActive: label.isActive, value: labelValue } })
              }
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
        <GroupContainerSettings>
          <ElementContainerSettings>
            <PropertiesSettings
              value={label.properties}
              onChange={(properties) =>
                onChange({ ...value, label: { isActive: label.isActive, value: label.value, properties } })
              }
              isMainContainerSettings
              disabledOpacity
              disabledBackgroundColorBy
              disabledComponentColorByValue
              disabledPadding
              disabledLineHeight
              disabledUnderline
              disabledFontColorByBlock
              disabledLetterSpacing
              disabledFontColorBy
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </>

      {isIncision && (
        <GroupContainerSettings
          titleText="Засечки меток"
          switcherState={incisionValue?.tickLabel?.isShow}
          switcherChange={() =>
            onChange({
              ...value,
              tickLabel: {
                ...incisionValue?.tickLabel,
                isShow: !incisionValue?.tickLabel?.isShow,
              },
            })
          }
        >
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: position }) => onChange({ ...value, tickLabel: { ...incisionValue?.tickLabel, position } })}
              activeValue={getActiveRadioValue(tickLabelOrientationOption, incisionValue?.tickLabel?.position || '')}
              options={tickLabelOrientationOption}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      )}
      {isIncision && (
        <GroupContainerSettings titleText="Ориентация меток">
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: labelOrientation }) => onChange({ ...value, labelOrientation })}
              activeValue={getActiveRadioValue(labelsOrientationOptions, incisionValue?.labelOrientation || '')}
              options={labelsOrientationOptions}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      )}
      {isIncision && !disabledLabelSize && (
        <AutoOrManualGroupValue
          titleText="Длинна метки"
          value={incisionValue!.labelSize}
          onChange={(labelSize) => onChange({ ...value, labelSize })}
        />
      )}
      <AutoOrManualGroupValue titleText="Шаг по оси" value={stepSize} onChange={(stepSize) => onChange({ ...value, stepSize })} />
      <GroupContainerSettings
        titleText="Показать сетку"
        switcherState={showGrid}
        switcherChange={() => onChange({ ...value, showGrid: !showGrid })}
      />
    </MainContainerSettings>
  );
};
