import styled from 'styled-components';
import { CursorType } from 'types/styles';
import { pathCircleMixin } from 'constants/styles';

export interface StyledIconWrapperProps {
  cursor?: CursorType;
  containerHeight?: string;
  containerWidth?: string;
  iconHeight?: string;
  iconWidth?: string;
  colorStroke?: string;
  hoverColorStroke: string;
}
export const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ containerHeight }) => containerHeight && `height: ${containerHeight};`};
  ${({ containerWidth }) => containerWidth && `width: ${containerWidth};`};

  :hover {
    svg {
      path,
      circle,
      rect {
        stroke: ${({ hoverColorStroke }) => hoverColorStroke};
      }
    }
  }
  cursor: ${({ cursor }) => cursor || 'pointer'};

  svg {
    height: ${({ iconHeight }) => iconHeight || '14px'};
    width: ${({ iconWidth }) => iconWidth || '14px'};

    ${({ colorStroke }) => pathCircleMixin(colorStroke)}
  }
`;
