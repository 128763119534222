import { BarValuePositionType, LineAndBarVisualisationType } from 'store/reducers/visualisations/types';
import { TopAndBottomType } from 'types/styles';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import { WrappedReactECharts } from 'modules/visualisations/components/WrappedReactECharts';
import { memo, useCallback, useMemo } from 'react';
import { BarSeriesOption, LineSeriesOption } from 'echarts';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { defaultLineAndBarDataSettings, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { LineAndBarEChartsOption, LineAndBarNode } from 'modules/visualisations/LineAndBar/visualisation/types';
import {
  getBarGraphGridDimensions,
  getBarPositionLabel,
  getIndicatorAxisWithGridDimensions,
  getLineAndBarData,
  getLinePositionLabel,
} from 'modules/visualisations/LineAndBar/visualisation/constants';
import { SingleIncisionLayout } from 'modules/visualisations/components/SingleIncisionLayout';
import { calculateGridDimension, initialDimensions } from 'utils/generateConfigGraphic';
import { DefaultAxisInterface, GridDimensionsInterface, ItemValueColor } from 'types/echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { useVisualisation } from 'modules/visualisations/hooks/visualisation';
import { useActiveIncision } from 'modules/visualisations/hooks/activeIncision';
import {
  onDataZoomHorizontalStartAndEndSettingsChange,
  onDataZoomVerticalStartAndEndSettingsChange,
} from 'modules/visualisations/LineAndBar/settings/ViewTab/constants';
import { useProperties } from 'modules/visualisations/hooks/useProperties';
import {
  createBarPropertiesData,
  createTooltipFormatter,
  defaultGridDimension,
  getIncisionAxisConfigWithGridDimensions,
  getLegendConfigWithGridDimensions,
  getMinMaxValues,
  onActiveIncisionIdChange,
} from 'modules/visualisations/common/constants';
import { useIncisionColors } from 'modules/visualisations/hooks/useIncisionColors';
import { useDataZoom } from 'modules/visualisations/hooks/useDataZoom';
import { isPositiveNumber } from 'utils/utils';
import { useManualResize } from 'modules/visualisations/hooks/manualResize';

export const LineAndBarVisualisationComponent: VisualisationOriginInterface<LineAndBarVisualisationType> = ({
  data,
  sqlRequest,
}) => {
  const {
    id,
    dataSettings,
    viewSettings: {
      dataZoomVerticalStartAndEnd,
      dataZoomHorizontalStartAndEnd,
      description,
      header,
      axisIncisionSettings,
      axisIndicatorSettings,
      axisAdditionalIndicatorSettings,
      horizontalZoom,
      verticalZoom,
      showTips,
      legendSettings,
      shadowSettings,
      incisionSelectorPosition,
      visualisationPaddings: {
        isShow: visualisationPaddingsIsShow,
        paddingsVisualisation: { topPadding, rightPadding, leftPadding, bottomPadding },
      },
    },
    sqlData,
    events,
  } = data;

  const { colorBySettings, colorByValueSettings } = dataSettings,
    {
      shadowColorSettings: { shadowColorBy },
    } = shadowSettings;

  const propertiesData = useMemo(() => createBarPropertiesData(dataSettings.indicators), [dataSettings.indicators]);

  const properties = useProperties(propertiesData);

  const {
    visualisationNormalizedValues,
    updateFilter,
    eChartRef,
    getColorByValue,
    getVisualisationColorsAndImagesData,
    formattingParams: { formatting },
    isColorByCondition,
  } = useVisualisation({
    sqlData,
    id,
    dataSettings,
    activeIncisionId: dataSettings?.activeIncisionId,
    colorsBy: [colorBySettings, colorByValueSettings, shadowColorBy],
    events,
    limit: dataSettings.limit,
    intervalRandomData: dataSettings.minAndMax,
    sqlRequest,
  });

  const activeIncision = useActiveIncision({
    activeIncisionId: dataSettings.activeIncisionId,
    defaultIncision: defaultLineAndBarDataSettings.incisions[0],
    incisions: dataSettings.incisions,
  });

  const { getColorValues, activeThemeSchema, defaultColor } = useColorValues();

  const lineAndBarData = useMemo(
    () => getLineAndBarData(dataSettings, visualisationNormalizedValues),
    [dataSettings, visualisationNormalizedValues],
  );

  useManualResize({ eChartRef: eChartRef.current, deps: [description, header, dataSettings.incisions.length] });

  /* TODO: combine getItem Value Color and getItem Color */
  const getItemColor = useMemo(
    () =>
      colorBySettings.type !== 'default'
        ? (params: CallbackDataParams) => {
            const { data: dataValue, dataIndex, seriesName } = params as LineAndBarNode,
              alias = colorBySettings.byCondition.alias;

            let value: string | number | null | undefined = dataValue?.value as number;

            if (isColorByCondition(alias)) {
              value = getVisualisationColorsAndImagesData(alias)[dataIndex];
            }

            return getColorByValue({ value, indicatorName: seriesName || '', alias });
          }
        : undefined,
    [colorBySettings, getColorByValue, isColorByCondition, getVisualisationColorsAndImagesData],
  );

  const getIncisionColor = useIncisionColors(lineAndBarData?.activeIncisionSum, activeIncision);

  /* TODO: combine getItem Value Color and getItem Color */
  const getItemValueColor = useCallback(
    (params: ItemValueColor) => {
      const { data, dataIndex, seriesName } = params,
        alias = colorByValueSettings.byCondition.alias;

      let value: string | number | null | undefined = data as number;

      if (isColorByCondition(alias)) {
        value = getVisualisationColorsAndImagesData(alias)[dataIndex];
      }

      return getColorByValue({ value, indicatorName: seriesName || '', alias });
    },
    [colorByValueSettings, getColorByValue, isColorByCondition, getVisualisationColorsAndImagesData],
  );

  const lineAndBarDataResult = useCallback(
    <T extends BarValuePositionType & TopAndBottomType>(
      isBar: boolean,
      seriesName: string,
      position: T,
      isHorizontalOrientation: boolean,
      isPileLabel: boolean,
    ) =>
      lineAndBarData?.data[seriesName]?.map((value, index) => {
        const isDefaultTypeColor = colorByValueSettings.type === 'default',
          positiveNumber = isPositiveNumber(Number(value)),
          itemValueColor = !isDefaultTypeColor ? getItemValueColor({ data: value, dataIndex: index, seriesName }) : undefined;

        const color =
          itemValueColor || getColorValues(properties[seriesName].fontColor) || activeThemeSchema[ColorVarsEnum.Level_1];

        return {
          value: value,
          label: isBar
            ? !isPileLabel
              ? {
                  ...getBarPositionLabel(positiveNumber, isHorizontalOrientation, dataSettings.rotateTo90)[position],
                  color,
                }
              : {
                  color,
                  position: ['50%', dataSettings.rotateTo90 ? '50%' : -15],
                  align: 'center',
                  distance: 5,
                  verticalAlign: 'middle',
                }
            : { ...getLinePositionLabel(dataSettings.rotateTo90, positiveNumber, isHorizontalOrientation)[position], color },
        };
      }),
    [
      lineAndBarData,
      getItemValueColor,
      getColorValues,
      colorByValueSettings,
      properties,
      activeThemeSchema,
      dataSettings.rotateTo90,
    ],
  );

  const series = useMemo<Array<LineSeriesOption | BarSeriesOption>>(() => {
    const lastBarSeriesIndex = dataSettings.indicators.reduce(
      (
        lastIndex,
        {
          settings: {
            elementSettings: { type },
          },
        },
        currentIndex,
      ) => (type === 'bar' ? currentIndex : lastIndex),
      0,
    );

    return dataSettings.indicators.map(
      (
        {
          settings: {
            nameFromDatabase,
            additionalIndicators,
            elementSettings: { type, parameters },
            showValue,
          },
          fieldName,
          name,
          color,
        },
        index,
      ) => {
        const isBar = type === 'bar';

        const seriesName = getVisualisationFieldName({ name, nameFromDatabase, fieldName }),
          isPile = dataSettings.type === 'pile',
          isShowSum = dataSettings.showSum.isShow && index === lastBarSeriesIndex;

        const sameOption = {
          markPoint: {
            symbol: 'circle',
            symbolSize: 200,
            symbolOffset: [13, 13],
            symbolRotate: 23,
          },
          stackStrategy: 'all',
          data: lineAndBarDataResult(
            isBar,
            seriesName,
            showValue.position as BarValuePositionType & TopAndBottomType,
            showValue.orientation === 'horizontal',
            isPile && dataSettings.showSum.isShow,
          ),
        };

        const labelOption = {
          fontSize: properties[name].fontSize,
          fontWeight: properties[name].fontStyle.bold ? 'bold' : 'normal',
          fontStyle: properties[name].fontStyle.italic ? 'italic' : 'normal',
        };

        if (isBar) {
          const pileLabel: BarSeriesOption['label'] =
            isPile && dataSettings.showSum.isShow
              ? {
                  show: isShowSum,
                  formatter: (params) => {
                    if (lineAndBarData.activeIncisionSum) {
                      return String(lineAndBarData.activeIncisionSum?.[params.name]);
                    }

                    return params.value === 0 ? '' : `${params.value}${dataSettings.type === 'nominated' ? ' %' : ''}`;
                  },
                  rotate: dataSettings.showSum.orientation === 'horizontal' ? 0 : 90,
                }
              : {};
          const formattingFunction = formatting[name];

          return {
            name: seriesName,
            type,
            yAxisIndex: !dataSettings.rotateTo90 ? (additionalIndicators ? 1 : 0) : 0,
            xAxisIndex: dataSettings.rotateTo90 ? (additionalIndicators ? 1 : 0) : 0,
            stack: dataSettings.type !== 'group' ? 'value' : undefined,
            label: {
              ...labelOption,
              backgroundColor: showValue.position !== 'outside' ? 'inherit' : 'transparent',
              show: showValue.isShow,
              rotate: showValue.orientation === 'horizontal' ? 0 : 90,
              formatter: (data) => {
                const isEmpty = data.value === 0;

                if (isEmpty) {
                  return '';
                }

                if (formatting && formattingFunction) {
                  return formattingFunction?.(data.value as number | string);
                }

                return `${data.value}${dataSettings.type === 'nominated' ? ' %' : ''}`;
              },
              ...pileLabel,
            },
            itemStyle: {
              color: getItemColor || getIncisionColor || getColorValues(color) || defaultColor,
            },
            ...sameOption,
          } as BarSeriesOption;
        }

        const formattingFunction = formatting[name];

        return {
          name: seriesName,
          type,
          yAxisIndex: !dataSettings.rotateTo90 && additionalIndicators ? 1 : 0,
          xAxisIndex: dataSettings.rotateTo90 && additionalIndicators ? 1 : 0,
          label: {
            ...labelOption,
            formatter: (data) => {
              const isEmpty = data.value === 0;

              if (isEmpty) {
                return '';
              }

              if (formatting && formattingFunction) {
                return formattingFunction?.(data.value as number | string);
              }

              return data.value;
            },
            show: showValue.isShow,
            rotate: showValue.orientation === 'horizontal' ? 0 : 90,
          },
          symbolSize: parameters.dotWidth,
          symbol: 'circle',
          lineStyle: {
            width: parameters.lineWidth,
            opacity: parameters.opacity / 100,
            type: parameters.isDotted ? 'dashed' : 'solid',
          },
          smooth: parameters.lineType === 'smooth',
          step: parameters.lineType === 'stepByStep' ? 'middle' : undefined,
          itemStyle: {
            color: getColorValues(color) || defaultColor,
          },
          ...sameOption,
        } as LineSeriesOption;
      },
    );
  }, [
    dataSettings.indicators,
    dataSettings.rotateTo90,
    dataSettings.type,
    dataSettings.showSum,
    lineAndBarData,
    getColorValues,
    defaultColor,
    getItemColor,
    getIncisionColor,
    formatting,
    lineAndBarDataResult,
    properties,
  ]);

  const xAxisValue = useMemo(
    () =>
      getIncisionAxisConfigWithGridDimensions({
        axisIncisionSettings: axisIncisionSettings,
        fieldName: activeIncision.fieldName,
        activeThemeSchema,
        rotateTo90: dataSettings.rotateTo90,
        data: lineAndBarData.data[activeIncision.fieldName],
      }),
    [activeThemeSchema, activeIncision.fieldName, lineAndBarData.data, axisIncisionSettings, dataSettings.rotateTo90],
  );

  const isNominated = useMemo(() => dataSettings.type === 'nominated', [dataSettings.type]);

  const minAndMaxValues = useMemo(
    () =>
      getMinMaxValues({
        settings: dataSettings.minAndMax,
        isNominated,
        activeIncisionSum: lineAndBarData.activeIncisionSum,
      }),
    [dataSettings.minAndMax, lineAndBarData.activeIncisionSum, isNominated],
  );

  const minAndMaxAdditionalValues = useMemo(
    () =>
      getMinMaxValues({
        settings: dataSettings.minAndMaxAdditional,
        isNominated,
        activeIncisionSum: lineAndBarData.activeIncisionSum,
      }),
    [dataSettings.minAndMaxAdditional, lineAndBarData.activeIncisionSum, isNominated],
  );

  const yAxisValue = useMemo(() => {
    const { minValue, maxValue, maxIndicatorLength } = minAndMaxValues;
    const {
      minValue: minValueAdditional,
      maxValue: maxValueAdditional,
      maxIndicatorLength: maxIndicatorLengthAdditional,
    } = minAndMaxAdditionalValues;

    return getIndicatorAxisWithGridDimensions({
      axisIndicatorSettings: axisIndicatorSettings,
      axisAdditionalIndicatorSettings: axisAdditionalIndicatorSettings,
      rotateTo90: dataSettings.rotateTo90,
      activeThemeSchema,
      mainIndicator: { max: maxValue, min: minValue, maxStringLength: maxIndicatorLength },
      additionalIndicator: { max: maxValueAdditional, min: minValueAdditional, maxStringLength: maxIndicatorLengthAdditional },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeThemeSchema,
    axisIndicatorSettings,
    axisAdditionalIndicatorSettings,
    dataSettings.rotateTo90,
    dataSettings.type,
    minAndMaxAdditionalValues,
    minAndMaxValues,
  ]);

  const axisValues = useMemo(
    () =>
      dataSettings.rotateTo90
        ? { xAxis: yAxisValue.config, yAxis: xAxisValue.config }
        : { xAxis: xAxisValue.config, yAxis: yAxisValue.config },
    [xAxisValue.config, yAxisValue.config, dataSettings.rotateTo90],
  );

  const legendValue = useMemo(
    () =>
      getLegendConfigWithGridDimensions({ indicators: dataSettings.indicators, legendSettings: legendSettings, defaultColor }),
    [dataSettings.indicators, legendSettings, defaultColor],
  );

  const { onDataZoom, zoomValues } = useDataZoom({
    data: lineAndBarData.data,
    legendSettings,
    horizontalZoom,
    verticalZoom,
    dataZoomHorizontalStartAndEnd,
    dataZoomVerticalStartAndEnd,
    onDataZoomHorizontalStartAndEndSettingsChange,
    onDataZoomVerticalStartAndEndSettingsChange,
  });

  const { horizontalSliderXZoomValue, horizontalInsideYZoomValue, verticalInsideXZoomValue, verticalSliderYZoomValue } =
    zoomValues;

  const showSumGridDimensions = useMemo<GridDimensionsInterface>(() => {
    const isPile = dataSettings.type === 'pile',
      { isShow, orientation } = dataSettings.showSum;

    const orientationGridDimensions = {
      vertical: { left: 0, right: 0, top: 30, bottom: 0 },
      horizontal: { left: 0, right: 0, top: 15, bottom: 0 },
    };

    return !dataSettings.rotateTo90 && isPile && isShow ? orientationGridDimensions[orientation] : initialDimensions;
  }, [dataSettings.type, dataSettings.showSum, dataSettings.rotateTo90]);

  const barGraphGridDimensions = useMemo<GridDimensionsInterface>(
    () => getBarGraphGridDimensions(dataSettings.indicators),
    [dataSettings.indicators],
  );

  const lineGraphGridDimensions = useMemo<GridDimensionsInterface>(() => {
    let isActiveGrid = false;

    dataSettings.indicators.forEach(
      ({
        settings: {
          elementSettings: { type },
          showValue: { isShow, position },
        },
      }) => {
        if (!isActiveGrid) {
          isActiveGrid =
            type === 'line' &&
            isShow &&
            (dataSettings.rotateTo90 ? (position as TopAndBottomType) === 'bottom' : (position as TopAndBottomType) === 'top');
        }
      },
    );

    const shiftPosition = dataSettings.rotateTo90 ? 'right' : 'top';

    return isActiveGrid ? { ...initialDimensions, [shiftPosition]: 20 } : initialDimensions;
  }, [dataSettings.indicators, dataSettings.rotateTo90]);

  const gridValue = useMemo<LineAndBarEChartsOption['grid']>(
    () =>
      calculateGridDimension([
        defaultGridDimension,
        legendValue.gridDimensions,
        horizontalInsideYZoomValue.gridDimensions,
        verticalInsideXZoomValue.gridDimensions,
        showSumGridDimensions,
        lineGraphGridDimensions,
        barGraphGridDimensions,
        xAxisValue.gridDimensions,
        yAxisValue.gridDimensions,
      ]),
    [
      legendValue.gridDimensions,
      horizontalInsideYZoomValue.gridDimensions,
      verticalInsideXZoomValue.gridDimensions,
      showSumGridDimensions,
      lineGraphGridDimensions,
      barGraphGridDimensions,
      xAxisValue.gridDimensions,
      yAxisValue.gridDimensions,
    ],
  );

  const tooltipFormatter = createTooltipFormatter({
    formatting,
  });

  const tooltipValue = useMemo<LineAndBarEChartsOption['tooltip']>(
    () => ({
      formatter: tooltipFormatter,
      show: showTips,
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
        crossStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
    }),
    [showTips, tooltipFormatter, activeThemeSchema],
  );

  const option = useMemo<LineAndBarEChartsOption>(
    () => ({
      textStyle: {
        color: defaultColor,
      },
      series: series as LineAndBarEChartsOption['series'],
      tooltip: tooltipValue,
      legend: legendValue.config,
      grid: visualisationPaddingsIsShow
        ? {
            top: topPadding,
            bottom: bottomPadding,
            left: leftPadding,
            right: rightPadding,
          }
        : gridValue,
      dataZoom: [
        horizontalSliderXZoomValue.config,
        verticalSliderYZoomValue.config,
        horizontalInsideYZoomValue.config,
        verticalInsideXZoomValue.config,
      ],
      ...(axisValues as DefaultAxisInterface),
    }),
    [
      series,
      tooltipValue,
      legendValue.config,
      horizontalSliderXZoomValue.config,
      horizontalInsideYZoomValue.config,
      verticalInsideXZoomValue.config,
      verticalSliderYZoomValue.config,
      axisValues,
      defaultColor,
      visualisationPaddingsIsShow,
      topPadding,
      bottomPadding,
      leftPadding,
      rightPadding,
      gridValue,
    ],
  );

  const onChartClick = useCallback(
    (params: CallbackDataParams) => {
      updateFilter({ selectedValue: params.name, fieldName: activeIncision.data.fieldName });
    },
    [activeIncision.data.fieldName, updateFilter],
  );

  const onEvents = {
    dataZoom: onDataZoom,
    click: onChartClick,
  };

  return (
    <SingleIncisionLayout
      incisions={dataSettings.incisions}
      value={dataSettings.activeIncisionId}
      incisionSelectorPosition={incisionSelectorPosition}
      onChange={(activeIncisionId) => activeIncisionId && onActiveIncisionIdChange({ activeIncisionId, id })}
    >
      <WrappedReactECharts
        onEvents={onEvents}
        notMerge
        ref={(e) => {
          eChartRef.current = e?.getEchartsInstance();
        }}
        style={{ width: '100%', height: '100%' }}
        option={option}
      />
    </SingleIncisionLayout>
  );
};

export const LineAndBarVisualisation = memo(
  LineAndBarVisualisationComponent,
) as VisualisationOriginInterface<LineAndBarVisualisationType>;
