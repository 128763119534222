import React from 'react';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { DefaultViewSettings } from 'modules/settingsContainer/common/view/DefaultViewSettings';
import { defaultTableViewSettings } from 'store/reducers/visualisations/constants';
import { BooleanSettings } from 'modules/settingsContainer/common/BooleanSettings';
import {
  onAdaptiveChange,
  onCellPaddingChange,
  onHeaderBeatChange,
  onIncisionBeatChange,
  onRowBeatChange,
  onShowHorizontalLineChange,
  onShowVerticalLineChange,
  onSubtotalsSettingsChange,
  onTotalBeatChange,
  onTotalRowChange,
} from 'modules/visualisations/Table/settings/ViewTab/constants';
import { BeatBySettings } from 'modules/settingsContainer/common/BeatBySettings';
import { CellPaddingSettings } from 'modules/settingsContainer/common/view/CellPaddingSettings';
import { TotalRowSettings } from 'modules/settingsContainer/common/view/TotalRowSettings';
import { TotalSettings } from './TotalSettings';
import { totalRowPositionOptions } from 'modules/settingsContainer/common/view/TotalRowSettings/constants';
import { MainContainerSettings } from 'modules/settingsContainer';

export const ViewTab = () => {
  const { viewSettings, positionConfig } = useGetActiveVisualisationSettings({
    defaultView: defaultTableViewSettings,
  });

  return (
    <>
      <DefaultViewSettings
        viewSettings={viewSettings}
        positionConfig={positionConfig}
        ThirdSlot={
          <>
            <BooleanSettings titleText="Адаптивная таблица" value={viewSettings.isAdaptive} onChange={onAdaptiveChange} />
            <BooleanSettings
              titleText="Вертикальные линии"
              value={viewSettings.showVerticalLine}
              onChange={onShowVerticalLineChange}
            />
            <BooleanSettings
              titleText="Горизонтальные линии"
              value={viewSettings.showHorizontalLine}
              onChange={onShowHorizontalLineChange}
            />
            <BeatBySettings
              titleText="Отбивка столбца разрезов"
              value={viewSettings.incisionBeat}
              onChange={onIncisionBeatChange}
            />
            <BeatBySettings titleText="Отбивка шапки" value={viewSettings.headerBeat} onChange={onHeaderBeatChange} />
            <BooleanSettings titleText="Отбивка строк" value={viewSettings.isRowBeat} onChange={onRowBeatChange} />
            <BeatBySettings titleText="Отбивка итогов" value={viewSettings.totalBeat} onChange={onTotalBeatChange} />
            <MainContainerSettings titleText={'Строка подитогов'}>
              <TotalSettings
                titleLocation={'Изменить расположение'}
                value={viewSettings.subtotalsSettings}
                onChange={onSubtotalsSettingsChange}
                options={totalRowPositionOptions}
              />
            </MainContainerSettings>
            <TotalRowSettings value={viewSettings.totalRowSettings} onChange={onTotalRowChange} />
            <CellPaddingSettings value={viewSettings.cellPaddingSettings} onChange={onCellPaddingChange} />
          </>
        }
      />
    </>
  );
};
