import React, { memo } from 'react';
import Checkbox, { CheckTypeType } from 'modules/ui/Checkbox';
import { NoopType, NoopValueType } from 'types/global';
import { FlexContainer } from 'styles/FlexContainer';
import { CommonItemComponent, CommonItemProps } from 'modules/ui/lists/MapList/item/CommonItem';
import { iconContainerWidth } from 'modules/ui/lists/MapList/item/CommonItem/constants';

interface ListItemProps extends Omit<CommonItemProps, 'afterSlot'> {
  isChecked?: boolean;
  id?: string;
  onChecked?: NoopType;
  onChange?: NoopValueType<boolean>;
  onDeleteItem?: NoopValueType<{ id: string; title: string }>;
  disabledCheckbox?: boolean;
  checkType?: CheckTypeType;
}

export const ListItemComponent = ({
  title,
  Icon,
  isCheckboxLast,
  isChecked,
  id,
  onChange,
  activeSelected,
  onDeleteItem,
  onChecked,
  disabled,
  disabledCheckbox = false,
  checkType,
}: ListItemProps) => {
  const onToggleChecked = () => {
    onChange && onChange(!isChecked);
  };

  const onToggleDeleteItem = () => {
    onDeleteItem && id && onDeleteItem({ id, title });
  };

  return (
    <CommonItemComponent
      title={title}
      activeSelected={activeSelected}
      Icon={Icon}
      isCheckboxLast={isCheckboxLast}
      titleType="normal"
      onClick={onChecked}
      onDelete={onDeleteItem && onToggleDeleteItem}
      disabled={disabled}
      afterSlot={
        !disabledCheckbox &&
        id && (
          <FlexContainer
            width={iconContainerWidth}
            alignItems="center"
            justifyContent="center"
            onClick={(e) => {
              !onChecked && e.stopPropagation();
              onToggleChecked();
            }}
          >
            <Checkbox
              disabled={disabled}
              padding="0"
              name={`list_item_checkbox_${id}`}
              checked={isChecked}
              checkType={checkType}
              label=""
              id={id}
              preventDefault
            />
          </FlexContainer>
        )
      }
    />
  );
};

export const ListItem = memo(ListItemComponent);
