import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import { UploadProjectScreenPayload } from 'store/reducers/projectSettings/types';
import { IdInterface } from 'types/store';

export const loadProjectSettings = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectSettingsResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/settings`);

export const loadDefaultModelId = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectDefaultModelIdResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/defaultModelId`);

export const uploadDashboardSettings = (
  {
    projectId,
    payload,
  }: {
    projectId: string;
    payload: FastBoard.API.UpdateDashboardSettingsDTO;
  },
  config?: AxiosRequestConfig,
) =>
  axiosClient.put<string, AxiosResponse<string>, FastBoard.API.UpdateDashboardSettingsDTO>(
    `/api/v3/project/${projectId}/dashboard-settings`,
    payload,
    config,
  );

export const uploadProjectScreen = ({ projectId, screen }: UploadProjectScreenPayload) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ProjectResponseDTO>>(`/api/v1/projects/screen/${projectId}`, screen);

export const loadLastSettingsTimestamp = (projectId: string): Promise<AxiosResponse<string>> =>
  axiosClient.get(`/api/v3/project/${projectId}/last-settings-timestamp`);

export const loadProjectTask = (projectId: string): Promise<AxiosResponse<FastBoard.API.ApiCronJobItemWithIdResponseDTO>> =>
  axiosClient.get(`/api/v1/cronjob/${projectId}`);

export const createProjectTask = ({ id, ...params }: FastBoard.API.CronJobCreateDTO & IdInterface) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiCronJobItemWithIdResponseDTO>, FastBoard.API.CronJobCreateDTO>(
    `/api/v1/cronjob/${id}`,
    params,
  );

export const updateProjectTask = ({ id, ...params }: FastBoard.API.CronJobCreateDTO & IdInterface) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiCronJobItemWithIdResponseDTO>, FastBoard.API.CronJobCreateDTO>(
    `/api/v1/cronjob/${id}`,
    params,
  );
