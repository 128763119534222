import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActivePageId } from 'store/reducers/projectPages/getters';
import { TState } from 'store/index';
import { v4 } from 'uuid';
import {
  addNewFilter,
  addNewFilterByData,
  disableFilterById,
  enableFilter,
  removeFilterById,
  removeFilterValuesById,
  removeFromFilterLoadingListById,
  setSlice,
  updateEnabledFilter,
  updateFilter,
} from '.';
import {
  DateEnabledFilterInterface,
  DateFilterInterface,
  EnabledFilterDataType,
  EnabledFilterIdsByPageInterface,
  EnableFilterActionPayload,
  FilterActionsTypes,
  FilterDataType,
  FilterIdsByPageInterface,
  FiltersValuesType,
  FilterType,
  LoadEnabledFiltersOutput,
  LoadFieldsPayload,
  LoadFieldsResponseInterface,
  LoadFiltersOutput,
  LoadGlobalEnabledFiltersOutput,
  MultipleEnabledFilterInterface,
  MultipleFilterInterface,
  SingleEnabledFilterInterface,
  SingleFilterInterface,
  UpdateEnabledFilterPayload,
  UpdateFilterPayload,
} from 'store/reducers/filters/types';
import { setActiveBoardElement } from 'store/reducers/board';
import { getActiveBoardElement } from 'store/reducers/board/getters';
import { loadEnabledFiltersByPageId, loadFiltersByPageId, loadGlobalEnabledFiltersByPageId } from 'store/reducers/filters/api';
import { mergeByReference } from 'utils/utils';
import {
  getEnabledFilterByFilterId,
  getEnabledFiltersAlreadyLoaded,
  getFiltersAlreadyLoaded,
} from 'store/reducers/filters/getters';
import { initialFilterStoreState, referenceEnabledFilter, referenceFilters } from 'store/reducers/filters/constants';
import { loadDataBySql } from 'store/reducers/visualisations/api';
import { generateSqlFullQuery } from 'utils/SQL/generateSQL';
import { addToLayerAction, deleteFromLayerByIdAction } from 'store/reducers/board/actions';
import { PageIdInterface, ProjectIdWithType } from 'types/store';
import { SettingsSnapshotType } from 'store/reducers/projectSettings/settingsSnapshotService';
import { generateILike, getWhereString, sqlParser } from 'utils/SQL/genereteAst';
import { Select } from 'node-sql-parser';
import { AST } from 'types/ast';
import { combineSqlStrings } from 'utils/SQL/formatSQL';

/* TODO: Adding types and interface for Error Messages  */
const validateError = (err: AxiosError, rejectWithValue?: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue?.(errorMessage);
};

export const loadFiltersAction = createAsyncThunk(
  FilterActionsTypes.LOAD_FILTERS,
  async (params: ProjectIdWithType<PageIdInterface>, { signal, getState, dispatch }) => {
    const filtersAlreadyLoaded = getFiltersAlreadyLoaded(params.pageId)(getState() as TState);

    if (!filtersAlreadyLoaded) {
      const request = dispatch(loadFiltersByPageIdAction(params));

      signal.addEventListener('abort', () => {
        request.abort();
      });
    }
  },
);

export const loadEnabledFiltersAction = createAsyncThunk(
  FilterActionsTypes.LOAD_ENABLED_FILTERS,
  async (params: ProjectIdWithType<PageIdInterface>, { signal, getState, dispatch }) => {
    const enabledFiltersAlreadyLoaded = getEnabledFiltersAlreadyLoaded(params.pageId)(getState() as TState);

    if (!enabledFiltersAlreadyLoaded) {
      const request = dispatch(loadEnabledFiltersByPageIdAction(params));

      signal.addEventListener('abort', () => {
        request.abort();
      });
    }
  },
);

export const loadFiltersByPageIdAction = createAsyncThunk<LoadFiltersOutput, ProjectIdWithType<PageIdInterface>>(
  FilterActionsTypes.LOAD_FILTERS_BY_PAGE_ID,
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await loadFiltersByPageId(params, { signal }),
        originFilters = response.data.filters;

      /* Merging reference filter data with filters data stored on the BE. When new data (new features) is added to the filter, this data will be synchronized with the BE data. */
      const filters = mergeByReference({
        originData: originFilters,
        getReferenceByType: (type) => referenceFilters[type as FilterType],
        typeKey: 'type',
      });

      const filtersByPages = { [params.pageId]: new Set(originFilters.map(({ id }) => id)) };

      return { filters, filtersByPages };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue({ filters: {}, filtersByPages: {} });
    }
  },
);

export const loadFiltersFromSnapshotAction = createAsyncThunk<LoadFiltersOutput, SettingsSnapshotType['filters']>(
  FilterActionsTypes.LOAD_FILTERS_FROM_SNAPSHOT,
  (filters) => {
    const originFilters = filters.reduce<FilterDataType[]>(
      (result, data) => [...result, ...data.filters],
      [] as FilterDataType[],
    );

    const mergedFilters = mergeByReference({
      originData: originFilters,
      getReferenceByType: (type) => referenceFilters[type as FilterType],
      typeKey: 'type',
    });

    const filtersByPages = filters.reduce<FilterIdsByPageInterface>(
      (result, { pageId, filters }) => ({ ...result, [pageId]: new Set<string>(filters.map(({ id }) => id)) }),
      {},
    );

    return { filters: mergedFilters, filtersByPages };
  },
);

export const loadEnabledFiltersByPageIdAction = createAsyncThunk<LoadEnabledFiltersOutput, ProjectIdWithType<PageIdInterface>>(
  FilterActionsTypes.LOAD_ENABLED_FILTER_BY_PAGE_ID,
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await loadEnabledFiltersByPageId(params, { signal }),
        originEnabledFilters = response.data.enabledFilters.filter((filter) => !filter.isGlobal);

      /* Merging reference filter data with filters data stored on the BE. When new data (new features) is added to the filter, this data will be synchronized with the BE data. */
      const enabledFilters = mergeByReference({
        originData: originEnabledFilters,
        getReferenceByType: (type) => referenceEnabledFilter[type as FilterType],
        typeKey: 'type',
      });

      const enabledFiltersByPages = { [params.pageId]: new Set(originEnabledFilters.map(({ id }) => id)) };

      return { enabledFilters, enabledFiltersByPages };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue({ enabledFilters: {}, enabledFiltersByPages: {} });
    }
  },
);

export const loadGlobalEnabledFiltersAction = createAsyncThunk<LoadGlobalEnabledFiltersOutput, string>(
  FilterActionsTypes.LOAD_GLOBAL_ENABLED_FILTER_BY_PAGE_ID,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await loadGlobalEnabledFiltersByPageId(projectId),
        originGlobalEnabledFilters = response.data.globalEnabledFilters;

      const globalEnabledFilters = mergeByReference({
        originData: originGlobalEnabledFilters,
        getReferenceByType: (type) => referenceEnabledFilter[type as FilterType],
        typeKey: 'type',
      });

      const enabledFiltersByPages = originGlobalEnabledFilters.reduce<EnabledFilterIdsByPageInterface>(
        (result, { pageId, id }) => {
          const oldEnabledFiltersByPages = result[pageId] || new Set<string>();
          oldEnabledFiltersByPages.add(id);

          return { ...result, [pageId]: oldEnabledFiltersByPages };
        },
        {},
      );

      return { enabledFilters: globalEnabledFilters, enabledFiltersByPages };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue({ enabledFilters: {}, enabledFiltersByPages: {} });
    }
  },
);

export const loadEnabledFiltersFromSnapshotAction = createAsyncThunk<
  LoadEnabledFiltersOutput,
  SettingsSnapshotType['enabledFilters']
>(FilterActionsTypes.LOAD_ENABLED_FILTER_FROM_SNAPSHOT, (enabledFilters) => {
  const originEnabledFilters = enabledFilters.reduce<EnabledFilterDataType[]>(
    (result, data) => [...result, ...data.enabledFilters],
    [] as EnabledFilterDataType[],
  );

  const mergedEnabledFilters = mergeByReference({
    originData: originEnabledFilters,
    getReferenceByType: (type) => referenceEnabledFilter[type as FilterType],
    typeKey: 'type',
  });

  const enabledFiltersByPages = enabledFilters.reduce<EnabledFilterIdsByPageInterface>(
    (result, { pageId, enabledFilters }) => ({ ...result, [pageId]: new Set<string>(enabledFilters.map(({ id }) => id)) }),
    {},
  );

  return { enabledFilters: mergedEnabledFilters, enabledFiltersByPages };
});

export const clearFiltersStore = createAsyncThunk(FilterActionsTypes.CLEAR_FILTERS_STORE, (_, { dispatch }) => {
  dispatch(setSlice(initialFilterStoreState));
});

export const addFilterAction = createAsyncThunk(FilterActionsTypes.ADD_FILTER, (type: FilterType, { getState, dispatch }) => {
  const pageId = getActivePageId(getState() as TState) || '',
    id = v4();

  dispatch(addNewFilter({ type, pageId, id }));
  dispatch(addToLayerAction(id));
  dispatch(setActiveBoardElement(id));
});

export const addFilterByDataAction = createAsyncThunk(FilterActionsTypes.ADD_FILTER, (data: FilterDataType, { dispatch }) => {
  dispatch(addNewFilterByData(data));
});

export const removeFilterValuesAction = createAsyncThunk<void, string>(
  FilterActionsTypes.REMOVE_FILTER_VALUES,
  (id, { dispatch }) => {
    dispatch(removeFilterValuesById(id));
  },
);

export const removeFromFilterLoadingListAction = createAsyncThunk<void, string>(
  FilterActionsTypes.REMOVE_FROM_FILTER_LOADING_LIST,
  (id, { dispatch }) => {
    dispatch(removeFromFilterLoadingListById(id));
  },
);

export const removeFilterByIdAction = createAsyncThunk<void, string>(
  FilterActionsTypes.REMOVE_FILTER_ID,
  (id: string, { dispatch, getState }) => {
    dispatch(removeFilterById({ id }));
    dispatch(removeFilterValuesAction(id));
    dispatch(removeFromFilterLoadingListAction(id));
    dispatch(deleteFromLayerByIdAction(id));

    const enabledFilter = getEnabledFilterByFilterId(id)(getState() as TState);
    if (enabledFilter) {
      dispatch(disableFilterByIdAction(enabledFilter.id));
    }
  },
);

export const updateTypedFilterActionById = <FilterInterface extends FilterDataType = FilterDataType>(
  params: UpdateFilterPayload<FilterInterface>,
) =>
  createAsyncThunk<void, UpdateFilterPayload<FilterInterface>>(FilterActionsTypes.UPDATE_FILTER, ({ id, data }, { dispatch }) => {
    dispatch(updateFilter({ id, data }));
  })(params);

export const updateFilterActionById = updateTypedFilterActionById;
export const updateSingleFilterActionById = updateTypedFilterActionById<SingleFilterInterface>;
export const updateMultipleFilterActionById = updateTypedFilterActionById<MultipleFilterInterface>;
export const updateDateFilterActionById = updateTypedFilterActionById<DateFilterInterface>;

export const updateTypedFilterAction = <FilterInterface extends FilterDataType = FilterDataType>(
  params: Partial<UpdateFilterPayload<FilterInterface>['data']>,
) =>
  createAsyncThunk<void, Partial<UpdateFilterPayload<FilterInterface>['data']>>(
    FilterActionsTypes.UPDATE_FILTER,
    (data, { dispatch, getState }) => {
      const id = getActiveBoardElement(getState() as TState);

      id && dispatch(updateTypedFilterActionById<FilterInterface>({ id, data }));
    },
  )(params);

export const updateFilterAction = updateTypedFilterAction;
export const updateSingleFilterAction = updateTypedFilterAction<SingleFilterInterface>;
export const updateMultipleFilterAction = updateTypedFilterAction<MultipleFilterInterface>;
export const updateDateFilterAction = updateTypedFilterAction<DateFilterInterface>;

export const typedEnableFilterAction = <EnabledFilterInterface extends EnabledFilterDataType>(
  params: EnableFilterActionPayload<EnabledFilterInterface>,
) =>
  createAsyncThunk<void, EnableFilterActionPayload<EnabledFilterInterface>>(
    FilterActionsTypes.ENABLE_FILTER,
    (data, { dispatch, getState }) => {
      const pageId = getActivePageId(getState() as TState) || '',
        id = v4();

      dispatch(enableFilter({ id, pageId, ...data } as EnabledFilterDataType));
    },
  )(params);

export const enableFilterAction = typedEnableFilterAction;
export const enableSingleFilterAction = typedEnableFilterAction<SingleEnabledFilterInterface>;
export const enableMultipleFilterAction = typedEnableFilterAction<MultipleEnabledFilterInterface>;
export const enableDateFilterAction = typedEnableFilterAction<DateEnabledFilterInterface>;

export const typedUpdateEnabledFilterAction = <EnabledFilterInterface extends EnabledFilterDataType>(
  params: UpdateEnabledFilterPayload<EnabledFilterInterface>,
) =>
  createAsyncThunk<void, UpdateEnabledFilterPayload<EnabledFilterInterface>>(
    FilterActionsTypes.UPDATE_ENABLED_FILTER,
    (params, { dispatch }) => {
      dispatch(updateEnabledFilter(params));
    },
  )(params);

export const updateEnabledFilterAction = typedUpdateEnabledFilterAction;
export const enableEnabledSingleFilterAction = typedUpdateEnabledFilterAction<SingleEnabledFilterInterface>;
export const enableEnabledMultipleFilterAction = typedUpdateEnabledFilterAction<MultipleEnabledFilterInterface>;
export const enableEnabledDateFilterAction = typedUpdateEnabledFilterAction<DateEnabledFilterInterface>;

export const disableFilterByIdAction = createAsyncThunk<void, string>(FilterActionsTypes.DISABLE_FILTER, (id, { dispatch }) => {
  dispatch(disableFilterById(id));
});

export const loadFieldsAction = createAsyncThunk<
  FiltersValuesType,
  LoadFieldsPayload & { isDataFilter?: boolean; sqlData: string | null },
  {
    rejectValue: string;
  }
>(
  FilterActionsTypes.LOAD_FILTER_VALUES,
  async (
    { projectId, fieldName, isDataFilter = false, fromQuery, whereQuery, modelId, searchString, sqlData },
    { rejectWithValue, signal },
  ) => {
    try {
      const whereString = whereQuery && whereQuery !== '' ? whereQuery : '',
        { where } = sqlParser.astify(`SELECT * ${whereString}`) as Select;

      const filterString = searchString && searchString !== '' ? `%${searchString.toLowerCase()}%` : null;

      let unionWhere: AST.UnionAndExpressions | AST.FunctionType | null = null;

      if (filterString) {
        unionWhere = generateILike({ fieldName: 'value', filterString });
      }

      if (where) {
        unionWhere =
          unionWhere !== null
            ? {
                type: 'binary_expr',
                left: where,
                operator: 'AND',
                right: unionWhere,
              }
            : where;
      }

      const unionWhereQuery = unionWhere !== null ? getWhereString(unionWhere) : undefined;

      const sql =
        generateSqlFullQuery({
          selectQuery: `SELECT ${fieldName} as value, COUNT(*) as count`,
          fromQuery,
          orderByQuery: `ORDER BY ${fieldName} ASC`,
          whereQuery: unionWhereQuery,
          groupByQuery: `GROUP BY ${fieldName}`,
          limitQuery: 'LIMIT 2000',
        }) || '';

      const selectQueryDataFilter = `SELECT '"' || toString(toDate(Min(${fieldName}))) || '", "' || toString(toDate(Max(${fieldName}))) || '"' as value`;

      const sqlDataFilter =
        generateSqlFullQuery({
          selectQuery: selectQueryDataFilter,
          fromQuery,
          whereQuery: unionWhereQuery,
        }) || '';

      // const finalSql = combineSqlStrings(isDataFilter ? sqlDataFilter : sql, sqlData);

      const response = await loadDataBySql<LoadFieldsResponseInterface>(
        { project: projectId, sql: isDataFilter ? sqlDataFilter : sql, modelId },
        { signal },
      );

      let filteredData: FiltersValuesType = [];

      if (response.data?.value && response.data?.count) {
        /* TODO: If on BE will converting value to string - delete code for converting to string on FE */
        filteredData = response.data?.value.reduce<FiltersValuesType>((result, value, valueIndex) => {
          if (value !== null) {
            return [...result, { value: String(value), count: response.data?.count?.[valueIndex] || 0 }];
          }

          return result;
        }, []);
      }

      if (response.data?.value && isDataFilter) {
        const value = response.data?.value.map((date) => String(date).replace(/"/g, ''));

        filteredData = value[0].split(', ').reduce<FiltersValuesType>((result, value, valueIndex) => {
          if (response.data?.value !== null) {
            return [...result, { value: String(value), count: response.data?.count?.[valueIndex] || 0 }];
          }

          return result;
        }, []);
      }

      return filteredData.sort((a, b) => (a.value > b.value ? 1 : a.value === b.value ? 0 : -1));
    } catch (err: any) {
      const errorMessage: string = err?.response?.data?.message;
      return rejectWithValue(errorMessage);
    }
  },
);
