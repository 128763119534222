import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import { PageIdInterface, ProjectIdWithType } from 'types/store';

export const loadFiltersByPageId = (
  { projectId, pageId }: ProjectIdWithType<PageIdInterface>,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<FastBoard.API.FiltersV3>> =>
  axiosClient.get(`/api/v3/project/${projectId}/page/${pageId}/filters`, config);

export const loadGlobalEnabledFiltersByPageId = (
  projectId: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<FastBoard.API.GlobalEnabledFiltersV3>> =>
  axiosClient.get(`/api/v3/project/${projectId}/globalEnabledFilters`, config);

export const loadEnabledFiltersByPageId = (
  { projectId, pageId }: ProjectIdWithType<PageIdInterface>,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<FastBoard.API.EnabledFiltersV3>> =>
  axiosClient.get(`/api/v3/project/${projectId}/page/${pageId}/enabledFilters`, config);
