import { BarType } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import React from 'react';
import { typeOptions } from 'modules/settingsContainer/common/data/TypeOfLineAndBarSettings/constants';

type TypeOfLineAndBarSettingsProps = OnValueChange<BarType> & { disabled: boolean };

export const TypeOfLineAndBarSettings = ({ value, onChange, disabled }: TypeOfLineAndBarSettingsProps) => {
  const options = typeOptions(disabled);

  return (
    <MainContainerSettings titleText="Тип">
      <SettingsRadio
        onChange={({ value }) => onChange(value)}
        activeValue={getActiveRadioValue(options, value)}
        options={options}
      />
    </MainContainerSettings>
  );
};
