import { FC, OnValueChange } from 'types/global';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { FilterElementsInterface } from 'modules/settingsContainer/FilterElementsSettings/types';
import { FictionalMetricField, FictionalMetricFieldProps } from 'modules/settingsContainer/common/FictionalMetricField';
import { FlexContainer } from 'styles/FlexContainer';
import React from 'react';

interface FilterElementsSettingsProps
  extends OnValueChange<FilterElementsInterface>,
    Pick<FictionalMetricFieldProps, 'isRealData' | 'options'> {}

export const FilterElementsSettings: FC<FilterElementsSettingsProps> = ({ isRealData, value, onChange, options, children }) => {
  const { fictionalData, nameSettings } = value;

  return (
    <MainContainerSettings titleText="Элементы фильтра">
      <FictionalMetricField
        onChange={({ fictionalData, fieldName }) => {
          fictionalData && onChange({ ...value, fictionalData });
          fieldName && onChange({ ...value, nameSettings: { ...nameSettings, fieldName } });
        }}
        value={{ fictionalData, fieldName: nameSettings.fieldName }}
        isRealData={isRealData}
        options={options}
        isFunctionButtonHide
      />
      {isRealData && <FlexContainer width="100%">{children}</FlexContainer>}
    </MainContainerSettings>
  );
};
