import { TaskInterface } from 'store/reducers/adminTasks/types';
import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { DetailsItem } from 'modules/ui/DetailsItem';
import { formatDateString } from 'utils/dates';
import { defaultDateTimeFormat } from 'constants/dates';
import { statusData } from 'components/admin/wrappers/ActiveDirectoriesAndTasksWrapper/constants';

type DetailsUiProps = {
  data?: TaskInterface | null;
};

export const Details: FC<DetailsUiProps> = ({ data }) => (
  <FlexContainer padding="16px" flexDirection="column" gap="16px">
    <DetailsItem title="Проект" description={data?.name} />
    <DetailsItem title="ID проекта" description={data?.id} />
    <DetailsItem
      title="Дата начала"
      description={formatDateString({ dateString: data?.startedTask, format: defaultDateTimeFormat })}
    />
    <DetailsItem
      title="Дата завершения"
      description={formatDateString({ dateString: data?.finishedTask, format: defaultDateTimeFormat })}
    />
    <DetailsItem title="Продолжительность" description={data?.duration} />
    <DetailsItem
      title="Статус"
      description={data?.status ? statusData[data.status]?.name : '-'}
      color={data?.status ? statusData[data.status]?.color : '-'}
    />
    <DetailsItem title="Сообщение" description={data?.message} />
  </FlexContainer>
);
