import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { OnValueChange } from 'types/global';
import Select from 'modules/ui/Select';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { getModelsFromAsArray } from 'store/reducers/models/getters';
import { useModelIdValue } from 'utils/hooks/visualisation/modelIdValue';

interface ModelSelectorSettingsProps extends OnValueChange<string | null> {
  title?: string;
}

export const ModelSelectorSettings = ({ title = 'Модель данных', value, onChange }: ModelSelectorSettingsProps) => {
  const modelsFrom = useSelector(getModelsFromAsArray);

  const modelIdValue = useModelIdValue(value);

  return (
    <MainContainerSettings titleText={title}>
      <GroupContainerSettings>
        <FlexContainer width="100%" justifyContent="flex-start">
          <Select
            needBackground={false}
            placeholder="Выбрать модель"
            name="model"
            options={modelsFrom.map(({ id, name }) => ({
              value: id,
              name,
            }))}
            width="60%"
            value={modelIdValue}
            onChange={onChange}
          />
        </FlexContainer>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
