import {
  AxisSettingsInterface,
  BarValuePositionType,
  LineAndBarDataSettings,
  LineAndBarIndicatorInterface,
  VisualisationValuesInterface,
} from 'store/reducers/visualisations/types';
import { TopAndBottomType } from 'types/styles';
import { getActiveIncisionById, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ThemeColorsInterface } from 'store/reducers/themes/types';
import { ConfigWithGridDimensionsInterface, GridDimensionsInterface } from 'types/echarts';
import { LineAndBarEChartsOption } from 'modules/visualisations/LineAndBar/visualisation/types';
import { calculateGridDimension, getLengthOfGraphicString, initialDimensions } from 'utils/generateConfigGraphic';
import { getAxisNameLocation, getNameIndicatorTextPadding } from 'modules/visualisations/common/constants';

export const getPercentValue = (value: string | number | null, sum: number) => {
  if (value === 0 || value === null || sum === 0) {
    return 0;
  }

  const numericValue = typeof value === 'string' ? 1 : value;
  return Math.floor((numericValue / sum) * 1000000) / 10000;
};

export const getLineAndBarData = (
  { incisions, indicators, activeIncisionId, type }: LineAndBarDataSettings,
  visualisationValues: VisualisationValuesInterface,
) => {
  const activeIncision = getActiveIncisionById(activeIncisionId, incisions);

  if (activeIncision === null) {
    return { data: {}, activeIncisionSum: {} };
  }

  const {
      name,
      fieldName,
      settings: { nameFromDatabase, isEmptyValues },
    } = activeIncision,
    activeIncisionFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase }),
    activeIncisionValues = (visualisationValues[activeIncisionFieldName] || []) as Array<string | number>;

  const activeIncisionValue: VisualisationValuesInterface = {
    [activeIncisionFieldName]: (isEmptyValues ? activeIncisionValues?.filter((value) => !!value) : activeIncisionValues) as
      | string[]
      | number[],
  };

  const indicatorsPreparedValues: VisualisationValuesInterface = indicators.reduce(
    (values, { name, fieldName, settings: { nameFromDatabase } }) => {
      const indicatorFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

      return {
        ...values,
        [indicatorFieldName]: visualisationValues[indicatorFieldName],
      };
    },
    {},
  );

  const isNominatedType = type === 'nominated';

  const activeIncisionSumArray: [string | number, number][] = [];
  const arrayLength = activeIncisionValue[activeIncisionFieldName]?.length || [];

  for (let index = 0; index < arrayLength; index++) {
    const key = (activeIncisionValue[activeIncisionFieldName] || [])[index] as string | number;

    let sum = 0;

    for (const indicator of indicators) {
      const {
        name,
        fieldName,
        settings: {
          nameFromDatabase,
          elementSettings: { type },
        },
      } = indicator;
      const indicatorFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
      const value = indicatorsPreparedValues[indicatorFieldName]?.[index] || 0;

      sum += type === 'bar' ? (typeof value === 'string' ? 1 : value) : 0;
    }
    activeIncisionSumArray.push([key, sum]);
  }

  const activeIncisionSum: Record<string, number> = Object.fromEntries(activeIncisionSumArray);

  const indicatorsValues: VisualisationValuesInterface = indicators.reduce(
    (values, { name, fieldName, settings: { nameFromDatabase } }) => {
      const indicatorFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

      const indicatorValues = indicatorsPreparedValues[indicatorFieldName] || [];

      return {
        ...values,
        [indicatorFieldName]: isNominatedType
          ? indicatorValues.map((value, index) => getPercentValue(value, (activeIncisionSumArray || [])[index]?.[1]))
          : indicatorValues,
      };
    },
    {},
  );

  return { data: { ...activeIncisionValue, ...indicatorsValues }, activeIncisionSum };
};

export const getLinePositionLabel: <T extends TopAndBottomType>(
  isRotated: boolean,
  isPositiveNumber: boolean,
  orientation: boolean,
) => Record<T, Record<'position' | 'align' | 'distance' | 'verticalAlign', string | number | [string, string]>> = (
  isRotated,
  isPositiveNumber,
  isOrientationHorizontal,
) =>
  isRotated
    ? {
        top: {
          position: 'left',
          align: isOrientationHorizontal ? 'right' : 'center',
          distance: 5,
          verticalAlign: isOrientationHorizontal ? 'middle' : 'bottom',
        },
        bottom: {
          position: 'right',
          align: isOrientationHorizontal ? 'left' : 'center',
          distance: 5,
          verticalAlign: isOrientationHorizontal ? 'middle' : 'top',
        },
      }
    : {
        top: {
          position: 'top',
          align: isOrientationHorizontal ? 'center' : 'left',
          distance: 5,
          verticalAlign: isOrientationHorizontal ? 'bottom' : 'middle',
        },
        bottom: {
          position: 'bottom',
          align: isOrientationHorizontal ? 'center' : 'right',
          distance: 5,
          verticalAlign: isOrientationHorizontal ? 'top' : 'middle',
        },
      };

interface AxisConfigIndicator {
  min?: number;
  max?: number;
  maxStringLength?: number;
}

interface GetIndicatorAxisConfigParams extends AxisConfigIndicator {
  axisIndicatorSettings: AxisSettingsInterface<'indicator'>;
  axisAdditionalIndicatorSettings: AxisSettingsInterface<'indicator'>;
  rotateTo90: boolean;
  activeThemeSchema: ThemeColorsInterface;
  mainIndicator: AxisConfigIndicator;
  additionalIndicator: AxisConfigIndicator;
}

export const getIndicatorAxisConfig: (params: GetIndicatorAxisConfigParams) => LineAndBarEChartsOption['yAxis'] = ({
  axisIndicatorSettings,
  axisAdditionalIndicatorSettings,
  rotateTo90,
  activeThemeSchema,
  mainIndicator,
  additionalIndicator,
}) => {
  const {
    isShow,
    showAxis,
    position,
    name,
    label: { isActive: isActiveLabel, value: labelValue, properties },
    stepSize,
    showGrid,
  } = axisIndicatorSettings;
  const {
    isShow: isShowAdditional,
    showAxis: showAxisAdditional,
    position: positionAdditional,
    name: nameAdditional,
    label: { isActive: isActiveLabelAdditional, value: labelValueAdditional, properties: propertiesAdditional },
    stepSize: stepSizeAdditional,
    showGrid: showGridAdditional,
  } = axisAdditionalIndicatorSettings;

  const { max, min, maxStringLength } = mainIndicator;
  const { max: maxAdditional, min: minAdditional, maxStringLength: maxStringAdditionalLength } = additionalIndicator;

  return [
    {
      type: 'value',
      show: isShow,
      position,
      max,
      min,
      name: name.isShow ? name.text : undefined,
      nameLocation: getAxisNameLocation[name.position.type],
      nameTextStyle: {
        padding: getNameIndicatorTextPadding({
          isRotated: rotateTo90,
          isAutoType: name.type === 'auto',
          positionValue: name.position.value,
          isLeft: position === 'left',
          maxStringLength,
        })[name.position.type],
        align: !rotateTo90 && name.position.type !== 'center' ? (position === 'right' ? 'left' : 'right') : undefined,
      },
      splitNumber: stepSize.type === 'manual' ? stepSize.value : undefined,
      axisLine: {
        show: showAxis,
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
      axisLabel: {
        show: isActiveLabel,
        margin: isActiveLabel ? labelValue : undefined,
        textStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_2],
          fontSize: properties?.fontSize,
          fontWeight: properties?.fontStyle?.bold ? 'bold' : 'normal',
          fontStyle: properties?.fontStyle?.italic ? 'italic' : 'normal',
        },
      },
      splitLine: {
        show: showGrid,
        lineStyle: {
          type: 'dashed',
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
      axisTick: {
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
    },
    {
      type: 'value',
      show: isShowAdditional,
      position: positionAdditional,
      max: maxAdditional,
      min: minAdditional,
      name: nameAdditional.isShow ? nameAdditional.text : undefined,
      nameLocation: getAxisNameLocation[nameAdditional.position.type],
      nameTextStyle: {
        padding: getNameIndicatorTextPadding({
          isRotated: rotateTo90,
          isAutoType: nameAdditional.type === 'auto',
          positionValue: nameAdditional.position.value,
          isLeft: positionAdditional === 'left',
          maxStringLength: maxStringAdditionalLength,
        })[nameAdditional.position.type],
        align:
          !rotateTo90 && nameAdditional.position.type !== 'center'
            ? positionAdditional === 'right'
              ? 'left'
              : 'right'
            : undefined,
      },
      splitNumber: stepSizeAdditional.type === 'manual' ? stepSizeAdditional.value : undefined,
      axisLine: {
        show: showAxisAdditional,
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
      axisLabel: {
        show: isActiveLabelAdditional,
        margin: isActiveLabelAdditional ? labelValueAdditional : undefined,
        textStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_2],
          fontSize: propertiesAdditional?.fontSize,
          fontWeight: propertiesAdditional?.fontStyle?.bold ? 'bold' : 'normal',
          fontStyle: propertiesAdditional?.fontStyle?.italic ? 'italic' : 'normal',
        },
      },
      splitLine: {
        show: showGridAdditional,
        lineStyle: {
          type: 'dashed',
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
      axisTick: {
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
    },
  ];
};

export const getIndicatorAxisGridDimension: (params: GetIndicatorAxisConfigParams) => GridDimensionsInterface = ({
  axisIndicatorSettings,
  axisAdditionalIndicatorSettings,
  rotateTo90,
  maxStringLength = 0,
}) => {
  const {
    isShow,
    position,
    name,
    label: { isActive: isActiveLabel },
  } = axisIndicatorSettings;
  const {
    isShow: isShowAdditional,
    name: nameAdditional,
    label: { isActive: isActiveLabelAdditional },
  } = axisAdditionalIndicatorSettings;

  if (!isShow && !isShowAdditional) return initialDimensions;

  const nameAdditionalGridShift = 20 + getLengthOfGraphicString(nameAdditional.text),
    baseStringLength = 10;

  if (rotateTo90) {
    const baseGrid = {
      ...initialDimensions,
      bottom: isActiveLabel ? baseStringLength : 0,
    };

    const namePositionGridDimensions = {
      'flex-start': {
        ...initialDimensions,
        bottom: 10,
        left:
          name.position.type === 'flex-start' && nameAdditional.position.type === 'flex-start'
            ? nameAdditionalGridShift / 2
            : nameAdditionalGridShift,
      },
      center: {
        ...initialDimensions,
        bottom: name.position.type === 'center' || nameAdditional.position.type === 'center' ? 20 : 0,
        top: name.position.type === 'center' || nameAdditional.position.type === 'center' ? 20 : 0,
      },
      'flex-end': {
        ...initialDimensions,
        bottom: 10,
        right:
          name.position.type === 'flex-end' && nameAdditional.position.type === 'flex-end' && name.isShow && nameAdditional.isShow
            ? nameAdditionalGridShift / 2
            : nameAdditionalGridShift,
      },
    };

    const namePositionGrid = name.isShow ? namePositionGridDimensions[name.position.type] : initialDimensions;
    const nameAdditionalPositionGrid = nameAdditional.isShow
      ? namePositionGridDimensions[nameAdditional.position.type]
      : initialDimensions;

    return calculateGridDimension([baseGrid, namePositionGrid, nameAdditionalPositionGrid]);
  } else {
    const baseGrid = {
      ...initialDimensions,
      [position]: baseStringLength + (isActiveLabel || isActiveLabelAdditional ? maxStringLength : 0),
    };

    const namePositionGridDimensions = {
      'flex-start': {
        ...initialDimensions,
        bottom: 20,
        left:
          name.position.type === 'flex-start' && nameAdditional.position.type === 'flex-start'
            ? nameAdditionalGridShift / 2
            : nameAdditionalGridShift,
        right:
          name.position.type === 'flex-start' && nameAdditional.position.type === 'flex-start'
            ? nameAdditionalGridShift
            : nameAdditionalGridShift / 2,
      },
      center: position === 'left' ? { ...initialDimensions, left: 20 } : { ...initialDimensions, right: 10 },
      'flex-end': {
        ...initialDimensions,
        top: 20,
        left:
          name.position.type === 'flex-end' && nameAdditional.position.type === 'flex-end'
            ? nameAdditionalGridShift / 2
            : nameAdditionalGridShift,
        right:
          name.position.type === 'flex-end' && nameAdditional.position.type === 'flex-end'
            ? nameAdditionalGridShift / 2
            : nameAdditionalGridShift,
      },
    };

    const namePositionGrid = name.isShow ? namePositionGridDimensions[name.position.type] : initialDimensions;

    return calculateGridDimension([baseGrid, namePositionGrid]);
  }
};

export const getIndicatorAxisWithGridDimensions: (
  params: GetIndicatorAxisConfigParams,
) => ConfigWithGridDimensionsInterface<LineAndBarEChartsOption['yAxis']> = (params) => ({
  config: getIndicatorAxisConfig(params),
  gridDimensions: getIndicatorAxisGridDimension(params),
});

export const getBarGraphGridDimensions = (indicators: LineAndBarIndicatorInterface[]) => {
  let isActiveGrid = false;

  indicators.forEach(
    ({
      settings: {
        elementSettings: { type },
        showValue: { isShow, position },
      },
    }) => {
      if (!isActiveGrid) {
        isActiveGrid = type === 'bar' && isShow && position === 'outside';
      }
    },
  );

  const shiftPosition = 'top';

  return isActiveGrid ? { ...initialDimensions, [shiftPosition]: 20 } : initialDimensions;
};

export const getBarPositionLabel: <T extends BarValuePositionType>(
  isPositiveNumber: boolean,
  orientation: boolean,
  isRotated?: boolean,
) => Record<T, Record<'position' | 'align' | 'distance' | 'verticalAlign', string | number | [string, string]>> = (
  isPositiveNumber,
  isOrientationHorizontal,
  isRotated = false,
) =>
  isRotated
    ? {
        'flex-start': {
          position: isPositiveNumber ? 'left' : 'right',
          align: isOrientationHorizontal ? (isPositiveNumber ? 'left' : 'right') : 'center',
          distance: -5,
          verticalAlign: !isOrientationHorizontal ? (isPositiveNumber ? 'top' : 'bottom') : 'middle',
        },
        'flex-end': {
          position: isPositiveNumber ? 'right' : 'left',
          align: isOrientationHorizontal ? (isPositiveNumber ? 'right' : 'left') : 'center',
          distance: -5,
          verticalAlign: !isOrientationHorizontal ? (isPositiveNumber ? 'bottom' : 'top') : 'middle',
        },
        outside: {
          position: 'outside',
          align: isOrientationHorizontal ? (isPositiveNumber ? 'left' : 'right') : 'center',
          distance: 5,
          verticalAlign: !isOrientationHorizontal ? (isPositiveNumber ? 'top' : 'bottom') : 'middle',
        },
        center: { position: ['50%', '50%'], align: 'center', distance: 5, verticalAlign: 'middle' },
      }
    : {
        'flex-start': {
          position: isPositiveNumber ? 'top' : 'bottom',
          align: !isOrientationHorizontal ? (isPositiveNumber ? 'right' : 'left') : 'center',
          distance: -5,
          verticalAlign: isOrientationHorizontal ? (isPositiveNumber ? 'top' : 'bottom') : 'middle',
        },
        'flex-end': {
          position: isPositiveNumber ? 'bottom' : 'top',
          align: !isOrientationHorizontal ? (isPositiveNumber ? 'left' : 'right') : 'center',
          distance: -5,
          verticalAlign: isOrientationHorizontal ? (isPositiveNumber ? 'bottom' : 'top') : 'middle',
        },
        outside: {
          position: 'outside',
          align: !isOrientationHorizontal ? (isPositiveNumber ? 'left' : 'right') : 'center',
          distance: 5,
          verticalAlign: isOrientationHorizontal ? (isPositiveNumber ? 'bottom' : 'top') : 'middle',
        },
        center: { position: ['50%', '50%'], align: 'center', distance: 5, verticalAlign: 'middle' },
      };
