import { OnValueChange } from 'types/global';
import { TotalSettings } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { MOCK_GRAPHIC_OPERATION_TYPE_EXTENDED } from 'constants/Mock';
import { Select } from 'modules/ui';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { CustomRequestSettings, CustomRequestSettingsProps } from 'modules/settingsContainer/common/data/CustomRequestSettings';
import { calculationResultsOptions } from '../PropertiesSettings/constants';
import { RadioSelected } from 'components/RadioSelected/RadioSelected';
import { Formattings } from '../FormattingSettings/Formattings';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FunctionIcon } from 'assets/icons/forDelete';

type TableTotalIndicatorSettingsProps = OnValueChange<TotalSettings> & Pick<CustomRequestSettingsProps, 'modelMetaData'>;

export const TableTotalIndicatorSettings = ({ value, onChange, modelMetaData }: TableTotalIndicatorSettingsProps) => {
  const { isShow, operationType, customRequest, isAutoAggregation, formatting } = value;

  return (
    <MainContainerSettings
      titleText="Рассчитать итог"
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <GroupContainerSettings>
        <RadioSelected
          onChange={(option) => onChange({ ...value, isAutoAggregation: option.value === 'auto' })}
          activeValue={calculationResultsOptions[isAutoAggregation ? 0 : 1]}
          options={calculationResultsOptions}
          gap={'16px'}
          width={'100%'}
        />
        {!isAutoAggregation && (
          <>
            <ElementContainerSettings>
              <FlexContainer flexDirection="column" width="100%" gap={'16px'}>
                <FlexContainer width="100%" gap="8px" alignItems="center">
                  <FlexContainer width="40%">
                    <PrimaryTextSpan fontSize="12px" lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`}>
                      Агрегировать по
                    </PrimaryTextSpan>
                  </FlexContainer>
                  <FlexContainer flex="1 1 0">
                    <Select
                      needBackground={false}
                      placeholder="aggregateBy"
                      name="aggregateBy"
                      options={MOCK_GRAPHIC_OPERATION_TYPE_EXTENDED}
                      width="100%"
                      value={operationType}
                      onChange={(operationType) => onChange({ ...value, operationType, customRequest: null })}
                    />
                  </FlexContainer>
                  {operationType === 'other' && (
                    <FlexContainer>
                      <CustomRequestSettings
                        value={customRequest || ''}
                        onChange={(customRequest) => onChange({ ...value, customRequest })}
                        Icon={FunctionIcon}
                        modelMetaData={modelMetaData}
                      />
                    </FlexContainer>
                  )}
                </FlexContainer>
                <GroupContainerSettings
                  titleText={'Форматирование значений'}
                  switcherState={formatting.isShow}
                  switcherChange={() =>
                    onChange({
                      ...value,
                      formatting: {
                        ...formatting,
                        isShow: !formatting.isShow,
                      },
                    })
                  }
                  padding={'none'}
                >
                  <Formattings
                    value={formatting}
                    onChange={(format) =>
                      onChange({
                        ...value,
                        formatting: format,
                      })
                    }
                  />
                </GroupContainerSettings>
              </FlexContainer>
            </ElementContainerSettings>
          </>
        )}
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
