import {
  BubbleIcon,
  GraphicLineBarIcon,
  GraphicPolarIcon,
  HeatmapIcon,
  TableIcon,
  TreemapIcon,
  WaterfallIcon,
} from 'assets/icons/graphicType';
import { VisualisationTypeType } from 'store/reducers/visualisations/types';
import { FilterType } from 'store/reducers/filters/types';
import { FilterMultipleIcon, FilterSingleIcon } from 'assets/icons/filterType';
import { DateIcon } from 'assets/icons/editor';

export const visualisations: { visualisationType: VisualisationTypeType; Icon: typeof GraphicPolarIcon; title: string }[] = [
  { visualisationType: 'lineAndBar', Icon: GraphicLineBarIcon, title: 'Комбинированная' },
  { visualisationType: 'pie', Icon: GraphicPolarIcon, title: 'Круговая' },
  { visualisationType: 'table', Icon: TableIcon, title: 'Таблица' },
  { visualisationType: 'waterfall', Icon: WaterfallIcon, title: 'Водопад (Мост)' },
  { visualisationType: 'tree', Icon: TreemapIcon, title: 'Дерево' },
  { visualisationType: 'bubble', Icon: BubbleIcon, title: 'Пузырьковая' },
  { visualisationType: 'heatmap', Icon: HeatmapIcon, title: 'Тепловая карта' },
];

export const filters: { type: FilterType; Icon: typeof FilterSingleIcon }[] = [
  { type: 'single', Icon: FilterSingleIcon },
  { type: 'multiple', Icon: FilterMultipleIcon },
  { type: 'date', Icon: DateIcon },
];
