export const pxUnit = 'пикс';
export const percentUnit = '%';
export const degUnit = 'градусов';
export const wrongSqlRequest = 'По текущему запросу нет данных';
export const wrongSqlQuery = 'Ошибка при построении SQL запроса';
export const backgroundNameTitle = 'Изображения';
export const colorNameTitle = 'Цвет';
export const colorBubbleNameTitle = 'Цвет пузырьков';
export const colorValueNameTitle = 'Цвет значения';
export const backgroundValueNameTitle = 'Цвет фона';
export const backgroundTableValueNameTitle = 'Цвет столбцов';
export const backgroundLineAndBarValueNameTitle = 'Цвет элементов';
export const getVariablePattern = (value: string | number) => `{{${value}}}`;
export const startAndEnd = { start: 0, end: 100 };

export const dayInTimestamp = 86400;

export const patternsForReplacement = {
  ' -> ': /\s*,\s*lambda\(\)\s*,\s*/g,
};

export const patterns: Record<keyof typeof patternsForReplacement, string> = {
  ' -> ': ', lambda(),',
};
