import React, { forwardRef, ReactElement, ReactNode, useId } from 'react';
import { FlexContainer, FlexContainerProps } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Switcher } from '../ui';
import styled, { css } from 'styled-components';
import { bottomLineStyleMixin } from 'constants/styles';

/* TODO: Refactoring this component */

interface IProps extends GroupContainerWrapperProps, FlexContainerProps {
  titleText?: string;
  children?: JSX.Element[] | JSX.Element | ReactNode | ReactElement<any, any> | any;
  switcherState?: boolean;
  switcherChange?: (e?: any) => void;
}

// eslint-disable-next-line react/display-name
export const GroupContainerSettings = forwardRef<HTMLDivElement, IProps>(
  ({ titleText = null, children = null, switcherState = false, switcherChange = null, showBottomLine, ...props }, ref) => {
    const uniqueId = useId();

    return (
      <GroupContainerWrapper
        ref={ref}
        flexDirection="column"
        gap="4px"
        padding="0 0 0 8px"
        showBottomLine={showBottomLine}
        {...props}
      >
        {titleText && (
          <FlexContainer height="16px" alignItems="center" justifyContent="space-between">
            <PrimaryTextSpan fontSize="12px" lineHeight="12px" color={`var(${ColorVarsEnum.Level_3})`}>
              {titleText}
            </PrimaryTextSpan>
            <FlexContainer gap="16px" alignItems="center">
              {switcherChange && <Switcher id={uniqueId} value={switcherState} onClick={switcherChange} />}
            </FlexContainer>
          </FlexContainer>
        )}
        {children && (
          <FlexContainer padding={titleText ? '0 0 0 8px' : '0'} gap="4px" alignItems="center" flexDirection="column">
            {React.Children.map(children, (el) => el)}
          </FlexContainer>
        )}
      </GroupContainerWrapper>
    );
  },
);

interface GroupContainerWrapperProps {
  showBottomLine?: boolean;
}

const GroupContainerWrapper = styled(FlexContainer)<GroupContainerWrapperProps>`
  position: relative;

  ${({ showBottomLine }) =>
    showBottomLine &&
    css`
      :after {
        margin: 5px 0;
        content: '';
        ${bottomLineStyleMixin}
      }
    `}
`;
