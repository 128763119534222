import { IdInterface, LoadingInterface, StatusType } from 'types/store';

export interface AdminActiveDirectoriesInterface extends IdInterface {
  name: string;
  isActive: boolean;
  schedule: string;
  type: 'project_import' | 'import_ad' | 'delete_old_projects';
  message?: string;
  duration?: string;
  status?: StatusType;
  lastStartAt?: string;
  nextStartAt?: string;
  startedTask?: string;
  finishedTask?: string;
}

export interface AdminActiveDirectoriesDataInterface extends LoadingInterface {
  adminActiveDirectoriesList: AdminActiveDirectoriesInterface[];
}

export interface AdminActiveDirectoriesSliceStateInterface {
  activeDirectoriesData: AdminActiveDirectoriesDataInterface;
  activeDirectorInfo: AdminActiveDirectoriesInterface | null;
}

/* Action Types */
export enum AdminActiveDirectoriesTypes {
  LOAD_ADMIN_DIRECTORIES = 'LOAD_ADMIN_DIRECTORIES',
  LOAD_ADMIN_DIRECTOR_INFO = 'LOAD_ADMIN_DIRECTOR_INFO',
  LOAD_ACTIVE_DIRECTOR = 'LOAD_ACTIVE_DIRECTOR',
  LOAD_CONNECTOR_USERS_GROUP = 'LOAD_CONNECTOR_USERS_GROUP',
  DELETE_ADMIN_DIRECTOR_BY_ID = 'DELETE_ADMIN_DIRECTOR_BY_ID',
  DELETE_ADMIN_DIRECTOR = 'DELETE_ADMIN_DIRECTOR',
  ADD_ADMIN_DIRECTOR_INFO = 'ADD_ADMIN_DIRECTOR_INFO',
  ADD_ADMIN_DIRECTOR = 'ADD_ADMIN_DIRECTOR',
  UPDATE_ADMIN_DIRECTOR = 'UPDATE_ADMIN_DIRECTOR',
  CREATE_ACTIVE_DIRECTOR = 'CREATE_ACTIVE_DIRECTOR',
  SYNCHRONIZE = 'SYNCHRONIZE',
  UPDATE_ADMIN_DIRECTORIES = 'UPDATE_ADMIN_DIRECTORIES',
}
