import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getActiveTheme } from 'store/reducers/themes/getters';

export const ApplyTheme: ({ children }: { children: any }) => JSX.Element = ({ children }) => {
  const theme = useSelector(getActiveTheme);

  useEffect(() => {
    if (theme) {
      [...theme.backgroundColors, ...theme.elementColors].forEach(({ cssVariable, hex }) => {
        document.documentElement.style.setProperty(cssVariable, hex);
      });
    }
  }, [theme]);

  return children;
};
