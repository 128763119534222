import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { dataGap } from 'modules/ui/lists/MapList/item/CommonItem/constants';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { defaultSelectedListItem } from 'constants/styles';

export interface CommonItemWrapperProps {
  withoutHover: boolean;
  disabled: boolean;
  justifyContent?: 'flex-start' | 'space-between';
  activeSelected?: boolean;
}

export const IconDeleteWrapper = styled(IconWrapper)`
  display: none;
`;

export const CommonItemWrapper = styled.div<CommonItemWrapperProps>`
  position: relative;
  height: 36px;
  display: flex;
  width: 100%;
  gap: ${dataGap};
  padding: 0 ${dataGap};
  cursor: pointer;
  justify-content: ${({ justifyContent }) => justifyContent};

  ${({ activeSelected }) =>
    activeSelected &&
    css`
      :after {
        ${defaultSelectedListItem}
      }
    `};

  ${({ withoutHover }) =>
    !withoutHover &&
    css`
      :hover {
        background-color: var(${ColorVarsEnum.Level_1_hover_btn});
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      :hover {
        background-color: transparent;
      }
    `};

  :hover {
    ${IconDeleteWrapper} {
      display: flex;
    }
  }
`;
