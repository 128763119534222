import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  loadMetaModels,
  loadModels,
  loadModelsFromData,
  loadTableFields,
  loadTablePreview,
  uploadMetaModels,
  uploadModels,
} from 'store/reducers/models/api';
import {
  ActiveModelItemAliasInterface,
  LoadTablePreviewPayloads,
  MetaModelPayloadInterface,
  ModelFromItemType,
  ModelPayloadInterface,
  ModelsActionsTypes,
  ModelTabInterface,
  TableFieldResponse,
  TablePreviewType,
  TabsType,
  UpdateTabByIdPayload,
} from 'store/reducers/models/types';
import { findNextIndex, getMapObject } from 'utils/utils';
import { ModelItem } from 'models/model/ModelItem';
import { Relation } from 'models/model/Relation';
import { Model } from 'models/model/Model';
import { TState } from 'store/index';
import {
  getActiveTab,
  getActiveTabId,
  getTablePreviewByName,
  getTablesAsArray,
  getTabsAsArray,
} from 'store/reducers/models/getters';
import { v4 } from 'uuid';
import {
  addNewTab,
  removeTabById,
  setActiveModelItemAlias,
  setActiveTab,
  setSlice,
  updateTabById,
} from 'store/reducers/models/index';
import { defaultTabName, getNewTab, initialModelsStoreState } from 'store/reducers/models/constants';
import { defaultZoom } from 'constants/defaults';

/* TODO: Adding types and interface for Error Messages  */

const validateError = (err: AxiosError, rejectWithValue?: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue?.(errorMessage);
};

export const loadTablePreviewAction = createAsyncThunk<TablePreviewType, LoadTablePreviewPayloads, { rejectValue: null }>(
  ModelsActionsTypes.LOAD_TABLE_PREVIEW,
  async (tableInfo, { rejectWithValue }) => {
    try {
      const response = await loadTablePreview(tableInfo);
      return response.data;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const loadTablePreviewByNameAction = createAsyncThunk(
  ModelsActionsTypes.LOAD_TABLE_PREVIEW_BY_NAME,
  async (tableInfo: LoadTablePreviewPayloads, { dispatch, getState }) => {
    const tablePreview = getTablePreviewByName(tableInfo.table)(getState() as TState);

    if (!tablePreview) {
      dispatch(loadTablePreviewAction(tableInfo));
    }
  },
);

export const uploadModelsDataAction = createAsyncThunk(
  ModelsActionsTypes.UPLOAD_MODELS,
  async (projectId: string, { rejectWithValue, getState }) => {
    try {
      const tabs = getTabsAsArray(getState() as TState);

      const models: ModelPayloadInterface[] = tabs.map(({ id, name, models }) => {
        if (models.length === 1) {
          const [model] = models;

          return { id, name, modelItems: model.modelData };
        }

        return { id, name, modelItems: [] };
      });

      const response = await uploadModels({ projectId, payload: { models } });
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadModelsDataAction = createAsyncThunk<
  ModelPayloadInterface[],
  string,
  {
    rejectValue: ModelPayloadInterface[];
  }
>(ModelsActionsTypes.LOAD_MODELS, async (projectId: string, { rejectWithValue }) => {
  try {
    const response = await loadModels(projectId);
    return response.data.models as ModelPayloadInterface[];
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return rejectWithValue([]);
  }
});

export const uploadMetaModelsDataAction = createAsyncThunk(
  ModelsActionsTypes.UPLOAD_META_MODELS,
  async (projectId: string, { rejectWithValue, getState }) => {
    try {
      const tabs = getTabsAsArray(getState() as TState);

      const models: MetaModelPayloadInterface[] = tabs.map(({ id, zoom, models }) => {
        if (models.length === 1) {
          const [model] = models;

          return { id, zoom, modelItems: model.metaModelData };
        }

        return { id, zoom, modelItems: [] };
      });

      const response = await uploadMetaModels({ projectId, payload: { models } });
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadMetaModelsDataAction = createAsyncThunk<
  MetaModelPayloadInterface[],
  string,
  {
    rejectValue: MetaModelPayloadInterface[];
  }
>(ModelsActionsTypes.LOAD_META_MODELS, async (projectId: string, { rejectWithValue }) => {
  try {
    const response = await loadMetaModels(projectId);
    return response.data.models;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return rejectWithValue([]);
  }
});

export const loadModelsFromDataAction = createAsyncThunk<
  ModelFromItemType[],
  string,
  {
    rejectValue: ModelFromItemType[];
  }
>(ModelsActionsTypes.LOAD_MODELS_FROM_DATA, async (projectId: string, { rejectWithValue }) => {
  try {
    const response = await loadModelsFromData(projectId);

    return response.data.modelsFrom;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return rejectWithValue([]);
  }
});

export const loadTables = createAsyncThunk<
  TableFieldResponse,
  string,
  {
    rejectValue: TableFieldResponse;
  }
>(ModelsActionsTypes.LOAD_TABLES, async (projectId: string, { rejectWithValue }) => {
  try {
    const response = await loadTableFields(projectId);
    return response.data;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return rejectWithValue({});
  }
});

export const uploadTabsAction = createAsyncThunk(ModelsActionsTypes.UPLOAD_TABS, async (projectId: string, { dispatch }) => {
  try {
    dispatch(uploadModelsDataAction(projectId));
    dispatch(uploadMetaModelsDataAction(projectId));
  } catch {
    Snackbar.show('Ошибка при загрузке моделей', 'error');
  }
});

export const loadTabsAction = createAsyncThunk<
  TabsType,
  string,
  {
    rejectValue: TabsType;
  }
>(ModelsActionsTypes.LOAD_TABS, async (projectId: string, { rejectWithValue, dispatch }) => {
  try {
    const { payload: tables } = await dispatch(loadTables(projectId));
    const { payload: modelsData } = await dispatch(loadModelsDataAction(projectId));
    const { payload: metaModelsData } = await dispatch(loadMetaModelsDataAction(projectId));

    const mapMetaModels = getMapObject(metaModelsData, 'id');

    const tabs: ModelTabInterface[] = (modelsData || []).map(({ name, id, modelItems: modelValues }) => {
      const { zoom, modelItems: metaModel } = mapMetaModels[id];

      const mapMetaModel = getMapObject(metaModel, 'alias');

      const modelItems: ModelItem[] = modelValues.map(({ relations: relationValues, ...modelItemValues }) => {
        const { table, alias } = modelItemValues;
        const relations: Relation[] | null = relationValues && relationValues.map((relation) => new Relation(relation));
        const columns = tables?.[table] || {};
        const { config } = mapMetaModel[alias];

        return new ModelItem({ ...modelItemValues, columns, relations, config });
      });

      let models: Model[] = [];

      if (modelItems.length) {
        models = [new Model(modelItems)];
      }

      return { id, name, zoom, models };
    });

    if (tabs.length) {
      dispatch(setActiveTab(tabs[0].id));
    }

    return getMapObject(tabs, 'id');
  } catch {
    Snackbar.show('Ошибка в полученной модели', 'error');
    return rejectWithValue({});
  }
});

export const addNewTabAction = createAsyncThunk(ModelsActionsTypes.ADD_TAB, (_, { getState, dispatch }) => {
  const tabs = getTabsAsArray(getState() as TState);

  const nextIndex = findNextIndex(
      tabs.map(({ name }) => name),
      defaultTabName,
    ),
    tabName = defaultTabName + nextIndex;

  const newTab = getNewTab({ name: tabName, id: v4() });

  dispatch(addNewTab(newTab));
});

export const setActiveTabAction = createAsyncThunk(ModelsActionsTypes.SET_ACTIVE_TAB_ID, (id: string | null, { dispatch }) => {
  dispatch(setActiveTab(id));
});

export const setActiveModelItemAliasAction = createAsyncThunk(
  ModelsActionsTypes.SET_ACTIVE_MODEL_ITEM_ALIAS,
  (alias: ActiveModelItemAliasInterface | null, { dispatch }) => {
    dispatch(setActiveModelItemAlias(alias));
  },
);

export const addTableToActiveTabAction = createAsyncThunk(
  ModelsActionsTypes.ADD_TABLE_TO_ACTIVE_TAB,
  (tableName: string, { dispatch, getState }) => {
    const state = getState() as TState,
      tables = getTablesAsArray(state),
      activeTab = getActiveTab(state);

    const table = tables.find(({ name }) => name === tableName);

    if (activeTab && table) {
      const alreadyExistedAlias: Record<string, boolean> = {};

      activeTab.models.forEach((model) =>
        model.modelItems.forEach(({ alias }) => {
          alreadyExistedAlias[alias] = true;
        }),
      );

      let newAliasName = tableName;

      while (alreadyExistedAlias[newAliasName]) {
        newAliasName = newAliasName + '_copy';
      }

      const modelItem = new ModelItem({
        alias: newAliasName,
        table: tableName,
        db: null,
        columns: table.fields || {},
        isHead: true,
      });

      const model = new Model([modelItem]);

      dispatch(updateTabById({ id: activeTab.id, data: { models: [...activeTab.models, model] } }));
    }
  },
);

export const updateTabByIdAction = createAsyncThunk<void, UpdateTabByIdPayload>(
  ModelsActionsTypes.UPDATE_TAB,
  (payload, { dispatch }) => {
    dispatch(updateTabById(payload));
  },
);

export const removeTabByIdAction = createAsyncThunk<void, string>(ModelsActionsTypes.REMOVE_TAB, (id, { dispatch, getState }) => {
  const state = getState() as TState,
    tabs = getTabsAsArray(state),
    activeTabId = getActiveTabId(state);

  if (activeTabId === id) {
    const newActiveTabId = tabs.filter((tab) => tab.id !== id)[0]?.id || null;
    dispatch(setActiveTabAction(newActiveTabId));
  }

  dispatch(removeTabById(id));
});

export const updateZoomAction = createAsyncThunk(
  ModelsActionsTypes.UPDATE_ZOOM,
  (isDecrease: boolean | undefined, { dispatch, getState }) => {
    const state = getState() as TState,
      activeTab = getActiveTab(state);

    if (activeTab) {
      const { zoom } = activeTab,
        minZoom = 0.3,
        newZoom = isDecrease ? zoom - 0.1 : zoom + 0.1,
        zoomValue = newZoom >= defaultZoom ? defaultZoom : newZoom <= minZoom ? minZoom : newZoom;

      dispatch(updateTabById({ id: activeTab.id, data: { zoom: zoomValue } }));
    }
  },
);

export const clearModelsStore = createAsyncThunk(ModelsActionsTypes.CLEAR_MODEL_STORE, (_, { dispatch }) => {
  dispatch(setSlice(initialModelsStoreState));
});
