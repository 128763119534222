import { AxiosError } from 'axios/index';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNewPalette, removePaletteById, setSlice, updatePaletteById } from 'store/reducers/palettes';
import {
  AddColorToGroupByIdPayload,
  AddGroupToPaletteByIdPayload,
  AddGroupToPalettePayload,
  AddNewPaletteByIdPayload,
  AddNewPalettePayload,
  GroupIdInterface,
  PaletteItemInterface,
  PalettesActionsTypes,
  RemoveColorByIdPayload,
  RemoveGroupByIdPayload,
} from 'store/reducers/palettes/types';
import {
  defaultGroupName,
  defaultPaletteName,
  getPaletteColorItem,
  getPaletteGroupItem,
  getPaletteItem,
  initialPalettesStoreState,
} from 'store/reducers/palettes/constants';
import { TState } from 'store/index';
import { getActivePalette, getPalettesAsArray, getPalettesById } from 'store/reducers/palettes/getters';
import { findNextIndex } from 'utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { getActivePaletteId, getActiveThemeId, getPaletteLinks } from 'store/reducers/themes/getters';
import { updatePaletteLinkAction } from 'store/reducers/themes/actions';
import { loadPalettes } from 'store/reducers/palettes/api';
import { SettingsSnapshotType } from 'store/reducers/projectSettings/settingsSnapshotService';

/* TODO: Adding types and interface for Error Messages  */

const validateError = (err: AxiosError, rejectWithValue?: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue?.(errorMessage);
};

export const loadPalettesAction = createAsyncThunk<PaletteItemInterface[], string>(
  PalettesActionsTypes.LOAD_PALETTES,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await loadPalettes(projectId);
      return response.data.projectPalette;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue([]);
    }
  },
);

export const loadPalettesFromSnapshotAction = createAsyncThunk<PaletteItemInterface[], SettingsSnapshotType['palettes']>(
  PalettesActionsTypes.LOAD_PALETTES_FROM_SNAPSHOT,
  (palettes) => palettes,
);

export const addNewPaletteAction = createAsyncThunk(
  PalettesActionsTypes.ADD_PALETTE,
  (paletteParams: AddNewPalettePayload, { dispatch, getState }) => {
    const activeThemeId = getActiveThemeId(getState() as TState);

    const newPalette = getPaletteItem(paletteParams);

    dispatch(addNewPalette(newPalette));
    activeThemeId && dispatch(updatePaletteLinkAction({ themeId: activeThemeId, paletteId: newPalette.id }));
  },
);

export const addNewPaletteByIdAction = createAsyncThunk(
  PalettesActionsTypes.ADD_PALETTE_BY_ID,
  ({ id, name, basedOn }: AddNewPaletteByIdPayload, { dispatch, getState }) => {
    const state = getState() as TState,
      palette = getPalettesById(id)(state),
      palettes = getPalettesAsArray(state);

    let paletteName = name;

    if (!paletteName) {
      const nextIndex = findNextIndex(
        palettes.map(({ name }) => name),
        defaultPaletteName,
      );

      paletteName = defaultPaletteName + nextIndex;
    }

    if (palette) {
      dispatch(addNewPaletteAction({ ...palette, name: paletteName, id: uuidv4(), basedOn }));
    }
  },
);

export const addNewPaletteByCurrentPaletteAction = createAsyncThunk(
  PalettesActionsTypes.ADD_PALETTE_BY_CURRENT,
  (_, { dispatch, getState }) => {
    const activePalette = getActivePalette(getState() as TState);

    if (activePalette) {
      const { name, basedOn } = activePalette;

      dispatch(
        addNewPaletteByIdAction({ id: activePalette.id, name: `${name} copy`, basedOn: [...(basedOn || []), activePalette.id] }),
      );
    }
  },
);

export const removePaletteByIdAction = createAsyncThunk(
  PalettesActionsTypes.REMOVE_PALETTE,
  (id: string, { dispatch, getState }) => {
    const state = getState() as TState,
      paletteLinks = getPaletteLinks(state),
      newPaletteId = getPalettesAsArray(state).filter((palette) => id !== palette.id)[0]?.id;

    const updateLinksList = paletteLinks.filter(({ paletteId }) => paletteId === id);

    if (newPaletteId) {
      dispatch(removePaletteById({ id }));

      updateLinksList.forEach(({ themeId }) => dispatch(updatePaletteLinkAction({ themeId, paletteId: newPaletteId })));
    }
  },
);

export const addGroupToPaletteByIdAction = createAsyncThunk(
  PalettesActionsTypes.ADD_GROUP_TO_PALETTE_BY_ID,
  ({ id, group: { name, colors } }: AddGroupToPaletteByIdPayload, { dispatch, getState }) => {
    const state = getState() as TState,
      palette = getPalettesById(id)(state),
      groups = palette?.groups || [];

    let groupName = name;

    if (!groupName) {
      const nextIndex = findNextIndex(
        groups.map(({ name }) => name),
        defaultGroupName,
      );

      groupName = defaultGroupName + nextIndex;
    }

    if (palette) {
      const newGroup = getPaletteGroupItem({ name: groupName, colors });

      dispatch(updatePaletteById({ ...palette, groups: [...groups, newGroup] }));
    }
  },
);

export const addGroupToCurrentPaletteAction = createAsyncThunk(
  PalettesActionsTypes.ADD_GROUP_TO_CURRENT_PALETTE,
  ({ group }: AddGroupToPalettePayload, { dispatch, getState }) => {
    const activePaletteId = getActivePaletteId(getState() as TState);

    if (activePaletteId) {
      dispatch(addGroupToPaletteByIdAction({ id: activePaletteId, group }));
    }
  },
);

export const removeGroupFromPaletteByIdAction = createAsyncThunk(
  PalettesActionsTypes.REMOVE_GROUP_FROM_PALETTE_BY_ID,
  ({ id, groupId }: RemoveGroupByIdPayload, { dispatch, getState }) => {
    const palette = getPalettesById(id)(getState() as TState);

    if (palette) {
      dispatch(updatePaletteById({ ...palette, groups: palette.groups.filter(({ id }) => id !== groupId) }));
    }
  },
);

export const removeGroupFromCurrentPaletteAction = createAsyncThunk(
  PalettesActionsTypes.REMOVE_GROUP_FROM_CURRENT_PALETTE,
  ({ groupId }: GroupIdInterface, { dispatch, getState }) => {
    const activePaletteId = getActivePaletteId(getState() as TState);

    if (activePaletteId) {
      dispatch(removeGroupFromPaletteByIdAction({ id: activePaletteId, groupId }));
    }
  },
);

export const addColorToGroupByIdAction = createAsyncThunk(
  PalettesActionsTypes.ADD_COLOR_TO_GROUP_BY_ID,
  ({ id, groupId, color }: AddColorToGroupByIdPayload, { dispatch, getState }) => {
    const state = getState() as TState,
      palette = getPalettesById(id)(state);

    if (palette) {
      const newColor = getPaletteColorItem({ ...color });

      dispatch(
        updatePaletteById({
          ...palette,
          groups: palette.groups.map((group) =>
            group.id === groupId ? { ...group, colors: [...group.colors, newColor] } : group,
          ),
        }),
      );
    }
  },
);

export const addColorToGroupByCurrentPaletteAction = createAsyncThunk(
  PalettesActionsTypes.ADD_COLOR_TO_GROUP_BY_CURRENT,
  ({ groupId, color }: Omit<AddColorToGroupByIdPayload, 'id'>, { dispatch, getState }) => {
    const activePaletteId = getActivePaletteId(getState() as TState);

    if (activePaletteId) {
      dispatch(addColorToGroupByIdAction({ id: activePaletteId, groupId, color }));
    }
  },
);

export const removeColorFromGroupByIdAction = createAsyncThunk(
  PalettesActionsTypes.REMOVE_COLOR_FROM_GROUP_BY_ID,
  ({ id, groupId, colorId }: RemoveColorByIdPayload, { dispatch, getState }) => {
    const state = getState() as TState,
      palette = getPalettesById(id)(state);

    if (palette) {
      const newColors = palette.groups.find((group) => group.id === groupId)?.colors.filter(({ id }) => id !== colorId);

      if (!newColors?.length) {
        dispatch(removeGroupFromPaletteByIdAction({ id, groupId }));

        return;
      }

      dispatch(
        updatePaletteById({
          ...palette,
          groups: palette.groups.map((group) => (group.id === groupId ? { ...group, colors: newColors } : group)),
        }),
      );
    }
  },
);

export const removeColorFromGroupByCurrentPaletteAction = createAsyncThunk(
  PalettesActionsTypes.REMOVE_COLOR_FROM_GROUP_BY_CURRENT,
  ({ groupId, colorId }: Omit<RemoveColorByIdPayload, 'id'>, { dispatch, getState }) => {
    const activePaletteId = getActivePaletteId(getState() as TState);

    if (activePaletteId) {
      dispatch(removeColorFromGroupByIdAction({ id: activePaletteId, groupId, colorId }));
    }
  },
);

export const updatePaletteAction = createAsyncThunk(
  PalettesActionsTypes.UPDATE_PALETTE,
  ({ id, ...paletteData }: PaletteItemInterface, { dispatch }) => {
    dispatch(updatePaletteById({ id, ...paletteData }));
  },
);

export const clearPalettesStore = createAsyncThunk(PalettesActionsTypes.CLEAR_PALETTES_STORE, (_, { dispatch }) => {
  dispatch(setSlice(initialPalettesStoreState));
});
