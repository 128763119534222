import { FC, useState } from 'react';
import { CellGridItem, CellGridWrapper } from 'shared/ui/CellGrid/styles';
import { CellGridOptionType } from 'shared/ui/CellGrid/types';

interface CellGridProps {
  options: CellGridOptionType[];
  columns: number;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export const CellGrid: FC<CellGridProps> = ({ options, columns, defaultValue, onChange }) => {
  const [active, setActive] = useState<string>(defaultValue || '');

  const handleChange = (value: string) => {
    setActive(value);
    onChange && onChange(value);
  };

  return (
    <CellGridWrapper columns={columns}>
      {options.map((option) => (
        <CellGridItem
          key={`cell-grid-item-${option.value}`}
          active={active === option.value}
          onClick={() => handleChange(option.value)}
        >
          {option.label}
        </CellGridItem>
      ))}
    </CellGridWrapper>
  );
};
