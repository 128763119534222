import { GroupWrapper, ItemsWrapper, MapListWrapper } from 'modules/ui/lists/MapList/list/styles';
import { TitleItem } from 'modules/ui/lists/MapList/item/TitleItem';
import {
  ListUIGroupItemInterface,
  ListUIItemInterface,
  RenderItemProps,
  SelectedItemInterface,
} from 'modules/ui/lists/MapList/list/types';
import { NoopValueType } from 'types/global';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { isOpenGroup, isSelectedOption } from 'modules/ui/lists/MapList/list/constants';
import { isEqual } from 'lodash';

export interface ListUIProps {
  options: ListUIGroupItemInterface[];
  selectedOptions?: SelectedItemInterface[];
  onSelect?: NoopValueType<SelectedItemInterface[]>;
  onDeleteItem?: NoopValueType<{ id: ListUIGroupItemInterface['itemId']; title: ListUIGroupItemInterface['title'] }>;
  onChecked?: NoopValueType<SelectedItemInterface>;
  openedGroups?: string[];
  onOpen?: NoopValueType<string[]>;
  defaultAllOpened?: boolean;
  renderItem: (props: RenderItemProps) => JSX.Element;
}

export const ListUIComponent = ({
  options,
  onSelect,
  selectedOptions = [],
  onOpen,
  onChecked,
  defaultAllOpened = false,
  renderItem,
  openedGroups,
  onDeleteItem,
}: ListUIProps) => {
  const [selectedOptionsState, setSelectedOptionsState] = useState<SelectedItemInterface[]>(selectedOptions);

  const onSelectChange = useCallback(
    (selectedOption: SelectedItemInterface, isSelected: boolean) => {
      const newSelectedOptionsState = isSelected
        ? selectedOptionsState.filter((value) => !isEqual(selectedOption, value))
        : [...selectedOptionsState, selectedOption];

      setSelectedOptionsState(newSelectedOptionsState);
      onSelect && onSelect(newSelectedOptionsState);
    },
    [onSelect, selectedOptionsState],
  );

  useEffect(() => {
    setSelectedOptionsState(selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedOptions)]);

  const [openedGroupsState, setOpenedGroupsState] = useState(defaultAllOpened ? options.map(({ id }) => id) : []);

  const onOpenChange = useCallback(
    (groupId: string, isOpen: boolean) => {
      const newOpenedGroupsState = isOpen
        ? openedGroupsState.filter((value) => value !== groupId)
        : [...openedGroupsState, groupId];

      onOpen && onOpen(newOpenedGroupsState);
      setOpenedGroupsState(newOpenedGroupsState);
    },
    [onOpen, openedGroupsState],
  );

  useEffect(() => {
    openedGroups && setOpenedGroupsState(openedGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(openedGroups)]);

  const renderItems = useCallback(
    (items: (ListUIItemInterface | ListUIGroupItemInterface)[], parentId: string) => {
      return items?.map((item) => {
        const { id, title, Icon, disabled, itemId } = item;
        const isSelected = isSelectedOption({ groupId: parentId, listId: item.id }, selectedOptionsState);

        /*Checking if an element is a group with nested elements*/
        if ('items' in item && item.items.length > 0) {
          const isSubGroupOpen = isOpenGroup(item.id, openedGroupsState);

          return (
            <GroupWrapper key={id}>
              <TitleItem
                title={title}
                Icon={Icon}
                isOpen={isSubGroupOpen}
                disabled={disabled}
                onClick={() => onOpenChange(id, isSubGroupOpen)}
              />
              {isSubGroupOpen && <ItemsWrapper>{renderItems(items, id)}</ItemsWrapper>}
            </GroupWrapper>
          );
        }

        return renderItem({
          item,
          isSelected,
          onSelectChange: () => onSelectChange({ groupId: parentId, listId: id }, isSelected),
          onChecked: () => onChecked && onChecked({ groupId: parentId, listId: id }),
          onDeleteItem: () => onDeleteItem && onDeleteItem({ id: itemId, title: title }),
        });
      });
    },
    [openedGroupsState, onSelectChange, onDeleteItem, onOpenChange, onChecked, selectedOptionsState, renderItem],
  );

  return (
    <MapListWrapper>
      {options.map(({ id: groupId, itemId, items, title, Icon, disabled, type }) => {
        const isOpen = isOpenGroup(groupId, openedGroupsState);

        if (items.length === 0) {
          return renderItem({
            item: { id: groupId, title, Icon, disabled, type, itemId },
            isSelected: isSelectedOption({ groupId, listId: groupId }, selectedOptionsState),
            onSelectChange: () =>
              onSelectChange({ groupId, listId: groupId }, isSelectedOption({ groupId, listId: groupId }, selectedOptionsState)),
            onChecked: () => onChecked && itemId && onChecked({ groupId: itemId, listId: groupId, type }),
            onDeleteItem: () => onDeleteItem && onDeleteItem({ id: itemId, title }),
          });
        }
        /*If there are nested items, render them as a group*/
        return (
          <GroupWrapper key={groupId}>
            <TitleItem
              title={title}
              Icon={Icon}
              isOpen={isOpen}
              disabled={disabled}
              onClick={() => onOpenChange(groupId, isOpen)}
            />
            {isOpen && <ItemsWrapper>{renderItems(items, groupId)}</ItemsWrapper>}
          </GroupWrapper>
        );
      })}
    </MapListWrapper>
  );
};

export const ListUI = memo(ListUIComponent);
