export enum ColorVarsEnum {
  Level_1 = '--color-level-1',
  Level_2 = '--color-level-2',
  Level_3 = '--color-level-3',
  Level_4 = '--color-level-4',
  Level_5 = '--color-level-5',
  Accent = '--color-accent',
  Alert = '--color-alert',
  Level_1_hover_btn = '--color-l_1_hover_btn',
  Level_2_btn = '--color-l_2_btn',
  Level_3_menu = '--color-l_3_menu',
  Level_4_menu = '--color-l_4_widget',
  Level_5_application = '--color-l_5_application',
}
