import { createAsyncThunk } from '@reduxjs/toolkit';
import { AstActionsTypes, SetAstOfVisualisationPayload, SourceAstItemInterface, SourcesAstType } from 'store/reducers/ast/types';
import {
  removeAstOfVisualisationById,
  setAstOfVisualisation,
  setSlice,
  updateAstOfEnabledFilters,
  updateAstOfSources,
} from 'store/reducers/ast';
import { generateFilterAstsFn, sqlParser } from 'utils/SQL/genereteAst';
import { EnabledFiltersDependencyInterface } from 'store/reducers/filters/types';
import { Select } from 'node-sql-parser';
import { AST } from 'types/ast';
import { ModelFromItemType } from 'store/reducers/models/types';
import { getMapObject } from 'utils/utils';
import Snackbar from 'services/Snackbar';
import { initialAstStoreState } from 'store/reducers/ast/constants';

export const setAstOfVisualisationActions = createAsyncThunk(
  AstActionsTypes.SET_AST_OF_VISUALISATION,
  (data: SetAstOfVisualisationPayload, { dispatch }) => {
    dispatch(setAstOfVisualisation(data));
  },
);

export const removeAstOfVisualisationActions = createAsyncThunk(
  AstActionsTypes.REMOVE_AST_OF_VISUALISATION,
  (visualisationId: string, { dispatch }) => {
    dispatch(removeAstOfVisualisationById(visualisationId));
  },
);

export const updateAstOfEnabledFiltersActions = createAsyncThunk(
  AstActionsTypes.UPDATE_AST_OF_ENABLED_FILTERS,
  (enabledFiltersDataDependency: EnabledFiltersDependencyInterface[], { dispatch }) => {
    const enabledFilters = enabledFiltersDataDependency.reduce((result, { fieldName, values, isRealData, id, type }) => {
      return isRealData && fieldName
        ? {
            ...result,
            [id]: generateFilterAstsFn({ fieldName, values, type }),
          }
        : result;
    }, {});

    dispatch(updateAstOfEnabledFilters(enabledFilters));
  },
);

export const updateAstOfSourcesActions = createAsyncThunk(
  AstActionsTypes.UPDATE_AST_OF_SOURCES,
  (modelsFrom: ModelFromItemType[], { dispatch }) => {
    let from: AST.FromFromParser = null;

    let astOfSources: SourcesAstType = {};

    try {
      const astOfSourcesAsArray: SourceAstItemInterface[] = modelsFrom.map(({ id, from: fromSection }) => {
        if (fromSection) {
          const parsedData = (sqlParser.astify(`SELECT * ${fromSection.replaceAll('"', '`')}`) as Select) || { from: null };
          from = parsedData.from;
        }

        return { id, ast: from };
      });

      astOfSources = getMapObject(astOfSourcesAsArray, 'id');
    } catch {
      Snackbar.show('Ошибка в секции FROM', 'error');
    } finally {
      dispatch(updateAstOfSources(astOfSources));
    }
  },
);

export const clearAstStore = createAsyncThunk(AstActionsTypes.CLEAR_AST_STORE, (_, { dispatch }) => {
  dispatch(setSlice(initialAstStoreState));
});
