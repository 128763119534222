import { ListBlockItemWrapper } from 'modules/ui/lists/BlockList/ListBlockItem/styles';
import { ContentItem } from 'modules/ui/lists/BlockList/ListBlockItem/ContentItem';
import { ImageItem } from 'modules/ui/lists/BlockList/ListBlockItem/ImageItem';
import { NoopType } from 'types/global';
import React, { memo } from 'react';
import { IconType } from 'assets/icons/withContainer';

export interface ListBlockItemProps {
  title: string;
  onItemClick: NoopType;
  onOpenInformationModal?: NoopType;
  image?: string | null;
  onActiveItem?: NoopType;
  activeItem?: boolean;
  CardIcon?: IconType;
  selectedInfo?: boolean;
  disabledProtect?: boolean;
  isProtected?: boolean;
  isViewer?: boolean;
}

const ListBlockItemComponent = ({
  title,
  onOpenInformationModal,
  image,
  isProtected,
  selectedInfo,
  disabledProtect,
  onItemClick,
  onActiveItem,
  activeItem,
  CardIcon,
  isViewer,
}: ListBlockItemProps) => {
  return (
    <ListBlockItemWrapper
      onClick={onItemClick}
      onMouseDown={(e) => {
        onActiveItem && onActiveItem();
        e.stopPropagation();
      }}
      activeItem={activeItem}
      selectedInfo={selectedInfo}
    >
      <ImageItem image={image} CardIcon={CardIcon} disabledProtect={disabledProtect || !isProtected} />
      <ContentItem
        disabledInfo={isViewer}
        title={title}
        onOpenInformationModal={onOpenInformationModal}
        selectedInfo={selectedInfo}
      />
    </ListBlockItemWrapper>
  );
};

export const ListBlockItem = memo(ListBlockItemComponent);
