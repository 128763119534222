import { NoopType, OnValueChange } from 'types/global';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import { codeEditorTitle } from 'modules/settingsContainer/common/data/SqlSettings/cosntants';
import React from 'react';
import { SqlField, SqlSection, SqlSectionProps } from 'modules/settingsContainer/common/data/SqlSettings';

export interface ModalCustomRequestProps extends OnValueChange<string | null>, Partial<Pick<SqlSectionProps, 'modelMetaData'>> {
  isOpen: boolean;
  onClose: NoopType;
  onSave: NoopType;
  onCancel: NoopType;
  hasChanges: boolean;
}

export const ModalCustomRequest = ({
  isOpen,
  onClose,
  onChange,
  onCancel,
  value,
  hasChanges,
  onSave,
  modelMetaData,
}: ModalCustomRequestProps) => {
  return (
    <ModalUniversal open={isOpen} onClose={onClose} headerText={codeEditorTitle} width="95vw" maxHeight="95vh">
      <SqlSection
        hasChanges={hasChanges}
        modelMetaData={modelMetaData}
        sqlEditorSlot={({ onEditorFocus }) => (
          <SqlField title="Выражение" handleChange={onChange} value={value || ''} onFocus={onEditorFocus} />
        )}
        onCloseEditor={() => {
          onCancel();
          onClose();
        }}
        onSaveEditor={onSave}
      />
    </ModalUniversal>
  );
};
