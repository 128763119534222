import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialModelsStoreState } from 'store/reducers/models/constants';
import { ActiveModelItemAliasInterface, AddNewTabPayload, UpdateTabByIdPayload } from 'store/reducers/models/types';
import { loadModelsFromDataAction, loadTablePreviewAction, loadTables, loadTabsAction } from 'store/reducers/models/actions';
import { getMapObject } from 'utils/utils';
import { setSliceFn } from 'constants/store';

export const modelsSlice = createSlice({
  name: 'models',
  initialState: initialModelsStoreState,
  reducers: {
    addNewTab: (state, { payload: tab }: PayloadAction<AddNewTabPayload>) => {
      state.tabs = {
        ...state.tabs,
        [tab.id]: tab,
      };
    },

    removeTabById: (state, { payload: id }: PayloadAction<string>) => {
      if (state.tabs[id]) {
        delete state.tabs[id];
      }
    },

    updateTabById: (state, { payload: { id, data } }: PayloadAction<UpdateTabByIdPayload>) => {
      const tab = state.tabs[id];

      if (tab) {
        state.tabs[id] = { ...tab, ...data };
      }
    },

    setActiveTab: (state, { payload: id }: PayloadAction<string | null>) => {
      state.activeTabId = id;
    },

    setActiveModelItemAlias: (state, { payload: alias }: PayloadAction<ActiveModelItemAliasInterface | null>) => {
      state.activeModelItemAlias = alias;
    },

    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadTables.pending, (state) => {
      state.tables = {};
      state.tablesLoading = true;
    });

    builder.addCase(loadTables.fulfilled, (state, { payload: tables }) => {
      state.tables = tables;
      state.tablesLoading = false;
    });

    builder.addCase(loadTables.rejected, (state) => {
      state.tables = {};
      state.tablesLoading = false;
    });

    builder.addCase(loadTabsAction.pending, (state) => {
      state.tabs = {};
      state.tabsLoading = true;
    });

    builder.addCase(loadTabsAction.fulfilled, (state, { payload: tabs }) => {
      state.tabs = tabs;
      state.tabsLoading = false;
    });

    builder.addCase(loadTabsAction.rejected, (state) => {
      state.tabs = {};
      state.tabsLoading = false;
    });

    builder.addCase(loadModelsFromDataAction.pending, (state) => {
      state.modelsFrom = {};
      state.modelsFromLoading = true;
    });

    builder.addCase(loadModelsFromDataAction.fulfilled, (state, { payload: modelsFrom }) => {
      state.modelsFrom = getMapObject(modelsFrom, 'id');
      state.modelsFromLoading = false;
    });

    builder.addCase(loadModelsFromDataAction.rejected, (state) => {
      state.modelsFrom = {};
      state.modelsFromLoading = false;
    });

    builder.addCase(
      loadTablePreviewAction.pending,
      (
        state,
        {
          meta: {
            arg: { table },
          },
        },
      ) => {
        state.tablePreviewLoading = state.tablePreviewLoading.add(table);
      },
    );

    builder.addCase(
      loadTablePreviewAction.fulfilled,
      (
        state,
        {
          payload: tablePreview,
          meta: {
            arg: { table },
          },
        },
      ) => {
        const tablePreviewLoading = state.tablePreviewLoading;
        tablePreviewLoading.delete(table);

        state.tablePreviewLoading = tablePreviewLoading;
        state.tablePreviews = { ...state.tablePreviews, [table]: tablePreview };
      },
    );

    builder.addCase(
      loadTablePreviewAction.rejected,
      (
        state,
        {
          meta: {
            arg: { table },
          },
        },
      ) => {
        const tablePreviewLoading = state.tablePreviewLoading;
        tablePreviewLoading.delete(table);

        state.tablePreviewLoading = tablePreviewLoading;
      },
    );
  },
});

export const { addNewTab, updateTabById, removeTabById, setActiveTab, setActiveModelItemAlias, setSlice } = modelsSlice.actions;

export default modelsSlice.reducer;
