import React, { useEffect, useMemo, useState } from 'react';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';
import { Button, Select } from 'modules/ui';
import { Text } from './styles';
import { getUsersGroups } from 'store/reducers/projectManager/getters';
import { loadUsersGroupsAction } from 'store/reducers/projectManager/actions';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { FlexContainer } from 'styles/FlexContainer';
import { accessEditSelect, accessIcons, accessItems, defaultSelectedAccess } from '../constants';
import { useSelector } from 'react-redux';
import { ModalComponentType } from 'store/reducers/modals/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { UsersGroupsInterface } from 'store/reducers/projectManager/types';
import { GroupType } from 'types/store';
import { getAdminActiveSource } from 'store/reducers/adminSources/getters';
import { addUsersGroupsSourceAccessAction, loadAdminSourceUsersAndGroupsAction } from 'store/reducers/adminSources/actions';
import { AddUsersGroupsSourceAccessPayload } from 'types/types';

export const AddAccessModal: ModalComponentType = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const activeSource = useSelector(getAdminActiveSource);
  const activeSourceId = activeSource?.id;
  const { loading, usersGroupsList } = useSelector(getUsersGroups);

  const [selectedValue, setSelectedValue] = useState<MultiSelectType<UsersGroupsInterface>[]>([]);
  const [selectedAccess, setSelectedAccess] = useState<GroupType>(defaultSelectedAccess);

  const options = useMemo(
    () => usersGroupsList.map((el) => ({ title: el.name, value: el.id, icon: accessIcons[el.entity], data: el })),
    [usersGroupsList],
  );

  useEffect(() => {
    if (activeSourceId) {
      dispatch(loadUsersGroupsAction({ sourceId: activeSourceId }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSourceId]);

  const onAdd = async () => {
    if (!selectedValue?.length) return onClose();

    try {
      const params: AddUsersGroupsSourceAccessPayload = {
        type: selectedAccess,
        sourceId: activeSourceId || '',
        users: [],
        groups: [],
      };

      selectedValue.forEach((el) => {
        const userOrGroup = el.data as UsersGroupsInterface;

        const entityMapping = {
          group: params.groups,
          user: params.users,
        };

        entityMapping[userOrGroup.entity]?.push(userOrGroup.id);
      });

      const response = await dispatch(addUsersGroupsSourceAccessAction(params)).unwrap();

      if (response && activeSourceId) {
        dispatch(loadAdminSourceUsersAndGroupsAction({ id: activeSourceId }));
      }
    } catch (error) {
      console.error('Error creating project version:', error);
    } finally {
      onClose();
    }
  };

  return (
    <FlexContainer position="relative" width="100%" flexDirection="column" padding="4px" gap="16px" height="100%">
      <LoadingOverlay loading={loading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />

      <FlexContainer flexDirection="column">
        <Text>Права</Text>
        <Select
          needBackground={false}
          width="100%"
          name={accessEditSelect}
          value={selectedAccess}
          onChange={setSelectedAccess}
          options={accessItems}
        />
      </FlexContainer>

      <FlexContainer flexDirection="column">
        <Text>Пользователю/группе</Text>
        <MultiSelect noOptionsText="Пользователи/группы не найдены" options={options} onChange={setSelectedValue} />
      </FlexContainer>

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" onClick={onAdd} />
      </FlexContainer>
    </FlexContainer>
  );
};
