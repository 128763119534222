import { Component } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ReactChildrenType } from 'types/global';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { AlertIcon } from 'assets/icons/withContainer';
import Snackbar from 'services/Snackbar';
import { errorVisualisationDescription, errorVisualisationTitle } from 'modules/visualisations/components/ErrorWrapper/constants';

interface ErrorWrapperProps {
  onError?: (error: Error) => void;
  children: ReactChildrenType;
}

export class ErrorWrapper extends Component<ErrorWrapperProps, { hasError: boolean }> {
  constructor(props: ErrorWrapperProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    if (this.props.onError) {
      Snackbar.show(
        <FlexContainer flexDirection="column" justifyContent="center">
          <PrimaryTextSpan color="#fff">{errorVisualisationTitle}</PrimaryTextSpan>
          <PrimaryTextSpan color="#fff">{errorVisualisationDescription}</PrimaryTextSpan>
        </FlexContainer>,
        'error',
      );
      this.props.onError(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <FlexContainer
          gap="8px"
          padding="20px 10px"
          height="100%"
          width="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor={`var(${ColorVarsEnum.Level_4_menu})`}
        >
          <FlexContainer>
            <IconWrapper
              iconHeight="20px"
              iconWidth="20px"
              cursor="default"
              opacity={0}
              colorVar={ColorVarsEnum.Alert}
              hoverColorVar={ColorVarsEnum.Alert}
              Icon={AlertIcon}
            />
          </FlexContainer>
          <FlexContainer>
            <PrimaryTextSpan
              color={`var(${ColorVarsEnum.Alert})`}
              textAlign="center"
              fontWeight="normal"
              fontSize="14px"
              lineHeight="17px"
            >
              {errorVisualisationTitle}
            </PrimaryTextSpan>
          </FlexContainer>
          <FlexContainer>
            <PrimaryTextSpan
              color={`var(${ColorVarsEnum.Alert})`}
              textAlign="center"
              fontWeight="normal"
              fontSize="12px"
              lineHeight="14px"
            >
              {errorVisualisationDescription}
            </PrimaryTextSpan>
          </FlexContainer>
        </FlexContainer>
      );
    }

    return this.props.children;
  }
}
