import { OnValueChange } from 'types/global';
import { DynamicsMarkerSettings as DynamicsMarkerSettingsInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React, { useRef } from 'react';
import { dynamicsMarkerPositionOptions } from 'modules/settingsContainer/common/data/DynamicsMarkerSettings/constants';

type DynamicsMarkerSettingsProps = OnValueChange<DynamicsMarkerSettingsInterface>;

export const DynamicsMarkerSettings = ({ value, onChange }: DynamicsMarkerSettingsProps) => {
  /* TODO: Make dynamic markers as SQL query  */
  // const { isOpen, onOpen, onClose } = useModalState();
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const { isShow, position } = value;

  return (
    <MainContainerSettings
      titleText="Маркер динамики"
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
      ref={mainContainerRef}
    >
      <GroupContainerSettings titleText="Позиционирование">
        <ElementContainerSettings>
          <SettingsRadio
            onChange={({ value: position }) => onChange({ ...value, position })}
            activeValue={getActiveRadioValue(dynamicsMarkerPositionOptions, position)}
            options={dynamicsMarkerPositionOptions}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
      {/*<GroupContainerSettings>*/}
      {/*  <ElementContainerSettings>*/}
      {/*<FlexContainer width="100%" alignItems="center" justifyContent="center">*/}
      {/*  <Button text="Настроить условие" heightSize="small" onClick={onOpen} />*/}
      {/*</FlexContainer>*/}
      {/*<FixedAreaSettings*/}
      {/*  onClose={onClose}*/}
      {/*  open={isOpen}*/}
      {/*  headerText="Настроить маркер динамики"*/}
      {/*  minWidth="640px"*/}
      {/*  anchorEl={mainContainerRef.current}*/}
      {/*>*/}
      {/*  <ValueMarkersSettings*/}
      {/*    value={{ defaultMarker, valueMarkers }}*/}
      {/*    onChange={({ defaultMarker, valueMarkers }) => onChange({ ...value, defaultMarker, valueMarkers })}*/}
      {/*    onClose={onClose}*/}
      {/*  />*/}
      {/*</FixedAreaSettings>*/}
      {/*  </ElementContainerSettings>*/}
      {/*</GroupContainerSettings>*/}
    </MainContainerSettings>
  );
};
