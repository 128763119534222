import { TState } from 'store/index';
import {
  createFlow,
  createGroup,
  createGroupUsersAccess,
  createSource,
  deleteFlows,
  deleteGroup,
  deleteGroupUserAccess,
  deleteSources,
  loadGroupAccessUsers,
  loadGroupFlows,
  loadGroups,
  loadGroupSources,
  loadNoGroupsFlows,
  loadNoGroupSources,
  loadUsersNoGroup,
  updateGroup,
  updateGroupFlow,
  updateGroupSource,
} from './api';
import {
  addGroup,
  changeActiveGroup,
  deleteByIdFlow,
  deleteByIdGroup,
  deleteByIdSource,
  deleteByIdUsersAccess,
  updateFlows,
  updateGroups,
  updateSources,
} from './index';
import { getGroupFlows, getGroups, getGroupSources } from './getters';
import {
  CreateFlowPayload,
  CreateSourcePayload,
  CreateUsersAccessPayload,
  DeleteFlowPayload,
  DeleteSourcePayload,
  DeleteUserAccessPayload,
  GetFlowsPayload,
  GetUsersAccessNoGroupPayload,
  GroupAccessUserListInterface,
  GroupFlowsListInterface,
  GroupPayload,
  GroupsActionsTypes,
  GroupsListInterface,
  GroupSourcesListInterface,
  UpdateFlowPayload,
  UpdateSourcePayload,
} from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadGroupsAction = createAsyncThunk<GroupsListInterface[], void, { rejectValue: null }>(
  GroupsActionsTypes.LOAD_GROUPS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadGroups();

      return response.data.adminGroupList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as GroupsListInterface[];
    }
  },
);

export const changeActiveGroupAction = createAsyncThunk<void, GroupsListInterface>(
  GroupsActionsTypes.ACTIVE_GROUP_ID,
  ({ id, title }, { dispatch }) => {
    dispatch(changeActiveGroup({ id, title }));
  },
);

export const deleteGroupAction = createAsyncThunk<string, string, { rejectValue: null }>(
  GroupsActionsTypes.DELETE_GROUP,
  async (userId, { rejectWithValue }) => {
    try {
      const response = await deleteGroup(userId);
      Snackbar.show('Удалено', 'success');

      return response.data.adminGroup.id;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteByIdGroupAction = createAsyncThunk(GroupsActionsTypes.DELETE_BY_ID_GROUP, (groupId: string, { dispatch }) => {
  dispatch(deleteByIdGroup({ id: groupId }));
});

export const createGroupAction = createAsyncThunk<FastBoard.API.ApiAdminGroupListItemDTO, GroupPayload, { rejectValue: null }>(
  GroupsActionsTypes.CREATE_GROUP,
  async (props, { rejectWithValue }) => {
    try {
      const response = await createGroup(props);
      Snackbar.show('Группа создана', 'success');

      return response.data.adminGroup;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateGroupAction = createAsyncThunk<FastBoard.API.ApiAdminGroupListItemDTO, GroupsListInterface>(
  GroupsActionsTypes.UPDATE_GROUP,
  async (props, { rejectWithValue }) => {
    try {
      const response = await updateGroup(props);
      Snackbar.show('Изменение успешно сохранены', 'success');

      return response.data.adminGroup;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateGroupsAction = createAsyncThunk<void, { group: GroupsListInterface }>(
  GroupsActionsTypes.UPDATE_GROUPS,
  ({ group }, { dispatch, getState }) => {
    const groups = getGroups(getState() as TState).groupsList.map((value) =>
      value.id === group.id ? { ...value, ...group } : value,
    );

    dispatch(updateGroups(groups));
  },
);

export const addGroupAction = createAsyncThunk<void, GroupsListInterface>(GroupsActionsTypes.ADD_GROUP, (data, { dispatch }) => {
  dispatch(addGroup(data));
});

/* Group users */

export const loadGroupAccessUsersAction = createAsyncThunk<
  FastBoard.API.ApiAdminGroupsAccessItemDTO[],
  string,
  { rejectValue: null }
>(GroupsActionsTypes.LOAD_GROUP_ACCESS_USERS, async (groupId, { rejectWithValue }) => {
  try {
    const response = await loadGroupAccessUsers(groupId);

    return response.data.adminGroupsAccessList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as GroupAccessUserListInterface[];
  }
});

export const loadUsersNoGroupAction = createAsyncThunk<
  FastBoard.API.ApiAdminUserGroupListItemResponseDTO[],
  GetUsersAccessNoGroupPayload,
  { rejectValue: null }
>(GroupsActionsTypes.LOAD_NO_GROUP_ACCESS_USERS, async ({ groupId }, { rejectWithValue }) => {
  try {
    const response = await loadUsersNoGroup({ groupId });

    return response.data.adminUserGroupList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.ApiAdminUserGroupListItemResponseDTO[];
  }
});

export const createGroupUsersAccessAction = createAsyncThunk<
  FastBoard.API.ApiAdminGroupsAddManyUsersResponseDTO,
  CreateUsersAccessPayload,
  { rejectValue: null }
>(GroupsActionsTypes.CREATE_USERS, async (props, { rejectWithValue }) => {
  try {
    const response = await createGroupUsersAccess(props);
    Snackbar.show('Пользователи добавлены', 'success');

    return response.data.adminGroupsAddManyUsersMessage;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

export const deleteGroupUserAccessAction = createAsyncThunk<string, DeleteUserAccessPayload, { rejectValue: null }>(
  GroupsActionsTypes.DELETE_USER_ACCESS,
  async (props, { rejectWithValue }) => {
    try {
      const response = await deleteGroupUserAccess(props);
      Snackbar.show('Удалено', 'success');

      return response.data.adminGroupsAccessRemoveMessage;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteByIdGroupUserAccessAction = createAsyncThunk(
  GroupsActionsTypes.DELETE_BY_ID_USER_ACCESS,
  (userId: string, { dispatch }) => {
    dispatch(deleteByIdUsersAccess({ id: userId }));
  },
);

/* Group flows */

export const loadNoGroupFlowsAction = createAsyncThunk<FastBoard.API.ApiAdminFlowDTO[], GetFlowsPayload, { rejectValue: null }>(
  GroupsActionsTypes.LOAD_NO_GROUP_FLOWS,
  async (params, { rejectWithValue }) => {
    try {
      const response = await loadNoGroupsFlows(params);

      return response.data.adminFlowsList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FastBoard.API.ApiAdminFlowDTO[];
    }
  },
);

export const loadGroupFlowsAction = createAsyncThunk<GroupFlowsListInterface[], string, { rejectValue: null }>(
  GroupsActionsTypes.LOAD_GROUP_FLOWS,
  async (groupId, { rejectWithValue }) => {
    try {
      const response = await loadGroupFlows(groupId);

      return response.data.adminFlowsWithTypeList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as GroupFlowsListInterface[];
    }
  },
);

export const updateGroupFlowAction = createAsyncThunk<FastBoard.API.ApiAdminFlowUpdateTypeFromGroupDTO, UpdateFlowPayload>(
  GroupsActionsTypes.UPDATE_GROUP_FLOW,
  async (props, { rejectWithValue }) => {
    try {
      const response = await updateGroupFlow(props);
      Snackbar.show('Изменение успешно сохранены', 'success');

      return response.data.adminFlowGroup;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateGroupFlowsAction = createAsyncThunk<void, { flow: GroupFlowsListInterface }>(
  GroupsActionsTypes.UPDATE_GROUP_FLOWS,
  ({ flow }, { dispatch, getState }) => {
    const flows = getGroupFlows(getState() as TState).groupFlowsList.map((value) =>
      value.id === flow.id ? { ...value, ...flow } : value,
    );

    dispatch(updateFlows(flows));
  },
);

export const deleteGroupFlowAction = createAsyncThunk<string, DeleteFlowPayload, { rejectValue: null }>(
  GroupsActionsTypes.DELETE_FLOW,
  async (props, { rejectWithValue }) => {
    try {
      const response = await deleteFlows(props);
      Snackbar.show('Удалено', 'success');

      return response.data.adminFlowGroup.flowId;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteByIdGroupFlowAction = createAsyncThunk(
  GroupsActionsTypes.DELETE_BY_ID_FLOW,
  (flowId: string, { dispatch }) => {
    dispatch(deleteByIdFlow({ id: flowId }));
  },
);

export const createGroupFlowAction = createAsyncThunk<
  FastBoard.API.ApiAdminFlowGroupResponseDTO,
  CreateFlowPayload,
  { rejectValue: null }
>(GroupsActionsTypes.CREATE_FLOW, async (props, { rejectWithValue }) => {
  try {
    const response = await createFlow(props);
    Snackbar.show('Поток добавлен', 'success');

    return response.data.adminGroupAddFlowsMessage;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

/* Group sources */

export const loadGroupSourcesAction = createAsyncThunk<GroupSourcesListInterface[], string, { rejectValue: null }>(
  GroupsActionsTypes.LOAD_GROUP_SOURCES,
  async (groupId, { rejectWithValue }) => {
    try {
      const response = await loadGroupSources(groupId);

      return response.data.adminSourceGroupList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as GroupSourcesListInterface[];
    }
  },
);

export const loadNoGroupSourcesAction = createAsyncThunk<
  FastBoard.API.ApiAdminSourcesListItemShort[],
  string,
  { rejectValue: null }
>(GroupsActionsTypes.LOAD_NO_GROUP_SOURCES, async (groupId, { rejectWithValue }) => {
  try {
    const response = await loadNoGroupSources(groupId);

    return response.data.adminSourcesListShort;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.ApiAdminSourcesListItemShort[];
  }
});

export const updateGroupSourceAction = createAsyncThunk<FastBoard.API.ApiAdminSourceGroupResponseDTO, UpdateSourcePayload>(
  GroupsActionsTypes.UPDATE_GROUP_SOURCE,
  async (props, { rejectWithValue }) => {
    try {
      const response = await updateGroupSource(props);
      Snackbar.show('Изменение успешно сохранены', 'success');

      return response.data.adminSourceGroup;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateGroupSourcesAction = createAsyncThunk<void, { source: GroupSourcesListInterface }>(
  GroupsActionsTypes.UPDATE_GROUP_SOURCES,
  ({ source }, { dispatch, getState }) => {
    const sources = getGroupSources(getState() as TState).groupSourcesList.map((value) =>
      value.id === source.id ? { ...value, ...source } : value,
    );

    dispatch(updateSources(sources));
  },
);

export const deleteGroupSourceAction = createAsyncThunk<string, DeleteSourcePayload, { rejectValue: null }>(
  GroupsActionsTypes.DELETE_SOURCE,
  async (props, { rejectWithValue }) => {
    try {
      const response = await deleteSources(props);
      Snackbar.show('Удалено', 'success');

      return response.data.adminSourceDeleteGroup;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteByIdGroupSourceAction = createAsyncThunk(
  GroupsActionsTypes.DELETE_BY_ID_SOURCE,
  (sourceId: string, { dispatch }) => {
    dispatch(deleteByIdSource({ id: sourceId }));
  },
);

export const createGroupSourceAction = createAsyncThunk<
  FastBoard.API.ApiAdminGroupAddSourcesResponseDTO,
  CreateSourcePayload,
  { rejectValue: null }
>(GroupsActionsTypes.CREATE_SOURCE, async (props, { rejectWithValue }) => {
  try {
    const response = await createSource(props);
    Snackbar.show('Подключение добавлено', 'success');

    return response.data.adminGroupAddSourcesMessage;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});
