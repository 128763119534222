import { SettingsRadioProps } from 'modules/settingsContainer/SettingsRadio';
import { RadioButtonWrapper, RadioItem, RadioItemProps } from 'modules/ui/RadioButtonSelector/styles';

interface RadioButtonSelectorProps<ValueType extends string | number>
  extends SettingsRadioProps<ValueType>,
    Partial<Pick<RadioItemProps, 'size'>> {}

export const RadioButtonSelector = <ValueType extends string | number>({
  activeValue,
  options,
  onChange,
  size = 'normal',
}: RadioButtonSelectorProps<ValueType>) => {
  return (
    <RadioButtonWrapper>
      {options.map((option) => {
        const { value, label, disabled } = option;

        return (
          <RadioItem
            size={size}
            disabled={disabled}
            selected={activeValue.value === value}
            onClick={() => !disabled && onChange(option)}
            key={value}
          >
            {label}
          </RadioItem>
        );
      })}
    </RadioButtonWrapper>
  );
};
