import styled, { css } from 'styled-components';
import { DownIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { defaultDropDownStyleMixin, defaultScrollbarMixin } from 'constants/styles';

interface MultipleFilterWrapperProps {
  isOpen: boolean;
}

export const MultipleFilterWrapper = styled.div<MultipleFilterWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 9px;
  gap: 10px;
  border: 1px solid transparent;
  min-width: 250px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${defaultDropDownStyleMixin};

      :after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(${ColorVarsEnum.Level_5_application});
        z-index: -1;
      }
    `};
`;

export const RotatedDownIcon = styled(DownIcon)`
  rotate: 180deg;
`;

export const FilterValuesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  min-height: 60px;
  overflow: auto;
  gap: 2px;

  ${defaultScrollbarMixin};
`;

interface FilterValueItemProps {
  selected: boolean;
}

export const FilterValueItem = styled.div<FilterValueItemProps>`
  position: relative;
  width: 100%;
  font-weight: normal;
  font-family: Roboto, sans-serif;
  transition: all 200ms ease 0s;
  font-size: 14px;
  line-height: 17px;
  color: var(${ColorVarsEnum.Level_1});
  padding: 8px 12px;
  cursor: pointer;

  :after {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(${ColorVarsEnum.Accent});
    opacity: 0.3;
    z-index: 0;
  }

  :hover {
    :after {
      display: block;
      opacity: 0.2;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      :after {
        display: block;
      }
    `}
`;
