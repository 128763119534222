import { ModelsStateInterface, ModelTabInterface } from 'store/reducers/models/types';

export const initialModelsStoreState: ModelsStateInterface = {
  tabs: {},
  tabsLoading: false,
  tables: {},
  tablesLoading: false,
  tablePreviews: {},
  tablePreviewLoading: new Set(),
  activeTabId: null,
  activeModelItemAlias: null,
  modelsFrom: {},
  modelsFromLoading: false,
};

export const defaultTabName = 'Модель ';

export const getNewTab: (params: { id: string; name: string }) => ModelTabInterface = ({ id, name }) => ({
  id,
  name,
  zoom: 0.5,
  models: [],
});
