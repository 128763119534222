import React from 'react';
import { TableCell, TableTextBody, TotalRow } from '../styles';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { Column, TotalValuesType } from 'modules/visualisations/Table/visualisation/types';

interface TotalComponentProps {
  columns: Column[];
  cellPaddings: string;
  columnVisibility: string[];
  totalValues: TotalValuesType;
}

export const TotalComponent = ({ columns, cellPaddings, columnVisibility, totalValues }: TotalComponentProps) => (
  <TotalRow>
    {columns.map(({ dataAccessor, isIncision, Header }, index) => {
      const totalValue = totalValues[dataAccessor];

      if (columnVisibility.length > 0 && !columnVisibility.includes(Header as string) && isIncision) {
        return null;
      }

      return (
        <TableCell key={dataAccessor}>
          <TableTextBody justifyContent={isIncision ? 'flex-start' : 'flex-end'} padding={cellPaddings}>
            <PrimaryTextSpan lineHeight="12px" fontSize="12px" fontWeight="bold">
              {index === 0 ? 'Итоги' : totalValue !== undefined ? totalValue : ''}
            </PrimaryTextSpan>
          </TableTextBody>
        </TableCell>
      );
    })}
  </TotalRow>
);
