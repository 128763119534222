import { PositionSettingType, ProportionsImageSettingType } from 'types/store';
import { TextVariablesInterface, VisualisationValuesInterface } from 'store/reducers/visualisations/types';
import { getVariablePattern } from 'constants/global';
import { FormattingRecordType } from 'modules/visualisations/hooks/useFormat';
import { ObjectFit } from 'react-gallery-carousel';
import { MapRecordType } from 'types/global';
import { findVariablesAndLinks, FindVariablesAndLinks, replaceValueIfPresent } from 'utils/utils';
import {
  TextVariablesType,
  TransformationTextVariablesElementInterface,
  VariablesStyle,
} from 'modules/visualisations/Text/visualisation/types';

export const textAlignMapper: Record<PositionSettingType, 'left' | 'center' | 'right'> = {
  'flex-start': 'left',
  center: 'center',
  'flex-end': 'right',
};

export const getImageObjectFit: Record<ProportionsImageSettingType, ObjectFit> = {
  auto: 'scale-down',
  contain: 'contain',
  cover: 'cover',
  stretch: 'initial',
};

export const getImageProportions: Record<ProportionsImageSettingType, 'contain' | 'auto' | 'cover' | string> = {
  auto: 'auto',
  contain: 'contain',
  cover: 'cover',
  stretch: '100% 100%',
};

export const transformationTextVariables = (
  variables: TextVariablesInterface[],
  visualisationNormalizedValues: VisualisationValuesInterface,
  formatting: FormattingRecordType,
  text: string,
) => {
  const variablesValues: MapRecordType<string> = variables.reduce((obj, item) => {
    return { ...obj, [item.name]: visualisationNormalizedValues[item.name]?.[0] };
  }, {});

  return Object.keys(variablesValues).reduce((resultText, key) => {
    const variableValue = variablesValues[key] !== undefined ? variablesValues[key] : '-',
      value = formatting[key]?.(variableValue) || variableValue;

    return resultText.split(getVariablePattern(key)).join(String(value));
  }, text);
};

export const getOverflowStyle = (showScroll: boolean, showAnotherScroll: boolean) =>
  showScroll ? 'auto' : showAnotherScroll ? 'hidden' : undefined;

export interface OnVariableInterface {
  element: FindVariablesAndLinks;
  createVariablesStyles: VariablesStyle;
  visualisationNormalizedValues: VisualisationValuesInterface;
  formatting: FormattingRecordType;
  variableSettingsText?: string;
}

export const onVariable = ({
  element,
  createVariablesStyles,
  visualisationNormalizedValues,
  formatting,
  variableSettingsText,
}: OnVariableInterface): {
  description: string | undefined;
  style: Record<string, string | number | undefined>;
  text: string;
  type: TextVariablesType;
}[] => {
  const variableName = element.text;

  const variableValue = visualisationNormalizedValues[variableName]?.[0];

  const variableValueRes = variableValue !== undefined ? variableValue : '-';
  const formattedValue = formatting[variableName]?.(variableValue) || variableValueRes;
  const style = createVariablesStyles[variableName];

  const text = replaceValueIfPresent(variableSettingsText || '', String(formattedValue));
  const textTransform = findVariablesAndLinks(text);

  return textTransform
    .map(({ text, type, description }) => ({
      type: type === 'text' ? 'variable' : type,
      text,
      style,
      description,
    }))
    .flat();
};

export const onVariableLink = (element: FindVariablesAndLinks): TransformationTextVariablesElementInterface | null => {
  if (!element.text || !element.description) return null;
  return { type: 'link', text: element.text, description: element.description };
};

export const onVariableText = (element: FindVariablesAndLinks): TransformationTextVariablesElementInterface | null => {
  if (!element.text) return null;
  return { type: 'text', text: String(element.text) };
};

export interface OnLinkVariableInterface {
  element: FindVariablesAndLinks;
  visualisationNormalizedValues: VisualisationValuesInterface;
  variableSettingsText?: string;
  createVariablesStyles: VariablesStyle;
}

export const onLinkVariable = ({
  visualisationNormalizedValues,
  element,
  variableSettingsText,
  createVariablesStyles,
}: OnLinkVariableInterface): TransformationTextVariablesElementInterface | null => {
  if (!element.text || !element.description) return null;

  const variableValue = visualisationNormalizedValues[element.text]?.[0];

  const variableValueRes = variableValue !== undefined ? variableValue : '-';
  const style = createVariablesStyles[element.text];

  const text = replaceValueIfPresent(variableSettingsText || '', String(variableValueRes));

  return { type: 'linkVariables', text: text, description: element.description, style };
};
