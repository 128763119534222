import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { ExtendedSettingsInterface } from 'store/reducers/visualisations/types';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { locationOptions, positionOptions, typeOptions } from 'modules/settingsContainer/common/view/ExtendedSettings/constants';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { OnValueChange } from 'types/global';
import { pxUnit } from 'constants/global';

type ExtendedSettingsProps = OnValueChange<ExtendedSettingsInterface> & { title: string };

export const ExtendedSettings = ({ value, onChange, title }: ExtendedSettingsProps) => {
  const {
      type,
      isShow,
      position: { type: positionType, value: positionValue },
      location: { type: locationType, value: locationValue },
      width,
    } = value,
    isHorizontalLocation = locationType === 'left' || locationType === 'right';

  const positionOptionsValue = positionOptions(isHorizontalLocation);

  return (
    <MainContainerSettings
      titleText={title}
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          <SettingsRadio
            onChange={({ value: type }) => onChange({ ...value, type })}
            activeValue={getActiveRadioValue(typeOptions, type)}
            options={typeOptions}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>

      <>
        <GroupContainerSettings titleText="Расположение">
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: type }) =>
                onChange({
                  ...value,
                  location: { ...value.location, type, value: locationValue },
                  position: { type: 'center', value: positionValue },
                })
              }
              activeValue={getActiveRadioValue(locationOptions, locationType)}
              options={locationOptions}
            />
          </ElementContainerSettings>
          {type === 'manual' && (
            <ElementContainerSettings>
              <NumberPropertiesBlock
                properties="Отступ"
                name="width"
                unit={pxUnit}
                value={locationValue}
                onChange={(locationValue) =>
                  onChange({
                    ...value,
                    location: { ...value.position, type: locationType, value: locationValue },
                  })
                }
              />
            </ElementContainerSettings>
          )}
        </GroupContainerSettings>

        <GroupContainerSettings titleText="Позиционирование">
          <SettingsRadio
            onChange={({ value: type }) => onChange({ ...value, position: { type, value: positionValue } })}
            activeValue={getActiveRadioValue(positionOptionsValue, positionType)}
            options={positionOptionsValue}
          />
          {type === 'manual' && positionType !== 'center' && (
            <ElementContainerSettings>
              <NumberPropertiesBlock
                properties="Отступ"
                name="width"
                unit={pxUnit}
                value={positionValue}
                onChange={(positionValue) =>
                  onChange({
                    ...value,
                    position: { ...value.position, type: positionType, value: positionValue },
                  })
                }
              />
            </ElementContainerSettings>
          )}
        </GroupContainerSettings>
        {isHorizontalLocation && (
          <GroupContainerSettings>
            <ElementContainerSettings>
              <NumberPropertiesBlock
                properties="Ширина"
                name="width"
                unit={pxUnit}
                value={width}
                onChange={(width) => onChange({ ...value, width })}
              />
            </ElementContainerSettings>
          </GroupContainerSettings>
        )}
      </>
    </MainContainerSettings>
  );
};
