import { format } from 'sql-formatter';
import { Parser, Select } from 'node-sql-parser';

export const formatSql = (sql?: string) => (sql && sql !== '' ? format(sql, { language: 'db2' }) : undefined);

export const combineSqlStrings = (sql1: string, sql2: string | null) => {
  if (!sql2) {
    return sql1;
  }

  try {
    const sqlParser = new Parser();

    const ast1: any = sqlParser.astify(sql1);
    const ast2: any = sqlParser.astify(`SELECT * ${sql2}`);

    const removeNulls = (array: any[]) => array.filter((item) => item !== null);

    const combineConditions = (cond1: any, cond2: any) => {
      if (cond1 && cond2) {
        return {
          type: 'binary_expr',
          operator: 'AND',
          left: cond1,
          right: cond2,
        };
      }
      return cond1 || cond2 || null;
    };

    const combineLimits = (limit1: any, limit2: any) => {
      if (limit1 && limit2) {
        return {
          separator: '',
          value: [Math.min(limit1.value[0], limit2.value[0])],
        };
      }
      return limit1 || limit2 || null;
    };

    const combinedAST: Select = {
      type: 'select',
      options: null,
      distinct: null,
      columns: removeNulls((ast1.columns || []).concat(ast2.columns || []).filter((col: any) => col !== '*')),
      from: removeNulls((ast1.from || []).concat(ast2.from || [])),
      where: combineConditions(ast1.where, ast2.where),
      groupby: removeNulls((ast1.groupby || []).concat(ast2.groupby || [])),
      having: combineConditions(ast1.having, ast2.having),
      orderby: removeNulls((ast1.orderby || []).concat(ast2.orderby || [])),
      limit: combineLimits(ast1.limit, ast2.limit),
      with: null,
    };

    const combinedSQL = sqlParser.sqlify(combinedAST);

    return combinedSQL;
  } catch (error) {
    console.error('Error combining SQL:', error);
    return sql1;
  }
};
