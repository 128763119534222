import { store } from 'store';
import { updateBubbleDataSettingsAction } from 'store/reducers/visualisations/actions';
import {
  BubbleSizeModeSettingsInterface,
  ColorByInterface,
  FormattingInterface,
  GradientColorByInterface,
  MinAndMaxInterface,
  ShowValueSettingsInterface,
} from 'store/reducers/visualisations/types';
import { OnChangeValue } from 'modules/visualisations/Bubble/settings/DataTab/types';
import { ColorValuesByThemeType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';

const dispatch = store.dispatch;

export const onMinAndMaxChange: OnChangeValue<MinAndMaxInterface> = (dataSettings, minAndMax, id) =>
  dispatch(
    updateBubbleDataSettingsAction({
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, minAndMax } } : indicator,
      ),
    }),
  );

/* Incision change */

export const onColorIncisionChange: OnChangeValue<PaletteValuesByThemeType | null> = (dataSettings, colors, id) =>
  dispatch(
    updateBubbleDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, colors } : incision)),
      indicators: dataSettings.indicators.map((incision) => ({ ...incision, color: null })),
    }),
  );

/* Indicator change */

export const onChangeShowValue: OnChangeValue<ShowValueSettingsInterface> = (dataSettings, showValue, id) =>
  dispatch(
    updateBubbleDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, showValue } } : incision,
      ),
    }),
  );

export const onColorIndicatorChange: OnChangeValue<ColorValuesByThemeType | null> = (dataSettings, color, id) => {
  dispatch(
    updateBubbleDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((incision) => (id === incision.id ? { ...incision, color } : incision)),
      incisions: dataSettings.incisions.map((incision) => ({ ...incision, colors: null })),
    }),
  );
};

export const onChangeIndicatorFormatting: OnChangeValue<FormattingInterface> = (dataSettings, formatting, id) =>
  dispatch(
    updateBubbleDataSettingsAction({
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, formatting } } : indicator,
      ),
    }),
  );

export const onChangeIndicatorSizeMode: OnChangeValue<BubbleSizeModeSettingsInterface> = (dataSettings, sizeSettings, id) =>
  dispatch(
    updateBubbleDataSettingsAction({
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, sizeSettings } } : indicator,
      ),
    }),
  );

export const onChangeIndicatorNameFromDataBase: OnChangeValue<boolean> = (dataSettings, nameFromDatabase, id) =>
  dispatch(
    updateBubbleDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, nameFromDatabase } } : indicator,
      ),
    }),
  );

export const onBubbleBackgroundBySettingsChange = (gradientBackgroundByValueSettings: GradientColorByInterface) =>
  dispatch(updateBubbleDataSettingsAction({ gradientBackgroundByValueSettings }));

export const onBubbleColorBySettingsChange = (gradientColorByValueSettings: ColorByInterface) =>
  dispatch(updateBubbleDataSettingsAction({ gradientColorByValueSettings }));

export const onIndicatorRangeIdChange = (rangeIndicatorId: string) =>
  dispatch(
    updateBubbleDataSettingsAction({
      rangeIndicatorId,
    }),
  );
