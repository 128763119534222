import React from 'react';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { defaultLineAndBarDataSettings, defaultLineAndBarViewSettings } from 'store/reducers/visualisations/constants';
import { DefaultViewSettings } from 'modules/settingsContainer/common/view/DefaultViewSettings';
import { BooleanTipsSettings } from 'modules/settingsContainer/common/view/BooleanTipsSettings';
import { AxisSettings } from 'modules/settingsContainer/common/view/AxisSettings';
import {
  onAxisAdditionalIndicatorChange,
  onAxisIncisionChange,
  onAxisIndicatorChange,
  onChangeHorizontalZoomChange,
  onChangePaddingVisualisation,
  onChangeVerticalZoomChange,
  onIncisionSelectorPositionChange,
  onLegendSettingsChange,
  onShowTipsChange,
} from 'modules/visualisations/LineAndBar/settings/ViewTab/constants';
import { ExtendedSettings } from 'modules/settingsContainer/common/view/ExtendedSettings';
import { IncisionsSelectorPositionSettings } from 'modules/settingsContainer/common/IncisionsSelectorPositionSettings';
import { PaddingVisualisationSettings } from 'modules/settingsContainer/common/data/PaddingVisualisationSettings';
import { ZoomSettings } from 'modules/settingsContainer/common/view/VerticalZoomSettings';
import { showLegendTitle } from 'modules/settingsContainer/common/view/LegendSettings/constants';

export const ViewTab = () => {
  const {
    positionConfig,
    viewSettings,
    dataSettings: { rotateTo90 },
  } = useGetActiveVisualisationSettings({
    defaultView: defaultLineAndBarViewSettings,
    defaultData: defaultLineAndBarDataSettings,
  });

  return (
    <>
      <DefaultViewSettings
        SecondSlot={
          <>
            <IncisionsSelectorPositionSettings
              value={viewSettings.incisionSelectorPosition}
              onChange={onIncisionSelectorPositionChange}
            />
            <ExtendedSettings title={showLegendTitle} value={viewSettings.legendSettings} onChange={onLegendSettingsChange} />
          </>
        }
        ThirdSlot={
          <>
            <BooleanTipsSettings value={viewSettings.showTips} onChange={onShowTipsChange} />
            <AxisSettings
              isRotated={rotateTo90}
              value={viewSettings.axisIncisionSettings}
              onChange={onAxisIncisionChange}
              titleText="Ось разрезов"
              type="incision"
              disabledOrderValue
            />
            <AxisSettings
              isRotated={rotateTo90}
              value={viewSettings.axisIndicatorSettings}
              onChange={(axisIndicatorSettings) => onAxisIndicatorChange(axisIndicatorSettings, viewSettings)}
              titleText="Ось показателей"
              type="indicator"
            />
            <AxisSettings
              isRotated={rotateTo90}
              value={viewSettings.axisAdditionalIndicatorSettings}
              onChange={(axisAdditionalIndicatorSettings) =>
                onAxisAdditionalIndicatorChange(axisAdditionalIndicatorSettings, viewSettings)
              }
              titleText="Ось доп. показателей"
              type="indicator"
            />
            <ZoomSettings
              title="Приближение по вертикали"
              value={viewSettings.verticalZoom}
              onChange={onChangeVerticalZoomChange}
            />
            <ZoomSettings
              title="Приближение по горизонтали"
              value={viewSettings.horizontalZoom}
              onChange={onChangeHorizontalZoomChange}
            />
            <PaddingVisualisationSettings value={viewSettings.visualisationPaddings} onChange={onChangePaddingVisualisation} />
          </>
        }
        viewSettings={viewSettings}
        positionConfig={positionConfig}
      />
    </>
  );
};
