import { IdInterface, LoadingInterface, StatusType } from 'types/store';

export interface TaskInterface extends IdInterface {
  name: string;
  isActive: boolean;
  schedule: string;
  type: 'project_import' | 'import_ad' | 'delete_old_projects';
  message?: string;
  duration?: string;
  status?: StatusType;
  lastStartAt?: string;
  nextStartAt?: string;
  startedTask?: string;
  finishedTask?: string;
}

export interface AdminTasksDataInterface extends LoadingInterface {
  tasks: TaskInterface[];
  taskInfo: TaskInterface | null;
}

export interface AdminTasksSliceStateInterface {
  adminTasksData: AdminTasksDataInterface;
}

/* Action Types */
export enum AdminTasksActionsTypes {
  LOAD_ADMIN_TASKS = 'LOAD_ADMIN_TASKS',
  DELETE_TASK_BY_ID = 'DELETE_TASK_BY_ID',
  DELETE_TASK = 'DELETE_TASK',
  CREATE_TASK = 'CREATE_TASK',
  ADD_TASK = 'ADD_TASK',
  ADD_TASK_INFO = 'ADD_TASK_INFO',
  UPDATE_TASK = 'UPDATE_TASK',
  UPDATE_TASKS = 'UPDATE_TASKS',
  LOAD_ADMIN_FLOWS = 'LOAD_ADMIN_FLOWS',
  LOAD_ADMIN_SHORT_FLOW = 'LOAD_ADMIN_SHORT_FLOW',
}

/* Payload Interfaces */
