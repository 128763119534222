import React from 'react';
import { useAppDispatch } from 'store';
import { ThemesListSettings } from 'modules/workspace/components/panelSettingsApp/tabsContent/ThemesTab/ThemesListSettings';
import { useSelector } from 'react-redux';
import { getActiveTheme, getThemesAsArray } from 'store/reducers/themes/getters';
import { NoopValueType } from 'types/global';
import { ThemeColorItemInterface, ThemeItemInterface } from 'store/reducers/themes/types';
import { addNewThemeByIdAction, removeThemeByIdAction, updateThemeAction } from 'store/reducers/themes/actions';
import { setActiveThemeId } from 'store/reducers/themes';
import { ThemesColorSettings } from 'modules/workspace/components/panelSettingsApp/tabsContent/ThemesTab/ThemesColorSettings';
import { CodeEditorThemeSettings } from 'modules/workspace/components/panelSettingsApp/tabsContent/ThemesTab/CodeEditorThemeSettings';

export const ThemesTab = () => {
  const dispatch = useAppDispatch();

  const themes = useSelector(getThemesAsArray);
  const activeTheme = useSelector(getActiveTheme);

  if (!activeTheme) return null;

  const onActiveThemeChange: NoopValueType<ThemeItemInterface> = (theme) => dispatch(setActiveThemeId(theme.id));

  const onThemeNameChange: NoopValueType<ThemeItemInterface> = (theme) => dispatch(updateThemeAction(theme));

  const onAddTheme = () => activeTheme && dispatch(addNewThemeByIdAction(activeTheme.id));

  const onDeleteTheme: NoopValueType<string> = (id) => dispatch(removeThemeByIdAction(id));

  const onColorChange: NoopValueType<ThemeColorItemInterface> = (color) =>
    dispatch(
      updateThemeAction({
        ...activeTheme,
        elementColors: activeTheme.elementColors.map((elementColor) => (elementColor.name === color.name ? color : elementColor)),
        backgroundColors: activeTheme.backgroundColors.map((backgroundColor) =>
          backgroundColor.name === color.name ? color : backgroundColor,
        ),
      }),
    );

  const onCodeEditorThemeChange: NoopValueType<ThemeItemInterface['codeEditorTheme']> = (codeEditorTheme) =>
    dispatch(
      updateThemeAction({
        ...activeTheme,
        codeEditorTheme,
      }),
    );

  return (
    <>
      <ThemesListSettings
        activeThemeId={activeTheme?.id}
        onActiveThemeChange={onActiveThemeChange}
        onThemeNameChange={onThemeNameChange}
        themes={themes}
        onAddTheme={onAddTheme}
        onDeleteTheme={onDeleteTheme}
      />
      <ThemesColorSettings activeThemeId={activeTheme?.id} theme={activeTheme} onColorChange={onColorChange} />
      <CodeEditorThemeSettings onChange={onCodeEditorThemeChange} value={activeTheme.codeEditorTheme} />
    </>
  );
};
