import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ClickAwayListener, FormControl, MenuItem, Select as MUISelect, Tooltip } from '@mui/material';
import { DownIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ISelectField } from 'interface';
import { FlexContainer } from 'styles/FlexContainer';
import { SELECTOR_MENU } from 'modules/workspace/constans';

export const useOpenSelector = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
  };
  const onClickAway = (e: MouseEvent | TouchEvent) => {
    if (e.target !== document.body) {
      handleClose();
    }
  };
  const toggleOpen = () => setOpen((value) => !value);

  return { open, handleClose, onClickAway, toggleOpen };
};

export interface ISelectItem<T extends string = string> {
  name: string;
  value: T;
}

export interface IProps {
  name: string;
  disabled?: boolean;
  onChange: (value: any) => void;
  heightSize?: 'small' | 'normal';
  needBackground?: boolean;
  visibleElements?: number;
  width?: string;
  multiple?: boolean;
  error?: boolean;
  title?: string;
  placeholder?: string;
  options: ISelectItem[] | any;
  value?: ISelectItem | any;
}

const Select: FC<IProps> = ({
  name,
  disabled = false,
  heightSize = 'normal',
  needBackground = true,
  visibleElements = 10,
  width = '190px',
  onChange,
  options,
  value,
  multiple = false,
  error = false,
  title = '',
  placeholder = '',
  ...props
}) => {
  const [openTitle, setOpenTitle] = useState<null | string>(null);
  const { open, handleClose, toggleOpen, onClickAway } = useOpenSelector();
  const handleShowTitle = (id: string) => setOpenTitle(id);
  const handleHideTitle = () => setOpenTitle(null);

  const handleShowName = (value: string) => {
    const option = options.find((p: ISelectField) => p.value === value);
    if (option) {
      return option.name;
    }

    return null;
  };

  return (
    <Container $width={width}>
      {title && <h5>{title}</h5>}
      <ClickAwayListener onClickAway={onClickAway}>
        <StyledSelect
          error={error}
          multiple={multiple}
          name={name}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: `var(${ColorVarsEnum.Level_1})`,
            },
          }}
          value={(value?.props?.value || value) as string}
          $width={width}
          disabled={disabled}
          $heightSize={heightSize}
          $needBackground={needBackground}
          IconComponent={DownIcon}
          $visibleElements={visibleElements}
          displayEmpty
          $placeholder={placeholder}
          renderValue={(value: any) => handleShowName(value)}
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={{ className: SELECTOR_MENU }}
          open={open}
          onOpen={toggleOpen}
          {...props}
        >
          <FlexContainer flexDirection="column" width="100%" maxHeight={`${30 * visibleElements}px`}>
            {options &&
              options.map((item: ISelectItem) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  onMouseEnter={() => handleShowTitle(item.value + 2)}
                  onMouseLeave={handleHideTitle}
                  onClick={() => {
                    onChange(item.value);
                    handleClose();
                  }}
                >
                  <Tooltip title={item.name} id={item.name + 2} open={openTitle === item.name + 2} placement="left">
                    <div>{item.name}</div>
                  </Tooltip>
                </MenuItem>
              ))}
          </FlexContainer>
        </StyledSelect>
      </ClickAwayListener>
    </Container>
  );
};

export default Select;

export const Container = styled(FormControl)<Pick<IStyle, '$width'>>`
  width: ${(props) => props.$width};

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
    color: ${`var(${ColorVarsEnum.Level_2})`};
    margin: 0 0 3px;
  }
`;

export interface IStyle {
  $needBackground: boolean;
  $heightSize: string;
  $width: string;
  value: string;
  $placeholder: string;
  $visibleElements: number;
  error: boolean;
}

const StyledSelect = styled(MUISelect)<IStyle>`
  background: ${(props) =>
    props.$needBackground ? `var(${ColorVarsEnum.Level_5_application})` : `var(${ColorVarsEnum.Level_3_menu})`};
  height: ${(props) => (props.$heightSize === 'normal' ? '30px' : '24px')};
  cursor: pointer !important;
  border-radius: 3px !important;
  border-bottom: ${(props) => (props.error ? `1px solid  var(${ColorVarsEnum.Alert})` : undefined)};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  .MuiSelect-select {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: ${(props) => (props.$heightSize === 'normal' ? '200%' : 'unset')};
    -webkit-text-fill-color: ${`var(${ColorVarsEnum.Level_1})`};
    height: ${(props) => (props.$heightSize === 'normal' ? '28px !important' : '22px !important')};
    padding: 0 8px;
    top: 2px;
    border-style: solid !important;
    border-color: ${(props) =>
      props.$needBackground
        ? `var(${ColorVarsEnum.Level_4}) !important`
        : props.value
        ? `var(${ColorVarsEnum.Level_4}) !important`
        : `var(${ColorVarsEnum.Level_3_menu}) !important`};
    border-width: 1px !important;
  }

  .MuiPaper-root {
    position: inherit !important;
    background: ${`var(${ColorVarsEnum.Level_3_menu})`};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: 1px solid ${`var(${ColorVarsEnum.Level_4}) !important`};
    border-top-width: 0;
    width: ${(props) => props.$width};
    z-index: 9999 !important;

    ul {
      cursor: pointer;
      padding: 0;
      max-height: ${(props) =>
        props.$heightSize === 'normal' ? `calc(30px * ${props.$visibleElements})` : `calc(24px * ${props.$visibleElements})`};

      li {
        color: ${`var(${ColorVarsEnum.Level_1})`};
        transition: all 200ms;
        padding: 8px;
        height: ${(props) => (props.$heightSize === 'normal' ? '30px' : '24px')};
        background: ${`var(${ColorVarsEnum.Alert})`};
        text-overflow: ellipsis;
        white-space: nowrap;

        div,
        span {
          z-index: 2;
        }
      }

      li:hover {
        background-color: ${`var(${ColorVarsEnum.Level_3_menu})`} !important;

        :before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: ${`var(${ColorVarsEnum.Level_5})`};
          z-index: 1;
        }
      }

      .Mui-selected {
        color: ${`var(${ColorVarsEnum.Accent})`};
      }
    }
  }

  fieldset {
    box-sizing: border-box;
    border-radius: 3px;
    border-color: ${`var(${ColorVarsEnum.Level_4})`} !important;
    border-style: solid;
    border-width: ${(props) => (props.$needBackground || props.open ? '1px !important' : '0 !important')};
    transition: all 200ms;
  }

  svg {
    height: 16px;
    width: 16px;

    path {
      stroke: ${`var(${ColorVarsEnum.Level_3}) !important`};
    }
  }

  :hover {
    fieldset {
      border: unset !important;
    }

    background: ${`var(${ColorVarsEnum.Level_5_application})`};

    .MuiSelect-select {
      border-color: ${`var(${ColorVarsEnum.Level_4}) !important`};
    }
  }
`;
