import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  monthPickerOptions,
  scheduleDefaultRadio,
  scheduleInit,
  ScheduleType,
  TaskRadioVariantsType,
  timeLineRadioOptions,
  weekPickerOptions,
} from 'components/TaskRadio/index';
import { TextField } from 'modules/ui';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { CellGrid, RadioButton } from 'shared/ui';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { TaskFormData } from 'components/admin/tasks/Modal/TaskModal/types';
import { UseFormSetValue } from 'react-hook-form';

interface TaskRadioProps {
  setValue: UseFormSetValue<TaskFormData>;
  name: keyof TaskFormData;
}

export const TaskRadio: FC<TaskRadioProps> = ({ setValue, name }) => {
  const [radio, setRadio] = useState<TaskRadioVariantsType>(scheduleDefaultRadio);
  const [schedule, setSchedule] = useState<ScheduleType>(scheduleInit);

  const handleSetValue = useCallback((value: string) => setValue(name, value), [name, setValue]);

  const handleChangeRadio = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    setSchedule(scheduleInit);
    setRadio(value as TaskRadioVariantsType);
  };

  const formattingValueTime = (value: string) => {
    let totalValue = value;

    if (totalValue.length === 3 && !totalValue.includes(':')) {
      totalValue = value.replace(/(\d{1,})(\d{1})/, '$1:$2');
    }

    const [hoursInString, minutesInString] = (totalValue || '').split(':');
    const hours = isNaN(+hoursInString) ? 0 : +hoursInString <= 23 ? hoursInString : 23;
    const minute = isNaN(+minutesInString) ? 0 : +minutesInString <= 59 ? minutesInString : 59;

    return `${hours}${minute ? `:${minute}` : ''}`;
  };

  const handleChangeTime = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = formattingValueTime(e.target.value);
    const [hoursInString, minutesInString] = (value || '').split(':');

    const hours = isNaN(+hoursInString) ? 0 : +hoursInString <= 23 ? +hoursInString : 23;
    const minutes = isNaN(+minutesInString) ? 0 : +minutesInString <= 59 ? +minutesInString : 59;

    setSchedule((prev) => ({ ...prev, minutes, hours }));
  }, []);

  const handleChangeCellGrid = (day: string) => setSchedule((prev) => ({ ...prev, day: +day }));
  const handleChangeDay = (e: ChangeEvent<HTMLInputElement>) => setSchedule((prev) => ({ ...prev, day: +e.target.value }));
  const handleChangeHour = (e: ChangeEvent<HTMLInputElement>) =>
    setSchedule((prev) => ({
      ...prev,
      hours: +e.target.value <= 23 ? +e.target.value : 23,
    }));

  const handleChangeMinutes = (e: ChangeEvent<HTMLInputElement>) =>
    setSchedule((prev) => ({ ...prev, minutes: +e.target.value <= 59 ? +e.target.value : 59 }));

  useEffect(() => {
    const { hours, minutes, day } = schedule;
    const m = minutes || '0';
    const h = hours || '0';
    const d = day || '*';

    radio === 'hour' && handleSetValue(`${m} ${hours ? `*/${h}` : `*`} * * *`);
    radio === 'day' && handleSetValue(`${m} ${h} ${day ? `*/${d}` : `*`} * *`);
    radio === 'week' && handleSetValue(`${m} ${h} * * ${d}`);
    radio === 'month' && handleSetValue(`${m} ${h} ${d} * *`);
  }, [handleSetValue, radio, schedule]);

  /*TODO заменить TextField на компонент с двунаправленным состоянием, без использования useState внутри компонента*/
  const getTimeField = useCallback(
    () => (
      <TextField
        key={radio}
        name="time"
        width="100%"
        label="Время"
        maxLength={5}
        defaultValue="00:00"
        onChange={handleChangeTime}
        formattingValue={formattingValueTime}
      />
    ),
    [handleChangeTime, radio],
  );

  const getTitle = (title: string) => (
    <PrimaryTextParagraph lineHeight="12px" fontSize="12px" color={`var(${ColorVarsEnum.Level_2})`}>
      {title}
    </PrimaryTextParagraph>
  );

  const component = useMemo(
    () => ({
      hour: (
        <>
          <TextField
            name="repeat"
            width="100%"
            label="Периодичность в часах, каждый"
            value={schedule.hours}
            onChange={handleChangeHour}
            maxLength={2}
          />
          <TextField
            name="minute"
            width="100%"
            label="На минуте"
            value={schedule.minutes}
            onChange={handleChangeMinutes}
            maxLength={2}
          />
        </>
      ),
      day: (
        <>
          <TextField
            name="repeat"
            width="100%"
            label="Периодичность в днях, каждый"
            value={schedule.day}
            onChange={handleChangeDay}
          />
          {getTimeField()}
        </>
      ),
      week: (
        <>
          {getTitle('День недели')}
          <CellGrid columns={7} options={weekPickerOptions} onChange={handleChangeCellGrid} />
          {getTimeField()}
        </>
      ),
      month: (
        <>
          {getTitle('День месяца')}
          <CellGrid columns={7} options={monthPickerOptions} onChange={handleChangeCellGrid} />
          {getTimeField()}
        </>
      ),
    }),
    [getTimeField, schedule],
  );

  return (
    <FlexContainer>
      <PrimaryTextParagraph lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
        График
      </PrimaryTextParagraph>

      <FlexContainer width="430px" marginLeft="auto">
        <FlexContainer>
          <RadioButton
            sx={{ gap: '16px' }}
            name="timeLine"
            defaultValue={scheduleDefaultRadio}
            options={timeLineRadioOptions}
            onChange={handleChangeRadio}
          />
        </FlexContainer>

        <FlexContainer width="260px" flexDirection="column" gap="16px" marginLeft="auto">
          {radio ? component[radio] : component.day}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
