import { TotalRowSettings as TotalRowSettingsInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { totalRowPositionOptions } from 'modules/settingsContainer/common/view/TotalRowSettings/constants';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';

type TotalRowSettingsProps = OnValueChange<TotalRowSettingsInterface>;

export const TotalRowSettings = ({ value, onChange }: TotalRowSettingsProps) => {
  const { isShow, position } = value;

  return (
    <MainContainerSettings
      titleText="Показать строку итогов"
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          <SettingsRadio
            onChange={({ value: position }) => onChange({ ...value, position })}
            activeValue={getActiveRadioValue(totalRowPositionOptions, position)}
            options={totalRowPositionOptions}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
