import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { DateIcon } from 'assets/icons/editor';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { useMemo } from 'react';
import { CodeEditorDataInterface } from 'store/reducers/visualisations/types';
import { FC, NoopType, NoopValueType, ReactChildrenType } from 'types/global';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import { useModalState } from 'utils/hooks/modalState';
import { useLocalValues } from 'utils/hooks/localValues';
import {
  codeEditorTitle,
  generateMetricAstMapData,
  getIncisionsChanges,
  getIndicatorsChanges,
  getServiceChanges,
} from 'modules/settingsContainer/common/data/SqlSettings/cosntants';
import { SqlAutocomplete, SqlAutocompleteProps } from 'modules/ui/SqlAutocomplete';
import { VisualisationAstInterface } from 'store/reducers/ast/types';
import { SqlSettingsChangesInterface } from 'modules/settingsContainer/common/data/SqlSettings/types';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { patterns } from 'constants/global';
import { StyledInfoIcon } from 'modules/settingsContainer/common/data/SqlSettings/styles';
import { Button } from 'modules/ui';
import Snackbar from 'services/Snackbar';
import { AdviceEditor } from 'modules/ui/SqlAutocomplete/types';
import { ListWithSearch } from 'modules/settingsContainer/SearchList/ListWithSearch';
import { usePasteToSqlEditor } from 'modules/visualisations/hooks/pasteToSqlEditor';
import { ModelFromMetaType } from 'store/reducers/models/types';
import { ListItem } from 'modules/ui/lists/MapList/item/ListItem';

interface TitledFieldInterface {
  title: string;
  afterTitleSlot?: ReactChildrenType;
}

const TitledField: FC<TitledFieldInterface> = ({ children, title, afterTitleSlot }) => {
  return (
    <FlexContainer flexDirection="column" gap="8px" flex="1 1 0">
      <FlexContainer gap="8px" alignItems="center" minHeight="24px">
        <PrimaryTextSpan fontSize="12px" lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`}>
          {title}
        </PrimaryTextSpan>
        {afterTitleSlot}
      </FlexContainer>
      {children}
    </FlexContainer>
  );
};

interface SqlFieldProps
  extends Pick<SqlAutocompleteProps, 'handleChange' | 'value' | 'adviceEditor' | 'onFocus'>,
    Pick<TitledFieldInterface, 'title'> {}

export const SqlField = ({ title, ...sqlAutocompleteProps }: SqlFieldProps) => {
  return (
    <TitledField
      title={title}
      afterTitleSlot={
        <StyledTooltip
          title={
            <FlexContainer flexDirection="column">
              {Object.entries(patterns).map(([from, to]) => (
                <FlexContainer key={`${from}_${to}`}>{`'${from}' - '${to}'`}</FlexContainer>
              ))}
            </FlexContainer>
          }
        >
          <StyledInfoIcon />
        </StyledTooltip>
      }
    >
      <SqlAutocomplete height="100%" autofocus {...sqlAutocompleteProps} />
    </TitledField>
  );
};

interface AdviceListProps {
  title: string;
  list?: AdviceEditor[];
}

export const AdviceList = ({ title, list }: AdviceListProps) => {
  return list && list.length ? (
    <FlexContainer flexDirection="column" gap="8px">
      <PrimaryTextSpan fontSize="12px" lineHeight="14px" color={`var(${ColorVarsEnum.Level_1})`} fontWeight={700}>
        {`${title}:`}
      </PrimaryTextSpan>
      <FlexContainer flexDirection="column" gap="10px" padding="0 8px 0">
        {list?.map((el) => (
          <PrimaryTextSpan
            key={el.caption}
            fontSize="12px"
            lineHeight="14px"
            color={`var(${ColorVarsEnum.Level_1})`}
            fontWeight={400}
          >
            {el.caption}
          </PrimaryTextSpan>
        ))}
      </FlexContainer>
    </FlexContainer>
  ) : null;
};

export interface SqlSectionProps {
  modelMetaData?: ModelFromMetaType[];
  adviceSlot?: ReactChildrenType;
  sqlEditorSlot: (props: { onEditorFocus: SqlAutocompleteProps['onFocus'] }) => JSX.Element;
  onSaveEditor: NoopType;
  onCloseEditor: NoopType;
  hasChanges: boolean;
}

export const SqlSection = ({
  sqlEditorSlot,
  adviceSlot,
  modelMetaData,
  onSaveEditor,
  hasChanges,
  onCloseEditor,
}: SqlSectionProps) => {
  const { setSelectedColumns, selectedColumns, onEditorFocus, onAddToEditor } = usePasteToSqlEditor();

  const modelMetaDataList = modelMetaData?.map(({ columns, alias }) => ({
    items: columns?.map(({ name, type }) => ({ title: name, type })),
    title: alias,
    type: alias,
  }));

  return (
    <FlexContainer width="100%" flex="1 1 0" flexDirection="column" padding="16px" gap="8px">
      <FlexContainer width="100%" flex="1 1 0" gap="32px">
        {modelMetaData && !!modelMetaData.length && (
          <FlexContainer width="300px" flexDirection="column">
            <FlexContainer flexDirection="column" flex="1 1 0">
              <TitledField title="Карта модели">
                <FlexContainer width="100%" flex="1 1 0" flexDirection="column">
                  <ListWithSearch
                    modelMetaData={modelMetaDataList || []}
                    onSelectItem={setSelectedColumns}
                    selectedOptions={selectedColumns}
                    renderItem={({ item, isSelected, onSelectChange }) => (
                      <ListItem
                        id={item.id}
                        title={item.title}
                        Icon={item.Icon}
                        disabled={item.disabled}
                        onChecked={onSelectChange}
                        isChecked={isSelected}
                      />
                    )}
                  />
                  <FlexContainer alignItems="center" justifyContent="center" padding="20px 0 0 0">
                    <Button
                      disabled={!selectedColumns.length}
                      text="Добавить в текст"
                      heightSize="normal"
                      onClick={onAddToEditor}
                    />
                  </FlexContainer>
                </FlexContainer>
              </TitledField>
            </FlexContainer>
          </FlexContainer>
        )}
        <FlexContainer flex="1 1 0" gap="16px">
          <FlexContainer flexDirection="column" width="100%" flex="1 1 0" gap="12px">
            {sqlEditorSlot({ onEditorFocus })}
            <FlexContainer justifyContent="flex-end">
              <FlexContainer marginRight="10px">
                <Button text="Отменить" heightSize="normal" width="95px" onClick={onCloseEditor} needBackground={false} />
              </FlexContainer>
              <Button disabled={!hasChanges} text="Сохранить" heightSize="normal" width="95px" onClick={onSaveEditor} />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        {adviceSlot && (
          <FlexContainer flexDirection="column" width="230px" gap="24px">
            {adviceSlot}
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

interface SqlSettingsProps {
  modelMetaData: ModelFromMetaType[];
  sqlData: CodeEditorDataInterface;
  astData: VisualisationAstInterface;
  adviceEditorIncision?: AdviceEditor[];
  adviceEditorIndicator?: AdviceEditor[];
  adviceEditorService?: AdviceEditor[];
  serviceSqlString?: string;
  serviceTitle?: string;
  onSave: NoopValueType<SqlSettingsChangesInterface>;
}

export const SqlSettings = ({
  sqlData: { incisionSqlString, indicatorSqlString, filterAndGroupSqlString },
  astData,
  adviceEditorIncision,
  adviceEditorIndicator,
  adviceEditorService,
  serviceTitle,
  serviceSqlString,
  onSave,
  modelMetaData,
}: SqlSettingsProps) => {
  const { isOpen, onOpen, onClose } = useModalState();

  const defaultRequests = useMemo(
    () => ({
      incisionRequest: incisionSqlString,
      indicatorRequest: indicatorSqlString,
      filterAndGroupRequest: filterAndGroupSqlString,
      serviceRequest: serviceSqlString,
    }),
    [incisionSqlString, indicatorSqlString, filterAndGroupSqlString, serviceSqlString],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const incisionsAstMapData = useMemo(() => generateMetricAstMapData(astData.incisions), [JSON.stringify(astData.incisions)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const indicatorsAstMapData = useMemo(() => generateMetricAstMapData(astData.indicators), [JSON.stringify(astData.indicators)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const servicesAstMapData = useMemo(() => generateMetricAstMapData(astData.variables), [JSON.stringify(astData.variables)]);

  const onChange = ({ incisionRequest, indicatorRequest, serviceRequest, filterAndGroupRequest }: typeof defaultRequests) => {
    try {
      const incisionsChanges = getIncisionsChanges({ incisionsAstMapData, incisionRequest });
      const indicatorsChanges = getIndicatorsChanges({ indicatorsAstMapData, indicatorRequest });
      const servicesChanges = getServiceChanges({ servicesAstMapData, serviceRequest });

      console.log(incisionsChanges, 'Разрезы');
      console.log(indicatorsChanges, 'Показатели');
      console.log(servicesChanges, 'Переменные');

      onSave({ incisionsChanges, indicatorsChanges, servicesChanges, filterAndGroupRequest: filterAndGroupRequest || '' });
    } catch (error) {
      const message = (error as { message?: string })?.message || '';
      Snackbar.show(message, 'error');
      throw Error(message);
    }
  };

  const {
    localValues,
    setLocalValues,
    onSave: onLocalSave,
    onCancel,
    hasChanges,
  } = useLocalValues({
    value: defaultRequests,
    onChange,
  });

  const onCloseEditor = () => {
    onClose();
    onCancel();
  };

  const onSaveEditor = () => {
    onLocalSave();
    onClose();
  };

  return (
    <>
      <MainContainerSettings titleText="SQL" ButtonIcon={DateIcon} onClickButtonIcon={onOpen} />
      <ModalUniversal
        open={isOpen}
        onClose={onCloseEditor}
        headerText={codeEditorTitle}
        width="95vw"
        maxHeight="95vh"
        disableEscapeKeyDown
      >
        <SqlSection
          sqlEditorSlot={({ onEditorFocus }) => (
            <>
              {!serviceTitle && (
                <SqlField
                  title="Разрезы"
                  handleChange={(incisionRequest) => setLocalValues({ ...localValues, incisionRequest })}
                  value={localValues.incisionRequest || ''}
                  adviceEditor={adviceEditorIncision}
                  onFocus={onEditorFocus}
                />
              )}
              {!serviceTitle && (
                <SqlField
                  title="Показатели"
                  handleChange={(indicatorRequest) => setLocalValues({ ...localValues, indicatorRequest })}
                  value={localValues.indicatorRequest || ''}
                  adviceEditor={adviceEditorIndicator}
                  onFocus={onEditorFocus}
                />
              )}
              {serviceTitle && (
                <SqlField
                  title={serviceTitle || ''}
                  handleChange={(serviceRequest) => setLocalValues({ ...localValues, serviceRequest })}
                  value={localValues.serviceRequest || ''}
                  adviceEditor={adviceEditorService}
                  onFocus={onEditorFocus}
                />
              )}
              <SqlField
                title="Группировки и фильтры"
                handleChange={(filterAndGroupRequest) => setLocalValues({ ...localValues, filterAndGroupRequest })}
                value={localValues.filterAndGroupRequest || ''}
                adviceEditor={adviceEditorIndicator}
                onFocus={onEditorFocus}
              />
            </>
          )}
          adviceSlot={
            <>
              <AdviceList title="Разрезы" list={adviceEditorIncision} />
              <AdviceList title="Показатели" list={adviceEditorIndicator} />
              <AdviceList title={serviceTitle || ''} list={adviceEditorService} />
            </>
          }
          modelMetaData={modelMetaData}
          hasChanges={hasChanges}
          onSaveEditor={onSaveEditor}
          onCloseEditor={onCloseEditor}
        />
      </ModalUniversal>
    </>
  );
};
