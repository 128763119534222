import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { HeightInterface } from 'types/styles';
import { IsActiveInterface } from 'store/reducers/visualisations/types';

export const EditableMenuWrapper = styled.div<Partial<HeightInterface>>`
  display: flex;
  height: 100%;
  ${({ height }) => height && `height: ${height};`};
  border-bottom: 1px solid var(${ColorVarsEnum.Level_5});
`;

export const MenuItemsWrapper = styled.div<Partial<HeightInterface>>`
  display: flex;
  height: 100%;
`;

export const EditButtonsWrapper = styled.div`
  position: absolute;
  bottom: -30px;
  width: 100%;
  height: 120%;
  display: none;
  align-items: flex-end;
  justify-content: center;
`;

const textStyleMixin = css`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: var(${ColorVarsEnum.Level_2});
  font-weight: 400;
  line-height: 100%;
  font-size: 12px;
  padding: 0 15px;
`;

export const MenuItem = styled.div`
  ${textStyleMixin};
`;

export const StyledTextInput = styled.input`
  border: none;
  background: none;
  outline: none;
  ${textStyleMixin};
`;

export const MenuItemWrapper = styled.div<IsActiveInterface>`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(${ColorVarsEnum.Level_2});
  border-bottom: 2px solid transparent;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 2px solid var(${ColorVarsEnum.Accent});

      ${StyledTextInput},${MenuItem} {
        color: var(${ColorVarsEnum.Accent});
      }
    `};

  :hover {
    ${EditButtonsWrapper} {
      display: flex;
    }
  }
`;
