import { ThemeColorItemInterface, ThemeItemInterface } from 'store/reducers/themes/types';
import React, { useEffect, useMemo, useState } from 'react';
import { GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { Color } from 'modules/settingsContainer/ColorPicker/styles';
import { NoopType, NoopValueType } from 'types/global';
import { HexAndHlsColorEditor } from 'modules/ui/colors/HexAndHlsColorEditor';
import { HlsColorEditorProps } from 'modules/ui/colors/HlsColorEditor';
import { debounce } from 'lodash';

interface ColorRowProps {
  color: ThemeColorItemInterface;
  isActive: boolean;
  onColorClick: NoopType;
}

const ColorRow = ({ color, isActive, onColorClick }: ColorRowProps) => {
  return (
    <FlexContainer cursor="pointer" alignItems="center" width="100%" gap="10px" onClick={onColorClick}>
      <Color active={isActive} color={color.hex} colorSize={25} />
      <PrimaryTextSpan color={`var(${isActive ? ColorVarsEnum.Accent : ColorVarsEnum.Level_1})`}>{color.name}</PrimaryTextSpan>
    </FlexContainer>
  );
};

interface ColorsColumnProps {
  colors: ThemeColorItemInterface[];
  title: string;
  activeColorName?: string | null;
  onActiveColorChange: NoopValueType<ThemeColorItemInterface>;
}

const ColorsColumn = ({ colors, activeColorName, title, onActiveColorChange }: ColorsColumnProps) => (
  <FlexContainer width="50%" flexDirection="column" gap="16px">
    <PrimaryTextSpan lineHeight="14px" color={`var(${ColorVarsEnum.Level_3})`}>
      {title}
    </PrimaryTextSpan>
    {colors.map((color) => (
      <ColorRow
        onColorClick={() => onActiveColorChange(color)}
        key={color.name}
        color={color}
        isActive={color.name === activeColorName}
      />
    ))}
  </FlexContainer>
);

interface ThemesColorSettingsProps {
  theme: ThemeItemInterface;
  onColorChange: NoopValueType<ThemeColorItemInterface>;
  activeThemeId: string | null;
}

export const ThemesColorSettings = ({ theme, onColorChange, activeThemeId }: ThemesColorSettingsProps) => {
  const [activeColorName, setActiveColorName] = useState<string | null>(null);

  const activeColorData = useMemo(
    () => [...theme.backgroundColors, ...theme.elementColors].find((color) => color.name === activeColorName) || null,
    [activeColorName, theme.backgroundColors, theme.elementColors],
  );

  const onActiveColorChange = (color: ThemeColorItemInterface) =>
    setActiveColorName((activeColorName) => (activeColorName === color.name ? null : color.name));

  const onLocalColorChange: HlsColorEditorProps['onChange'] = (color) =>
    activeColorData && onColorChange({ ...activeColorData, ...color });

  const onHexAndHlsColor = debounce((color, type) => onLocalColorChange(color, type), 300);

  useEffect(() => {
    setActiveColorName(null);
  }, [activeThemeId]);

  return (
    <>
      <MainContainerSettings titleText="Цвета">
        <GroupContainerSettings>
          <FlexContainer width="100%">
            <ColorsColumn
              title="Элементы"
              colors={theme.elementColors}
              activeColorName={activeColorName}
              onActiveColorChange={onActiveColorChange}
            />
            <ColorsColumn
              title="Фон"
              colors={theme.backgroundColors}
              activeColorName={activeColorName}
              onActiveColorChange={onActiveColorChange}
            />
          </FlexContainer>
        </GroupContainerSettings>
      </MainContainerSettings>
      {activeColorName && activeColorData && (
        <MainContainerSettings isOpenDefault>
          <GroupContainerSettings>
            <HexAndHlsColorEditor value={activeColorData} onChange={onHexAndHlsColor} />
          </GroupContainerSettings>
        </MainContainerSettings>
      )}
    </>
  );
};
