import CircularProgress from '@mui/material/CircularProgress';
import { FlexContainer } from 'styles/FlexContainer';
import { Checkbox } from 'modules/ui';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { ChangeEventHandler, useCallback } from 'react';
import { FiltersValuesType } from 'store/reducers/filters/types';
import { OnValueChange } from 'types/global';

interface FilterValuesListProps extends OnValueChange<string[]> {
  loading?: boolean;
  filterValues: FiltersValuesType;
}

export const FilterValuesList = ({ loading, filterValues, value: selectedData, onChange }: FilterValuesListProps) => {
  const isChecked = useCallback((value: string) => selectedData.includes(value), [selectedData]);

  const onSelectedDataChange: (value: string) => ChangeEventHandler<HTMLInputElement> = (value: string) => (e) => {
    const newSelectedData = e.target.checked
      ? [...selectedData, value]
      : selectedData.filter((selectedValue) => selectedValue !== value);

    onChange(newSelectedData);
  };
  return (
    <FlexContainer maxHeight="400px" minHeight={loading ? '70px' : undefined} width="100%" overflow="auto">
      {loading ? (
        <FlexContainer width="100%" justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexContainer>
      ) : (
        <FlexContainer width="100%" flexDirection="column" position="relative">
          {filterValues.map(({ value, count }) => {
            const normalizedValue = value !== '' ? value : 'NULL',
              key = `${value}_${count}`;

            return (
              <FlexContainer key={key} justifyContent="space-between" padding="0 5px" alignItems="center">
                <Checkbox
                  value={normalizedValue}
                  name={normalizedValue}
                  id={normalizedValue}
                  label={normalizedValue}
                  onChange={onSelectedDataChange(value)}
                  checked={isChecked(value)}
                />
                <PrimaryTextSpan lineHeight="12px" marginBottom="8px">
                  {count}
                </PrimaryTextSpan>
              </FlexContainer>
            );
          })}
        </FlexContainer>
      )}
    </FlexContainer>
  );
};
