import { ListWrapper } from 'modules/settingsContainer/List/styles';
import { ListUI, ListUIProps } from 'modules/ui/lists/MapList/list';
import { memo, useMemo } from 'react';
import { getListOptions } from 'modules/settingsContainer/List/constants';
import { ListInterface } from 'modules/settingsContainer/List/types';

export interface ListProps {
  modelMetaData: ListInterface[];
  onSelectItem?: NonNullable<ListUIProps['onSelect']>;
  onChecked?: NonNullable<ListUIProps['onChecked']>;
  onOpenList: NonNullable<ListUIProps['onOpen']>;
  openedGroups: NonNullable<ListUIProps['openedGroups']>;
  renderItem: NonNullable<ListUIProps['renderItem']>;
  selectedOptions?: NonNullable<ListUIProps['selectedOptions']>;
  onDeleteItem?: NonNullable<ListUIProps['onDeleteItem']>;
}

export const ListComponent = ({
  modelMetaData,
  onOpenList,
  onSelectItem,
  selectedOptions,
  onChecked,
  openedGroups,
  onDeleteItem,
  renderItem,
}: ListProps) => {
  const options = useMemo(() => getListOptions(modelMetaData), [modelMetaData]);

  return (
    <ListWrapper>
      <ListUI
        options={options}
        onDeleteItem={onDeleteItem}
        selectedOptions={selectedOptions}
        onSelect={onSelectItem}
        onOpen={onOpenList}
        onChecked={onChecked}
        openedGroups={openedGroups}
        renderItem={renderItem}
      />
    </ListWrapper>
  );
};

export const List = memo(ListComponent);
