import { FilterInterface, FilterType } from 'store/reducers/filters/types';
import { ReactChildrenType } from 'types/global';
import { ContainerSettings } from 'modules/settingsContainer/common/view/ContainerSettings';
import {
  onBorderSettingsChange,
  onDisableDraggingChange,
  onFilterInfluencesChange,
  onGlobalChange,
  onModelIdChange,
  onNameSettingsChange,
  onPositionChange,
  onPositionConfigChange,
  onRealDataChange,
  onShadowSettingsChange,
  onShowBackgroundChange,
  onSqlDataChange,
} from 'modules/settingsContainer/common/data/DefaultFilterDataSettings/constants';
import { RealDataSettings } from 'modules/settingsContainer/common/data/RealDataSettings';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { PositionSettings } from 'modules/settingsContainer/common/view/PositionSettings';
import React from 'react';
import { NameFilterSettings } from 'modules/settingsContainer/NameFilterSettings';
import { FilterInfluenceSettings } from '../../FilterInfluenceSettings';
import { toggleInfluenceFiltersMode } from 'store/reducers/board';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { getActiveBoardElement, getInfluenceFiltersMode } from 'store/reducers/board/getters';
import { getVisualisations } from 'store/reducers/visualisations/getters';
import { getFilters } from 'store/reducers/filters/getters';
import { applyToAllInfluences } from 'utils/utils';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { getAstOfVisualisations } from 'store/reducers/ast/getters';

import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { SqlFilterSettings } from '../SqlFilterSettings';
import { sqlParser } from 'utils/SQL/genereteAst';

interface DefaultFilterDataSettingsProps<Type extends FilterType> {
  data: FilterInterface<Type>;
  children?: ReactChildrenType;
  modelIdValue: string;
}

export const DefaultFilterDataSettings = <Type extends FilterType>({
  children,
  data,
  modelIdValue,
}: DefaultFilterDataSettingsProps<Type>) => {
  const dispatch = useAppDispatch();
  const influenceFiltersMode = useSelector(getInfluenceFiltersMode);
  const visualisations = useSelector(getVisualisations);
  const filters = useSelector(getFilters);
  const activeElement = useSelector(getActiveBoardElement);
  const { dataSettings } = useGetActiveVisualisationSettings();
  const { modelMetaData } = useDataSettingsMetric(dataSettings.modelId);
  const activeElementData = filters[activeElement || ''];

  const {
    id,
    isRealData,
    isGlobal,
    position,
    disableDragging,
    shadowSettings,
    borderSettings,
    showBackground,
    positionConfig,
    nameSettings,
    filterInfluences,
  } = data;

  const toggleInfluenceFilterMode = () => {
    dispatch(toggleInfluenceFiltersMode());
  };

  const onSaveSql = (payload: string) => {
    const isValidAst = sqlParser.astify(`SELECT * ${payload}`);

    if (isValidAst) {
      onSqlDataChange({ sqlData: payload });
    }
  };

  const onApplyToAllFilterInfluences = (payload: boolean) => () => {
    if (!influenceFiltersMode) {
      toggleInfluenceFilterMode();
    }

    const influenceData = applyToAllInfluences({ ...visualisations, ...filters }, payload, id);

    onFilterInfluencesChange({ ...filterInfluences, ...influenceData });
  };

  return (
    <>
      <ContainerSettings
        positionConfig={positionConfig}
        disableDragging={disableDragging}
        showBackground={showBackground}
        borderSettings={borderSettings}
        shadowSettings={shadowSettings}
        onExternalPositionChange={onPositionConfigChange}
        onExternalDisableDraggingChange={onDisableDraggingChange}
        onExternalBorderSettingsChange={onBorderSettingsChange}
        onExternalShowBackgroundChange={onShowBackgroundChange}
        onExternalShadowSettingsChange={onShadowSettingsChange}
      />
      <RealDataSettings value={isRealData} onChange={onRealDataChange} />
      <ModelSelectorSettings value={modelIdValue} onChange={onModelIdChange} />
      <NameFilterSettings isRealData={isRealData} value={nameSettings} onChange={onNameSettingsChange} />
      {children}
      {/* <SqlFilterSettings sqlData={activeElementData?.sqlData} onSave={onSaveSql} modelMetaData={modelMetaData} /> */}
      <PositionSettings value={position} onChange={onPositionChange} />
      <FilterInfluenceSettings
        value={isGlobal}
        onChange={onGlobalChange}
        influenceModeValue={influenceFiltersMode}
        onToggleInfluenceMode={toggleInfluenceFilterMode}
        onApplyToAllFilterInfluences={onApplyToAllFilterInfluences}
      />
    </>
  );
};
