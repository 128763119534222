import React, { FC, useCallback } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Diagram, Filter, TextBlock } from '../../../../assets/icons/forDelete';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useAppDispatch } from 'store';
import { addVisualisationAction } from 'store/reducers/visualisations/actions';
import { BoardMenuItem, BoardMenuItemProps } from 'modules/workspace/components/BoardMenuItem';
import { HeaderSettingsAreaLayout } from 'modules/settingsContainer/FieldSettingsArea';
import { filters, visualisations } from './constants';
import { VisualisationTypeType } from 'store/reducers/visualisations/types';
import { NoopType } from 'types/global';
import { IconContainer } from 'modules/workspace/components/leftBar/styles';
import { FilterType } from 'store/reducers/filters/types';
import { addFilterAction } from 'store/reducers/filters/actions';
import { StyledTooltip } from 'modules/ui/StyledTooltip';

const CreateVisualisationModal: BoardMenuItemProps['ModalData'] = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const handleCreateVisualisation = (visualisationType: VisualisationTypeType, onClose: NoopType) => () => {
    dispatch(addVisualisationAction(visualisationType));
    onClose();
  };

  return (
    <HeaderSettingsAreaLayout headerText="Визуализации" onClose={onClose}>
      <FlexContainer margin="10px" gap="10px">
        {visualisations.map(({ visualisationType, Icon, title }) => (
          <StyledTooltip key={title} title={title}>
            <IconContainer key={visualisationType} onClick={handleCreateVisualisation(visualisationType, onClose)}>
              <Icon />
            </IconContainer>
          </StyledTooltip>
        ))}
      </FlexContainer>
    </HeaderSettingsAreaLayout>
  );
};

const CreateFilterModal: BoardMenuItemProps['ModalData'] = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const handleCreateFilter = (type: FilterType, onClose: NoopType) => () => {
    dispatch(addFilterAction(type));
    onClose();
  };

  return (
    <HeaderSettingsAreaLayout headerText="Фильтры" onClose={onClose}>
      <FlexContainer margin="10px" gap="10px">
        {filters.map(({ type, Icon }) => (
          <IconContainer key={type} onClick={handleCreateFilter(type, onClose)}>
            <Icon />
          </IconContainer>
        ))}
      </FlexContainer>
    </HeaderSettingsAreaLayout>
  );
};

export const LeftBar: FC = () => {
  const dispatch = useAppDispatch();

  const createTextContainer = useCallback(() => {
    dispatch(addVisualisationAction('text'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexContainer
      flexDirection="column"
      maxWidth="60px"
      backgroundColor={`var(${ColorVarsEnum.Level_5_application})`}
      zIndex="2"
      alignItems="center"
      flex="1"
    >
      <BoardMenuItem Icon={Diagram} ModalData={CreateVisualisationModal} />
      <BoardMenuItem Icon={Filter} ModalData={CreateFilterModal} />
      <BoardMenuItem Icon={TextBlock} onClick={createTextContainer} />
    </FlexContainer>
  );
};
