import {
  AxisSettingsInterface,
  VisualisationValuesInterface,
  WaterfallDataSettings,
  WaterfallIndicatorInterface,
} from 'store/reducers/visualisations/types';
import {
  defaultIndicatorPhantomName,
  getVisualisationFieldName,
  processDataWaterfall,
} from 'store/reducers/visualisations/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ThemeColorsInterface } from 'store/reducers/themes/types';
import { ConfigWithGridDimensionsInterface, GridDimensionsInterface } from 'types/echarts';
import { calculateGridDimension, getLengthOfGraphicString, initialDimensions } from 'utils/generateConfigGraphic';
import { WaterfallEChartsOption } from 'modules/visualisations/Waterfall/visualisation/types';
import { getAxisNameLocation, getNameIndicatorTextPadding } from 'modules/visualisations/common/constants';

export const createFieldName = (fieldName: string) => {
  return `${fieldName} - источник`;
};

/* TODO - will fix the data algorithm */
export const getWaterfallData = (
  { incisions, indicators, positiveAndNegativeIndicators }: WaterfallDataSettings,
  visualisationValues: VisualisationValuesInterface,
) => {
  const activeIncision = incisions[0];
  const [indicatorPlan, indicatorFact] = indicators;
  const [positiveIndicator, negativeIndicator] = positiveAndNegativeIndicators;

  if (!activeIncision || !indicatorPlan || !indicatorFact) {
    return { data: {}, activeIncisionSum: {} };
  }

  const {
      name,
      fieldName,
      settings: { nameFromDatabase },
    } = activeIncision,
    {
      name: planName,
      fieldName: planFieldName,
      settings: { nameFromDatabase: planNameFromDatabase },
    } = indicatorPlan,
    {
      name: factName,
      fieldName: factFieldName,
      settings: { nameFromDatabase: factNameFromDatabase },
    } = indicatorFact,
    {
      name: positiveName,
      fieldName: positiveFieldName,
      settings: { nameFromDatabase: positiveNameFromDatabase },
    } = positiveIndicator,
    {
      name: negativeName,
      fieldName: negativeFieldName,
      settings: { nameFromDatabase: negativeNameFromDatabase },
    } = negativeIndicator;

  const activeIncisionFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase }),
    indicatorPlanFieldName = getVisualisationFieldName({
      name: planName,
      fieldName: planFieldName,
      nameFromDatabase: planNameFromDatabase,
    }),
    indicatorFactFieldName = getVisualisationFieldName({
      name: factName,
      fieldName: factFieldName,
      nameFromDatabase: factNameFromDatabase,
    }),
    indicatorPositiveFieldName = getVisualisationFieldName({
      name: positiveName,
      fieldName: positiveFieldName,
      nameFromDatabase: positiveNameFromDatabase,
    }),
    indicatorNegativeFieldName = getVisualisationFieldName({
      name: negativeName,
      fieldName: negativeFieldName,
      nameFromDatabase: negativeNameFromDatabase,
    });

  const plan = visualisationValues[indicatorPlanFieldName] as number[];
  const fact = visualisationValues[indicatorFactFieldName] as number[];

  const activeIncisionValues = (visualisationValues[activeIncisionFieldName] || []) as Array<string | number>,
    unionIncisionValues = [indicatorPlanFieldName, ...activeIncisionValues, indicatorFactFieldName] as string[] | number[];

  const activeIncisionValue: VisualisationValuesInterface = {
    [activeIncisionFieldName]: unionIncisionValues,
  };

  let overallPlan = 0;
  let overallFact = 0;
  let newArrayPlan: (number | null)[] = [];
  let newArrayFact: (number | null)[] = [];
  let dataWaterfallPlan: number[] = [];
  let difference = 0;

  (plan || []).forEach((value, index) => {
    const factValue = (fact && fact[index]) || 0;
    overallPlan += value || 0;
    overallFact += factValue || 0;

    newArrayPlan.push(null);
    newArrayFact.push(null);

    difference = Number(factValue) - Number(value);
    dataWaterfallPlan.push(difference);
  });

  newArrayPlan = [overallPlan, ...newArrayPlan, null];
  newArrayFact = [...newArrayFact, null, overallFact];
  dataWaterfallPlan = [overallPlan, ...dataWaterfallPlan];

  const { fantomResult, positiveResult, negativeResult } = processDataWaterfall(dataWaterfallPlan);

  const indicatorsValues: VisualisationValuesInterface = {
    [indicatorPlanFieldName]: newArrayPlan,
    [indicatorFactFieldName]: newArrayFact,
    [defaultIndicatorPhantomName]: fantomResult,
    [indicatorPositiveFieldName]: positiveResult,
    [indicatorNegativeFieldName]: negativeResult,
    [createFieldName(indicatorPlanFieldName)]: [null, ...(plan || []), null],
    [createFieldName(indicatorFactFieldName)]: [null, ...(fact || []), null],
  };

  const activeIncisionSum = (activeIncisionValue[activeIncisionFieldName] as string[])?.reduce<Record<string, number>>(
    (result, key, index) => ({
      ...result,
      [key]: indicators.reduce((totalSum, { name, fieldName, settings: { nameFromDatabase } }) => {
        const indicatorFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase }),
          value = indicatorsValues[indicatorFieldName]?.[index] || 0;

        return totalSum + (typeof value === 'string' ? 1 : value);
      }, 0),
    }),
    {},
  );

  return { data: { ...activeIncisionValue, ...indicatorsValues }, activeIncisionSum };
};

interface AxisConfigIndicator {
  min?: number;
  max?: number;
  maxStringLength?: number;
}

interface GetIndicatorAxisConfigParams extends AxisConfigIndicator {
  axisIndicatorSettings: AxisSettingsInterface<'indicator'>;
  activeThemeSchema: ThemeColorsInterface;
  mainIndicator: AxisConfigIndicator;
}

export const getIndicatorAxisConfig: (params: GetIndicatorAxisConfigParams) => WaterfallEChartsOption['yAxis'] = ({
  axisIndicatorSettings,
  activeThemeSchema,
  mainIndicator,
}) => {
  const {
    isShow,
    showAxis,
    position,
    name,
    label: { isActive: isActiveLabel, value: labelValue, properties },
    stepSize,
    showGrid,
  } = axisIndicatorSettings;

  const { max, min, maxStringLength } = mainIndicator;

  return [
    {
      type: 'value',
      show: isShow,
      position,
      max,
      min,
      name: name.isShow ? name.text : undefined,
      nameLocation: getAxisNameLocation[name.position.type],
      nameTextStyle: {
        padding: getNameIndicatorTextPadding({
          isRotated: false,
          isAutoType: name.type === 'auto',
          positionValue: name.position.value,
          isLeft: position === 'left',
          maxStringLength,
        })[name.position.type],
        align: undefined,
      },
      splitNumber: stepSize.type === 'manual' ? stepSize.value : undefined,
      axisLine: {
        show: showAxis,
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
      axisLabel: {
        show: isActiveLabel,
        margin: isActiveLabel ? labelValue : undefined,
        textStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_2],
          fontSize: properties?.fontSize,
          fontWeight: properties?.fontStyle?.bold ? 'bold' : 'normal',
          fontStyle: properties?.fontStyle?.italic ? 'italic' : 'normal',
        },
      },
      splitLine: {
        show: showGrid,
        lineStyle: {
          type: 'dashed',
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
      axisTick: {
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
    },
  ];
};

export const getIndicatorAxisGridDimension: (params: GetIndicatorAxisConfigParams) => GridDimensionsInterface = ({
  axisIndicatorSettings,
  maxStringLength = 0,
}) => {
  const {
    isShow,
    position,
    name,
    label: { isActive: isActiveLabel },
  } = axisIndicatorSettings;

  if (!isShow) return initialDimensions;

  const nameGridShift = 20 + getLengthOfGraphicString(name.text),
    baseStringLength = 20;

  const baseGrid = {
    ...initialDimensions,
    [position]: baseStringLength + (isActiveLabel ? maxStringLength : 0),
  };

  const namePositionGridDimensions = {
    'flex-start': {
      ...initialDimensions,
      bottom: 20,
      left: name.position.type === 'flex-start' && name.position.type === 'flex-start' ? nameGridShift / 2 : nameGridShift,
      right: name.position.type === 'flex-start' && name.position.type === 'flex-start' ? nameGridShift : nameGridShift / 2,
    },
    center: position === 'left' ? { ...initialDimensions, left: 20 } : { ...initialDimensions, right: 10 },
    'flex-end': {
      ...initialDimensions,
      top: 20,
      left: name.position.type === 'flex-end' && name.position.type === 'flex-end' ? nameGridShift / 2 : nameGridShift,
      right: name.position.type === 'flex-end' && name.position.type === 'flex-end' ? nameGridShift / 2 : nameGridShift,
    },
  };

  const namePositionGrid = name.isShow ? namePositionGridDimensions[name.position.type] : initialDimensions;

  return calculateGridDimension([baseGrid, namePositionGrid]);
};

export const getIndicatorAxisWithGridDimensions: (
  params: GetIndicatorAxisConfigParams,
) => ConfigWithGridDimensionsInterface<WaterfallEChartsOption['yAxis']> = (params) => ({
  config: getIndicatorAxisConfig(params),
  gridDimensions: getIndicatorAxisGridDimension(params),
});

export const getBarGraphGridDimensions = (indicators: WaterfallIndicatorInterface[]) => {
  let isActiveGrid = false;

  indicators.forEach(
    ({
      settings: {
        showValue: { isShow, position },
      },
    }) => {
      if (!isActiveGrid) {
        isActiveGrid = isShow && position === 'outside';
      }
    },
  );

  const shiftPosition = 'top';

  return isActiveGrid ? { ...initialDimensions, [shiftPosition]: 20 } : initialDimensions;
};
