export const timeLineRadioOptions = [
  { value: 'hour', label: 'Ежечасно' },
  { value: 'day', label: 'Ежедневно' },
  { value: 'week', label: 'Еженедельно' },
  { value: 'month', label: 'Ежемесячно' },
];

export const weekPickerOptions = [
  { value: '1', label: 'ПН' },
  { value: '2', label: 'ВТ' },
  { value: '3', label: 'СР' },
  { value: '4', label: 'ЧТ' },
  { value: '5', label: 'ПТ' },
  { value: '6', label: 'СБ' },
  { value: '7', label: 'ВС' },
];

export const monthPickerOptions = Array.from({ length: 28 }).map((_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));

export const scheduleInit = {
  minutes: 0,
  hours: 0,
  day: 0,
};

export const scheduleDefaultRadio = 'hour';
