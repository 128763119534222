import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import {
  addTaskInfoAction,
  deleteTaskAction,
  deleteTaskByIdAction,
  loadAdminTasksAction,
} from 'store/reducers/adminTasks/actions';
import { getAdminTasksData, getAdminTasksInfo } from 'store/reducers/adminTasks/getters';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { TaskInterface } from 'store/reducers/adminTasks/types';
import { Tab } from 'modules/ui/tabs/types';
import { useToggle } from 'utils/hooks/toggle';
import { Details } from '../wrappers/ActiveDirectoriesAndTasksWrapper/Details';
import { TaskModal } from './Modal/TaskModal';
import { createTask, editTask } from 'components/admin/tasks/Modal/TaskModal/constants';
import { TaskFormData } from 'components/admin/tasks/Modal/TaskModal/types';
import { ModeForm } from 'types/store';
import { ActiveDirectoriesAndTasksWrapper } from '../wrappers/ActiveDirectoriesAndTasksWrapper';

export const AdminTasks: FC = () => {
  const dispatch = useAppDispatch();
  const { loading, tasks } = useSelector(getAdminTasksData);
  const taskInfo = useSelector(getAdminTasksInfo);

  const [activeTaskId, setTaskId] = useState<string | null | undefined>(null);
  const [open, toggleOpen] = useToggle(false);

  const addTaskInfo = useCallback(
    (data: TaskInterface | null) => {
      setTaskId(data?.id);
      dispatch(addTaskInfoAction(data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    dispatch(loadAdminTasksAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteTask = useCallback(
    async (task: TaskInterface) => {
      try {
        const taskId = task?.id;

        if (taskId) {
          const taskRes = await dispatch(deleteTaskAction(taskId)).unwrap();

          if (taskRes) {
            dispatch(deleteTaskByIdAction(taskId));

            addTaskInfo(null);
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addTaskInfo],
  );

  const onDeleteTaskModal = useCallback(
    async (task: TaskInterface) => {
      dispatch(
        openConfirmationModalAction({
          titleText: 'Удалить задачу',
          onConfirm: () => onDeleteTask(task),
          subTitleText: `Действительно удалить ${task.name}?`,
          confirmationButtonText: 'Да',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDeleteTask],
  );

  const onCloseTaskModal = useCallback(
    (name: string) => dispatch(closeModalAction(name)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onTaskModal = useCallback(
    ({
      modalName,
      data,
      mode,
      headerTextModal,
    }: {
      modalName: string;
      mode: ModeForm;
      headerTextModal: string;
      data?: Omit<TaskFormData, 'flowsId'>;
    }) => {
      dispatch(
        openModalTypedAction({
          Component: TaskModal,
          componentProps: {
            onClose: () => onCloseTaskModal(modalName),
            dataEdit: data,
            updateSetTaskInfo: addTaskInfo,
            mode,
          },
          modalSettings: {
            position: 'static',
            width: '575px',
            headerText: headerTextModal,
          },
          name: modalName,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseTaskModal],
  );

  const onCreate = useCallback(
    () => onTaskModal({ modalName: createTask, headerTextModal: 'Создание задания', mode: 'add' }),
    [onTaskModal],
  );
  const onEdit = useCallback(
    (task: TaskInterface) => {
      const { id, name, schedule, isActive } = task;

      return onTaskModal({
        modalName: editTask,
        mode: 'edit',
        headerTextModal: 'Изменение задания',
        data: { schedule, isActive, projectId: { name: id, value: name } },
      });
    },
    [onTaskModal],
  );

  const tabs: Tab[] = useMemo(
    () =>
      [
        {
          content: 'Детали',
          prompt: 'Детали',
          type: 'text',
          id: 'version',
          Component: () => <Details data={taskInfo} />,
        },
      ].filter(({ Component }) => Component) as Tab[],
    [taskInfo],
  );

  const onUpdateTasks = useCallback(
    async () => {
      await dispatch(loadAdminTasksAction());

      const adminActiveDirector = tasks?.find(({ id }) => id === activeTaskId);

      if (adminActiveDirector) {
        addTaskInfo(adminActiveDirector);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTaskId, addTaskInfo, tasks],
  );

  return (
    <ActiveDirectoriesAndTasksWrapper
      tabs={tabs}
      onDeleteItem={onDeleteTaskModal}
      onEdit={onEdit}
      onCreate={onCreate}
      dataList={tasks}
      loadingDatalist={loading}
      onRowClick={addTaskInfo}
      onOpenDropdown={toggleOpen}
      openDropdown={open}
      onUpdate={onUpdateTasks}
    />
  );
};
