import { GroupType, IdInterface, LoadingInterface, RoleType } from 'types/store';

export interface GroupsListInterface extends IdInterface {
  title: string;
}

export interface GroupsInterface extends LoadingInterface {
  groupsList: GroupsListInterface[];
}

export interface GroupAccessUserListInterface extends IdInterface {
  role: RoleType;
  name: string;
}

export interface GroupAccessUserInterface extends LoadingInterface {
  groupAccessUsersList: GroupAccessUserListInterface[];
}

export interface GroupFlowsListInterface extends IdInterface {
  groupType: GroupType;
  name: string;
}

export interface GroupFlowsInterface extends LoadingInterface {
  groupFlowsList: GroupFlowsListInterface[];
}

export interface GroupSourcesListInterface extends IdInterface {
  sourceGroupType: GroupType;
  name: string;
}

export interface GroupSourcesInterface extends LoadingInterface {
  groupSourcesList: GroupSourcesListInterface[];
}

export interface GroupsSliceStateInterface {
  groups: GroupsInterface;
  groupAccessUsers: GroupAccessUserInterface;
  groupFlows: GroupFlowsInterface;
  groupSources: GroupSourcesInterface;
  activeGroup: GroupsListInterface | null;
}

/* Action Types */
export enum GroupsActionsTypes {
  LOAD_GROUPS = 'LOAD_GROUPS',
  ACTIVE_GROUP_ID = 'ACTIVE_GROUP_ID',
  DELETE_GROUP = 'DELETE_GROUP',
  DELETE_BY_ID_GROUP = 'DELETE_BY_ID_GROUP',
  CREATE_GROUP = 'CREATE_GROUP',
  UPDATE_GROUP = 'UPDATE_GROUP',
  ADD_GROUP = 'ADD_GROUP',
  UPDATE_GROUPS = 'UPDATE_GROUPS',

  LOAD_GROUP_ACCESS_USERS = 'LOAD_GROUP_ACCESS_USERS',
  LOAD_NO_GROUP_ACCESS_USERS = 'LOAD_NO_GROUP_ACCESS_USERS',
  CREATE_USERS = 'CREATE_USERS',
  DELETE_USER_ACCESS = 'DELETE_USER_ACCESS',
  DELETE_BY_ID_USER_ACCESS = 'DELETE_BY_ID_USER_ACCESS',

  LOAD_NO_GROUP_FLOWS = 'LOAD_NO_GROUP_FLOWS',
  LOAD_GROUP_FLOWS = 'LOAD_GROUP_FLOWS',
  UPDATE_GROUP_FLOW = 'UPDATE_GROUP_FLOW',
  UPDATE_GROUP_FLOWS = 'UPDATE_GROUP_FLOWS',
  DELETE_FLOW = 'DELETE_FLOW',
  DELETE_BY_ID_FLOW = 'DELETE_BY_ID_FLOW',
  CREATE_FLOW = 'CREATE_FLOW',

  LOAD_GROUP_SOURCES = 'LOAD_GROUP_SOURCES',
  LOAD_NO_GROUP_SOURCES = 'LOAD_NO_GROUP_SOURCES',
  UPDATE_GROUP_SOURCE = 'UPDATE_GROUP_SOURCE',
  UPDATE_GROUP_SOURCES = 'UPDATE_GROUP_SOURCES',
  DELETE_SOURCE = 'DELETE_SOURCE',
  DELETE_BY_ID_SOURCE = 'DELETE_BY_ID_SOURCE',
  CREATE_SOURCE = 'CREATE_SOURCE',
}

/* Payload Interfaces */

export type GroupPayload = {
  title: string;
  users: string[];
};

export type DeleteFlowPayload = {
  flowId: string;
  groupId: string;
};
export type UpdateFlowPayload = DeleteFlowPayload & {
  type: GroupType;
};
export type CreateFlowPayload = {
  groupId: string;
  type: GroupType;
  flows: string[];
};
export type GetFlowsPayload = {
  groupId?: string;
  userId?: string;
};

export type DeleteSourcePayload = {
  sourceId: string;
  groupId: string;
};
export type UpdateSourcePayload = DeleteSourcePayload & {
  type: GroupType;
};
export type CreateSourcePayload = {
  groupId: string;
  type: GroupType;
  sources: string[];
};

export type DeleteUserAccessPayload = {
  userId: string;
  groupId: string;
};
export type CreateUsersAccessPayload = {
  users: string[];
  groupId: string;
};
export type GetUsersAccessNoGroupPayload = {
  groupId: string;
};
