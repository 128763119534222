import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { TState } from 'store/index';
import { UsersActionsTypes } from 'store/reducers/adminUsers/types';
import {
  createLicenseUser,
  deleteUserLicense,
  loadAdminLicense,
  loadUsersNoLicense,
  updateLicenseUser,
  updateRoleUser,
  uploadLicenseFile,
} from 'store/reducers/adminLicenses/api';
import {
  AdminLicenseDataInterface,
  AdminLicensesActionsTypes,
  AdminLicenseUsersInterface,
  PayloadUpdateLicenseUser,
  PayloadUpdateRoleUser,
  PayloadUsersNoLicense,
} from 'store/reducers/adminLicenses/types';
import { addNewLicenseUser, deleteLicenseByIdUser, updateLicenseUsers } from '.';
import { getAdminLicenseUsers } from 'store/reducers/adminLicenses/getters';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadAdminLicenseAction = createAsyncThunk<
  AdminLicenseDataInterface['adminLicenseData'],
  void,
  { rejectValue: null }
>(AdminLicensesActionsTypes.LOAD_ADMIN_LICENSE, async (_, { rejectWithValue }) => {
  try {
    const response = await loadAdminLicense();
    const adminLicense = response.data.adminLicense;
    const adminLicenseData: AdminLicenseDataInterface['adminLicenseData'] = {
      adminInfo: {
        licenseNum: adminLicense.licenseNum,
        companyName: adminLicense.companyName,
        expires: adminLicense.expires,
        type: adminLicense.type,
      },
      meta: adminLicense.meta,
      users: adminLicense.users.map(({ login, id, roleId, lastDate }) => ({
        id,
        login,
        role: roleId,
        lastVisit: lastDate?.createdAt,
      })),
    };

    return adminLicenseData;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return {} as AdminLicenseDataInterface['adminLicenseData'];
  }
});

export const uploadLicenseFileAction = createAsyncThunk<string, File, { rejectValue: null }>(
  AdminLicensesActionsTypes.UPLOAD_LICENSE_FILE,
  async (file, { rejectWithValue }) => {
    try {
      const response = await uploadLicenseFile(file);
      Snackbar.show('Новая лицензыя успешно загружен.', 'success');

      return response.data;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const deleteUserLicenseAction = createAsyncThunk<string, string, { rejectValue: null }>(
  AdminLicensesActionsTypes.DELETE_LICENSE_USER,
  async (userId, { rejectWithValue }) => {
    try {
      const response = await deleteUserLicense(userId);
      Snackbar.show('Лицензия успешно удалена', 'success');

      return response.data.adminUserLicense.id;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const createLicenseUserAction = createAsyncThunk<FastBoard.API.ApiAdminUserLicenseDTO, string, { rejectValue: null }>(
  AdminLicensesActionsTypes.CREATE_LICENSE_USER,
  async (userId, { rejectWithValue }) => {
    try {
      const response = await createLicenseUser(userId);
      Snackbar.show('Лицензия успешно выдана', 'success');

      return response.data.adminUserLicense;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateLicenseUserAction = createAsyncThunk<FastBoard.API.ApiAdminUserLicenseDTO, PayloadUpdateLicenseUser>(
  AdminLicensesActionsTypes.UPDATE_LICENSE_USER,
  async ({ oldUserId, newUserId }, { rejectWithValue }) => {
    try {
      const response = await updateLicenseUser({ newUserId, oldUserId });

      Snackbar.show('Пользователь лицензии успешно изменен', 'success');

      return response.data.adminUserLicense;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateRoleUserAction = createAsyncThunk<FastBoard.API.ApiAdminUserLicenseDTO, PayloadUpdateRoleUser>(
  AdminLicensesActionsTypes.UPDATE_ROLE_USER,
  async ({ userId, role }, { rejectWithValue }) => {
    try {
      const response = await updateRoleUser({ userId, role });
      Snackbar.show('Роль пользователся успешно изменина', 'success');

      return response.data.adminUserLicense;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateLicenseUsersAction = createAsyncThunk<void, { user: AdminLicenseUsersInterface }>(
  UsersActionsTypes.UPDATE_USERS,
  ({ user }, { dispatch, getState }) => {
    const users = getAdminLicenseUsers(getState() as TState)?.map((value) =>
      value.id === user.id ? { ...value, ...user } : value,
    );

    dispatch(updateLicenseUsers(users));
  },
);

export const deleteLicenseByIdUserAction = createAsyncThunk(
  AdminLicensesActionsTypes.DELETE_LICENSE_BY_ID_USER,
  (userId: string, { dispatch }) => {
    dispatch(deleteLicenseByIdUser({ id: userId }));
  },
);

export const addNewLicenseUserAction = createAsyncThunk<void, AdminLicenseUsersInterface>(
  AdminLicensesActionsTypes.ADD_LICENSE_BY_ID_USER,
  (data, { dispatch }) => {
    dispatch(addNewLicenseUser(data));
  },
);

export const loadUsersNoLicenseAction = createAsyncThunk<
  FastBoard.API.ApiAdminUserGroupListItemResponseDTO[],
  PayloadUsersNoLicense
>(AdminLicensesActionsTypes.LOAD_USER_NO_LICENSE, async ({ licenseNum }, { rejectWithValue }) => {
  try {
    const response = await loadUsersNoLicense({ licenseNum });

    return response.data.adminUserGroupList;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return [] as FastBoard.API.ApiAdminUserGroupListItemResponseDTO[];
  }
});
