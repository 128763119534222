import React from 'react';
import { useSelector } from 'react-redux';
import { getActivePageId, getPages } from 'store/reducers/projectPages/getters';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { PageInterface, UpdatePageByIdPayload } from 'store/reducers/projectPages/types';
import { useAppDispatch } from 'store';
import { SizeHIcon, SizeWIcon } from 'assets/icons/withContainer';
import { pxUnit } from 'constants/global';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { FlexContainer } from 'styles/FlexContainer';
import {
  addPageAction,
  copyPageAction,
  deletePageByIdAction,
  updatePageByIdAction,
  updatePagePositionAction,
} from 'store/reducers/projectPages/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { goToBoardUrl, ProjectIdParam } from 'constants/Routes';
import { SettingsPagesFieldEntry } from 'modules/settingsContainer/SettingsPagesFieldEntry';
import { SizeSVGWrapper } from 'modules/settingsContainer/common/data/PropertiesSettings/styles';
import { openConfirmationModalAction } from 'store/reducers/modals/actions';

export const PagesTab = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pages = useSelector(getPages);
  const activePageId = useSelector(getActivePageId);
  const { projectId } = useParams<ProjectIdParam>();

  const { controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected } = useDataSettingsMetric();
  const onPageChange = (params: UpdatePageByIdPayload) => dispatch(updatePageByIdAction(params));
  const onAddNewPage = () => dispatch(addPageAction());
  const onDeletePage = (pagesId: string) => {
    const namePage = pages.find((page) => page.id === pagesId)?.name;

    dispatch(
      openConfirmationModalAction({
        confirmationButtonText: 'Да',
        cancelButtonText: 'Отмена',
        description: `Вы собираетесь удалить страницу "${namePage}". Это действие нельзя будет отменить. Продолжить?`,
        onConfirm: () => {
          const nextPagesId = pages.find((el) => el.id !== pagesId)?.id;

          dispatch(deletePageByIdAction(pagesId));
          navigate(goToBoardUrl(projectId, nextPagesId));
        },
        onCancel: () => {},
        titleText: 'Удалить страницу',
      }),
    );
  };

  const pageRender: MetricsRenderType<PageInterface> = ({ metrics }) => (
    <>
      {metrics.map((page) => {
        const {
          name,
          id,
          isHidden,
          boardSettings: {
            sizes: { width, height },
          },
        } = page;

        const onUpClick = () => dispatch(updatePagePositionAction({ id, moveTo: 'up' }));
        const onDownClick = () => dispatch(updatePagePositionAction({ id, moveTo: 'down' }));
        const onCopiedClick = () => dispatch(copyPageAction());
        const onClickMetric = onMetricClick(id);
        const isActivePages = activePageId === id;

        return (
          <SettingsPagesFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={() => {
              onClickMetric();
              navigate(goToBoardUrl(projectId, id));
            }}
            onCopyClick={onCopiedClick}
            isSelected={metricIsSelected(id) || isActivePages}
            isActive={isActivePages}
            isHidden={isHidden}
            fieldValue={name}
            onFieldChange={(name) => onPageChange({ id, page: { ...page, name } })}
            onDownClick={onDownClick}
            onUpClick={onUpClick}
            onClickHidden={() => onPageChange({ id, page: { ...page, isHidden: !isHidden } })}
            key={id}
          >
            <FlexContainer justifyContent="space-between" width="100%">
              <NumberPropertiesBlock
                properties={
                  <SizeSVGWrapper>
                    <SizeWIcon />
                  </SizeSVGWrapper>
                }
                name="horizontal"
                unit={pxUnit}
                value={width}
                onChange={(width) => onPageChange({ id, page: { ...page, boardSettings: { sizes: { width: width, height } } } })}
              />
              <NumberPropertiesBlock
                properties={
                  <SizeSVGWrapper>
                    <SizeHIcon />
                  </SizeSVGWrapper>
                }
                name="vertical"
                unit={pxUnit}
                value={height}
                onChange={(height) => onPageChange({ id, page: { ...page, boardSettings: { sizes: { width: width, height } } } })}
              />
            </FlexContainer>
          </SettingsPagesFieldEntry>
        );
      })}
    </>
  );
  return (
    <>
      <MetricSettings
        isOpenDefaultMainContainer
        addButtonText="Создать страницу"
        metricRender={pageRender}
        metrics={pages}
        onAdd={() => onAddNewPage()}
        onDelete={(id) => id && onDeletePage(id)}
        controls={controls}
      />
    </>
  );
};
