import { RadioButtonSelector } from 'modules/ui/RadioButtonSelector';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { FlexContainer } from 'styles/FlexContainer';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { enableFilterAction, updateEnabledFilterAction } from 'store/reducers/filters/actions';
import { disableFilterById } from 'store/reducers/filters';
import { usePrimitiveHaveBeenChanged } from 'utils/hooks/primitiveHaveBeenChanged';
import { PrimaryTextSpan } from 'styles/TextsElements';
import {
  UpdateEnableFilterDataFnType,
  useChangedFilterData,
  useEnabledFilter,
  useFilterQuery,
} from 'modules/filters/hooks/filter';
import { SingleEnabledFilterInterface, SingleFilterInterface } from 'store/reducers/filters/types';
import { FilterViewInterface } from 'modules/workspace/components/FilterArea/types';

export const SingleFilterComponent: FilterViewInterface<SingleFilterInterface> = ({ data, whereQuery }) => {
  const {
    type,
    isGlobal,
    selectedData,
    position,
    isRealData,
    fictionalData,
    paddings,
    id,
    nameSettings,
    modelId,
    filterInfluences,
    sqlData,
  } = data;

  const [activeValue, setActiveValue] = useState<null | string>(null);

  const selectedValues = useMemo(() => (activeValue ? [activeValue] : []), [activeValue]);

  const { dispatch, filterValues, modelIdValue } = useFilterQuery({
    nameSettings,
    id,
    modelId,
    whereQuery,
    sqlData,
  });

  const updateEnableFilterDataFn: UpdateEnableFilterDataFnType<SingleEnabledFilterInterface> = (enableFilterData) => {
    if (enabledFilter?.id && enableFilterData.selectedValues.length) {
      dispatch(
        updateEnabledFilterAction<SingleEnabledFilterInterface>({
          id: enabledFilter?.id,
          data: { ...enableFilterData },
        }),
      );
    }
  };

  const { enabledFilter, enableFilterData } = useEnabledFilter({
    id,
    type,
    modelIdValue,
    filterInfluences,
    selectedValues,
    isRealData,
    isGlobal,
    nameSettings,
    updateEnableFilterDataFn,
  });

  const { isRealDataIsChanged, fieldNameIsChanged } = useChangedFilterData({ nameSettings, isRealData });

  const filterOptions = useMemo(() => {
    const data = isRealData ? selectedData : fictionalData;

    return data.reduce<SettingsRadioItem<string>[]>((result, value) => {
      if (value) {
        const notIncludesToValues = isRealData && !filterValues.find(({ value: filterValue }) => filterValue === value);

        return [...result, { value, label: value, disabled: notIncludesToValues }];
      }

      return result;
    }, []);
  }, [selectedData, isRealData, fictionalData, filterValues]);

  const filterPadding = useMemo(() => {
    if (!paddings) return undefined;

    const { vertical, horizontal } = paddings;

    return `${vertical}px ${horizontal}px`;
  }, [paddings]);

  const activeRadioValue = useMemo(() => {
    if (activeValue === null) {
      return { label: '', value: '' };
    }

    return getActiveRadioValue(filterOptions, activeValue);
  }, [filterOptions, activeValue]);

  const activeValueIsIncluded = useMemo(() => !!activeValue && selectedData.includes(activeValue), [selectedData, activeValue]);
  const activeValueIsIncludedIsChanged = usePrimitiveHaveBeenChanged(activeValueIsIncluded);

  /* To clear the filter after changing isRealData or nameSettings.fieldName */
  useEffect(() => {
    if (isRealDataIsChanged || fieldNameIsChanged || (activeValueIsIncludedIsChanged && !activeValueIsIncluded)) {
      setActiveValue(null);
      if (enabledFilter) {
        dispatch(disableFilterById(enabledFilter.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRealDataIsChanged, fieldNameIsChanged, activeValueIsIncludedIsChanged, activeValueIsIncluded]);

  /* Restore active filter state */
  useEffect(() => {
    if (enabledFilter && enabledFilter?.selectedValues?.length > 0) {
      setActiveValue(enabledFilter.selectedValues[0]);
    } else {
      setActiveValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledFilter?.selectedValues]);

  /* Enable Filter and Disable by click to Save button   */
  const onEnableFilter = useCallback(
    ({ value }: SettingsRadioItem<string>) => {
      setActiveValue((activeValue) => (activeValue === value ? null : value));

      if (!enabledFilter) {
        dispatch(enableFilterAction({ ...enableFilterData, isRealData, selectedValues: [value] }));
        return;
      }

      if (activeValue === value) {
        dispatch(disableFilterById(enabledFilter.id));
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setActiveValue, isRealData, enabledFilter, enableFilterData, activeValue],
  );

  return (
    <FlexContainer width="100%" flexWrap="wrap" justifyContent={position} padding={filterPadding} position="relative">
      {filterOptions.length === 0 ? (
        <PrimaryTextSpan lineHeight="12px" marginBottom="8px">
          Выберите фильтр
        </PrimaryTextSpan>
      ) : (
        <RadioButtonSelector size="large" onChange={onEnableFilter} options={filterOptions} activeValue={activeRadioValue} />
      )}
    </FlexContainer>
  );
};

export const SingleFilter = memo(SingleFilterComponent) as FilterViewInterface;
