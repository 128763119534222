import React, { memo } from 'react';
import { ListUIItemInterface } from 'modules/ui/lists/BlockList/ListBlockUI/types';
import { ListBlockWrapper } from 'modules/ui/lists/BlockList/ListBlockUI/styles';
import { ListBlockItem } from 'modules/ui/lists/BlockList/ListBlockItem';
import { NoopType, NoopValueType } from 'types/global';
import { FlowAddCardIcon } from 'assets/icons/flow';
import { useWindowDimensions } from 'utils/hooks/screenSizeHook';
import { startMobileSize } from 'constants/constants';

export interface ListUIProps {
  data: ListUIItemInterface[];
  onOpenInformationModal: NoopValueType<{ id: string; name: string }>;
  onItemClick: NoopValueType<Omit<ListUIItemInterface, 'title'>>;
  onActiveItem: NoopValueType<string>;
  onAddItem: NoopType;
  activeItem: string;
  selectedInfoId: string;
  disabledAddCard?: boolean;
  isViewer: boolean;
}

const ListBlockUIComponent = ({
  data,
  onOpenInformationModal,
  onItemClick,
  onActiveItem,
  activeItem,
  disabledAddCard = false,
  selectedInfoId,
  isViewer,
  onAddItem,
}: ListUIProps) => {
  const { width } = useWindowDimensions();

  return (
    <ListBlockWrapper>
      {!disabledAddCard && width > startMobileSize && (
        <ListBlockItem title="Создать новый проект" onItemClick={onAddItem} CardIcon={FlowAddCardIcon} disabledProtect />
      )}

      {data.map(({ id, title, infoItem, isProtected, image }) => (
        <ListBlockItem
          isViewer={isViewer}
          key={id}
          title={title}
          image={image}
          onItemClick={() => onItemClick({ id, infoItem })}
          onOpenInformationModal={() => onOpenInformationModal({ id, name: title })}
          onActiveItem={() => onActiveItem(id)}
          activeItem={activeItem === id}
          selectedInfo={selectedInfoId === id}
          isProtected={isProtected}
        />
      ))}
    </ListBlockWrapper>
  );
};

export const ListBlockUI = memo(ListBlockUIComponent);
