import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';
import { BarValuePositionType, LineAndBarIndicatorType } from 'store/reducers/visualisations/types';
import { TopAndBottomType } from 'types/styles';

const barPositionOptions: (isRotated: boolean) => SettingsRadioItem<BarValuePositionType>[] = (isRotated) => [
  { value: 'flex-start', label: isRotated ? 'В начале ' : 'В конце' },
  { value: 'center', label: 'В центре' },
  { value: 'flex-end', label: isRotated ? 'В конце' : 'В начале' },
  { value: 'outside', label: 'Снаружи' },
];

const linePositionOptions: (isRotated: boolean) => SettingsRadioItem<TopAndBottomType>[] = (isRotated) => [
  { value: 'top', label: isRotated ? 'Слева' : 'Сверху' },
  { value: 'bottom', label: isRotated ? 'Справа' : 'Снизу' },
];

export const positionOptions: (
  type: LineAndBarIndicatorType,
  isRotated: boolean,
) => SettingsRadioItem<BarValuePositionType | TopAndBottomType>[] = (type, isRotated) =>
  type === 'bar' ? barPositionOptions(isRotated) : linePositionOptions(isRotated);
