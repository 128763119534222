import { ColorByConditionUtilsInterface } from 'modules/visualisations/Table/visualisation/types';
import { ColorAndImageByInterfaceEnum } from 'store/reducers/visualisations/types';
import { isArray } from 'mathjs';

export const ColorByConditionUtils = ({
  visualisationValues,
  colorBy,
  index,
  indexAccesor,
  alias,
}: ColorByConditionUtilsInterface) => {
  const value = visualisationValues[alias] as Array<Array<string | null> | null>;
  const rowColorByCondition = colorBy.type === ColorAndImageByInterfaceEnum.Condition ? value[index] : null;
  const colorsIndex = isArray(rowColorByCondition) && rowColorByCondition?.[indexAccesor];
  const colorByCondition = colorsIndex ? colorBy.byCondition.colors[+colorsIndex - 1]?.value : null;

  return colorByCondition;
};
