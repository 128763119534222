import { OnValueChange } from 'types/global';
import { ShowSumInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { OrientationGroupSettings } from 'modules/settingsContainer/common/OrientationGroupSettings';

type ShowSumSettingsProps = OnValueChange<ShowSumInterface>;

export const ShowSumSettings = ({ value: showSum, onChange }: ShowSumSettingsProps) => {
  const { isShow, orientation } = showSum;
  return (
    <MainContainerSettings
      titleText="Показать сумму"
      switcherState={isShow}
      switcherChange={() => onChange({ ...showSum, isShow: !isShow })}
    >
      <OrientationGroupSettings value={orientation} onChange={(orientation) => onChange({ ...showSum, orientation })} />
    </MainContainerSettings>
  );
};
