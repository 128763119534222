import { createColumnHelper } from '@tanstack/react-table';
import { TableCell } from 'modules/ui/Table/TableCell';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store';
import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { LoadLayoutAdmin } from 'components/layouts/LoadLayoutAdmin';
import { useSelector } from 'react-redux';
import { FlexContainer } from 'styles/FlexContainer';
import { ActivationKey } from 'components/admin/licenses/ActivationKey';
import { FilterRoleStatistics } from 'components/admin/licenses/FilterRoleStatistics';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import {
  getAdminLicense,
  getAdminLicenseInfo,
  getAdminLicenseMeta,
  getAdminLicenseUsers,
} from 'store/reducers/adminLicenses/getters';
import {
  addNewLicenseUserAction,
  createLicenseUserAction,
  deleteLicenseByIdUserAction,
  deleteUserLicenseAction,
  loadAdminLicenseAction,
  loadUsersNoLicenseAction,
  updateLicenseUserAction,
  updateLicenseUsersAction,
  updateRoleUserAction,
  uploadLicenseFileAction,
} from 'store/reducers/adminLicenses/actions';
import { AdminLicenseUsersInterface } from 'store/reducers/adminLicenses/types';
import { roleData } from 'constants/constants';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { getStringDateByFormat } from 'utils/dates';
import { defaultDateTimeFormat } from 'constants/dates';
import { RoleKeyType } from './FilterRoleStatistics/types';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import { addLicenseUser, editLicenseUser, filterUsersByRole, roleUser } from './constants';
import { SelectAddAndEditModal } from 'components/admin/licenses/Modal/SelectAddAndEditModal';
import { LicenseUserModalCallbackProps } from 'components/admin/licenses/types';
import { RoleType } from 'types/store';
import { RenameRoleModal } from 'components/admin/licenses/Modal/RenameRoleModal';

export const AdminLicenses: FC = () => {
  const dispatch = useAppDispatch();

  const { loading } = useSelector(getAdminLicense);
  const adminLicenseInfo = useSelector(getAdminLicenseInfo);
  const adminLicenseUsers = useSelector(getAdminLicenseUsers);
  const adminLicenseMeta = useSelector(getAdminLicenseMeta);

  const [filteredRole, setFilteredRole] = useState<RoleKeyType>('general');

  useEffect(() => {
    dispatch(loadAdminLicenseAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDepriveLicense = useCallback(async (user: AdminLicenseUsersInterface) => {
    try {
      const userId = await dispatch(deleteUserLicenseAction(user?.id)).unwrap();

      if (userId) {
        dispatch(deleteLicenseByIdUserAction(userId));
      }
    } catch (error) {
      console.error('Error creating project version:', error);
    } finally {
      dispatch(closeConfirmationModalAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDepriveLicenseModal = useCallback(
    async (user: AdminLicenseUsersInterface) => {
      dispatch(
        openConfirmationModalAction({
          titleText: 'Лишение лицензии',
          onConfirm: () => onDepriveLicense(user),
          subTitleText: `Действительно лишить пользователя ${user.login} лицензии?`,
          confirmationButtonText: 'Да',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDepriveLicense],
  );

  const onCloseLicenseUserModal = (name: string) => dispatch(closeModalAction(name));

  const onAddLicenseUser = useCallback(
    async (userId: string) => {
      try {
        const resProtect = await dispatch(createLicenseUserAction(userId)).unwrap();

        if (resProtect) {
          const { roleId, id, login, lastDate } = resProtect;
          await dispatch(addNewLicenseUserAction({ id, role: roleId, lastVisit: lastDate?.createdAt, login }));
          onCloseLicenseUserModal(addLicenseUser);
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseLicenseUserModal],
  );
  const onEditLicenseUser = useCallback(
    async (userId: string, oldUserId?: string) => {
      try {
        if (oldUserId) {
          const resProtect = await dispatch(updateLicenseUserAction({ newUserId: userId, oldUserId })).unwrap();

          if (resProtect) {
            const { roleId, id, login, lastDate } = resProtect;
            await dispatch(deleteLicenseByIdUserAction(oldUserId));
            await dispatch(addNewLicenseUserAction({ id, login, role: roleId, lastVisit: lastDate?.createdAt }));
            onCloseLicenseUserModal(editLicenseUser);
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseLicenseUserModal],
  );

  const onLicenseUserModal = useCallback(
    async (
      { onLicenseUser, titleModal, headerText, nameModal, confirmationButtonText }: LicenseUserModalCallbackProps,
      user?: AdminLicenseUsersInterface,
    ) => {
      const oldUserId = user?.id;
      const oldUserLogin = user?.login;

      try {
        if (adminLicenseInfo?.licenseNum) {
          const resProtect = await dispatch(loadUsersNoLicenseAction({ licenseNum: adminLicenseInfo?.licenseNum })).unwrap();
          const selectData = resProtect.map(({ id, name }) => ({ name: id, value: name }));

          if (resProtect && selectData.length > 0) {
            dispatch(
              openModalTypedAction({
                Component: SelectAddAndEditModal,
                componentProps: {
                  onClose: () => onCloseLicenseUserModal(nameModal),
                  onClick: (id) => onLicenseUser(id, oldUserId || ''),
                  title: titleModal,
                  selectData,
                  selectItem: oldUserLogin && oldUserId ? { value: oldUserLogin, name: oldUserId } : undefined,
                  confirmationButtonText,
                },
                modalSettings: {
                  position: 'static',
                  width: '320px',
                  maxHeight: '168px',
                  headerText: headerText,
                },
                name: nameModal,
              }),
            );
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adminLicenseInfo?.licenseNum, onCloseLicenseUserModal],
  );

  const onChangeLicense = useCallback(
    async (file: File) => {
      try {
        const action = await dispatch(uploadLicenseFileAction(file)).unwrap();
        if (action) {
          await dispatch(loadAdminLicenseAction());
        }
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onCloseRoleUserModal = () => dispatch(closeModalAction(roleUser));

  const onRoleUser = useCallback(
    async (userId: string, role?: RoleType) => {
      try {
        if (role) {
          const resProtect = await dispatch(updateRoleUserAction({ role, userId })).unwrap();

          if (resProtect) {
            const { roleId, id, login, lastDate } = resProtect;
            await dispatch(updateLicenseUsersAction({ user: { id, role: roleId, login, lastVisit: lastDate?.createdAt } }));
            onCloseRoleUserModal();
          }
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseLicenseUserModal],
  );

  const onRoleUserModal = useCallback(
    (user: AdminLicenseUsersInterface) => {
      dispatch(
        openModalTypedAction({
          Component: RenameRoleModal,
          componentProps: {
            onClose: onCloseRoleUserModal,
            onClick: (role) => onRoleUser(user?.id, role as RoleType),
          },
          modalSettings: {
            position: 'static',
            width: '320px',
            maxHeight: '168px',
            headerText: 'Изменить роль',
          },
          name: roleUser,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseRoleUserModal, onRoleUser],
  );

  const rows: AdminLicenseUsersInterface[] = useMemo(() => {
    let usersToDisplay = adminLicenseUsers || [];

    if (filteredRole !== 'general') {
      usersToDisplay = filterUsersByRole(filteredRole, adminLicenseUsers) || [];
    }

    return usersToDisplay || [];
  }, [adminLicenseUsers, filteredRole]);

  const columnHelper = createColumnHelper<AdminLicenseUsersInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('login', {
        header: 'Пользователь',
        cell: (info) => (
          <TableCell
            text={info.getValue()}
            actions={{
              onRemove: async () => {
                await onDepriveLicenseModal(info.row.original);
              },
              onEdit: async () => {
                await onLicenseUserModal(
                  {
                    onLicenseUser: onEditLicenseUser,
                    titleModal: 'Пользователь',
                    headerText: 'Изменение пользователя лицензии',
                    nameModal: editLicenseUser,
                    confirmationButtonText: 'Изменить',
                  },
                  info.row.original,
                );
              },
            }}
          />
        ),
      }),
      columnHelper.accessor('role', {
        header: 'Роль',
        cell: (info) => (
          <TableCell text={roleData[info.getValue()]} actions={{ onEdit: () => onRoleUserModal(info.row.original) }} />
        ),
      }),
      columnHelper.accessor('lastVisit', {
        header: 'Последнее посещение',
        cell: (info) => {
          const { lastVisit } = info.row.original;
          const text = lastVisit ? String(getStringDateByFormat(new Date(lastVisit), defaultDateTimeFormat)) : '';

          return <TableCell text={text} />;
        },
      }),
    ],
    [columnHelper, onDepriveLicenseModal, onEditLicenseUser, onLicenseUserModal, onRoleUserModal],
  );

  return (
    <LoadLayoutAdmin>
      <Body>
        <LoadingOverlay loading={loading} backgroundColor={`var(${ColorVarsEnum.Level_5_application})`} />
        {adminLicenseInfo && <ActivationKey data={adminLicenseInfo} onChangeLicense={onChangeLicense} />}
        <FlexContainer flexDirection="column" gap="35px" width="100%" height="100%">
          {adminLicenseMeta && (
            <FilterRoleStatistics adminLicenseMeta={adminLicenseMeta} onStatisticsProgressClick={setFilteredRole} />
          )}
          <FlexContainer flexDirection="column" width="100%" height="calc(100vh - 300px)" position="relative">
            <TableWithSearch
              addButtonName="Выдать лицензию"
              filterColumnName="login"
              columns={columns}
              rows={rows}
              onAddModal={() =>
                onLicenseUserModal({
                  onLicenseUser: onAddLicenseUser,
                  titleModal: 'Пользователю',
                  headerText: 'Выдача лицензии',
                  nameModal: addLicenseUser,
                  confirmationButtonText: 'Выдать',
                })
              }
              loading={loading}
            />
          </FlexContainer>
        </FlexContainer>
      </Body>
    </LoadLayoutAdmin>
  );
};

const Body = styled.div`
  max-width: 1464px;
  margin: 0 auto;
  display: flex;
  padding: 32px;
  flex-direction: row;
  flex: 1;
  background: ${`var(${ColorVarsEnum.Level_5_application})`};
  flex-wrap: nowrap;
  gap: 32px;
`;
