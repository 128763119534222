import {
  BarValuePositionType,
  FormattingInterface,
  WaterfallIndicatorTypeInterface,
  WaterfallVisualisationType,
} from 'store/reducers/visualisations/types';
import { TopAndBottomType } from 'types/styles';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import { WrappedReactECharts } from 'modules/visualisations/components/WrappedReactECharts';
import { memo, useCallback, useMemo } from 'react';
import { BarSeriesOption, LineSeriesOption } from 'echarts';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import {
  defaultIndicatorPhantomName,
  defaultWaterfallDataSettings,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import { WaterfallEChartsOption } from 'modules/visualisations/Waterfall/visualisation/types';
import {
  getBarGraphGridDimensions,
  getIndicatorAxisWithGridDimensions,
  getWaterfallData,
} from 'modules/visualisations/Waterfall/visualisation/constants';
import { SingleIncisionLayout } from 'modules/visualisations/components/SingleIncisionLayout';
import { calculateGridDimension, initialDimensions } from 'utils/generateConfigGraphic';
import { DefaultAxisInterface, GridDimensionsInterface } from 'types/echarts';
import { useVisualisation } from 'modules/visualisations/hooks/visualisation';
import { useActiveIncision } from 'modules/visualisations/hooks/activeIncision';
import {
  onDataZoomHorizontalStartAndEndSettingsChange,
  onDataZoomVerticalStartAndEndSettingsChange,
} from 'modules/visualisations/Waterfall/settings/ViewTab/constants';
import { useProperties } from 'modules/visualisations/hooks/useProperties';
import { useFormat } from 'modules/visualisations/hooks/useFormat';
import {
  createBarPropertiesData,
  createTooltipFormatter,
  defaultGridDimension,
  getIncisionAxisConfigWithGridDimensions,
  getLegendConfigWithGridDimensions,
  getMinMaxValues,
  getPositionLabel,
} from 'modules/visualisations/common/constants';
import { useIncisionColors } from 'modules/visualisations/hooks/useIncisionColors';
import { useDataZoom } from 'modules/visualisations/hooks/useDataZoom';
import { onActiveIncisionIdChange } from 'modules/visualisations/Pie/visualisation/constants';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { isPositiveNumber } from 'utils/utils';
import { useManualResize } from 'modules/visualisations/hooks/manualResize';

export const WaterfallVisualisationComponent: VisualisationOriginInterface<WaterfallVisualisationType> = ({
  data,
  sqlRequest,
}) => {
  const {
    id,
    dataSettings,
    viewSettings: {
      dataZoomVerticalStartAndEnd,
      dataZoomHorizontalStartAndEnd,
      description,
      header,
      axisIncisionSettings,
      axisIndicatorSettings,
      horizontalZoom,
      verticalZoom,
      showTips,
      legendSettings,
      incisionSelectorPosition,
      visualisationPaddings: {
        isShow: visualisationPaddingsIsShow,
        paddingsVisualisation: { topPadding, rightPadding, leftPadding, bottomPadding },
      },
    },
    sqlData,
    events,
  } = data;

  const allIndicators = useMemo(
    () => [...dataSettings.indicators, ...dataSettings.positiveAndNegativeIndicators],
    [dataSettings.indicators, dataSettings.positiveAndNegativeIndicators],
  );

  const propertiesData = useMemo(() => createBarPropertiesData(allIndicators), [allIndicators]);

  const properties = useProperties(propertiesData);

  const { visualisationNormalizedValues, updateFilter, eChartRef } = useVisualisation({
    sqlData,
    id,
    dataSettings,
    events,
    limit: dataSettings.limit,
    intervalRandomData: dataSettings.minAndMax,
    sqlRequest,
  });

  const formattingDataIndicators = useMemo(
    () =>
      allIndicators.reduce<Record<string, FormattingInterface>>(
        (indicators, { name, settings: { formatting } }) => ({
          ...indicators,
          [name]: formatting,
        }),
        {},
      ),
    [allIndicators],
  );

  const { formatting } = useFormat(formattingDataIndicators);

  const activeIncision = useActiveIncision({
    activeIncisionId: dataSettings.activeIncisionId,
    defaultIncision: defaultWaterfallDataSettings.incisions[0],
    incisions: dataSettings.incisions,
  });

  const { getColorValues, activeThemeSchema, defaultColor } = useColorValues();

  const waterfallData = useMemo(
    () => getWaterfallData(dataSettings, visualisationNormalizedValues),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dataSettings.isRealData,
      dataSettings.incisions,
      dataSettings.indicators,
      dataSettings.positiveAndNegativeIndicators,
      dataSettings.phantomIndicators,
      dataSettings.activeIncisionId,
      visualisationNormalizedValues,
    ],
  );

  useManualResize({ eChartRef: eChartRef.current, deps: [description, header, dataSettings.incisions.length] });

  const getIncisionColor = useIncisionColors(waterfallData?.activeIncisionSum, activeIncision);

  const waterfallDataResult = useCallback(
    <T extends BarValuePositionType & TopAndBottomType>(
      seriesName: string,
      position: T,
      isHorizontalOrientation: boolean,
      indicatorType: WaterfallIndicatorTypeInterface['type'],
    ) =>
      waterfallData?.data[seriesName]?.map((value) => {
        const positiveNumber = isPositiveNumber(Number(indicatorType === 'negative' ? -Number(value) : value)),
          color = getColorValues(properties[seriesName]?.fontColor) || activeThemeSchema[ColorVarsEnum.Level_1];

        return {
          value: value !== 0 ? value : null,
          label: {
            ...getPositionLabel(positiveNumber, isHorizontalOrientation)[position],
            color,
          },
        };
      }),
    [waterfallData, getColorValues, properties, activeThemeSchema],
  );

  const [plan, fact] = dataSettings.indicators;
  const planIndicator = plan || defaultWaterfallDataSettings.indicators[0];
  const factIndicator = fact || defaultWaterfallDataSettings.indicators[1];
  const [positive, negative] = dataSettings.positiveAndNegativeIndicators;
  const [phantomIndicators] = dataSettings.phantomIndicators;

  const {
    name: namePlan,
    fieldName: fieldNamePlan,
    settings: { nameFromDatabase: nameFromDatabasePlan },
  } = plan;

  const {
    name: nameFact,
    fieldName: fieldNameFact,
    settings: { nameFromDatabase: nameFromDatabaseFact },
  } = fact;

  const {
    name: namePhantom,
    fieldName: fieldNamePhantom,
    settings: { nameFromDatabase: nameFromDatabasePhantom },
  } = phantomIndicators;

  const seriesPlanName = getVisualisationFieldName({
    name: namePlan,
    nameFromDatabase: nameFromDatabasePlan,
    fieldName: fieldNamePlan,
  });

  const seriesPhantomName = getVisualisationFieldName({
    name: namePhantom,
    nameFromDatabase: nameFromDatabasePhantom,
    fieldName: fieldNamePhantom,
  });

  const seriesFactName = getVisualisationFieldName({
    name: nameFact,
    nameFromDatabase: nameFromDatabaseFact,
    fieldName: fieldNameFact,
  });

  const series = useMemo<Array<LineSeriesOption | BarSeriesOption>>(() => {
    return [phantomIndicators, planIndicator, positive, negative, factIndicator]?.map(
      ({ settings: { nameFromDatabase, showValue }, fieldName, name, type, color }) => {
        const seriesName = getVisualisationFieldName({ name, nameFromDatabase, fieldName });

        const sameOption = {
          markPoint: {
            symbol: 'circle',
            symbolSize: 200,
            symbolOffset: [13, 13],
            symbolRotate: 23,
          },
          stack: 'Total',
          data: waterfallDataResult(
            seriesName,
            showValue.position as BarValuePositionType & TopAndBottomType,
            showValue.orientation === 'horizontal',
            type,
          ),
        };

        const labelOption = {
          fontSize: properties[name]?.fontSize,
          fontWeight: properties[name]?.fontStyle.bold ? 'bold' : 'normal',
          fontStyle: properties[name]?.fontStyle.italic ? 'italic' : 'normal',
        };

        const itemStyleOption =
          seriesName === defaultIndicatorPhantomName
            ? {
                itemStyle: {
                  borderColor: 'transparent',
                  color: 'transparent',
                },
              }
            : {
                itemStyle: {
                  color: getIncisionColor || getColorValues(color) || defaultColor,
                },
              };

        const formattingFunction = formatting[name],
          isNegative = type === 'negative';

        return {
          name: seriesName,
          type: 'bar',

          label: {
            ...labelOption,
            backgroundColor: showValue.position !== 'outside' ? 'inherit' : 'transparent',
            show: showValue.isShow,
            rotate: showValue.orientation === 'horizontal' ? 0 : 90,
            formatter:
              formatting && formattingFunction
                ? (data) => {
                    const value = data.value as number | string | null;
                    if (value !== null) {
                      return formattingFunction?.(isNegative ? -value : value);
                    }
                  }
                : `${isNegative ? '-' : ''}{c}`,
          },
          ...itemStyleOption,

          ...sameOption,
        } as BarSeriesOption;
      },
    );
  }, [
    phantomIndicators,
    planIndicator,
    positive,
    negative,
    factIndicator,
    waterfallDataResult,
    properties,
    getIncisionColor,
    getColorValues,
    defaultColor,
    formatting,
  ]);

  const xAxisValue = useMemo(
    () =>
      getIncisionAxisConfigWithGridDimensions({
        axisIncisionSettings: axisIncisionSettings,
        fieldName: activeIncision.fieldName,
        activeThemeSchema,
        data: waterfallData.data[activeIncision.fieldName],
      }),
    [activeThemeSchema, activeIncision.fieldName, waterfallData.data, axisIncisionSettings],
  );

  const minAndMaxValues = useMemo(
    () =>
      getMinMaxValues({
        settings: dataSettings.minAndMax,
        isNominated: dataSettings.type === 'nominated',
        activeIncisionSum: waterfallData.activeIncisionSum,
      }),
    [dataSettings.minAndMax, dataSettings.type, waterfallData.activeIncisionSum],
  );

  const yAxisValue = useMemo(() => {
    const { minValue, maxValue, maxIndicatorLength } = minAndMaxValues;

    return getIndicatorAxisWithGridDimensions({
      axisIndicatorSettings: axisIndicatorSettings,
      activeThemeSchema,
      mainIndicator: { max: maxValue, min: minValue, maxStringLength: maxIndicatorLength },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeThemeSchema, JSON.stringify(axisIndicatorSettings), minAndMaxValues]);

  const axisValues = useMemo(
    () => ({ xAxis: xAxisValue.config, yAxis: yAxisValue.config }),
    [xAxisValue.config, yAxisValue.config],
  );

  const indicatorsData = [plan, positive, negative, fact];

  const legendValue = useMemo(
    () =>
      getLegendConfigWithGridDimensions({
        indicators: indicatorsData.length > 0 ? [plan, positive, negative, fact] : defaultWaterfallDataSettings.indicators,
        legendSettings: legendSettings,
        defaultColor,
      }),
    [indicatorsData.length, plan, positive, negative, fact, legendSettings, defaultColor],
  );

  const { onDataZoom, zoomValues } = useDataZoom({
    data: waterfallData.data,
    legendSettings,
    horizontalZoom,
    verticalZoom,
    dataZoomHorizontalStartAndEnd,
    dataZoomVerticalStartAndEnd,
    onDataZoomHorizontalStartAndEndSettingsChange,
    onDataZoomVerticalStartAndEndSettingsChange,
  });

  const { horizontalSliderXZoomValue, horizontalInsideYZoomValue, verticalInsideXZoomValue, verticalSliderYZoomValue } =
    zoomValues;

  const barGraphGridDimensions = useMemo<GridDimensionsInterface>(
    () => getBarGraphGridDimensions(dataSettings.indicators),
    [dataSettings.indicators],
  );

  const lineGraphGridDimensions = useMemo<GridDimensionsInterface>(() => {
    let isActiveGrid = false;

    dataSettings.indicators.forEach(
      ({
        settings: {
          showValue: { isShow, position },
        },
      }) => {
        if (!isActiveGrid) {
          isActiveGrid = isShow && (position as TopAndBottomType) === 'top';
        }
      },
    );

    const shiftPosition = 'top';

    return isActiveGrid ? { ...initialDimensions, [shiftPosition]: 20 } : initialDimensions;
  }, [dataSettings.indicators]);

  const gridValue = useMemo<WaterfallEChartsOption['grid']>(
    () =>
      calculateGridDimension([
        defaultGridDimension,
        legendValue.gridDimensions,
        horizontalInsideYZoomValue.gridDimensions,
        verticalInsideXZoomValue.gridDimensions,
        initialDimensions,
        lineGraphGridDimensions,
        barGraphGridDimensions,
        xAxisValue.gridDimensions,
        yAxisValue.gridDimensions,
      ]),
    [
      legendValue.gridDimensions,
      horizontalInsideYZoomValue.gridDimensions,
      verticalInsideXZoomValue.gridDimensions,
      lineGraphGridDimensions,
      barGraphGridDimensions,
      xAxisValue.gridDimensions,
      yAxisValue.gridDimensions,
    ],
  );

  const tooltipFormatter = createTooltipFormatter({
    seriesPhantomName,
    formatting,
  });

  const tooltipValue = useMemo<WaterfallEChartsOption['tooltip']>(
    () => ({
      formatter: tooltipFormatter,
      show: showTips,
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
        crossStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
    }),
    [showTips, tooltipFormatter, activeThemeSchema],
  );

  const option = useMemo<WaterfallEChartsOption>(
    () => ({
      textStyle: {
        color: defaultColor,
      },
      series: series as WaterfallEChartsOption['series'],
      tooltip: tooltipValue,
      legend: legendValue.config,
      grid: visualisationPaddingsIsShow
        ? {
            top: topPadding,
            bottom: bottomPadding,
            left: leftPadding,
            right: rightPadding,
          }
        : gridValue,
      dataZoom: [
        horizontalSliderXZoomValue.config,
        verticalSliderYZoomValue.config,
        horizontalInsideYZoomValue.config,
        verticalInsideXZoomValue.config,
      ],
      ...(axisValues as DefaultAxisInterface),
    }),
    [
      defaultColor,
      series,
      tooltipValue,
      legendValue.config,
      visualisationPaddingsIsShow,
      topPadding,
      bottomPadding,
      leftPadding,
      rightPadding,
      gridValue,
      horizontalSliderXZoomValue.config,
      verticalSliderYZoomValue.config,
      horizontalInsideYZoomValue.config,
      verticalInsideXZoomValue.config,
      axisValues,
    ],
  );

  const onChartClick = useCallback(
    (params: CallbackDataParams) => {
      if (![seriesPlanName, seriesFactName].includes(params.name)) {
        updateFilter({ selectedValue: params.name, fieldName: activeIncision.data.fieldName });
      }
    },
    [activeIncision.data.fieldName, seriesFactName, seriesPlanName, updateFilter],
  );

  const onEvents = {
    dataZoom: onDataZoom,
    click: onChartClick,
  };

  return (
    <SingleIncisionLayout
      incisions={dataSettings.incisions}
      value={dataSettings.activeIncisionId}
      incisionSelectorPosition={incisionSelectorPosition}
      onChange={(activeIncisionId) => activeIncisionId && onActiveIncisionIdChange({ activeIncisionId, id })}
    >
      <WrappedReactECharts
        onEvents={onEvents}
        notMerge
        ref={(e) => {
          eChartRef.current = e?.getEchartsInstance();
        }}
        style={{ width: '100%', height: '100%' }}
        option={option}
      />
    </SingleIncisionLayout>
  );
};

export const WaterfallVisualisation = memo(
  WaterfallVisualisationComponent,
) as VisualisationOriginInterface<WaterfallVisualisationType>;
