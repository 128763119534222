import { RoleType } from 'types/store';

export const startMobileSize = 1024;

export const roleItems: Array<{ name: string; value: RoleType }> = [
  { name: 'Админ', value: 'admin' },
  { name: 'Аналитик', value: 'analyst' },
  { name: 'Разработчик', value: 'developer' },
  { name: 'Зритель/Бизнес-пользователь', value: 'viewer' },
];

export const roleData: Record<RoleType, string> = {
  admin: 'Админ',
  analyst: 'Аналитик',
  developer: 'Разработчик',
  viewer: 'Зритель/Бизнес-пользователь',
};
