import { FilterViewInterface } from 'modules/workspace/components/FilterArea/types';
import { FlexContainer } from 'styles/FlexContainer';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { enableFilterAction, updateEnabledFilterAction } from 'store/reducers/filters/actions';
import { disableFilterById } from 'store/reducers/filters';
import { UpdateEnableFilterDataFnType, useEnabledFilter, useFilterQuery } from 'modules/filters/hooks/filter';
import { DateEnabledFilterInterface, DateFilterInterface, DateFilterSelectedValuesInterface } from 'store/reducers/filters/types';
import { DatePicker } from 'modules/ui/inputs/DatePicker';
import { defaultDateFormat } from 'constants/dates';
import { getDateByFormat, getStringDateByFormat } from 'utils/dates';
import { OnChangeType, PeriodDateType } from 'modules/ui/inputs/DatePicker/types';
import { getFilterFieldName } from 'store/reducers/filters/constants';
import { DATE_FILTER } from 'modules/filters/Date/view/Filter/constants';

export const DateFilterComponent: FilterViewInterface<DateFilterInterface> = ({ data, whereQuery }) => {
  const { type, isGlobal, position, isRealData, id, nameSettings, modelId, filterInfluences, sqlData } = data;

  const [activeValue, setActiveValue] = useState<DateFilterSelectedValuesInterface['selectedValues'] | null>(null);
  const [oldestAndNewestDates, setOldestAndNewestDates] = useState<string[]>([]);

  const { dispatch, modelIdValue, filterValues } = useFilterQuery({
    nameSettings,
    id,
    modelId,
    whereQuery,
    isDataFilter: true,
    sqlData,
  });

  const updateEnableFilterDataFn: UpdateEnableFilterDataFnType<DateEnabledFilterInterface> = (enableFilterData) => {
    if (enabledFilter?.id && enableFilterData.selectedValues) {
      dispatch(
        updateEnabledFilterAction<DateEnabledFilterInterface>({
          id: enabledFilter?.id,
          data: { ...enableFilterData },
        }),
      );
    }
  };

  const { enabledFilter, enableFilterData } = useEnabledFilter({
    id,
    type,
    modelIdValue,
    selectedValues: activeValue,
    isRealData,
    isGlobal,
    nameSettings,
    filterInfluences,
    updateEnableFilterDataFn,
  });

  /* Restore active filter state */
  useEffect(() => {
    setActiveValue(enabledFilter ? enabledFilter.selectedValues : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledFilter?.selectedValues]);

  /* Enable Filter and Disable by click to Save button   */
  const onEnableFilter: OnChangeType<true | false | undefined> = useCallback(
    ({ dates, byType }, type) => {
      let startDate: null | string = null,
        endDate: null | string = null;

      if (Array.isArray(dates)) {
        const [start, end] = dates;
        startDate = getStringDateByFormat(start, defaultDateFormat);
        endDate = getStringDateByFormat(end, defaultDateFormat);
      }

      if ((byType !== 'byToday' && !startDate && !endDate) || type === 'clear') {
        dispatch(disableFilterById(enabledFilter?.id));
        return;
      }

      const newValue = { startDate, endDate, byType };

      if (byType === 'byToday' || (startDate && endDate)) {
        setActiveValue(newValue);
      }

      if (!enabledFilter) {
        dispatch(enableFilterAction({ ...enableFilterData, isRealData, selectedValues: newValue }));
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setActiveValue, isRealData, enabledFilter, enableFilterData, activeValue],
  );

  const value = useMemo<Date | PeriodDateType>(
    () =>
      activeValue?.byType === 'byToday'
        ? new Date()
        : [
            getDateByFormat(activeValue?.startDate || null, defaultDateFormat),
            getDateByFormat(activeValue?.endDate || null, defaultDateFormat),
          ],
    [activeValue],
  );

  useEffect(() => {
    if (isRealData && filterValues && filterValues.length > 0) {
      const minDate = filterValues[0].value,
        maxDate = filterValues[1].value;

      if (oldestAndNewestDates[0] !== minDate || oldestAndNewestDates[1] !== maxDate) {
        setOldestAndNewestDates([minDate, maxDate]);
      }
    }
  }, [filterValues, isRealData, oldestAndNewestDates]);

  return (
    <FlexContainer
      padding="8px"
      width="100%"
      flexWrap="wrap"
      justifyContent={position}
      position="relative"
      className={DATE_FILTER}
    >
      <DatePicker
        oldestAndNewestDates={oldestAndNewestDates}
        name={getFilterFieldName(nameSettings)}
        byType={activeValue?.byType}
        value={value}
        onChange={onEnableFilter}
        id={id}
        isRealData={isRealData}
      />
    </FlexContainer>
  );
};

export const DateFilter = memo(DateFilterComponent) as FilterViewInterface;
