import { DefaultVisualisationOptionsType } from 'store/reducers/visualisations/types';
import { EmptyComponent, VisualisationRender } from 'modules/workspace/components/VisualisationArea/constants';
import { useAppDispatch } from 'store';
import { VisualisationLayout } from 'modules/visualisations/components/VisualisationLayout';
import { BoardPositionConfigInterface } from 'types/store';
import React, { memo } from 'react';
import { useContainerColors } from 'modules/visualisations/hooks/containerColors';
import { AreaInterface } from 'modules/workspace/components/WorkAreaSpace/types';
import { useVisualisationNormalizedValues } from 'modules/visualisations/hooks/visualisationNormalizedValues';
import { BoardContainer } from 'modules/workspace/components/BoardContainer';
import { updatePositionConfigByIdAction } from 'store/reducers/visualisations/actions';
import { FilterInfluencePopup } from '../FilterInfluencePopup';
import { getActiveVisualisationSettings } from 'store/reducers/visualisations/getters';
import { useSelector } from 'react-redux';
import { getActiveFilter } from 'store/reducers/filters/getters';

export const Visualisation: AreaInterface<DefaultVisualisationOptionsType> = ({
  data,
  sqlRequest,
  isShowMode,
  scale,
  isActive,
  isLoading,
  isError,
  isInfluenceEditing,
  onInfluenceChange,
  isActiveInViewMode,
}) => {
  const dispatch = useAppDispatch();

  const {
    id,
    positionConfig,
    visualisationType,
    viewSettings: { shadowSettings, borderSettings, disableDragging, showBackground },
  } = data;

  const visualizationData = useSelector(getActiveVisualisationSettings);
  const filterData = useSelector(getActiveFilter);

  const visualizationInfluences = visualizationData?.events?.filterSettings?.filterInfluences;
  const filterVisualizationInfluences = filterData?.filterInfluences;
  const filterInfluences = visualizationInfluences || filterVisualizationInfluences || {};

  const Visualisation = VisualisationRender[visualisationType]?.Visualisation || EmptyComponent;

  const SettingComponent = VisualisationRender[visualisationType]?.MainSettings;

  const onPositionConfigChange = (positionConfig: Partial<BoardPositionConfigInterface>) => {
    dispatch(
      updatePositionConfigByIdAction({
        id,
        positionConfig,
      }),
    );
  };

  const handleFilterInfluence = (payload: boolean) => () => {
    const events = visualizationData?.events;
    const filterSettings = events?.filterSettings;

    const data = {
      visualizationInfluences: {
        ...events,
        filterSettings: { ...filterSettings, filterInfluences: { ...filterInfluences, [id]: payload } },
      },
      filterInfluences: { ...filterInfluences, [id]: payload },
      isVisualization: !!visualizationData?.visualisationType,
    };

    onInfluenceChange(data);
  };

  const { visualisationNormalizedValues } = useVisualisationNormalizedValues({
    id,
    isReactingToFilter: data.events.isReactingToFilter,
    dataSettings: data.dataSettings,
  });

  const containerColors = useContainerColors({ visualisationNormalizedValues, shadowSettings, borderSettings, showBackground });

  return (
    <>
      <BoardContainer
        isLoading={isLoading}
        isShowMode={isShowMode}
        isActive={isActive}
        isActiveInViewMode={isActiveInViewMode}
        isInfluenceEditing={isInfluenceEditing}
        scale={scale}
        id={id}
        SettingComponent={SettingComponent}
        config={positionConfig}
        onChange={onPositionConfigChange}
        disableDragging={disableDragging}
        shadowSettings={shadowSettings}
        borderSettings={borderSettings}
        isError={isError}
        {...containerColors}
      >
        <FilterInfluencePopup
          isActive={isActive}
          id={id}
          isInfluenceEditing={isInfluenceEditing}
          handleFilterInfluence={handleFilterInfluence}
          filterInfluences={filterInfluences}
        />
        <VisualisationLayout data={data}>
          <Visualisation data={data} sqlRequest={sqlRequest} isLoading={isLoading} />
        </VisualisationLayout>
      </BoardContainer>
    </>
  );
};

export const VisualisationArea = memo(Visualisation);
