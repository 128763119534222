import {
  HorizontalPositionSettings,
  HorizontalPositionSettingsProps,
} from 'modules/settingsContainer/common/HorizontalPositionSettings';

type IncisionsSelectorPositionSettingsProps = Omit<HorizontalPositionSettingsProps, 'titleText'>;

export const IncisionsSelectorPositionSettings = (props: IncisionsSelectorPositionSettingsProps) => (
  <HorizontalPositionSettings {...props} titleText="Позиционирование названий разрезов" />
);
