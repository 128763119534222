import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import { LoadMetaModelsPayloads, LoadTablePreviewPayloads, TableFieldResponse } from 'store/reducers/models/types';

export const uploadModels = ({ projectId, payload }: { projectId: string; payload: FastBoard.API.ProjectModelsUpdateDTO }) =>
  axiosClient.post<string, AxiosResponse<string>, FastBoard.API.ProjectModelsUpdateDTO>(
    `/api/v2/project/${projectId}/models`,
    payload,
  );

export const uploadMetaModels = ({ projectId, payload }: { projectId: string; payload: FastBoard.API.ProjectMetaModelsDTO }) =>
  axiosClient.post<string, AxiosResponse<string>, LoadMetaModelsPayloads>(`/api/v2/project/${projectId}/meta-models`, payload);

export const loadModels = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectModelsResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/models`);

export const loadMetaModels = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectMetaModelsResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/meta-models`);

export const loadModelsFromData = (projectId: string): Promise<AxiosResponse<FastBoard.API.ModelsFromResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/model/from`);

/* TODO: Adding type for payload and response */
export const loadTableFields = (projectId: string): Promise<AxiosResponse<TableFieldResponse>> =>
  axiosClient.post(`/api/v1/models/get_fields`, { project: projectId });

export const loadTablePreview = ({
  projectId,
  table,
}: LoadTablePreviewPayloads): Promise<AxiosResponse<FastBoard.API.ClickHouseResponse>> =>
  axiosClient.get(`/api/v1/projects/${projectId}/table/${table}`);
