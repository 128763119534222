import { AxiosError } from 'axios/index';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addNewPaletteLink,
  addNewTheme,
  removePaletteLinkByThemeId,
  removeThemeById,
  setActiveThemeId,
  setSlice,
  updatePaletteLinkByThemeId,
  updateThemeById,
} from 'store/reducers/themes';
import { AddNewThemePayload, PaletteLinkInterface, ThemeItemInterface, ThemesActionsTypes } from 'store/reducers/themes/types';
import { initialThemesStoreState } from 'store/reducers/themes/constants';
import { getActivePaletteId, getActiveThemeId, getThemeById, getThemesAsArray } from 'store/reducers/themes/getters';
import { TState } from 'store/index';
import { v4 as uuidv4 } from 'uuid';
import { loadActiveTheme, loadPaletteLinks, loadThemes } from 'store/reducers/themes/api';
import { SettingsSnapshotType } from 'store/reducers/projectSettings/settingsSnapshotService';

/* TODO: Adding types and interface for Error Messages  */

const validateError = (err: AxiosError, rejectWithValue?: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue?.(errorMessage);
};

export const loadThemesAction = createAsyncThunk<ThemeItemInterface[], string>(
  ThemesActionsTypes.LOAD_THEMES,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await loadThemes(projectId);
      return response.data.projectThemes as ThemeItemInterface[];
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue([]);
    }
  },
);

export const loadThemesFromSnapshotAction = createAsyncThunk<ThemeItemInterface[], SettingsSnapshotType['themes']>(
  ThemesActionsTypes.LOAD_THEMES_FROM_SNAPSHOT,
  (themes) => themes,
);

export const loadActiveThemeAction = createAsyncThunk<string | null, string>(
  ThemesActionsTypes.LOAD_ACTIVE_THEME,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await loadActiveTheme(projectId);
      return response.data.activeThemeId;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const loadActiveThemeFromSnapshotAction = createAsyncThunk<string | null, SettingsSnapshotType['activeThemeId']>(
  ThemesActionsTypes.LOAD_ACTIVE_THEME_FROM_SNAPSHOT,
  (activeThemeId) => activeThemeId,
);

export const loadPaletteLinksAction = createAsyncThunk<PaletteLinkInterface[], string>(
  ThemesActionsTypes.LOAD_PALETTE_LINKS,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await loadPaletteLinks(projectId);
      return response.data.projectPaletteLinks;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue([]);
    }
  },
);

export const loadPaletteLinksFromSnapshotAction = createAsyncThunk<PaletteLinkInterface[], SettingsSnapshotType['paletteLinks']>(
  ThemesActionsTypes.LOAD_PALETTE_LINKS_FROM_SNAPSHOT,
  (paletteLinks) => paletteLinks,
);

export const addNewThemeAction = createAsyncThunk(
  ThemesActionsTypes.ADD_THEME,
  (themeData: AddNewThemePayload, { dispatch, getState }) => {
    const activePaletteId = getActivePaletteId(getState() as TState);

    if (activePaletteId) {
      dispatch(addNewTheme(themeData));
      dispatch(addPaletteLinkAction({ themeId: themeData.id, paletteId: activePaletteId }));
    }
  },
);

export const addNewThemeByIdAction = createAsyncThunk(
  ThemesActionsTypes.ADD_THEME_BY_ID,
  (id: string, { dispatch, getState }) => {
    const theme = getThemeById(id)(getState() as TState);

    if (theme) {
      const { name } = theme;

      dispatch(addNewThemeAction({ ...theme, name: `${name} copy`, id: uuidv4() }));
    }
  },
);

export const removeThemeByIdAction = createAsyncThunk(ThemesActionsTypes.REMOVE_THEME, (id: string, { dispatch, getState }) => {
  const state = getState() as TState,
    activeThemeId = getActiveThemeId(state);

  dispatch(removeThemeById({ id }));
  dispatch(removePaletteLinkByIdAction(id));

  if (activeThemeId === id) {
    const newThemeId = getThemesAsArray(state).filter((theme) => theme.id !== id)?.[0]?.id;

    newThemeId && dispatch(setActiveThemeId(newThemeId));
  }
});

export const updateThemeAction = createAsyncThunk(
  ThemesActionsTypes.UPDATE_THEME,
  ({ id, ...themeData }: ThemeItemInterface, { dispatch }) => {
    dispatch(updateThemeById({ id, ...themeData }));
  },
);

export const addPaletteLinkAction = createAsyncThunk(
  ThemesActionsTypes.ADD_PALETTE_LINKS,
  (paletteLink: PaletteLinkInterface, { dispatch }) => {
    dispatch(addNewPaletteLink(paletteLink));
  },
);

export const removePaletteLinkByIdAction = createAsyncThunk(
  ThemesActionsTypes.REMOVE_PALETTE_LINKS,
  (themeId: string, { dispatch }) => {
    dispatch(removePaletteLinkByThemeId(themeId));
  },
);

export const updatePaletteLinkAction = createAsyncThunk(
  ThemesActionsTypes.UPDATE_PALETTE_LINKS,
  ({ themeId, paletteId }: PaletteLinkInterface, { dispatch }) => {
    dispatch(updatePaletteLinkByThemeId({ themeId, paletteId }));
  },
);

export const setActiveThemeAction = createAsyncThunk(ThemesActionsTypes.SET_ACTIVE_THEME, (id: string | null, { dispatch }) => {
  dispatch(setActiveThemeId(id));
});

export const clearThemesStore = createAsyncThunk(ThemesActionsTypes.CLEAR_THEMES_STORE, (_, { dispatch }) => {
  dispatch(setSlice(initialThemesStoreState));
});
