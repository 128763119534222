import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import { ColorByItem, TableVisualisationType, VisualisationValuesInterface } from 'store/reducers/visualisations/types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useVisualisation } from 'modules/visualisations/hooks/visualisation';
import {
  getCalculatedTotalValues,
  getTableData,
  getTotalSqlRequest,
  lastRowClass,
  loaderSize,
  onSortClick,
  transformLoadedValuesToTotalValues,
} from 'modules/visualisations/Table/visualisation/constants';
import {
  Column,
  ColumnWidthInterface,
  DataTableInterface,
  MakeHyperLinksRecordType,
  MinMaxFuncInterface,
  TableDataInterface,
  TotalValuesType,
} from 'modules/visualisations/Table/visualisation/types';
import { colorAlias, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import {
  ButtonWrapper,
  Observe,
  RowLoader,
  StyledTableWrapper,
  TableBody,
  TableCell,
  tableCellItem,
  TableHead,
  TableHeader,
  TableRow,
  TableTextBody,
  TanStackTableStyled,
} from './styles';
import { PrimaryTextSpan, TextLink } from 'styles/TextsElements';
import { loadVisualisationValuesAction } from 'store/reducers/visualisations/actions';
import { useSelector } from 'react-redux';
import { getAstForSqlGenerationQueryById, getSqlRequestForGroupingRowTable } from 'store/reducers/visualisations/getters';
import { PropertiesRecordType, useProperties } from 'modules/visualisations/hooks/useProperties';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { TotalComponent } from './TotalComponent';
import CircularProgress from '@mui/material/CircularProgress';
import { useInView } from 'react-intersection-observer';
import { generateUnionWhereIn } from 'utils/SQL/genereteAst';
import { addProtocolToLink, getColorByValueMinMax, getRandomInt, isURL } from 'utils/utils';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { chainFormatter } from 'utils/formatting';

export const TableVisualisationComponent: VisualisationOriginInterface<TableVisualisationType> = ({ data, sqlRequest }) => {
  const { id, dataSettings, viewSettings, sqlData, events } = data,
    { colorByValueSettings, backgroundByValueSettings, isRealData, limit } = dataSettings,
    {
      shadowColorSettings: { shadowColorBy },
    } = viewSettings.shadowSettings;

  const getSqlRequestForGroupingRow = useSelector(getSqlRequestForGroupingRowTable);
  const {
    from,
    whereAstData,
    filtersAndGroups: { where },
  } = useSelector(getAstForSqlGenerationQueryById(id));
  const { getColorValues } = useColorValues();

  const [offsetSqlRequest, setOffsetSqlRequest] = useState(25);
  const [minMaxIndicators, setMinMaxIndicators] = useState<Record<string, [number, number]>>({});
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [columnVisibility, setColumnVisibility] = useState<string[]>([]);
  const [loadedTotalValues, setLoadedTotalValues] = useState<TotalValuesType>({});
  const tableRef = useRef<HTMLDivElement | null>(null);
  const { ref: observeDownRef, inView: inViewDownObserve } = useInView({
    threshold: 0,
  });
  const [tableData, setTableData] = useState<TableDataInterface>({
    columns: [],
    data: [],
  });

  const grouping = useMemo(() => {
    if (dataSettings.hasAllGroupIncision) {
      return dataSettings.incisions.map((incision) =>
        incision.settings.nameFromDatabase ? incision.fieldName || '' : incision.name,
      );
    } else {
      return dataSettings.incisions
        .filter((incision) => incision.settings.isGroup)
        .map((incision) => (incision.settings.nameFromDatabase ? incision.fieldName || '' : incision.name));
    }
  }, [dataSettings.incisions, dataSettings.hasAllGroupIncision]);
  const sqlRequestTable =
    grouping.length > 0
      ? getSqlRequestForGroupingRow({
          id,
          columnNextIndex: 1,
        })
      : sqlRequest;
  const cellPaddings = `${viewSettings.cellPaddingSettings.vertical}px ${viewSettings.cellPaddingSettings.horizontal}px`;
  const countIncisions = useMemo(
    () => columnVisibility.length || tableData.columns.length - dataSettings.indicators.length,
    [tableData.columns.length, dataSettings.indicators.length, columnVisibility.length],
  );

  const minMaxFunc = ({ columns, payloadRequest, parentsChain = 'BigDad' }: MinMaxFuncInterface) => {
    columns.forEach(({ isIncision, Header }) => {
      if (!isIncision) {
        const array = payloadRequest[Header] as number[],
          min = Math.min.apply(null, array),
          max = Math.max.apply(null, array);

        setMinMaxIndicators((prev) => {
          const keyName = Header + parentsChain;
          if (prev[keyName]) {
            prev[keyName][0] = prev[keyName][0] < min ? prev[keyName][0] : min;
            prev[keyName][1] = prev[keyName][1] > max ? prev[keyName][1] : max;
            return prev;
          } else {
            return {
              ...prev,
              [keyName]: [min, max],
            };
          }
        });
      }
    });
  };

  const {
    getColorByValue,
    visualisationNormalizedValues,
    dispatch,
    projectId,
    formattingParams: { formatting },
    modelId,
  } = useVisualisation({
    sqlData,
    id,
    colorsBy: [colorByValueSettings, backgroundByValueSettings, shadowColorBy],
    dataSettings,
    limit: { isActive: true, value: 25 },
    events,
    sqlRequest: sqlRequestTable,
  });

  const columnWidth = useMemo<ColumnWidthInterface>(() => {
    const columns = [...dataSettings.incisions, ...dataSettings.indicators];

    return columns.reduce(
      (
        data,
        {
          fieldName,
          name,
          settings: {
            columnWidthSettings: { isActive, width },
            nameFromDatabase,
          },
        },
      ) => {
        const columnName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

        return { ...data, [columnName]: isActive ? width : undefined };
      },
      {},
    );
  }, [dataSettings.indicators, dataSettings.incisions]);

  const propertiesData = useMemo(() => {
    const properties = [...dataSettings.incisions, ...dataSettings.indicators];

    return properties.reduce<PropertiesRecordType>((data, { name, fieldName, settings: { properties, nameFromDatabase } }) => {
      const propertiesName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
      data[propertiesName] = properties;
      return { ...data, [propertiesName]: properties };
    }, {});
  }, [dataSettings.indicators, dataSettings.incisions]);

  const properties = useProperties(propertiesData);

  const makeHyperLinks = useMemo<MakeHyperLinksRecordType>(() => {
    return dataSettings.indicators.reduce((data, { fieldName, name, settings: { makeHyperLinks, nameFromDatabase } }) => {
      const indicatorFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

      return {
        ...data,
        [indicatorFieldName]: makeHyperLinks || undefined,
      };
    }, {});
  }, [dataSettings.indicators]);

  useEffect(() => {
    let lengthOpenRows = 0;

    someName: for (let x = dataSettings.incisions.length; x > 0; x--) {
      for (let i = 0; i < tableData.data.length; i++) {
        if (tableData.data[i][x - 1]?.value !== null) {
          lengthOpenRows = x;
          break someName;
        }
      }
    }

    setColumnVisibility(
      dataSettings.incisions
        .slice(0, lengthOpenRows)
        .map((incision) => (incision.settings.nameFromDatabase ? incision.fieldName || '' : incision.name)),
    );
  }, [tableData.data, tableData.data.length, dataSettings.incisions]);

  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
    setOffsetSqlRequest(25);
    setHasMoreData(true);
    const { data, columns } = getTableData({
      allColumns: dataSettings,
      visualisationValues: visualisationNormalizedValues,
      columnWidth,
      properties,
      formatting,
      makeHyperLinks,
      countIncisions: dataSettings.incisions.length,
      grouping,
    });
    setMinMaxIndicators({});
    minMaxFunc({ columns, payloadRequest: visualisationNormalizedValues });
    setTableData({ data, columns });
  }, [
    visualisationNormalizedValues,
    columnWidth,
    properties,
    formatting,
    makeHyperLinks,
    grouping,
    dataSettings,
    viewSettings.subtotalsSettings.location,
  ]);

  const getColors = ({
    column,
    rowData,
    parentsChain,
  }: {
    column: Column;
    rowData: DataTableInterface;
    parentsChain: string;
  }) => {
    const { properties, Header, dataAccessor } = column,
      { backgroundColor, backgroundColorBy, fontColorBy } = properties,
      {
        valueFromBD,
        valueBackgroundColorByCondition,
        valueFontColorByCondition,
        backgroundColorBy: backgroundBy,
        fontColorBy: fontBy,
      } = rowData,
      isDefaultTypeBackground = backgroundByValueSettings.type === 'default',
      isDefaultTypeFont = colorByValueSettings.type === 'default',
      backgroundColorDefault = backgroundColor ? getColorValues(backgroundColor) : null,
      minMax = minMaxIndicators[Header + parentsChain],
      backgroundColorByValue =
        minMax &&
        backgroundColorBy.type === 'value' &&
        (valueFromBD || valueFromBD === 0) &&
        backgroundColorBy.byValue.colors?.[colorAlias]
          ? getColorByValueMinMax<ColorByItem>({
              min: minMax[0],
              max: minMax[1],
              colors: backgroundColorBy.byValue.colors[colorAlias],
              value: valueFromBD,
            })
          : null,
      fontColorByValue =
        minMax && fontColorBy?.type === 'value' && (valueFromBD || valueFromBD === 0) && fontColorBy.byValue.colors?.[colorAlias]
          ? getColorByValueMinMax<ColorByItem>({
              min: minMax[0],
              max: minMax[1],
              colors: fontColorBy.byValue.colors[colorAlias],
              value: valueFromBD,
            })
          : null,
      fontColorDefault = properties?.fontColor && getColorValues(properties?.fontColor),
      commonBackgroundColorByCondition =
        !isDefaultTypeBackground && valueBackgroundColorByCondition
          ? getColorByValue({
              value: valueBackgroundColorByCondition,
              indicatorName: dataAccessor,
              alias: backgroundByValueSettings?.byCondition.alias,
            })
          : null,
      commonFontColorByCondition =
        !isDefaultTypeFont && valueFontColorByCondition
          ? getColorByValue({
              value: valueFontColorByCondition,
              indicatorName: dataAccessor,
              alias: colorByValueSettings?.byCondition.alias,
            })
          : null,
      background = properties.changeFillColor
        ? getColorValues(backgroundBy) ||
          (backgroundColorByValue && getColorValues(backgroundColorByValue.value)) ||
          backgroundColorDefault
        : commonBackgroundColorByCondition || null,
      fontColor =
        getColorValues(fontBy) ||
        (fontColorByValue && getColorValues(fontColorByValue.value)) ||
        commonFontColorByCondition ||
        fontColorDefault;

    return { fontColor, background };
  };

  /* Generate Total Data */

  const isTotalShow = viewSettings.totalRowSettings.isShow;
  const isTotalTop = viewSettings.totalRowSettings.position === 'top';

  const totalValues = useMemo<TotalValuesType>(() => {
    if (!isTotalShow) {
      return {};
    }

    if (dataSettings.isRealData) {
      return loadedTotalValues;
    }

    return getCalculatedTotalValues({
      indicators: dataSettings.indicators,
      visualisationValues: visualisationNormalizedValues,
    });
  }, [isTotalShow, visualisationNormalizedValues, dataSettings.indicators, dataSettings.isRealData, loadedTotalValues]);

  const totalSqlRequest = useMemo(
    () =>
      dataSettings.isRealData && isTotalShow
        ? getTotalSqlRequest({
            indicators: dataSettings.indicators,
            from,
            where: generateUnionWhereIn([whereAstData, where].filter((where) => !!where)),
          })
        : undefined,
    [isTotalShow, dataSettings.isRealData, from, whereAstData, dataSettings.indicators, where],
  );

  const loadTotalValues = useCallback(
    (sqlRequest: string) => {
      const abortController = new AbortController();

      setLoadedTotalValues({});

      const request = dispatch(loadVisualisationValuesAction({ projectId, sqlRequest, modelId }));

      abortController.signal.addEventListener('abort', () => {
        request.abort();
      });

      request
        .then(({ payload }) => {
          if (payload) {
            const totalValues = transformLoadedValuesToTotalValues(payload as VisualisationValuesInterface);

            Object.keys(totalValues).forEach((accessor) => {
              const totalSettings = dataSettings.indicators.find((indicator) => indicator.name === accessor)?.settings
                .totalSettings;
              const formattingFunction = formatting && formatting[accessor];
              const value = totalValues[accessor];
              const totalValue = formattingFunction && formattingFunction(value);
              const customValue =
                !totalSettings?.isAutoAggregation && totalSettings?.formatting?.isShow
                  ? chainFormatter(totalSettings.formatting.formats, String(value))
                  : null;

              if (totalValue) {
                totalValues[accessor] = customValue || totalValue;
              }
            });

            setLoadedTotalValues(totalValues);
          }
        })
        .catch((e) => {
          /* TODO: Добавить адекватный логер */
          console.log(e);
        });

      return abortController;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, modelId, tableData],
  );

  useEffect(() => {
    if (totalSqlRequest) {
      const request = loadTotalValues(totalSqlRequest);

      return () => {
        request.abort();
      };
    } else {
      setLoadedTotalValues({});
    }
  }, [totalSqlRequest, loadTotalValues]);

  const cellAlign = (isIncision: boolean) => (isIncision ? 'flex-start' : 'flex-end');

  const addDataWithGroup = ({
    column,
    valueColumn,
    isExpanded,
    indexRow,
    indexColumn,
  }: {
    column: string;
    valueColumn: string;
    isExpanded: boolean;
    indexRow: number;
    indexColumn: number;
  }) => {
    if (isExpanded) {
      setTableData((prev) => {
        let indexColumnInGroup = indexColumn;
        const { position, isShow } = viewSettings.subtotalsSettings.location;

        if (isShow && position?.value === 'top') {
          const totalRow = prev.data.splice(indexRow + 1, 1);
          totalRow[0].forEach((cell, index) => {
            prev.data[indexRow][index].value = index >= countIncisions ? cell.value : prev.data[indexRow][index].value;
          });
        }

        for (let i = indexRow + 1; i < prev.data.length; i) {
          if (prev.data[i][indexColumnInGroup + 1]?.isExpanded) {
            prev.data[i][indexColumnInGroup + 1].isExpanded = false;
            prev.data.splice(i, 1);
            indexColumnInGroup = indexColumnInGroup + 1;
            continue;
          }

          if (prev.data[i][indexColumnInGroup + 1]?.value !== null) {
            prev.data.splice(i, 1);
          } else if (indexColumnInGroup !== indexColumn) {
            indexColumnInGroup = indexColumnInGroup - 1;
          } else {
            break;
          }
        }
        prev.data[indexRow][indexColumn].isExpanded = false;

        if (isShow && position?.value === 'bottom') {
          const totalRow = prev.data.splice(indexRow + 1, 1);
          totalRow[0].forEach((cell, index) => {
            prev.data[indexRow][index].value = index >= countIncisions ? cell.value : prev.data[indexRow][index].value;
          });
        }

        return prev;
      });

      return;
    }

    const updateData = (payload: VisualisationValuesInterface) => {
      const excludeGroups = columnNextIndex ? grouping.slice(0, columnNextIndex - 1) : grouping,
        { data, columns } = getTableData({
          allColumns: dataSettings,
          visualisationValues: payload,
          columnWidth,
          properties,
          formatting,
          makeHyperLinks,
          countIncisions: dataSettings.incisions.length,
          grouping,
          excludeGroups,
          parentsChain: parentsChainTotal,
        });

      minMaxFunc({ columns, payloadRequest: payload, parentsChain: parentsChainTotal.join('>') });

      setTableData((prev) => {
        const row = prev.data[indexRow];
        const cell = row[indexColumn];
        const { position, isShow } = viewSettings.subtotalsSettings.location;
        cell.isExpanded = true;
        cell.countChildren = data.length;

        prev.data.splice(indexRow + 1, 0, ...data);

        if (isShow) {
          const totalRow: DataTableInterface[] = JSON.parse(JSON.stringify(row));
          totalRow.forEach((cell, index) => {
            cell.value = index < countIncisions && cell.value ? `Итог ${cell.value}` : cell.value;
            cell.isTotalRow = true;
          });

          row.forEach((cell, index) => {
            cell.value = index >= countIncisions ? null : cell.value;
          });

          position?.value === 'top' && prev.data.splice(indexRow + 1, 0, totalRow);
          position?.value === 'bottom' && prev.data.splice(indexRow + data.length + 1, 0, totalRow);
        }

        return {
          columns: prev.columns,
          data: prev.data,
        };
      });
    };

    const columnNext = grouping[grouping.indexOf(column) + 1];
    const columnNextIndex = columnNext ? grouping.indexOf(column) + 2 : dataSettings.incisions.length;
    const { parentsChain } = tableData.data[indexRow][indexColumn];
    const parentsChainTotal = parentsChain ? [...parentsChain, valueColumn] : [valueColumn];

    if (!isRealData) {
      dataSettings.incisions.map((incision) => {
        incision.settings.isGroup;
      });

      const fictionalDataColumnNext = dataSettings.incisions[columnNextIndex - 1]?.fictionalData;

      const incisionsValues = dataSettings.incisions
        .slice(0, columnNextIndex)
        .reduce((values, { fieldName, name, fictionalData, settings: { nameFromDatabase } }) => {
          const incisionName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

          return { ...values, [incisionName]: fictionalData.slice(0, fictionalDataColumnNext?.length) };
        }, {});

      const indicatorsValues = dataSettings.indicators.reduce((values, { fieldName, name, settings: { nameFromDatabase } }) => {
        const indicatorName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

        return {
          ...values,
          [indicatorName]: fictionalDataColumnNext.map(() => getRandomInt(0, 100)),
        };
      }, {});

      updateData({
        ...incisionsValues,
        ...indicatorsValues,
      });
    }

    const sqlRequest = getSqlRequestForGroupingRow({
      id,
      columnNextIndex,
      parentsChain: parentsChainTotal,
      limitData: limit.isActive
        ? {
            seperator: ',',
            value: [
              {
                type: 'number',
                value: limit.value,
              },
            ],
          }
        : undefined,
    });

    if (!sqlRequest) {
      return;
    }

    const request = dispatch(loadVisualisationValuesAction({ projectId, sqlRequest, modelId }));

    request
      .then(({ payload }) => {
        if (payload) {
          updateData(payload as VisualisationValuesInterface);
        }
      })
      .catch((e) => {
        /* TODO: Добавить адекватный логер */
        console.log(e);
      });
  };

  const fetchData = useCallback(() => {
    setIsLoadingData(true);

    if (!sqlRequestTable) {
      return;
    }

    const request = dispatch(
      loadVisualisationValuesAction({
        projectId,
        sqlRequest: `${sqlRequestTable} OFFSET ${offsetSqlRequest}`,
        modelId,
      }),
    );

    request
      .then(({ payload }) => {
        if (payload) {
          const payloadRequest = payload as VisualisationValuesInterface;

          if (Object.keys(payloadRequest).length === 0) {
            setHasMoreData(false);
            setIsLoadingData(false);
            return;
          }

          const { data, columns } = getTableData({
            allColumns: dataSettings,
            visualisationValues: payloadRequest,
            columnWidth,
            properties,
            formatting,
            makeHyperLinks,
            countIncisions: dataSettings.incisions.length,
            grouping,
          });

          minMaxFunc({ columns, payloadRequest });

          setTableData((prev) => {
            prev.data.push(...data);

            return {
              columns: prev.columns,
              data: prev.data,
            };
          });
          setOffsetSqlRequest(offsetSqlRequest + 25);
          setIsLoadingData(false);
        }
      })
      .catch((e) => {
        setHasMoreData(false);
        setIsLoadingData(false);
        console.log(e);
      });
    // eslint-disable-next-line
  }, [sqlRequestTable, offsetSqlRequest]);

  useEffect(() => {
    if (inViewDownObserve && hasMoreData) {
      fetchData();
    }
  }, [inViewDownObserve, fetchData, hasMoreData]);

  return (
    <StyledTableWrapper
      showBackground={viewSettings.showBackground}
      isAdaptive={viewSettings.isAdaptive}
      verticalLine={viewSettings.showVerticalLine}
      horizontalLine={viewSettings.showHorizontalLine}
      beatColumnBackground={viewSettings.incisionBeat.includes('background')}
      beatColumnLine={viewSettings.incisionBeat.includes('line')}
      beatHeaderBackground={viewSettings.headerBeat.includes('background')}
      beatHeaderLine={viewSettings.headerBeat.includes('line')}
      beatTotalBackground={viewSettings.totalBeat.includes('background')}
      beatTotalLine={viewSettings.totalBeat.includes('line')}
      beatBody={viewSettings.isRowBeat}
      paddingSizeHeight={viewSettings.cellPaddingSettings.vertical}
      paddingSizeWidth={viewSettings.cellPaddingSettings.horizontal}
      isTotalTop={isTotalTop}
      isTotalShow={isTotalShow}
      incisionsCount={countIncisions}
      ref={tableRef}
    >
      <TanStackTableStyled>
        <TableHead>
          <TableRow>
            {tableData.columns.map(({ id: columnId, Header, isIncision, width }) => {
              if (columnVisibility.length > 0 && !columnVisibility.includes(Header as string) && isIncision) {
                return null;
              }

              const sortItsColumn = dataSettings.orderBy.filter((el) => el.columnName === Header)?.[0];

              return (
                <TableHeader
                  key={columnId}
                  width={width}
                  onClick={onSortClick({
                    dataSettings,
                    columnName: Header as string,
                    sortingColumn: sortItsColumn,
                    isSorted: sortItsColumn?.columnName === Header,
                    id,
                  })}
                >
                  <TableTextBody justifyContent={isIncision ? 'flex-start' : 'flex-end'} padding={cellPaddings}>
                    <PrimaryTextSpan lineHeight="12px">{Header as string}</PrimaryTextSpan>
                    <PrimaryTextSpan lineHeight="12px">
                      {sortItsColumn?.columnName === Header && (sortItsColumn?.type === 'DESC' ? '⏷' : '⏶')}
                    </PrimaryTextSpan>
                  </TableTextBody>
                </TableHeader>
              );
            })}
          </TableRow>
          {isTotalShow && isTotalTop && (
            <TotalComponent
              columns={tableData.columns}
              cellPaddings={cellPaddings}
              columnVisibility={columnVisibility}
              totalValues={totalValues}
            />
          )}
        </TableHead>
        <TableBody>
          {tableData.data.map((rows, indexRow) => {
            const lastRowClassName = indexRow === tableData.data.length - 1 ? lastRowClass : undefined;

            return (
              <TableRow key={indexRow} className={lastRowClassName}>
                {rows.map((row, index) => {
                  const column = tableData.columns[index],
                    { properties, isHyperLink, isIncision, Header, isGroup } = column,
                    { id, value, isExpanded, accessorKey, strParentsChain, isTotalRow } = row,
                    { fontColor, background } = getColors({ column, rowData: row, parentsChain: strParentsChain });

                  if (!!columnVisibility.length && !columnVisibility.includes(Header) && isIncision) {
                    return null;
                  }

                  return (
                    <TableCell key={id} className={tableCellItem} backgroundColor={background}>
                      <TableTextBody justifyContent={cellAlign(isIncision)} padding={cellPaddings}>
                        {value === null ? (
                          ''
                        ) : (
                          <>
                            {!isTotalRow && isGroup && isIncision && index !== dataSettings.incisions.length - 1 && (
                              <ButtonWrapper
                                onClick={() => {
                                  addDataWithGroup({
                                    column: accessorKey,
                                    valueColumn: value,
                                    isExpanded,
                                    indexRow,
                                    indexColumn: index,
                                  });
                                }}
                              >
                                <PrimaryTextSpan lineHeight={'12px'}>{isExpanded ? '–' : '+'}</PrimaryTextSpan>
                              </ButtonWrapper>
                            )}
                            <PrimaryTextSpan
                              fontSize={`${properties?.fontSize}px`}
                              lineHeight={`${properties?.lineHeight}%`}
                              color={fontColor || undefined}
                              opacity={properties?.opacity}
                              letterSpacing={`${properties?.letterSpacing}px`}
                              fontWeight={properties?.fontStyle.bold ? 'bold' : 'normal'}
                              fontStyle={properties?.fontStyle.italic ? 'italic' : 'normal'}
                              textDecoration={properties?.fontStyle.underline ? 'underline' : undefined}
                              padding={isTotalRow ? '0 0 0 20px' : undefined}
                            >
                              {isHyperLink && isURL(value) ? (
                                <TextLink color={`var(${ColorVarsEnum.Accent})`} href={addProtocolToLink(value)} target="_blank">
                                  {value}
                                </TextLink>
                              ) : (
                                value
                              )}
                            </PrimaryTextSpan>
                          </>
                        )}
                      </TableTextBody>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {isRealData && <Observe ref={observeDownRef} />}
          {isLoadingData && (
            <RowLoader>
              <div>
                <CircularProgress size={loaderSize} />
              </div>
            </RowLoader>
          )}
          {isTotalShow && !isTotalTop && (
            <TotalComponent
              columns={tableData.columns}
              cellPaddings={cellPaddings}
              columnVisibility={columnVisibility}
              totalValues={totalValues}
            />
          )}
        </TableBody>
      </TanStackTableStyled>
    </StyledTableWrapper>
  );
};

export const TableVisualisation = memo(TableVisualisationComponent) as VisualisationOriginInterface<TableVisualisationType>;
