import { OnValueChange } from 'types/global';
import { ElementSettingsInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import React from 'react';
import { elementTypeOptions, lineTypeOptions } from 'modules/settingsContainer/common/data/LineAndBarElementSettings/constants';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { BooleanElementSettings } from 'modules/settingsContainer/common/BooleanElementSettings';
import { percentUnit, pxUnit } from 'constants/global';

type LineAndBarElementSettingsProps = OnValueChange<ElementSettingsInterface>;

export const LineAndBarElementSettings = ({ value, onChange }: LineAndBarElementSettingsProps) => {
  const { type, parameters } = value;

  return (
    <MainContainerSettings titleText="Тип элемента">
      <GroupContainerSettings>
        <ElementContainerSettings>
          <SettingsRadio
            onChange={({ value: type }) => onChange({ ...value, type })}
            activeValue={getActiveRadioValue(elementTypeOptions, type)}
            options={elementTypeOptions}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
      {type === 'line' && (
        <GroupContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              properties="Толщина"
              name="lineWidth"
              unit={pxUnit}
              value={parameters.lineWidth}
              onChange={(lineWidth) => onChange({ ...value, parameters: { ...parameters, lineWidth } })}
            />
          </ElementContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              properties="Точка"
              name="dotWidth"
              unit={pxUnit}
              value={parameters.dotWidth}
              onChange={(dotWidth) => onChange({ ...value, parameters: { ...parameters, dotWidth } })}
            />
          </ElementContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              properties="Непрозрачность"
              name="opacity"
              unit={percentUnit}
              value={parameters.opacity}
              onChange={(opacity) => onChange({ ...value, parameters: { ...parameters, opacity } })}
            />
          </ElementContainerSettings>
          <BooleanElementSettings
            titleText="Пунктирная"
            value={parameters.isDotted}
            onChange={(isDotted) => onChange({ ...value, parameters: { ...parameters, isDotted } })}
          />
          <SettingsRadio
            onChange={({ value: lineType }) => onChange({ ...value, parameters: { ...parameters, lineType } })}
            activeValue={getActiveRadioValue(lineTypeOptions, parameters.lineType)}
            options={lineTypeOptions}
          />
        </GroupContainerSettings>
      )}
    </MainContainerSettings>
  );
};
