import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { GroupSvg, UserSvg } from 'components/admin/sources/elements/AccessSource/styles';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCell } from 'modules/ui/Table/TableCell';
import { UserFlowModal } from 'components/admin/usersPage/elemets/Flows/Modal/UserFlowModal';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import { useAppDispatch } from 'store';
import {
  deleteByIdUserFlowAction,
  deleteUserFlowAction,
  loadNoUserFlowsAction,
  loadUserFlowsAction,
} from 'store/reducers/adminUsers/actions';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import { getActiveUser, getUserFlows } from 'store/reducers/adminUsers/getters';
import { addUserFlow, editUserFlow } from 'components/admin/usersPage/elemets/Flows/constants';
import { accessText } from 'components/admin/groups/constants';
import { useSelector } from 'react-redux';
import { FlexContainer } from 'styles/FlexContainer';
import { UserFlowsListInterface } from 'store/reducers/adminUsers/types';

export const Flows: FC = () => {
  const dispatch = useAppDispatch();

  const activeUser = useSelector(getActiveUser);
  const activeUserId = activeUser?.id;
  const { loading, userFlowsList } = useSelector(getUserFlows);

  useEffect(() => {
    if (activeUserId) {
      dispatch(loadUserFlowsAction({ userId: activeUserId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUserId]);

  const onCloseEditUserFlowModal = () => dispatch(closeModalAction(editUserFlow));

  const onEditModal = useCallback(
    (flow: UserFlowsListInterface) =>
      dispatch(
        openModalTypedAction({
          Component: UserFlowModal,
          componentProps: {
            onClose: onCloseEditUserFlowModal,
            data: flow,
            mode: 'edit',
          },
          modalSettings: {
            headerText: 'Изменение прав доступа',
            width: '320px',
          },
          name: editUserFlow,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseEditUserFlowModal],
  );

  const onDelete = useCallback(
    async (flow: UserFlowsListInterface) => {
      try {
        if (!activeUserId) {
          return;
        }

        const response = await dispatch(deleteUserFlowAction({ flowId: flow.id, userId: activeUserId })).unwrap();

        if (response) {
          dispatch(deleteByIdUserFlowAction(response));
          dispatch(closeConfirmationModalAction());
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserId],
  );

  const onDeleteModal = useCallback(
    async (flow: UserFlowsListInterface) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Да',
          description: `Действительно удалить доступ к потоку «${flow.name}» ?`,
          onConfirm: () => onDelete(flow),
          titleText: 'Удаление доступа',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const onCloseAddUserFlowModal = () => dispatch(closeModalAction(addUserFlow));

  const onAddModal = useCallback(
    async () => {
      try {
        if (!activeUserId) {
          return;
        }

        const noUserFlows = await dispatch(loadNoUserFlowsAction({ userId: activeUserId })).unwrap();
        const selectData = noUserFlows.map((flow) => ({ name: flow.name, value: flow.id }));

        dispatch(
          openModalTypedAction({
            Component: UserFlowModal,
            componentProps: {
              onClose: onCloseAddUserFlowModal,
              selectData,
              mode: 'add',
            },
            modalSettings: {
              headerText: 'Добавление доступа',
            },
            name: addUserFlow,
          }),
        );
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserId, onCloseAddUserFlowModal],
  );

  const columnHelper = createColumnHelper<UserFlowsListInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Название потока',
        cell: (info) => (
          <TableCell
            text={info.getValue()}
            actions={{
              ...(!info.row.original.groupName && {
                onRemove: async () => {
                  await onDeleteModal(info.row.original);
                },
              }),
            }}
          />
        ),
      }),
      columnHelper.accessor('flowGroupType', {
        header: 'Права',
        cell: (info) => (
          <TableCell
            text={accessText[info.getValue()]}
            actions={{
              ...(!info.row.original.groupName && {
                onEdit: async () => {
                  await onEditModal(info.row.original);
                },
              }),
            }}
          />
        ),
      }),
      columnHelper.accessor('groupName', {
        header: 'Тип доступа/группа',
        cell: (info) => <TableCell Icon={info.getValue() ? GroupSvg : UserSvg} text={info.getValue() || 'Личный'} />,
      }),
    ],
    [columnHelper, onDeleteModal, onEditModal],
  );

  return (
    <FlexContainer position="relative" width="100%" padding="24px" maxWidth="800px">
      <TableWithSearch filterColumnName="name" columns={columns} rows={userFlowsList} onAddModal={onAddModal} loading={loading} />
    </FlexContainer>
  );
};
