import { FlexContainer } from 'styles/FlexContainer';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import React from 'react';
import { RadioItem } from 'modules/settingsContainer/SettingsRadio/styles';
import { SettingsRadioItem } from 'modules/settingsContainer/SettingsRadio/types';

export interface SettingsRadioProps<ValueType extends string | number> {
  activeValue: SettingsRadioItem<ValueType>;
  options: SettingsRadioItem<ValueType>[];
  onChange: (value: SettingsRadioItem<ValueType>) => void;
}

export const SettingsRadio = <ValueType extends string | number>({
  activeValue,
  options,
  onChange,
}: SettingsRadioProps<ValueType>) => {
  return (
    <ElementContainerSettings>
      <FlexContainer gap="16px">
        {options.map((option) => {
          const { value, label, disabled } = option;

          return (
            <>
              <RadioItem
                key={value}
                disabled={disabled}
                selected={value === activeValue.value}
                onClick={() => !disabled && onChange(option)}
              >
                {label}
              </RadioItem>
            </>
          );
        })}
      </FlexContainer>
    </ElementContainerSettings>
  );
};
