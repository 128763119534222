import { ElementContainerSettings, GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { getDefaultModelId, getProjectSettings } from 'store/reducers/projectSettings/getters';
import { UpdateProjectSettingsPayload } from 'store/reducers/projectSettings/types';
import { updateDefaultModelIdAction, updateProjectSettingsAction } from 'store/reducers/projectSettings/actions';
import React from 'react';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { pxUnit } from 'constants/global';

/* TODO: We comment on real data before implementing the functionality. */
export const SettingsTab = () => {
  const dispatch = useAppDispatch();

  const { isViewMode, gridSpacing, isShowWorkSpace, scaleOfPage } = useSelector(getProjectSettings),
    // { isShow, isForce } = realData,
    defaultModelId = useSelector(getDefaultModelId);

  const onSettingsChange = (projectSettings: UpdateProjectSettingsPayload) =>
    dispatch(updateProjectSettingsAction(projectSettings));

  const onDefaultModelIdChange = (defaultModelId: string | null) => dispatch(updateDefaultModelIdAction(defaultModelId));

  return (
    <>
      <MainContainerSettings
        titleText="Режим просмотра"
        switcherState={isViewMode}
        switcherChange={() => onSettingsChange({ isViewMode: !isViewMode })}
      />
      <ModelSelectorSettings value={defaultModelId} onChange={onDefaultModelIdChange} title="Модель данных по умолчанию" />
      {/*<MainContainerSettings titleText="Реальные данные">*/}
      {/*  <GroupContainerSettings*/}
      {/*    titleText="Принудительно"*/}
      {/*    switcherState={isForce}*/}
      {/*    switcherChange={() => onSettingsChange({ realData: { ...realData, isForce: !isForce } })}*/}
      {/*  />*/}
      {/*  <GroupContainerSettings*/}
      {/*    titleText="Показать данные"*/}
      {/*    switcherState={isShow}*/}
      {/*    switcherChange={() => onSettingsChange({ realData: { ...realData, isShow: !isShow } })}*/}
      {/*  >*/}
      {/*    <FlexContainer margin="5px 0 0 0" width="100%">*/}
      {/*      <PrimaryTextSpan fontSize="10px" lineHeight="13px">*/}
      {/*        Показывать, либо не показывать данные независимо от локальных настроек виджета*/}
      {/*      </PrimaryTextSpan>*/}
      {/*    </FlexContainer>*/}
      {/*  </GroupContainerSettings>*/}
      {/*</MainContainerSettings>*/}
      <MainContainerSettings
        titleText="Показать границы страницы"
        switcherState={isShowWorkSpace}
        switcherChange={() => onSettingsChange({ isShowWorkSpace: !isShowWorkSpace })}
      />
      <MainContainerSettings titleText="Шаг сетки">
        <GroupContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              name="gridSpacing"
              value={gridSpacing}
              unit={pxUnit}
              onChange={(space) => onSettingsChange({ gridSpacing: Math.abs(space) !== 0 ? Math.abs(space) : 1 })}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </MainContainerSettings>
      <MainContainerSettings
        titleText="Масштабирование страницы"
        switcherState={scaleOfPage.isActive}
        switcherChange={() => onSettingsChange({ scaleOfPage: { ...scaleOfPage, isActive: !scaleOfPage.isActive } })}
      >
        <GroupContainerSettings>
          <ElementContainerSettings>
            <NumberPropertiesBlock
              properties="С ширины экрана в"
              name="width"
              value={scaleOfPage.width}
              unit={pxUnit}
              onChange={(width) => onSettingsChange({ scaleOfPage: { ...scaleOfPage, width } })}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </MainContainerSettings>
    </>
  );
};
