import { OnValueChange } from 'types/global';
import { BeatType } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { SettingsCheckbox } from 'modules/settingsContainer/SettingsCheckbox';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsCheckbox/constants';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { beatByOptions } from 'modules/settingsContainer/common/BeatBySettings/constants';

interface BeatBySettingsProps extends OnValueChange<BeatType[]> {
  titleText: string;
}

export const BeatBySettings = ({ titleText, onChange, value }: BeatBySettingsProps) => {
  return (
    <MainContainerSettings titleText={titleText}>
      <GroupContainerSettings>
        <SettingsCheckbox
          onChange={(value) => onChange(value.map(({ value }) => value))}
          options={beatByOptions}
          activeValue={getActiveRadioValue(beatByOptions, value)}
        />
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
