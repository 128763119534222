import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { cssVar, lighten } from 'polished';
import ColorTheme from 'constants/ColorTheme';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  cursor: pointer;

  path,
  circle {
    stroke: ${`var(${ColorVarsEnum.Level_1})`};
    transition: all 450ms;
  }

  &:hover {
    background: ${`var(${ColorVarsEnum.Level_2_btn})`};
    rect {
      fill: ${lighten(0.1, cssVar(ColorVarsEnum.Level_2_btn, ColorTheme.default.l_2_btn) as string)};
    }
  }
`;
