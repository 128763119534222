import { IdInterface, NameInterface, PositionConfigInterface, ProjectIdInterface } from 'types/store';
import { Model } from 'models/model/Model';
import { MapRecordType } from 'types/global';

export interface Table {
  table: string;
}

export interface DB {
  db: string | null;
}

export interface Alias {
  alias: string;
}

/* For Column`s meta sata like type (data-time, string, number) etc */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MetaColumn {}

export type Columns = Record<string, MetaColumn>;

export interface Column extends Table, DB {
  column: string;
}

export interface Custom {
  value: string;
}

export type JoinTypeType = 'INNER' | 'FULL' | 'RIGHT' | 'LEFT';

export type JoinType = {
  mode: 'JOIN';
  type: JoinTypeType;
};

export type EqualRelationType = '=';
export type ConditionRelationType = '>' | '≥' | '<' | '≤' | '≠';
export type OperatorRelationType = EqualRelationType | ConditionRelationType;
export type ANDType = 'AND';
export type ORType = 'OR';
export type OperatorType = OperatorRelationType | ANDType | ORType;

export type ConditionValueType = Column | Custom;

export interface LinkRelationType {
  left: Column;
  right: Column;
}

export interface Condition {
  left: ConditionValueType | Condition;
  operator: OperatorType;
  right: ConditionValueType | Condition;
}

export interface RelationInterface {
  type: JoinType;
  link: LinkRelationType;
  condition: Condition | null;
}

export interface IsHeadInterface {
  isHead: boolean;
}

export interface ModelItemType extends Table, DB, Alias, IsHeadInterface {
  relations: RelationInterface[] | null;
}

export interface MetaModelItemType extends Alias, Table {
  config: PositionConfigInterface;
}

export interface ModelTabInterface extends IdInterface, NameInterface {
  zoom: number;
  models: Model[];
}

export type TableType = Record<string, { type: string }>;
export type TablesType = MapRecordType<TableType>;

export type TabsType = MapRecordType<ModelTabInterface>;

export type TablePreviewType = FastBoard.API.ClickHouseResponse;
export type TablePreviewsType = MapRecordType<TablePreviewType>;

export interface ActiveModelItemAliasInterface extends Table, Alias {}

export interface MetaColumnType {
  name: string;
  type: string;
}

export interface ModelFromMetaType extends Alias, Table {
  columns: MetaColumnType[];
}

export interface ModelFromItemType extends NameInterface, IdInterface {
  from: string | null;
  meta: ModelFromMetaType[];
}

export type ModelsFromType = Record<string, ModelFromItemType | undefined>;

/* Store SLice Type */

export interface ModelsStateInterface {
  tabs: TabsType;
  tabsLoading: boolean;
  tables: TablesType;
  tablesLoading: boolean;
  tablePreviews: TablePreviewsType;
  tablePreviewLoading: Set<string>;
  activeTabId: string | null;
  activeModelItemAlias: ActiveModelItemAliasInterface | null;
  modelsFrom: ModelsFromType;
  modelsFromLoading: boolean;
}

/* Action Types */

export enum ModelsActionsTypes {
  SET_ACTIVE_TAB_ID = 'SET_ACTIVE_TAB_ID',
  SET_ACTIVE_MODEL_ITEM_ALIAS = 'SET_ACTIVE_MODEL_ITEM_ALIAS',
  ADD_TAB = 'ADD_TAB',
  ADD_TABLE_TO_ACTIVE_TAB = 'ADD_TABLE_TO_ACTIVE_TAB',
  REMOVE_TAB = 'REMOVE_TAB',
  UPDATE_TAB = 'UPDATE_TAB',
  UPDATE_ZOOM = 'UPDATE_ZOOM',
  LOAD_MODELS = 'LOAD_MODELS',
  UPLOAD_MODELS = 'UPLOAD_MODELS',
  LOAD_META_MODELS = 'LOAD_META_MODELS',
  UPLOAD_META_MODELS = 'UPLOAD_META_MODELS',
  LOAD_TABLES = 'LOAD_TABLES',
  LOAD_TABS = 'LOAD_TABS',
  UPLOAD_TABS = 'UPLOAD_TABS',
  LOAD_MODELS_FROM_DATA = 'LOAD_MODELS_FROM_DATA',
  LOAD_TABLE_PREVIEW = 'LOAD_TABLE_PREVIEW',
  LOAD_TABLE_PREVIEW_BY_NAME = 'LOAD_TABLE_PREVIEW_BY_NAME',
  CLEAR_MODEL_STORE = 'CLEAR_MODEL_STORE',
}

/* Payloads */

export interface ModelPayloadInterface extends Pick<ModelTabInterface, 'id' | 'name'> {
  modelItems: ModelItemType[];
}

export interface LoadModelsPayloads {
  models: ModelPayloadInterface[];
}

export interface MetaModelPayloadInterface extends IdInterface {
  modelItems: MetaModelItemType[];
  zoom: number;
}

export interface LoadMetaModelsPayloads {
  models: MetaModelPayloadInterface[];
}

export type TableFieldResponse = TablesType;

export type AddNewTabPayload = ModelTabInterface;

export interface UpdateTabByIdPayload extends IdInterface {
  data: Partial<ModelTabInterface>;
}

export interface LoadTablePreviewPayloads extends ProjectIdInterface, Table {}
