import { OnValueChange } from 'types/global';
import {
  DefaultDataSettingsInterface,
  LineAndBarIndicatorType,
  ShowValuePositionGeneric,
  ShowValueSettingsInterface,
} from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { OrientationGroupSettings } from 'modules/settingsContainer/common/OrientationGroupSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { positionOptions } from 'modules/settingsContainer/common/data/LineAndBarShowValueSettings/constants';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { settingsLayoutWidthSecondLevel } from 'modules/settingsContainer/SettingsLayout/constants';

interface LineAndBarShowValueSettingsProps<IndicatorType extends LineAndBarIndicatorType>
  extends OnValueChange<ShowValueSettingsInterface<IndicatorType>>,
    Partial<Pick<DefaultDataSettingsInterface, 'indicators'>> {
  type: IndicatorType;
  isRotated?: boolean;
  disabledPosition?: boolean;
}

/* TODO: Will need to merge Waterfall and LineAndBar and Heatmap and Bubble  */
export const LineAndBarShowValueSettings = <IndicatorType extends LineAndBarIndicatorType>({
  value,
  onChange,
  indicators,
  type,
  isRotated = false,
  disabledPosition = false,
}: LineAndBarShowValueSettingsProps<IndicatorType>) => {
  const { isShow, position, orientation, properties } = value;

  const positionOptionsValue = positionOptions(type, isRotated);

  return (
    <MainContainerSettings
      titleText="Показать значения"
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      {!disabledPosition && (
        <GroupContainerSettings titleText="Расположение">
          <ElementContainerSettings>
            <SettingsRadio
              onChange={({ value: position }) =>
                onChange({ ...value, position: position as ShowValuePositionGeneric<IndicatorType> })
              }
              activeValue={getActiveRadioValue(positionOptionsValue, position)}
              options={positionOptionsValue}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      )}
      <OrientationGroupSettings value={orientation} onChange={(orientation) => onChange({ ...value, orientation })} />
      <PropertiesSettings
        rightPositionModal={settingsLayoutWidthSecondLevel}
        value={properties}
        indicators={indicators}
        onChange={(properties) => onChange({ ...value, properties })}
        isMainContainerSettings
        isMainContainerColorSettings
        disabledOpacity
        disabledPadding
        disabledFontColorByBlock
        disabledLineHeight
        disabledBackgroundColorBy
        disabledUnderline
        disabledLetterSpacing
      />
    </MainContainerSettings>
  );
};
