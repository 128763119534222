import { useSelector } from 'react-redux';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { FlexContainer } from 'styles/FlexContainer';
import { format } from 'sql-formatter';
import { marked } from 'marked';
import hljs from 'highlight.js';
import lightTheme from '!!raw-loader!highlight.js/styles/base16/one-light.css';
import darkTheme from '!!raw-loader!highlight.js/styles/github-dark.css';
import styled from 'styled-components';
import { useSqlRequest } from 'modules/visualisations/hooks/sqlRequest';
import { getArrayVisualisations } from 'store/reducers/visualisations/getters';
import { getActiveBoardElement } from 'store/reducers/board/getters';
import { ACTIVE_BOARD_AREA_LABEL } from 'modules/workspace/constans';
import { getActiveThemeCodeEditorTheme } from 'store/reducers/themes/getters';
import { ThemeColorType } from 'types/store';
import { useEffect } from 'react';
import { defaultScrollbarMixin } from 'constants/styles';

export const SQLRequest = () => {
  const visualisations = useSelector(getArrayVisualisations);
  const activeBoardElement = useSelector(getActiveBoardElement);
  const { getSqlRequest } = useSqlRequest();
  const codeEditorTheme = useSelector(getActiveThemeCodeEditorTheme);

  const isVisualisationActive = visualisations.some(({ id }) => id === activeBoardElement);

  const sqlRequests = visualisations.reduce<string[]>((sqlResult, { sqlData, id }) => {
    if (!sqlData) {
      return sqlResult;
    }

    const sqlRequest = getSqlRequest(id);

    if (isVisualisationActive && activeBoardElement === id && sqlRequest) {
      return [...sqlResult, sqlRequest];
    }

    if (!isVisualisationActive && sqlRequest) {
      return [...sqlResult, sqlRequest];
    }

    return sqlResult;
  }, []);

  useEffect(() => {
    hljs.highlightAll();
  });

  return (
    <FlexContainer
      id={ACTIVE_BOARD_AREA_LABEL}
      flexDirection="column"
      width="600px"
      overflow="auto"
      backgroundColor={`var(${ColorVarsEnum.Level_3_menu})`}
    >
      <FlexContainer
        flexDirection="column"
        overflow="auto"
        height="calc(100vh - 130px)"
        backgroundColor={codeEditorTheme === 'dark' ? '#0D1117' : '#FAFAFA'}
        margin="8px"
      >
        <FlexContainer flexDirection="column">
          {sqlRequests.map((sqlRequest, index) => {
            let formattedSqlRequest;

            try {
              formattedSqlRequest = format(sqlRequest || '', {
                tabWidth: 2,
                useTabs: false,
                expressionWidth: 50,
                keywordCase: 'upper',
                tabulateAlias: false,
                denseOperators: false,
                language: 'sql',
                commaPosition: 'after',
                indentStyle: 'standard',
                logicalOperatorNewline: 'before',
              });
            } catch (e) {
              formattedSqlRequest = sqlRequest;
            }

            const markdown = '```sql\n' + formattedSqlRequest + '\n```';

            return (
              <CodeModule
                key={index}
                className="code"
                themeType={codeEditorTheme}
                dangerouslySetInnerHTML={{
                  __html: marked(markdown),
                }}
              />
            );
          })}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

const themes: Record<ThemeColorType, typeof darkTheme> = {
  dark: darkTheme,
  light: lightTheme,
};

interface CodeModuleProps {
  themeType: ThemeColorType;
}

const CodeModule = styled.div<CodeModuleProps>`
  p {
    margin: 0;
  }

  ${({ themeType }) => themes[themeType]}

  ${defaultScrollbarMixin};
`;
