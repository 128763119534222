import {
  BoldIcon,
  HorizontalCenter,
  HorizontalEnd,
  HorizontalStartIcon,
  ItalicIcon,
  UnderlineIcon,
  VerticalCenter,
  VerticalEnd,
  VerticalStart,
} from 'assets/icons/fontStyle';
import { TextPropertiesInterface } from 'store/reducers/visualisations/types';
import { PositionSettingType, SwitchAutoSettingType } from 'types/store';
import { RadioSelectedItem } from 'components/RadioSelected/types';

export const fontStyleIcons: (
  disabledUnderline?: boolean,
) => Array<{ Icon: typeof BoldIcon; type: keyof TextPropertiesInterface['fontStyle'] }> = (disabledUnderline) => {
  const underline = !disabledUnderline
    ? [
        {
          Icon: UnderlineIcon,
          type: 'underline' as keyof TextPropertiesInterface['fontStyle'],
        },
      ]
    : [];

  return [
    {
      Icon: BoldIcon,
      type: 'bold',
    },
    {
      Icon: ItalicIcon,
      type: 'italic',
    },
    ...underline,
  ];
};

export const alignmentIcons: (
  disabledUnderline?: boolean,
) => Array<{ Icon: typeof BoldIcon; type: keyof TextPropertiesInterface['fontStyle'] }> = (disabledUnderline) => {
  const underline = !disabledUnderline
    ? [
        {
          Icon: UnderlineIcon,
          type: 'underline' as keyof TextPropertiesInterface['fontStyle'],
        },
      ]
    : [];

  return [
    {
      Icon: BoldIcon,
      type: 'bold',
    },
    {
      Icon: ItalicIcon,
      type: 'italic',
    },
    ...underline,
  ];
};

export const alignmentHorizontalOptions: RadioSelectedItem<PositionSettingType>[] = [
  {
    Component: HorizontalStartIcon,
    value: 'flex-start',
  },
  {
    Component: HorizontalCenter,
    value: 'center',
  },
  {
    Component: HorizontalEnd,
    value: 'flex-end',
  },
];

export const alignmentVerticalOptions: RadioSelectedItem<PositionSettingType>[] = [
  {
    Component: VerticalStart,
    value: 'flex-start',
  },
  {
    Component: VerticalCenter,
    value: 'center',
  },
  {
    Component: VerticalEnd,
    value: 'flex-end',
  },
];

export const calculationResultsOptions: RadioSelectedItem<SwitchAutoSettingType>[] = [
  {
    label: 'Авто',
    value: 'auto',
  },
  {
    label: 'Вручную',
    value: 'manually',
  },
];
