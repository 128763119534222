import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { loaderSize } from 'modules/visualisations/Table/visualisation/constants';
import { defaultScrollbarMixin } from 'constants/styles';
import { isSafari } from 'utils/utils';

export const TanStackTableStyled = styled.table`
  width: max-content;
`;

export const tableCellItem = 'table_cell_item';

export const Observe = styled.div`
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 2px;
  bottom: 150px;
`;

export const RowLoader = styled.div`
  display: table-row;
  position: relative;
  height: 60px;

  div {
    position: absolute;
    left: calc(50% - ${loaderSize});
    top: 12px;
  }
`;

const backgroundColorMixin = css`
  content: '';
  position: absolute;
  background-color: ${`var(${ColorVarsEnum.Level_1})`};
  opacity: 0.05;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
`;

const stickyMixin = (isBottom?: boolean) => css`
  position: ${() => (isSafari() ? 'relative' : 'sticky')};
  z-index: 3;
  ${isBottom ? 'bottom' : 'top'}: 0;
  background-color: var(${ColorVarsEnum.Level_5_application});
`;

export const TableRow = styled.tr`
  position: relative;
`;

export const TotalRow = styled.tr`
  height: 100%;
  position: relative;
  ${stickyMixin(true)};

  :after {
    ${backgroundColorMixin};
    opacity: 0.8;
    background: ${`var(${ColorVarsEnum.Level_5_application})`};
  }
`;

interface TableHeaderProps {
  width?: number | string;
  justifyContent?: string;
  padding?: string;
}

export const TableHeader = styled.th<TableHeaderProps>`
  position: relative;
  font-weight: bold;
  text-align: center;
  gap: 4px;
  align-items: center;

  ${({ width }) => width && `width: ${width}px;`};
`;

interface TableTextBodyProps {
  justifyContent?: string;
  padding?: string;
}

export const TableTextBody = styled.div<TableTextBodyProps>`
  display: flex;
  gap: 4px;
  align-items: center;

  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`};
  ${({ padding }) => padding && `padding: ${padding};`};
`;

interface TableCellProps {
  backgroundColor?: string | null;
}

export const TableCell = styled.td<TableCellProps>`
  position: relative;
  z-index: 2;
  height: inherit;
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};
`;

export const TableHead = styled.thead`
  ${stickyMixin()};
  z-index: 5;

  ${TableRow} {
    :after {
      ${backgroundColorMixin};
      top: 0;
      opacity: 0.8;
      background: ${`var(${ColorVarsEnum.Level_5_application})`};
    }
  }
`;

export const TableBody = styled.tbody`
  position: relative;

  ${TableRow} {
    :hover {
      .${tableCellItem} {
        cursor: pointer;
        z-index: 1;

        :after {
          ${backgroundColorMixin};

          opacity: 0.1 !important;
        }
      }
    }
  }
`;

interface StyledTableWrapperProps {
  showBackground: boolean;
  isAdaptive: boolean;
  verticalLine: boolean;
  horizontalLine: boolean;
  beatColumnBackground: boolean;
  beatColumnLine: boolean;
  beatHeaderBackground: boolean;
  beatHeaderLine: boolean;
  beatTotalBackground: boolean;
  beatTotalLine: boolean;
  beatBody: boolean;
  paddingSizeHeight: number;
  paddingSizeWidth: number;
  isTotalTop: boolean;
  isTotalShow: boolean;
  incisionsCount: number;
}

export const StyledTableWrapper = styled.div<StyledTableWrapperProps>`
  overflow: auto;
  width: 100%;
  height: 100%;

  ${defaultScrollbarMixin};

  ${({ showBackground }) =>
    showBackground &&
    css`
      ${TableHead}, ${TotalRow} {
        background-color: var(${ColorVarsEnum.Level_4_menu});
      }
    `}
  ${({ isAdaptive }) =>
    isAdaptive &&
    css`
      ${TanStackTableStyled} {
        width: 100%;
      }
    `}
  ${({ isTotalShow, isTotalTop, beatHeaderLine }) =>
    !(isTotalShow && isTotalTop && beatHeaderLine) &&
    css`
      ${TableHead} {
        ${TableRow} {
          :after {
            top: 0;
          }
        }
      }
    `}
  ${TanStackTableStyled} {
    border-spacing: 0;
    border-collapse: separate;
    color: var(${ColorVarsEnum.Level_1});
    padding: 0;

    ${TotalRow} {
      :last-child {
        ${TableCell} {
          border-bottom: 0;
        }
      }
    }

    ${TableHeader}, ${TableCell} {
      margin: 0;

      ${({ horizontalLine, verticalLine }) =>
        (horizontalLine || verticalLine) &&
        css`
          z-index: 1;
          border-bottom: ${horizontalLine ? '1px' : 0} solid var(${ColorVarsEnum.Level_5});

          &:not(:first-child) {
            border-left: ${verticalLine ? '1px' : 0} solid var(${ColorVarsEnum.Level_5});
          }
        `}
    }

    ${TableHeader} {
      ${({ horizontalLine, isTotalShow, isTotalTop }) =>
        horizontalLine &&
        isTotalShow &&
        isTotalTop &&
        css`
          border-bottom: 1px solid var(${ColorVarsEnum.Level_5});
        `}
    }

    span {
      z-index: 2;
    }

    ${({ beatBody }) =>
      beatBody &&
      css`
        ${TableBody} {
          ${TableRow}:nth-child(2n) {
            .${tableCellItem} {
              :after {
                ${backgroundColorMixin};
              }
            }
          }
        }
      `}

    ${({ beatHeaderLine }) =>
      beatHeaderLine &&
      css`
        ${TableHead} {
          ${TableHeader} {
            border-bottom: 1px solid var(${ColorVarsEnum.Level_3});
          }
        }
      `}

    ${({ beatHeaderBackground }) =>
      !beatHeaderBackground &&
      css`
        ${TableHead} {
          ${TableRow} {
            :after {
              background-color: transparent;
            }
          }
        }
      `}

    ${({ beatTotalBackground }) =>
      !beatTotalBackground &&
      css`
        ${TotalRow} {
          :after {
            background-color: transparent;
          }
        }
      `}

    ${({ beatTotalLine }) =>
      beatTotalLine &&
      css`
        ${TableBody} {
          ${TotalRow} {
            ${TableCell} {
              box-shadow: 0 -1px 0 0 var(${ColorVarsEnum.Level_3});
            }
          }
        }

        ${TableHead} {
          ${TotalRow} {
            ${TableCell} {
              box-shadow: 0 1px 0 0 var(${ColorVarsEnum.Level_3});
            }
          }
        }
      `}

    ${({ beatColumnLine, horizontalLine, incisionsCount }) =>
      beatColumnLine &&
      css`
        ${TableBody} {
          ${TableRow} {
            ${TableCell}:nth-child(${incisionsCount + 1}) {
              box-shadow: -1px 0 0 0 var(${ColorVarsEnum.Level_3});

              ${horizontalLine &&
              css`
                box-shadow: inset 0 0 0 0 var(${ColorVarsEnum.Level_5}), -1px 0 0 0 var(${ColorVarsEnum.Level_3});
              `}
            }
          }
        }
      `}

    ${({ beatColumnBackground, incisionsCount }) =>
      beatColumnBackground &&
      css`
        ${TableBody} {
          ${TableRow} {
            ${TableCell}:nth-child(-n + ${incisionsCount}):after {
              ${backgroundColorMixin};
            }
          }
        }

        ${TableHead} {
          ${TableRow} {
            ${TableHeader}:nth-child(-n + ${incisionsCount}):after {
              ${backgroundColorMixin};
            }
          }
        }

        ${TotalRow} {
          ${TableCell}:nth-child(-n + ${incisionsCount}) {
            position: relative;

            :after {
              ${backgroundColorMixin};
            }
          }
        }
      `}

    ${({ beatColumnBackground, beatBody, incisionsCount }) =>
      beatColumnBackground &&
      beatBody &&
      css`
        ${TableBody} {
          ${TableRow}:nth-child(2n) {
            ${TableCell}:nth-child(-n + ${incisionsCount}):after {
              opacity: 0.1;
            }
          }
        }
      `}
  }
}`;

export const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  width: 14px;
  height: 14px;
  background: var(${ColorVarsEnum.Level_5});
  color: var(${ColorVarsEnum.Level_2});
  border-radius: 2px;
  outline: none;
  border: none;

  :focus {
    outline: none;
    border: none;
  }
`;
