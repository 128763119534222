import { store } from 'store';
import {
  ColorByInterface,
  ColumnWidthSettings,
  DefaultPropertiesInterface,
  DynamicsMarkerSettings,
  TableDataSettings,
  TotalSettings,
} from 'store/reducers/visualisations/types';
import { moveArrayItem, MoveToType } from 'utils/utils';
import { updateTableDataSettingsAction } from 'store/reducers/visualisations/actions';
import {
  OnChangeValue,
  onStepIncisionClickWithGroupingInterface,
  SortChangeInterface,
} from 'modules/visualisations/Table/settings/DataTab/types';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { onChangeOrderBy } from 'modules/visualisations/common/onChangeFunctions';

const dispatch = store.dispatch;

export const onColorByValueSettingsChange = (colorByValueSettings: ColorByInterface) =>
  dispatch(updateTableDataSettingsAction({ colorByValueSettings }));

export const onBackgroundByValueSettingsChange = (backgroundByValueSettings: ColorByInterface) =>
  dispatch(updateTableDataSettingsAction({ backgroundByValueSettings }));

/* Incision change */

export const onColumnWidthIncisionsChange: OnChangeValue<ColumnWidthSettings> = (dataSettings, columnWidthSettings, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, columnWidthSettings } } : incision,
      ),
    }),
  );

export const onChangeIncisionsLabelProperties: OnChangeValue<DefaultPropertiesInterface> = (dataSettings, properties, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, properties } } : incision,
      ),
    }),
  );

export const onColorIncisionChange: OnChangeValue<ColorValuesByThemeType | null> = (dataSettings, color, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, color } : incision)),
    }),
  );

export const onMoveIncision = (dataSettings: TableDataSettings, incisionId: string, moveTo: MoveToType) => {
  const incisions = dataSettings.incisions,
    indexOfIncision = incisions.findIndex(({ id }) => id === incisionId),
    { newArray } = moveArrayItem(incisions, indexOfIncision, moveTo);

  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      incisions: newArray,
    }),
  );
};

/* Indicator change */

export const onColumnWidthIndicatorChange: OnChangeValue<ColumnWidthSettings> = (dataSettings, columnWidthSettings, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, columnWidthSettings } } : indicator,
      ),
    }),
  );

export const onTotalIndicatorChange: OnChangeValue<TotalSettings> = (dataSettings, totalSettings, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, totalSettings } } : indicator,
      ),
    }),
  );

export const onDynamicsMarkerChange: OnChangeValue<DynamicsMarkerSettings> = (dataSettings, dynamicsMarkerSettings, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, dynamicsMarkerSettings } } : indicator,
      ),
    }),
  );

export const onSortChange = ({ dataSettings, columnName, type, sortItsColumn, isActive }: SortChangeInterface) => {
  const order = dataSettings.orderBy.filter((el) => el.columnName !== columnName);

  if (!isActive && isActive !== undefined) {
    return onChangeOrderBy(dataSettings, order);
  }

  if (sortItsColumn || isActive) {
    return onChangeOrderBy(dataSettings, [
      ...order,
      {
        columnName,
        type,
      },
    ]);
  }

  return onChangeOrderBy(dataSettings, order);
};

export const onColorIndicatorChange: OnChangeValue<ColorValuesByThemeType | null> = (dataSettings, color, id) => {
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) => (id === indicator.id ? { ...indicator, color } : indicator)),
    }),
  );
};

export const onMoveIndicator = (dataSettings: TableDataSettings, indicatorId: string, moveTo: MoveToType) => {
  const indicators = dataSettings.indicators,
    indexOfIndicators = indicators.findIndex(({ id }) => id === indicatorId),
    { newArray } = moveArrayItem(indicators, indexOfIndicators, moveTo);

  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: newArray,
    }),
  );
};

export const onChangeIndicatorMakeHyperLinks: OnChangeValue<boolean> = (dataSettings, makeHyperLinks, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, makeHyperLinks } } : indicator,
      ),
    }),
  );

export const onChangeIndicatorLabelProperties: OnChangeValue<DefaultPropertiesInterface> = (dataSettings, properties, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, properties } } : indicator,
      ),
    }),
  );

export const onStepIncisionClickWithGrouping = ({
  dataSettings,
  hasIncisionsGrouping,
  id,
  moveTo,
}: onStepIncisionClickWithGroupingInterface) => {
  const dataSettingsCopy: TableDataSettings = JSON.parse(JSON.stringify(dataSettings));

  hasIncisionsGrouping.map((incision) => {
    dataSettingsCopy.incisions.forEach((el) => {
      if (el.id === incision.id) {
        el.settings.isGroup = false;
      }
    });
  });

  onMoveIncision(dataSettingsCopy, id, moveTo);
};
