import React, { useEffect, useId } from 'react';
import { WorkspaceTaskForm } from 'components/console/elements/workSpace/Modal/WorkSpaceTaskModal/types';
import { TaskRadio } from 'components/TaskRadio';
import { TaskInterface } from 'store/reducers/adminTasks/types';
import { createProjectTaskAction, setProjectTaskAction, updateProjectTaskAction } from 'store/reducers/projectSettings/actions';
import { FlexContainer } from 'styles/FlexContainer';
import { Button, Switcher, TextField } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { Form } from './styles';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { ModeForm } from 'types/store';
import { HintChildrenCron } from 'components/admin/wrappers/ActiveDirectoriesAndTasksWrapper/HintChildrenCron';

type WorkSpaceTaskModalProps = {
  mode: ModeForm;
  projectId: string;
  task?: TaskInterface;
};

export const WorkSpaceTaskModal: ModalComponentType<WorkSpaceTaskModalProps> = ({ mode, task, onClose, projectId }) => {
  const uniqueId = useId();
  const dispatch = useAppDispatch();

  const isAdd = mode === 'add';

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<WorkspaceTaskForm>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    setValue('schedule', task?.schedule || '0 0 15 * *');
    setValue('isActive', task?.isActive || false);
  }, [task, setValue]);

  const onSubmit: SubmitHandler<WorkspaceTaskForm> = async ({ schedule, isActive }) => {
    if (!isDirty || isSubmitting) {
      return null;
    }

    let actionTaskRes;
    const params = { run: isActive, schedule, id: projectId };

    try {
      if (isAdd) {
        actionTaskRes = await dispatch(createProjectTaskAction(params)).unwrap();
      }

      if (!isAdd) {
        actionTaskRes = await dispatch(updateProjectTaskAction(params)).unwrap();
      }

      actionTaskRes && dispatch(setProjectTaskAction(actionTaskRes));
      onClose && onClose();
    } catch (e) {
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      <FlexContainer width="100%" alignItems="flex-start" flexDirection="column" height="100%" gap="32px" padding="12px">
        <Controller
          name="isActive"
          control={control}
          render={({ field }) => (
            <FlexContainer flexDirection="row" alignItems="center" gap="8px">
              <Switcher id={uniqueId} value={field.value} onClick={field.onChange} />

              <PrimaryTextParagraph lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                Активно
              </PrimaryTextParagraph>
            </FlexContainer>
          )}
        />
        <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
          <TaskRadio setValue={setValue} name="schedule" />

          <Controller
            render={({ field }) => (
              <FlexContainer marginLeft="auto">
                <TextField
                  HintChildrenText={<HintChildrenCron />}
                  error={!!errors.schedule}
                  widthContainer="430px"
                  label="Задание в крон"
                  width="100%"
                  {...field}
                />
              </FlexContainer>
            )}
            rules={{ required: true }}
            name="schedule"
            control={control}
          />

          <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
            <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
            <Button
              text={isAdd ? 'Создать' : 'Изменить'}
              iconSize="normal"
              needBackground={true}
              heightSize="normal"
              type="submit"
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Form>
  );
};
