import { TitleSettings } from 'modules/settingsContainer/common/view/TitleSettings';
import { DescriptionSettings } from 'modules/settingsContainer/common/view/DescriptionSettings';
import { TitlePositionSettings } from 'modules/settingsContainer/common/view/TitlePositionSettings';
import React from 'react';
import { DefaultViewSettingsInterface } from 'store/reducers/visualisations/types';
import { TextHintSettings } from 'modules/settingsContainer/common/view/TextHintSettings';
import { ReactChildrenType } from 'types/global';
import { ContainerSettings, ContainerSettingsProps } from 'modules/settingsContainer/common/view/ContainerSettings';

interface DefaultViewSettingsProps extends Pick<ContainerSettingsProps, 'positionConfig' | 'ExternalBackgroundSettings'> {
  viewSettings: DefaultViewSettingsInterface;
  FirstSlot?: ReactChildrenType;
  SecondSlot?: ReactChildrenType;
  ThirdSlot?: ReactChildrenType;
}

export const DefaultViewSettings = ({
  viewSettings,
  FirstSlot,
  SecondSlot,
  ThirdSlot,
  positionConfig,
  ExternalBackgroundSettings,
}: DefaultViewSettingsProps) => (
  <>
    <ContainerSettings
      positionConfig={positionConfig}
      disableDragging={viewSettings.disableDragging}
      showBackground={viewSettings?.showBackground}
      borderSettings={viewSettings.borderSettings}
      shadowSettings={viewSettings.shadowSettings}
      ExternalBackgroundSettings={ExternalBackgroundSettings}
    />
    {FirstSlot}
    {viewSettings?.header && <TitleSettings value={viewSettings.header} />}
    {viewSettings?.description && <DescriptionSettings value={viewSettings.description} />}
    {viewSettings?.headerPosition && <TitlePositionSettings value={viewSettings.headerPosition} />}
    {SecondSlot}
    {viewSettings?.hint && <TextHintSettings value={viewSettings.hint} />}
    {ThirdSlot}
  </>
);
