import { store } from 'store';
import { updateHeatmapViewSettingsAction } from 'store/reducers/visualisations/actions';
import {
  AxisSettingsInterface,
  LegendSettingsInterface,
  PaddingVisualisationInterface,
  VisualMapSettingsInterface,
  ZoomSettingsInterface,
} from 'store/reducers/visualisations/types';
import { PositionSettingType, StartAndEndInterface } from 'types/store';

const dispatch = store.dispatch;

export const onXAxisIncisionChange = (axisXIncisionSettings: AxisSettingsInterface) =>
  dispatch(updateHeatmapViewSettingsAction({ axisXIncisionSettings }));

export const onYAxisIncisionChange = (axisYIncisionSettings: AxisSettingsInterface) =>
  dispatch(updateHeatmapViewSettingsAction({ axisYIncisionSettings }));

export const onLegendSettingsChange = (legendSettings: LegendSettingsInterface) =>
  dispatch(updateHeatmapViewSettingsAction({ legendSettings }));

export const onVisualMapSettingsChange = (visualMapSettings: VisualMapSettingsInterface) =>
  dispatch(updateHeatmapViewSettingsAction({ visualMapSettings }));

export const onDataZoomHorizontalStartAndEndSettingsChange = (dataZoomHorizontalStartAndEnd: StartAndEndInterface) =>
  dispatch(updateHeatmapViewSettingsAction({ dataZoomHorizontalStartAndEnd }));

export const onDataZoomVerticalStartAndEndSettingsChange = (dataZoomVerticalStartAndEnd: StartAndEndInterface) =>
  dispatch(updateHeatmapViewSettingsAction({ dataZoomVerticalStartAndEnd }));

export const onDataVisualMapSelectedMinAndMaxSettingsChange = (dataVisualMapSelectedMinAndMax: StartAndEndInterface) =>
  dispatch(updateHeatmapViewSettingsAction({ dataVisualMapSelectedMinAndMax }));

export const onShowTipsChange = (showTips: boolean) => dispatch(updateHeatmapViewSettingsAction({ showTips }));

export const onIncisionSelectorPositionChange = (incisionSelectorPosition: PositionSettingType) =>
  dispatch(updateHeatmapViewSettingsAction({ incisionSelectorPosition }));

export const onChangePaddingVisualisation = (visualisationPaddings: PaddingVisualisationInterface) =>
  dispatch(
    updateHeatmapViewSettingsAction({
      visualisationPaddings,
    }),
  );

export const onChangeHorizontalZoomChange = (horizontalZoom: ZoomSettingsInterface) =>
  dispatch(updateHeatmapViewSettingsAction({ horizontalZoom }));

export const onChangeVerticalZoomChange = (verticalZoom: ZoomSettingsInterface) =>
  dispatch(updateHeatmapViewSettingsAction({ verticalZoom }));
