import { FilterContainer } from 'modules/workspace/components/FilterContainer';
import { SingleEnabledFilterInterface } from 'store/reducers/filters/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React from 'react';
import { EnabledFilterViewInterface } from 'modules/workspace/components/FilterRow/types';

export const SingleEnabledFilter: EnabledFilterViewInterface<SingleEnabledFilterInterface> = ({ onDelete, selectedValues }) => {
  return (
    <FilterContainer onDelete={onDelete}>
      <PrimaryTextSpan
        lineHeight="14px"
        fontSize="14px"
        color={`var(${ColorVarsEnum.Level_1})`}
        cursor="default"
        whiteSpace="nowrap"
      >
        {selectedValues[0]}
      </PrimaryTextSpan>
    </FilterContainer>
  );
};
