import { ByType } from 'types/store';
import { dateUtils } from 'utils/dates';

export const defaultDateFormat = 'dd-MM-yyyy';
export const defaultTimeFormat = 'HH:mm';
export const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`;
export const defaultTimeSecondFormat = 'HH:mm:ss';
export const defaultSqlDateFormat = 'yyyy-MM-dd';

export const byTypeFormat: Record<ByType, string> = {
  byDay: 'dd.MM.yyyy',
  byMonth: 'LLLL yyyy',
  byQuarter: 'QQQQ yyyy',
  byWeek: 'Io неделя yyyy',
  byToday: 'Сегодня dd.MM.yyyy',
};

export const getFromToFnByType: Record<ByType, { from: (value: Date) => Date; to: (value: Date) => Date }> = {
  byDay: { from: (value) => value, to: (value) => value },
  byMonth: { from: dateUtils.startOfMonth, to: dateUtils.lastDayOfMonth },
  byQuarter: { from: dateUtils.startOfQuarter, to: dateUtils.lastDayOfQuarter },
  byWeek: {
    from: dateUtils.startOfWeek,
    to: dateUtils.lastDayOfWeek,
  },
  byToday: { from: dateUtils.today, to: dateUtils.today },
};
