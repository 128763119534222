import { FilterViewInterface } from 'modules/workspace/components/FilterArea/types';
import {
  FilterValueItem,
  FilterValuesWrapper,
  MultipleFilterWrapper,
  RotatedDownIcon,
} from 'modules/filters/Multiple/view/Filter/styles';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { getFilterFieldName } from 'store/reducers/filters/constants';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ClearIcon, DeleteIcon, DownIcon } from 'assets/icons/withContainer';
import { useModalState } from 'utils/hooks/modalState';
import { Button } from 'modules/ui';
import { useLocalValues } from 'utils/hooks/localValues';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { enableFilterAction, updateEnabledFilterAction } from 'store/reducers/filters/actions';
import { disableFilterById } from 'store/reducers/filters';
import {
  UpdateEnableFilterDataFnType,
  useChangedFilterData,
  useEnabledFilter,
  useFilterQuery,
} from 'modules/filters/hooks/filter';
import { MultipleEnabledFilterInterface, MultipleFilterInterface } from 'store/reducers/filters/types';
import { SearchInput } from 'modules/ui/inputs/SearchInput';

export const MultipleFilterComponent: FilterViewInterface<MultipleFilterInterface> = ({ data, whereQuery }) => {
  const { nameSettings, position, isRealData, fictionalData, id, type, isGlobal, modelId, filterInfluences, sqlData } = data;

  const [searchString, setSearchString] = useState('');

  const [selectedFilterValues, setSelectedFilterValues] = useState<string[]>([]);

  const selectedValues = useMemo(() => selectedFilterValues, [selectedFilterValues]);

  const { dispatch, filterValues, modelIdValue } = useFilterQuery({
    nameSettings,
    id,
    modelId,
    whereQuery,
    searchString,
    sqlData,
  });

  const updateEnableFilterDataFn: UpdateEnableFilterDataFnType<MultipleEnabledFilterInterface> = (enableFilterData) => {
    if (enabledFilter?.id && enableFilterData.selectedValues.length) {
      dispatch(
        updateEnabledFilterAction<MultipleEnabledFilterInterface>({
          id: enabledFilter?.id,
          data: { ...enableFilterData },
        }),
      );
    }
  };

  const { enabledFilter, enableFilterData } = useEnabledFilter({
    id,
    type,
    modelIdValue,
    selectedValues,
    isRealData,
    filterInfluences,
    isGlobal,
    nameSettings,
    updateEnableFilterDataFn,
  });

  const { isRealDataIsChanged, fieldNameIsChanged } = useChangedFilterData({ nameSettings, isRealData });

  const normalizedFilterValues = useMemo(
    () =>
      isRealData
        ? filterValues
        : fictionalData.map((value) => ({
            value,
          })),
    [isRealData, fictionalData, filterValues],
  );

  const { onClose, toggleOpen, isOpen } = useModalState();

  const { localValues, setLocalValues, onSave, onCancel, hasChanges } = useLocalValues({
    value: selectedFilterValues,
    onChange: setSelectedFilterValues,
  });

  const filteredFilterValues = useMemo(
    () =>
      normalizedFilterValues.reduce<string[]>((result, { value }) => {
        if (!localValues.includes(value)) {
          return [...result, value];
        }

        return [...result];
      }, []),
    [localValues, normalizedFilterValues],
  );

  const values = useMemo(() => {
    if (searchString !== '' && searchString) {
      return [...filteredFilterValues];
    }

    return [...localValues, ...filteredFilterValues];
  }, [filteredFilterValues, localValues, searchString]);

  const isSelectedFilterValue = useCallback((value: string) => localValues.includes(value), [localValues]);

  const onSelectFilterValue: (value: string) => () => void = useCallback(
    (value: string) => () => {
      const newSelectedData = !isSelectedFilterValue(value)
        ? [...localValues, value]
        : localValues.filter((selectedValue) => selectedValue !== value);

      setLocalValues(newSelectedData);
    },
    [isSelectedFilterValue, localValues, setLocalValues],
  );

  const filterName = getFilterFieldName(nameSettings);

  const alreadyAllSelected = useMemo(() => localValues.length === values.length, [localValues.length, values.length]);

  const onUnselectAll = useCallback(() => setLocalValues([]), [setLocalValues]);

  const onSelectAll = useCallback(() => {
    if (alreadyAllSelected) {
      onUnselectAll();
      return;
    }

    setLocalValues(values.map((value) => value));
  }, [alreadyAllSelected, values, onUnselectAll, setLocalValues]);

  /* To clear the filter after changing isRealData or nameSettings.fieldName */
  useEffect(() => {
    if (isRealDataIsChanged || fieldNameIsChanged) {
      setSelectedFilterValues([]);
      if (enabledFilter) {
        dispatch(disableFilterById(enabledFilter.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRealDataIsChanged, fieldNameIsChanged]);

  /* Restore active filter state */
  useEffect(() => {
    if (enabledFilter && enabledFilter?.selectedValues) {
      setSelectedFilterValues(enabledFilter.selectedValues);
    } else {
      setSelectedFilterValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledFilter?.selectedValues]);

  /* Enable Filter and Disable by click to Save button   */
  const onEnableFilter = useCallback(() => {
    onClose();
    onSave();

    if (!enabledFilter) {
      dispatch(enableFilterAction({ ...enableFilterData, isRealData, selectedValues: localValues }));
      return;
    }

    if (localValues.length === 0 && enabledFilter) {
      dispatch(disableFilterById(enabledFilter.id));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSave, enabledFilter, enableFilterData, localValues]);

  const onDiscard = () => {
    onClose();
    onCancel();
  };

  const onDisableFilter = () => enabledFilter && dispatch(disableFilterById(enabledFilter.id));

  return (
    <ClickAwayListener onClickAway={onClose}>
      <MultipleFilterWrapper isOpen={isOpen}>
        <FlexContainer gap="5px" justifyContent={position} width="100%">
          <FlexContainer gap="10px" alignItems="center" justifyContent="center" cursor="pointer" onClick={toggleOpen}>
            <PrimaryTextSpan fontSize="18px" lineHeight="18px" color={`var(${ColorVarsEnum.Level_1})`}>
              {filterName}
            </PrimaryTextSpan>
            <IconWrapper
              Icon={isOpen ? RotatedDownIcon : DownIcon}
              colorVar={ColorVarsEnum.Accent}
              iconHeight="18px"
              iconWidth="18px"
              hoverColorVar={ColorVarsEnum.Accent}
              opacity={0}
            />
          </FlexContainer>
          {enabledFilter && !isOpen && (
            <FlexContainer>
              <IconWrapper
                onClick={onDisableFilter}
                Icon={ClearIcon}
                colorVar={ColorVarsEnum.Level_1}
                iconHeight="15px"
                iconWidth="15px"
              />
            </FlexContainer>
          )}
        </FlexContainer>
        {isOpen && (
          <>
            <FlexContainer width="100%">
              <SearchInput
                useDebounce
                name="filter"
                needBackground={false}
                type="text"
                width="100%"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </FlexContainer>
            <FlexContainer width="100%" justifyContent="space-between">
              <Button
                heightSize="small"
                text={alreadyAllSelected ? 'Снять выбор' : 'Выбрать все'}
                needBackground={false}
                onClick={onSelectAll}
              />
              <Button leftIcon={<DeleteIcon />} heightSize="small" needBackground={false} onClick={onUnselectAll} />
            </FlexContainer>
            <FilterValuesWrapper>
              {/* Need to make another loading (this one has bad UX) */}
              {/*{isLoading && <LoadingOverlay loading />}*/}
              {values.length > 0 ? (
                values.map((value, index) => {
                  const normalizedValue = value !== '' ? value : 'NULL',
                    key = `${value}_${index}`;

                  return (
                    <FilterValueItem key={key} selected={isSelectedFilterValue(value)} onClick={onSelectFilterValue(value)}>
                      {normalizedValue}
                    </FilterValueItem>
                  );
                })
              ) : (
                <FlexContainer
                  height="100%"
                  width="100%"
                  justifyContent="center"
                  flex="1"
                  flexDirection="column"
                  alignItems="center"
                >
                  <PrimaryTextSpan textAlign="center">Нет подходящих данных, либо они уже выбраны</PrimaryTextSpan>
                </FlexContainer>
              )}
            </FilterValuesWrapper>
            <FlexContainer justifyContent="flex-end" gap="8px">
              <Button text="Отменить" needBackground={false} heightSize="small" width="80px" onClick={onDiscard} />
              <Button
                disabled={!hasChanges}
                text="Сохранить"
                needBackground={false}
                heightSize="small"
                width="80px"
                onClick={onEnableFilter}
              />
            </FlexContainer>
          </>
        )}
      </MultipleFilterWrapper>
    </ClickAwayListener>
  );
};

export const MultipleFilter = memo(MultipleFilterComponent) as FilterViewInterface;
