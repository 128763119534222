import { store } from 'store';
import { updateHeatmapDataSettingsAction } from 'store/reducers/visualisations/actions';
import {
  FormattingInterface,
  GradientColorByInterface,
  MinAndMaxInterface,
  ShowValueSettingsInterface,
} from 'store/reducers/visualisations/types';
import { OnChangeValue } from 'modules/visualisations/Heatmap/settings/DataTab/types';
import { ColorValuesByThemeType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';

const dispatch = store.dispatch;

export const onMinAndMaxChange = (minAndMax: MinAndMaxInterface) => dispatch(updateHeatmapDataSettingsAction({ minAndMax }));

/* Incision change */

export const onColorIncisionChange: OnChangeValue<PaletteValuesByThemeType | null> = (dataSettings, colors, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, colors } : incision)),
      indicators: dataSettings.indicators.map((incision) => ({ ...incision, color: null })),
    }),
  );

/* Indicator change */

export const onChangeShowValue: OnChangeValue<ShowValueSettingsInterface> = (dataSettings, showValue, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, showValue } } : indicator,
      ),
    }),
  );

export const onColorIndicatorChange: OnChangeValue<ColorValuesByThemeType | null> = (dataSettings, color, id) => {
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((incision) => (id === incision.id ? { ...incision, color } : incision)),
      incisions: dataSettings.incisions.map((incision) => ({ ...incision, colors: null })),
    }),
  );
};

export const onChangeIndicatorFormatting: OnChangeValue<FormattingInterface> = (dataSettings, formatting, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, formatting } } : indicator,
      ),
    }),
  );

export const onChangeIndicatorNameFromDataBase: OnChangeValue<boolean> = (dataSettings, nameFromDatabase, id) =>
  dispatch(
    updateHeatmapDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, nameFromDatabase } } : indicator,
      ),
    }),
  );

export const onHeatmapBackgroundBySettingsChange = (gradientBackgroundByValueSettings: GradientColorByInterface) =>
  dispatch(updateHeatmapDataSettingsAction({ gradientBackgroundByValueSettings }));
