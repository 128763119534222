import { ClickAwayListener, Popover } from '@mui/material';
import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { ActivatorImageProps, ActivatorImageType } from './types';
import { ImageValues } from 'store/reducers/visualisations/types';
import { Button, TextField } from 'modules/ui';
import { ImageActivatorContainer, ImageBlock, ImagePickerWrapper } from 'modules/settingsContainer/ImagePicker/styles';
import { imagePickerClassName } from './constants';

interface OriginCircleImageActivatorProps extends ActivatorImageProps {
  width?: string;
  height?: string;
}

export const OriginCircleImageActivator = ({
  width = '24px',
  height = '24px',
  onClick,
  imageValue,
}: OriginCircleImageActivatorProps) => {
  return <ImageActivatorContainer onClick={onClick} backgroundImage={imageValue} height={height} width={width} />;
};

export interface ImagePickerProps {
  disabled?: boolean;
  onChange?: (value: ImageValues | null) => void;
  value?: ImageValues | null;
  closeOnClick?: boolean;
  Activator?: ActivatorImageType;
}

export const ImagePicker = ({
  disabled,
  onChange,
  value = null,
  closeOnClick = false,
  Activator = OriginCircleImageActivator,
}: ImagePickerProps) => {
  const [manualImage, setManualImage] = useState<ImageValues>();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLDivElement | null>(null);

  const [activeImagePickerValue, setActiveImagePickerValue] = useState<ImageValues | null>(value);

  const onImageSelectorClick: MouseEventHandler<HTMLDivElement> = (e) => setPopoverAnchorEl(e.currentTarget);
  const onCloseMenu = () => setPopoverAnchorEl(null);

  const onLocalChange = useCallback(
    (newValue: ImageValues) => {
      setActiveImagePickerValue(newValue);
      onChange && onChange(newValue);
    },
    [onChange],
  );

  const setValue = useCallback(
    <T extends ImageValues>(value: T) => {
      const oldValue = activeImagePickerValue,
        linkImage = isEqual(oldValue, value) ? value : value;

      onLocalChange({ ...activeImagePickerValue, linkImage } as unknown as ImageValues);
      closeOnClick && onCloseMenu();
    },
    [activeImagePickerValue, closeOnClick, onLocalChange],
  );

  useEffect(() => {
    setActiveImagePickerValue(value);
  }, [value]);

  return (
    <ImagePickerWrapper>
      {Activator && (
        <Activator imageValue={activeImagePickerValue?.linkImage} onClick={disabled ? undefined : onImageSelectorClick} />
      )}
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorEl={popoverAnchorEl}
        open={!!popoverAnchorEl}
        className={imagePickerClassName}
      >
        <ClickAwayListener onClickAway={onCloseMenu}>
          <ImageBlock>
            <TextField
              name="fictionalData"
              width="100%"
              needBackground={false}
              needBorderBottom={false}
              value={activeImagePickerValue?.linkImage}
              onChange={(event: any) => {
                return setManualImage(event.target.value);
              }}
              useDebounce
            />
            <Button text="Сохранить" onClick={() => manualImage && setValue(manualImage)} />
          </ImageBlock>
        </ClickAwayListener>
      </Popover>
    </ImagePickerWrapper>
  );
};
