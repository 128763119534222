import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { fontSizeOfDayBlock } from 'modules/ui/inputs/DatePicker/constants';
import { defaultDropDownStyleMixin } from 'constants/styles';

interface ColorProps {
  outsideMonthBackgroundColor: string;
}

const dayBlockMixin = (height = 30) => css`
  color: var(${ColorVarsEnum.Level_1});
  border: 1px solid transparent;
  display: inline-block;
  width: 30px;
  height: ${height}px;
  padding: ${(height - parseInt(fontSizeOfDayBlock)) / 2}px 0;
  text-align: center;
  margin: 2px;
  font-size: ${fontSizeOfDayBlock};
  line-height: ${fontSizeOfDayBlock};
  font-style: normal;
`;

const disabledMixin = css`
  cursor: default;
  color: var(${ColorVarsEnum.Level_4});
  background-color: var(${ColorVarsEnum.Level_4_menu});

  :hover {
    color: var(${ColorVarsEnum.Level_4});
    background-color: var(${ColorVarsEnum.Level_4_menu});
  }

  :after {
    content: none;
  }
`;

const actionDayBlockMixing = (height = 30) => css`
  ${dayBlockMixin(height)};
  cursor: pointer;
  background-color: var(${ColorVarsEnum.Level_2_btn});
  border-radius: 2px;

  :hover {
    color: var(${ColorVarsEnum.Accent});
  }

  &--in-range,
  &--selected,
  &--in-selecting-range {
    border-radius: 30px;
    background-color: var(${ColorVarsEnum.Level_4_menu});
    border: 1px solid var(${ColorVarsEnum.Accent});
  }

  &--today {
    font-weight: bold;
  }
`;

export const datePickerInputMixin = css`
  .react-datepicker__input-container {
  }
`;

export const datePickerCalendarMixin = css<ColorProps>`
  .react-datepicker-popper {
    z-index: 1;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 10px;
  }

  .react-datepicker-popper[data-placement^='top'] {
    padding-bottom: 10px;
  }

  .react-datepicker-popper[data-placement^='right'] {
    padding-left: 8px;
  }

  .react-datepicker-popper[data-placement^='left'] {
    padding-right: 8px;
  }

  .react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
  }

  .react-datepicker {
    cursor: default;
    font-family: 'Roboto', sans-serif;
    text-align: center;

    ${defaultDropDownStyleMixin};
    padding: 8px 0 12px 0;
    overflow: hidden;

    .react-datepicker__month-container {
      width: 290px;

      .react-datepicker__header {
        background-color: var(${ColorVarsEnum.Level_4_menu});
        border: 0;
        border-top-left-radius: 0;
        padding: 0;

        .react-datepicker__day-names {
          margin: 0;

          .react-datepicker__day-name {
            :first-letter {
              text-transform: capitalize;
            }

            ${dayBlockMixin()};
            color: var(${ColorVarsEnum.Level_2});
          }
        }
      }

      .react-datepicker__monthPicker {
        .react-datepicker__month-wrapper {
          .react-datepicker__month-text {
            ${actionDayBlockMixing()};
            width: 80px;
            &--disabled,
            &--excluded {
              ${disabledMixin}
            }
          }
        }
      }

      .react-datepicker__quarterPicker {
        .react-datepicker__quarter-wrapper {
          .react-datepicker__quarter-text {
            ${actionDayBlockMixing(100)};
            width: 120px;

            &--disabled,
            &--excluded {
              ${disabledMixin}
            }
          }
        }
      }

      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__week-number {
            ${dayBlockMixin()};
            color: var(${ColorVarsEnum.Level_2});
          }

          .react-datepicker__day {
            ${actionDayBlockMixing()};

            &--outside-month {
              color: var(${ColorVarsEnum.Level_4});
              position: relative;
              background-color: ${({ outsideMonthBackgroundColor }) => outsideMonthBackgroundColor};
            }

            &--disabled,
            &--excluded {
              ${disabledMixin}
            }
          }
        }
      }

      .react-datepicker__footer {
        padding: 0 12px;
        margin-top: 25px;
        display: flex;
        justify-content: space-between;

        button {
          border: unset;
          outline: none !important;
          background: transparent;
          color: ${`var(${ColorVarsEnum.Level_1})`};
          white-space: nowrap;
          cursor: pointer;
          transition: all 200ms;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 400;

          svg {
            path,
            circle,
            rect {
              stroke: ${`var(${ColorVarsEnum.Level_2})`};
            }
          }

          :hover {
            svg {
              path,
              circle,
              rect {
                opacity: 0.8;
              }
            }
          }
        }

        .react-datepicker__clear-button {
        }

        .react-datepicker__control-buttons {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          button {
            padding: 0 8px;

            :hover {
              background-color: var(${ColorVarsEnum.Level_2_btn});
            }
          }
        }
      }
    }
  }
`;

export const DatePickerWrapper = styled.div<ColorProps>`
  ${datePickerInputMixin};

  ${datePickerCalendarMixin};
`;
