import {
  BoardContent,
  BoardContentWrapper,
  BoardWrapper,
  ControlButtonWrapper,
  ModelsListWrapper,
} from 'components/contents/Models/Board/styles';
import { Button } from 'modules/ui';
import React, { useCallback, useEffect, useRef } from 'react';
import { EditableMenu } from 'modules/ui/menu/EditableMenu';
import { useSelector } from 'react-redux';
import { getActiveModelItemAlias, getModelItemDataByAlias, getTabsAsArray, getTabsLoading } from 'store/reducers/models/getters';
import {
  addNewTabAction,
  removeTabByIdAction,
  setActiveModelItemAliasAction,
  setActiveTabAction,
  updateTabByIdAction,
  updateZoomAction,
  uploadTabsAction,
} from 'store/reducers/models/actions';
import { HeaderItemInterface } from 'modules/ui/menu/HeaderMenu/types';
import { throttle } from 'lodash';
import { BoardAreaContainer } from 'modules/models/components/BoardAreaContainter';
import { useModelTab } from 'modules/models/hooks/modelTab';
import { BezierBoard } from 'modules/ui/blocks/BezierBoard';
import { BezierSettingItem } from 'modules/ui/blocks/BezierBoard/types';
import { getMiddleOfColumnByIndex } from 'components/contents/Models/TableView/constants';
import { AbsolutePositionType } from 'types/styles';
import { DELETE_BUTTONS } from 'modules/workspace/constans';
import { Model } from 'models/model/Model';
import { ModelItem } from 'models/model/ModelItem';
import { ProjectIdInterface } from 'types/store';
import Snackbar from 'services/Snackbar';

type BoardProps = ProjectIdInterface;

export const Board = ({ projectId }: BoardProps) => {
  const tabs = useSelector(getTabsAsArray);
  const { dispatch, activeTab, updaterTab, flatModelItems, flatRelations } = useModelTab();
  const activeModelItemAlias = useSelector(getActiveModelItemAlias);
  const { model: activeModel, modelItem: activeModelItem } = useSelector(
    getModelItemDataByAlias(activeModelItemAlias?.alias || ''),
  );

  const isTabsLoading = useSelector(getTabsLoading);

  const tabsItems = tabs.map(({ id, name }) => ({ key: id, name }));

  const bezierSettings: BezierSettingItem[] = flatRelations.map(
    ({ relation, meta: { leftColumnNumber, rightColumnNumber, fromLeftToRight } }) => {
      const {
        link: { left, right },
      } = relation;

      let startSide: AbsolutePositionType = 'right',
        endSide: AbsolutePositionType = 'left';

      if (fromLeftToRight) {
        [startSide, endSide] = [endSide, startSide];
      }

      if (relation.isSelf) {
        startSide = 'left';
        endSide = 'left';
      }

      return {
        from: {
          id: left.table,
          key: left.column,
        },
        to: {
          id: right.table,
          key: right.column,
        },
        style: 'line',
        positions: {
          start: { side: startSide, indent: getMiddleOfColumnByIndex(leftColumnNumber, activeTab?.zoom) },
          end: { side: endSide, indent: getMiddleOfColumnByIndex(rightColumnNumber, activeTab?.zoom) },
        },
      };
    },
  );

  const onTabClick = (id: string) => dispatch(setActiveTabAction(id));
  const onAddTab = () => dispatch(addNewTabAction());
  const onDeleteTab = (id: string) => dispatch(removeTabByIdAction(id));
  const onTabNameChange = ({ key, name }: HeaderItemInterface<string>) =>
    dispatch(updateTabByIdAction({ id: key, data: { name } }));

  const onDeleteModelItem = (model: Model, alias: string) => () =>
    updaterTab((activeTab) => {
      const restModels = activeTab?.models?.filter((currentModel) => currentModel !== model) || [];
      const models = model.deleteModelByAlias(alias);

      return [...restModels, ...models];
    });

  const isDenyDelete = (model: Model, modelItem: ModelItem) => modelItem.isHead && !model.isLastItem(modelItem.alias);

  const disableSaveButton = !tabs.every(({ models }) => models.length === 1);

  const onSave = () => {
    dispatch(uploadTabsAction(projectId));
    Snackbar.show('Модели успешно сохранены', 'success');
  };

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const onChangeZoom = throttle((decrease?: boolean) => dispatch(updateZoomAction(decrease)), 50);

  const onWheel = useCallback((e: WheelEvent) => {
    if (e.ctrlKey) {
      e.preventDefault();
      onChangeZoom(e.deltaY > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    wrapperRef.current?.addEventListener('wheel', onWheel);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => wrapperRef.current?.removeEventListener('wheel', onWheel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteByKey = useCallback(
    (e: KeyboardEvent) => {
      if (activeModel && activeModelItem && DELETE_BUTTONS.includes(e.key) && !isDenyDelete(activeModel, activeModelItem)) {
        onDeleteModelItem(activeModel, activeModelItem.alias)();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeModelItem, onDeleteModelItem],
  );

  useEffect(() => {
    document.addEventListener('keydown', onDeleteByKey);

    return () => document.removeEventListener('keydown', onDeleteByKey);
  }, [onDeleteByKey]);

  return (
    <BoardWrapper>
      <ModelsListWrapper>
        <EditableMenu
          items={tabsItems}
          onItemClick={onTabClick}
          activeItemKey={activeTab?.id || ''}
          onAddClick={onAddTab}
          onDeleteClick={onDeleteTab}
          onItemChange={onTabNameChange}
          isLoading={isTabsLoading}
          deletionDisabled={tabsItems.length === 1}
        />
      </ModelsListWrapper>
      <ControlButtonWrapper>
        <Button text="Сохранить" disabled={disableSaveButton} onClick={onSave} />
        <Button width="30px" text="+" onClick={() => onChangeZoom()} />
        <Button width="30px" text="-" onClick={() => onChangeZoom(true)} />
      </ControlButtonWrapper>
      {activeTab && (
        <BoardContentWrapper ref={wrapperRef}>
          <BoardContent zoom={activeTab.zoom}>
            <BezierBoard settings={bezierSettings} scale={activeTab.zoom}>
              {flatModelItems.map(({ model, modelItem }, modelItemIndex) => {
                const { alias, table, isHead, config } = modelItem,
                  key = `${activeTab.id}_${modelItemIndex}`,
                  relativeColumns = model.getRelativeColumnsByAlias(modelItem.alias);

                const columnsContainer = modelItem.columnsAsArray.map((name) => ({
                  key: name,
                  name,
                  isConnected: relativeColumns.has(name),
                }));

                return (
                  <BoardAreaContainer
                    key={key}
                    id={key}
                    table={table}
                    isActive={activeModelItemAlias?.alias === alias}
                    columns={columnsContainer}
                    alias={alias}
                    isHead={isHead}
                    prohibitDelete={isDenyDelete(model, modelItem)}
                    onDeleteClick={onDeleteModelItem(model, alias)}
                    makeHeadClick={() =>
                      updaterTab((activeTab) => {
                        const filteredModels = activeTab?.models?.filter((currentModel) => currentModel !== model) || [];

                        const newModel = model.setHeadByAlias(alias);

                        return [...filteredModels, newModel];
                      })
                    }
                    onChangeAlias={(alias: string) =>
                      updaterTab(() => {
                        model.changeAlias({ fromAlias: modelItem.alias, toAlias: alias });
                      })
                    }
                    config={config}
                    onActiveChange={(isActive: boolean) => {
                      dispatch(setActiveModelItemAliasAction(isActive ? { alias, table } : null));
                    }}
                    scale={activeTab.zoom}
                    onPositionChange={(config) =>
                      updaterTab(() => {
                        modelItem.config = config;
                      })
                    }
                  />
                );
              })}
            </BezierBoard>
          </BoardContent>
        </BoardContentWrapper>
      )}
    </BoardWrapper>
  );
};
