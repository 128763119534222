import { HexAndHLSColorInterface, IdInterface, NameInterface } from 'types/store';
import { MapRecordType } from 'types/global';

export interface BasedOnInterface {
  basedOn: string[] | null;
}

export interface ShowGroupNamesInterface {
  showGroupNames: boolean;
}

export interface PaletteColorItemInterface extends IdInterface, HexAndHLSColorInterface {}

export interface GroupItemInterface extends IdInterface, NameInterface {
  colors: PaletteColorItemInterface[];
}

export interface PaletteItemInterface extends IdInterface, NameInterface, BasedOnInterface, ShowGroupNamesInterface {
  groups: GroupItemInterface[];
}

export interface ReturnPaletteByIdInterface {
  data: PaletteItemInterface;
}

export interface ColorIdInterface {
  colorId: string;
}

export interface GroupIdInterface {
  groupId: string;
}

export interface ActiveColorItemInterface extends GroupIdInterface, ColorIdInterface {}

/* Store SLice Type */

export interface PalettesStateInterface {
  palettes: MapRecordType<PaletteItemInterface>;
  serverStateOfPalettes: MapRecordType<PaletteItemInterface> | null;
  palettesLoading: boolean;
}

/* Action Types */

export enum PalettesActionsTypes {
  ADD_PALETTE = 'ADD_PALETTE',
  ADD_PALETTE_BY_ID = 'ADD_PALETTE_BY_ID',
  ADD_PALETTE_BY_CURRENT = 'ADD_PALETTE_BY_CURRENT',
  ADD_GROUP_TO_PALETTE_BY_ID = 'ADD_GROUP_TO_PALETTE_BY_ID',
  ADD_GROUP_TO_CURRENT_PALETTE = 'ADD_GROUP_TO_CURRENT_PALETTE',
  REMOVE_GROUP_FROM_PALETTE_BY_ID = 'REMOVE_GROUP_FROM_PALETTE_BY_ID',
  REMOVE_GROUP_FROM_CURRENT_PALETTE = 'REMOVE_GROUP_FROM_CURRENT_PALETTE',
  ADD_COLOR_TO_GROUP_BY_ID = 'ADD_COLOR_TO_GROUP_BY_ID',
  ADD_COLOR_TO_GROUP_BY_CURRENT = 'ADD_COLOR_TO_GROUP_BY_CURRENT',
  REMOVE_COLOR_FROM_GROUP_BY_ID = 'REMOVE_COLOR_FROM_GROUP_BY_ID',
  REMOVE_COLOR_FROM_GROUP_BY_CURRENT = 'REMOVE_COLOR_FROM_GROUP_BY_CURRENT',
  UPDATE_PALETTE = 'UPDATE_PALETTE',
  REMOVE_PALETTE = 'REMOVE_PALETTE',
  LOAD_PALETTES = 'LOAD_PALETTES',
  LOAD_PALETTES_FROM_SNAPSHOT = 'LOAD_PALETTES_FROM_SNAPSHOT',
  CLEAR_PALETTES_STORE = 'CLEAR_PALETTES_STORE',
}

/* Payloads */

export type AddNewPalettePayload = IdInterface & Partial<Omit<PaletteItemInterface, 'id'>>;

export interface ReturnNewPaletteInterface extends ReturnPaletteByIdInterface, IdInterface {}

export type AddNewPaletteByIdPayload = IdInterface & Partial<NameInterface & BasedOnInterface>;

export interface AddGroupToPalettePayload {
  group: Pick<GroupItemInterface, 'colors'> & Partial<NameInterface>;
}

export interface AddColorToGroupPayload {
  color: Omit<PaletteColorItemInterface, 'id'>;
}

export interface AddGroupToPaletteByIdPayload extends IdInterface, AddGroupToPalettePayload {}

export interface RemoveGroupByIdPayload extends IdInterface, GroupIdInterface {}

export interface AddColorToGroupByIdPayload extends RemoveGroupByIdPayload, AddColorToGroupPayload {}

export interface RemoveColorByIdPayload extends RemoveGroupByIdPayload, ColorIdInterface {}
