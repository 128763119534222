import React, { FC } from 'react';
import { IconProps } from './types';

export const HorizontalStartIcon: FC<IconProps> = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.3335 6.16602L14.8573 6.16602"
        stroke={isActive ? '#26A5ED' : '#9DA1AF'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M5.3335 12L18.6668 12" stroke={isActive ? '#26A5ED' : '#9DA1AF'} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.3335 17.832L11.0478 17.832" stroke={isActive ? '#26A5ED' : '#9DA1AF'} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
