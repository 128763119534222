import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import { IdInterface } from 'types/store';

export const loadAdminTasks = (): Promise<AxiosResponse<FastBoard.API.ApiCronJobWithIdListResponseDTO>> =>
  axiosClient.get(`/api/v1/cronjobs/projects`);

export const createTask = ({ run, schedule, id }: FastBoard.API.CronJobCreateDTO & IdInterface) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiCronJobItemWithIdResponseDTO>, FastBoard.API.CronJobCreateDTO>(
    `/api/v1/cronjob/${id}`,
    {
      run,
      schedule,
    },
  );

export const updateTask = ({ run, id, schedule }: FastBoard.API.CronJobCreateDTO & IdInterface) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiCronJobItemWithIdResponseDTO>, FastBoard.API.CronJobCreateDTO>(
    `/api/v1/cronjob/${id}`,
    {
      run,
      schedule,
    },
  );

export const deleteTask = (id: string): Promise<string> => axiosClient.delete(`/api/v1/cronjob/${id}`);

export const loadAdminFlows = (): Promise<AxiosResponse<FastBoard.API.ApiAdminFlowsListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/flows`);

export const loadAdminShortProject = (
  flowId: string,
): Promise<AxiosResponse<FastBoard.API.ApiAdminProjectShortListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/projects/for-flow/${flowId}/short`);
