import styled, { css, FlattenInterpolation, ThemedStyledProps } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { RadioButtonSizesType } from 'modules/ui/RadioButtonSelector/types';

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 30px;
`;

/* TODO: Move all interface same as selected, active, checked etc. to types/global.ts */

export interface RadioItemProps {
  selected: boolean;
  disabled?: boolean;
  size: RadioButtonSizesType;
}

const fontSizes: Record<RadioButtonSizesType, FlattenInterpolation<ThemedStyledProps<Pick<RadioItemProps, 'selected'>, any>>> = {
  normal: css<Pick<RadioItemProps, 'selected'>>`
    font-size: 14px;
    line-height: 24px;
    border-bottom: 2px solid transparent;

    ${({ selected }) =>
      selected &&
      css`
        color: var(${ColorVarsEnum.Accent});
        border-bottom: 2px solid var(${ColorVarsEnum.Accent});
      `};
  `,
  large: css<Pick<RadioItemProps, 'selected'>>`
    font-size: 18px;
    line-height: 18px;
    padding-bottom: 8px;
    border-bottom: 4px solid transparent;

    ${({ selected }) =>
      selected &&
      css`
        color: var(${ColorVarsEnum.Accent});
        border-bottom: 4px solid var(${ColorVarsEnum.Accent});
      `};
  `,
};

export const RadioItem = styled.div<RadioItemProps>`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  transition: color 200ms;
  color: var(${ColorVarsEnum.Level_1});
  margin: 0;
  cursor: pointer;
  white-space: nowrap;

  ${({ size }) => fontSizes[size]};

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(${ColorVarsEnum.Level_3});
      cursor: not-allowed;
    `};
`;
