import React from 'react';
import { FilterElementsSettings } from 'modules/settingsContainer/FilterElementsSettings';
import { DefaultFilterDataSettings } from 'modules/settingsContainer/common/data/DefaultFilterDataSettings';
import { useGetActiveFilter } from 'modules/filters/hooks/getActiveFilter';
import { FilterValuesList } from 'modules/settingsContainer/FilterValuesList';
import { onPaddingChange, onSelectedDataChange } from 'modules/filters/Single/settings/DataTab/constants';
import { InternalPaddingSettings } from 'modules/settingsContainer/InternalPaddingSettings';
import { useSelector } from 'react-redux';
import { getFiltersValuesStateByFilterId } from 'store/reducers/filters/getters';
import { FilterElementsInterface } from 'modules/settingsContainer/FilterElementsSettings/types';
import { onFilterElementsChange as dispatchedFilterElementsChange } from 'modules/settingsContainer/common/data/DefaultFilterDataSettings/constants';

export const DataTab = () => {
  const { data, modelIdValue, tableFields } = useGetActiveFilter({ type: 'single' });

  const { isRealData, nameSettings, fictionalData, selectedData, paddings, id } = data;

  const { filterValues, loadingFilterValue } = useSelector(getFiltersValuesStateByFilterId(id));

  const onFilterElementsChange = (filterElements: FilterElementsInterface) => {
    const { fieldName } = filterElements.nameSettings;

    dispatchedFilterElementsChange(filterElements);

    if (fieldName !== nameSettings.fieldName) {
      onSelectedDataChange([]);
    }
  };

  return (
    <>
      <DefaultFilterDataSettings modelIdValue={modelIdValue} data={data}>
        <FilterElementsSettings
          onChange={onFilterElementsChange}
          isRealData={isRealData}
          options={tableFields}
          value={{ fictionalData, nameSettings }}
        >
          <FilterValuesList
            onChange={onSelectedDataChange}
            value={selectedData}
            filterValues={filterValues}
            loading={loadingFilterValue}
          />
        </FilterElementsSettings>
        {paddings && <InternalPaddingSettings value={paddings} onChange={onPaddingChange} />}
      </DefaultFilterDataSettings>
    </>
  );
};
