import { OnValueChange } from 'types/global';
import { ColumnWidthSettings as ColumnWidthSettingsInterface } from 'store/reducers/visualisations/types';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { pxUnit } from 'constants/global';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';

type ColumnWidthSettingsProps = OnValueChange<ColumnWidthSettingsInterface>;

export const ColumnWidthSettings = ({ onChange, value }: ColumnWidthSettingsProps) => {
  const { isActive, width } = value;

  return (
    <MainContainerSettings
      titleText="Задать ширину столбца"
      switcherChange={() => onChange({ ...value, isActive: !isActive })}
      switcherState={isActive}
    >
      <GroupContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Ширина"
            name="width"
            value={width}
            unit={pxUnit}
            onChange={(width) => onChange({ ...value, width })}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
