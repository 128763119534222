import { Item, TextItem } from './styles';
import React from 'react';
import { RadioSelectedItem } from './types';
import { FlexContainer, FlexContainerProps } from 'styles/FlexContainer';

export interface RadioSelectedProps<ValueType extends string | number> extends FlexContainerProps {
  activeValue: RadioSelectedItem<ValueType>;
  options: RadioSelectedItem<ValueType>[];
  onChange: (value: RadioSelectedItem<ValueType>) => void;
}

export const RadioSelected = <ValueType extends string | number>({
  options,
  activeValue,
  onChange,
  ...props
}: RadioSelectedProps<ValueType>) => {
  return (
    <FlexContainer gap={'16px'} {...props}>
      {options.map((option) => {
        const { Component, value, label, disabled } = option;

        return (
          <>
            <Item key={value} onClick={() => !disabled && onChange(option)}>
              {Component && <Component isActive={value === activeValue.value} />}
              {label && (
                <TextItem disabled={disabled} selected={value === activeValue.value}>
                  {label}
                </TextItem>
              )}
            </Item>
          </>
        );
      })}
    </FlexContainer>
  );
};
