import { BodyWrapper, SettingsTabsWrapper, TabItem, TabsWrapper } from 'modules/ui/tabs/SettingsTabs/styles';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorWrapper } from 'modules/visualisations/components/ErrorWrapper';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { Loader } from 'shared/ui/Loader';
import { FlexContainer } from 'styles/FlexContainer';
import { Tab } from '../types';

interface SettingsTabsProps {
  tabs: Tab[];
}

/* TODO: It will be necessary to merge or replace SettingsTabs and NavigationsTabs */

export const SettingsTabs = ({ tabs }: SettingsTabsProps) => {
  const defaultTab = useMemo(() => tabs[0], [tabs]);
  const [activeTabId, setActiveTabId] = useState(defaultTab.id);

  const activeTab = useMemo(() => tabs.find(({ id }) => id === activeTabId) || defaultTab, [activeTabId, defaultTab, tabs]);

  const { Component, isLoading } = activeTab;

  const ComponentRender = useCallback(() => {
    return (
      <ErrorWrapper>
        <Component />
      </ErrorWrapper>
    );
  }, [Component]);

  useEffect(() => {
    return () => {
      setActiveTabId(activeTabId || defaultTab.id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  return (
    <SettingsTabsWrapper>
      <TabsWrapper>
        {tabs.map((tab) => {
          const { id, content, type } = tab,
            Content = content;
          return (
            <TabItem isActive={activeTab.id === id} onClick={() => setActiveTabId(id)} key={id}>
              {type === 'text' && <PrimaryTextSpan title={content}>{content}</PrimaryTextSpan>}
              {type === 'icon' && (
                <IconWrapper containerHeight="18px" containerWidth="20px" iconWidth="20px" iconHeight="20px" Icon={content} />
              )}
              {type === 'component' && <Content />}
            </TabItem>
          );
        })}
      </TabsWrapper>
      <BodyWrapper>
        <ComponentRender />
        {isLoading && (
          <FlexContainer zIndex="1" position="absolute" width="100%" height="100%" alignItems="center" left="0" top="0">
            <Loader size="small" title="Загрузка настроек" />
          </FlexContainer>
        )}
      </BodyWrapper>
    </SettingsTabsWrapper>
  );
};
