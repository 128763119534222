import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { FlexContainer } from 'styles/FlexContainer';
import { dataFormattingMethods, formatIcons } from '../constants';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';
import { DecreaseIcon, IncreaseIcon, NumericIcon, TxtIcon } from 'assets/icons/editor';
import TextField from 'modules/ui/TextField';
import { Select } from 'modules/ui';
import { CURRENCY } from 'constants/Mock';
import React from 'react';
import { Formatting, FormattingInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';

type FormattingProps = OnValueChange<FormattingInterface>;
export const Formattings = ({ value: formatting, onChange }: FormattingProps) => {
  const {
    formats: {
      formattingItem: { formattingType, meta },
      numberOfZeros,
      editText,
      numeric,
    },
  } = formatting;

  const dataFormattingList = Object.entries(dataFormattingMethods) as Array<[Formatting, string]>;

  const incrementControlList = [
    {
      title: 'Уменьшить количество знаков после запятой',
      onClick: () =>
        onChange({
          ...formatting,
          formats: {
            ...formatting.formats,
            numberOfZeros: numberOfZeros > 0 ? numberOfZeros - 1 : 0,
          },
        }),
      Icon: IncreaseIcon,
    },
    {
      title: 'Увеличить количество знаков после запятой',
      onClick: () =>
        onChange({
          ...formatting,
          formats: {
            ...formatting.formats,
            numberOfZeros: numberOfZeros + 1,
          },
        }),
      Icon: DecreaseIcon,
    },
  ];
  return (
    <>
      <GroupContainerSettings padding={'none'} width={'100%'}>
        <ElementContainerSettings>
          <FlexContainer gap="10px" flexWrap="wrap">
            {dataFormattingList.map(([key, value]) => {
              const Icon = formatIcons[key];

              return (
                <TooltipIconButton
                  key={key}
                  selected={formattingType === key}
                  leftIcon={<Icon />}
                  onClick={() => {
                    onChange({
                      ...formatting,
                      formats: {
                        ...formatting.formats,
                        formattingItem: {
                          formattingType: key,
                          meta,
                        },
                      },
                    });
                  }}
                  title={value}
                />
              );
            })}
          </FlexContainer>
        </ElementContainerSettings>
        <ElementContainerSettings>
          <FlexContainer gap="10px" flexWrap="wrap">
            <TooltipIconButton
              leftIcon={<TxtIcon />}
              title="Текстовое поле"
              selected={editText.isActive}
              onClick={() =>
                onChange({
                  ...formatting,
                  formats: {
                    ...formatting.formats,
                    numberOfZeros: numberOfZeros,
                    numeric: numeric,
                    editText: { ...editText, isActive: !editText.isActive },
                  },
                })
              }
            />
            {incrementControlList.map(({ title, onClick, Icon }) => (
              <TooltipIconButton key={title} title={title} onClick={onClick} leftIcon={<Icon />} />
            ))}
            <TooltipIconButton
              title="Числовой формат"
              selected={numeric}
              leftIcon={<NumericIcon />}
              onClick={() =>
                onChange({
                  ...formatting,
                  formats: {
                    ...formatting.formats,
                    numeric: !numeric,
                  },
                })
              }
            />
          </FlexContainer>
        </ElementContainerSettings>
      </GroupContainerSettings>
      <FlexContainer justifyContent="space-between" width={'100%'} gap={'12px'}>
        {editText.isActive && (
          <TextField
            name=""
            value={editText?.text}
            onChange={(e) =>
              onChange({
                ...formatting,
                formats: {
                  ...formatting.formats,
                  numberOfZeros: numberOfZeros,
                  numeric: numeric,
                  editText: { ...editText, text: e.target.value, isActive: editText.isActive },
                },
              })
            }
            needBackground={false}
            width="100%"
          />
        )}
        {formattingType.includes('money') && (
          <>
            <Select
              needBackground={false}
              placeholder="aggregateBy"
              name="aggregateBy"
              options={CURRENCY}
              width="100%"
              value={meta?.money || 'RUB'}
              onChange={(value) =>
                onChange({
                  ...formatting,
                  formats: {
                    ...formatting.formats,
                    formattingItem: {
                      ...formatting.formats.formattingItem,
                      formattingType: 'money',
                      meta: { money: value },
                    },
                  },
                })
              }
            />
          </>
        )}
      </FlexContainer>
    </>
  );
};
