const ColorTheme = {
  default: {
    level_1: '#E2E1EA',
    level_2: '#95939F',
    level_3: '#7B7986',
    level_4: '#63616B',
    level_5: '#44424C',
    accent: '#0DC2FF',
    alert: '#D95050',
    l_1_hover_btn: '#494851',
    l_2_btn: '#413F4A',
    l_3_menu: '#363540',
    l_4_widget: '#2F2E38',
    l_5_application: '#292831',
  },
};

export default ColorTheme;
