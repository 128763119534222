import React, { Children, forwardRef, ReactElement, ReactNode, useCallback, useId, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Switcher } from '../ui';
import styled from 'styled-components';
import { IconType } from 'assets/icons/withContainer';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ArrowIcon } from 'modules/ui/icons/ArrowIcon/styles';

interface IProps {
  titleText?: string;
  isOpenDefault?: boolean;
  children?: JSX.Element[] | JSX.Element | ReactNode | ReactElement<any, any> | any;
  switcherState?: boolean;
  switcherChange?: (e?: any) => void;
  customPadding?: string;
  ButtonIcon?: IconType;
  onClickButtonIcon?: (e?: any) => void;
  onDropDownClick?: (isOpen: boolean) => void;
}

// eslint-disable-next-line react/display-name
export const MainContainerSettings = forwardRef<HTMLDivElement, IProps>(
  (
    {
      titleText = null,
      children = null,
      isOpenDefault = false,
      switcherState = false,
      switcherChange = null,
      customPadding,
      ButtonIcon,
      onClickButtonIcon,
      onDropDownClick,
    },
    ref,
  ) => {
    const [open, setIsOpen] = useState(isOpenDefault);
    const uniqueId = useId();

    const toggleShowSetting = useCallback(() => setIsOpen((prev) => !prev), []);

    return (
      <MainContainer ref={ref}>
        <FlexContainer width="100%" flexDirection="column" padding={children && open ? customPadding || '12px' : '12px'}>
          {titleText && (
            <ClickZoneAreas
              isOpen={open}
              onClick={() => {
                toggleShowSetting();
                onClickButtonIcon && onClickButtonIcon();
              }}
            >
              <FlexContainer
                alignItems="center"
                justifyContent="space-between"
                margin={children && open ? `0 ${customPadding ? '4px' : '0'} 12px` : '0'}
                height="16px"
              >
                <PrimaryTextSpan fontSize="14px" lineHeight="14px" color={`var(${ColorVarsEnum.Level_1})`}>
                  {titleText}
                </PrimaryTextSpan>
                <FlexContainer gap="16px" alignItems="center">
                  {switcherChange && <Switcher id={uniqueId} value={switcherState} onClick={switcherChange} />}
                  {ButtonIcon && (
                    <FlexContainer zIndex="2">
                      <IconWrapper
                        hoverColorVar={ColorVarsEnum.Level_1}
                        colorVar={ColorVarsEnum.Level_3}
                        containerWidth="20px"
                        containerHeight="20px"
                        iconHeight="16px"
                        iconWidth="16px"
                        Icon={ButtonIcon}
                      />
                    </FlexContainer>
                  )}
                  {children && <ArrowIcon isActive={open} />}
                </FlexContainer>
              </FlexContainer>
            </ClickZoneAreas>
          )}
          {open && children && (
            <FlexContainer gap="16px" flexDirection="column" zIndex="1" padding="0">
              {Children.map(children, (el) => el)}
            </FlexContainer>
          )}
          <BackgroundOnHover className="backgroundOnHover" />
        </FlexContainer>
      </MainContainer>
    );
  },
);

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  :hover .backgroundOnHover {
    opacity: 0.02 !important;
  }
`;

interface IClickZoneAreas {
  isOpen: boolean;
}

export const ClickZoneAreas = styled.div<IClickZoneAreas>`
  position: relative;
  z-index: 1;
  cursor: pointer;

  :before {
    content: '';
    position: absolute;
    display: block;
    top: -12px;
    left: 0;
    right: 0;
    bottom: ${({ isOpen }) => (isOpen ? '0' : '-12px')};
  }
`;

const BackgroundOnHover = styled.div`
  position: absolute;
  background: ${`var(${ColorVarsEnum.Level_1})`};
  opacity: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.2s;
`;
