import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ACTIVE_BOARD_AREA_LABEL, ERROR_BOARD_AREA_LABEL, SHOW_MODE } from 'modules/workspace/constans';
import { ContainerSettingsInterface } from 'types/store';
import { getPointShift, halfPointSize, pointSize } from 'modules/workspace/components/BoardAreaContainer/constants';
import { Rnd } from 'react-rnd';
import ColorTheme from 'constants/ColorTheme';

export interface StyledBoardAreaContainerInterface extends Omit<ContainerSettingsInterface, 'showBackground'> {
  zIndex: number;
  borderColor: string;
  shadowColor: string;
  hoverColor: string | null;
  backgroundColor: string | null;
  activeBackgroundColor: string | null;
  hoverBackgroundColor: string | null;
  backgroundImage?: string;
  backgroundSize?: string;
  isError?: string | null;
  isInfluenceEditing?: boolean;
}

const boardAreaContainerMixin = css<StyledBoardAreaContainerInterface>`
  z-index: ${({ zIndex }) => zIndex};
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: background-color 0.2s, filter 0.2s, transform 0.2s;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage})
    }

    ;
    `}

  ${({ backgroundSize }) =>
    backgroundSize &&
    css`
      background-size: ${backgroundSize}
    }

    ;
    `}

  background-position: center;
  background-repeat: no-repeat;

  ${({ hoverBackgroundColor }) =>
    hoverBackgroundColor &&
    css`
      :hover {
        background-color: ${hoverBackgroundColor};
      }
    `}

  ${({ activeBackgroundColor }) =>
    activeBackgroundColor &&
    css`
      background-color: ${activeBackgroundColor};
    `}

  ${({ isInfluenceEditing }) =>
    isInfluenceEditing &&
    css`
      outline: 1px solid ${ColorTheme.default.accent};
    `}

  ${({ isError, isInfluenceEditing }) =>
    isError &&
    !isInfluenceEditing &&
    css`
      outline: 1px solid ${ColorTheme.default.alert};
    `}

  ${({ borderSettings, borderColor }) => {
    const pointShift =
      getPointShift(borderSettings.isActive ? { borderRadius: borderSettings.radius, additionalShift: pointSize } : null) + 'px';

    return (
      borderSettings.isActive &&
      css`
        border: ${borderSettings.width}px ${borderSettings.style} ${borderColor};
        border-radius: ${borderSettings.radius}px;
        padding: ${pointShift};
      `
    );
  }};

  ${({ borderSettings, borderColor }) => {
    const pointShift =
      getPointShift(
        borderSettings.isActive ? { borderRadius: borderSettings.radius, additionalShift: -borderSettings.width } : null,
      ) + 'px';
    const straightPointShift = borderSettings.isActive ? `${-borderSettings.width - 3}px` : '-3px';

    const borderWidth = borderSettings.isActive ? borderSettings.width : 1;

    return css`
      :hover {
        outline: 1px dashed ${ColorTheme.default.accent};
      }

      &.${SHOW_MODE} {
        outline: none !important;

        :hover {
          border: ${borderWidth}px ${borderSettings.isActive ? borderSettings.style : 'solid'}
            ${borderSettings.isActive ? borderColor : 'transparent'};
        }
      }

      &.${ERROR_BOARD_AREA_LABEL}, &.${ERROR_BOARD_AREA_LABEL}.${ACTIVE_BOARD_AREA_LABEL} {
        outline: ${borderWidth}px dashed ${`var(${ColorVarsEnum.Alert})`};

        :hover {
          outline: ${borderWidth}px dashed ${`var(${ColorVarsEnum.Alert})`};
        }

        .point {
          background: ${`var(${ColorVarsEnum.Alert})`};
        }
      }

      &.${ACTIVE_BOARD_AREA_LABEL} {
        outline: 1px dashed ${ColorTheme.default.accent} !important;

        .point {
          display: block;
          width: ${pointSize}px !important;
          height: ${pointSize}px !important;
          background: ${ColorTheme.default.accent};

          &:first-child,
          &:nth-child(3) {
            left: calc(50% - ${halfPointSize}px) !important;
          }

          &:nth-child(2),
          &:nth-child(4) {
            top: calc(50% - ${halfPointSize}px) !important;
          }

          &:first-child {
            top: ${straightPointShift} !important;
          }

          &:nth-child(2) {
            right: ${straightPointShift} !important;
          }

          &:nth-child(3) {
            bottom: ${straightPointShift} !important;
          }

          &:nth-child(4) {
            left: ${straightPointShift} !important;
          }

          &:nth-child(5) {
            top: ${pointShift} !important;
            right: ${pointShift} !important;
          }

          &:nth-child(6) {
            bottom: ${pointShift} !important;
            right: ${pointShift} !important;
          }

          &:nth-child(7) {
            bottom: ${pointShift} !important;
            left: ${pointShift} !important;
          }

          &:nth-child(8) {
            top: ${pointShift} !important;
            left: ${pointShift} !important;
          }
        }
      }
    `;
  }};

  will-change: transform;

  ${({ shadowSettings, shadowColor }) =>
    shadowSettings.isActive &&
    css`
      filter: drop-shadow(${shadowSettings.offsetX}px ${shadowSettings.offsetY}px ${shadowSettings.blurRadius}px ${shadowColor});
    `};

  ${({ hoverColor, shadowSettings }) =>
    shadowSettings.isActive &&
    hoverColor &&
    css`
      :hover {
        filter: drop-shadow(${shadowSettings.offsetX}px ${shadowSettings.offsetY}px ${shadowSettings.blurRadius}px ${hoverColor});
      }
    `}
  .point {
    display: none;
  }

  &:focus {
    outline: none;
  }
`;

export const RndBoardAreaContainer = styled(Rnd)<StyledBoardAreaContainerInterface>`
  ${boardAreaContainerMixin};
`;
