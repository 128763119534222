import { OnValueChange } from 'types/global';
import { TotalRowSettingsInterface } from 'store/reducers/visualisations/types';
import { ElementContainerSettings, GroupContainerSettings } from 'modules/settingsContainer';
import React from 'react';
import { RadioSelected } from 'components/RadioSelected/RadioSelected';
import { TopAndBottomType } from 'types/styles';
import { RadioSelectedItem } from 'components/RadioSelected/types';

interface TotalSettingsInterface extends OnValueChange<TotalRowSettingsInterface> {
  titleLocation?: string;
  options: RadioSelectedItem<TopAndBottomType>[];
}

export const TotalSettings = (props: TotalSettingsInterface) => {
  const { value, onChange, titleLocation, options } = props;
  const { location } = value;

  return (
    <GroupContainerSettings
      titleText={titleLocation}
      switcherState={location.isShow}
      switcherChange={() => onChange({ ...value, location: { ...location, isShow: !location.isShow } })}
    >
      <ElementContainerSettings>
        <RadioSelected
          onChange={(position) =>
            position &&
            onChange({
              ...value,
              location: {
                ...location,
                position,
              },
            })
          }
          activeValue={location.position}
          options={options}
        />
      </ElementContainerSettings>
    </GroupContainerSettings>
  );
};
