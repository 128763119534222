import React from 'react';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { ZoomSettingsInterface } from 'store/reducers/visualisations/types';
import { OnValueChange } from 'types/global';
import { AutoOrManualGroupValue } from 'modules/settingsContainer/common/AutoOrManualGroupValue';

type ZoomSettingsSettingsProps = OnValueChange<ZoomSettingsInterface> & {
  title: string;
};

export const ZoomSettings = ({ title, value, onChange }: ZoomSettingsSettingsProps) => {
  const { isShow, padding } = value;

  return (
    <MainContainerSettings
      titleText={title}
      switcherState={isShow}
      switcherChange={() => onChange({ ...value, isShow: !isShow })}
    >
      <AutoOrManualGroupValue titleText="Отступ" value={padding} onChange={(padding) => onChange({ ...value, padding })} />
    </MainContainerSettings>
  );
};
