import { useEffect, useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import { GetFlatFunctionType, GetOriginListFromFuseResultFunctionType, SearchEngineLifecycleFunctionType } from 'types/global';
import { SortOrderType, SortType } from 'modules/settingsContainer/SearchList/ListFlowSearch/SortOptions/types';

export type SortFunction<OriginListItem> = (a: OriginListItem, b: OriginListItem, sortOrder: SortOrderType) => number;

export type SortFunctions<OriginListItem> = Record<SortType, SortFunction<OriginListItem>>;

interface SearchEngineParams<FlatListItem, OriginListItem> {
  options: Fuse.IFuseOptions<FlatListItem>;
  data: OriginListItem[];
  searchString: string;
  getFlatList: GetFlatFunctionType<FlatListItem, OriginListItem>;
  getOriginListFromResult: GetOriginListFromFuseResultFunctionType<FlatListItem, OriginListItem>;
  sortFunctions?: SortFunctions<OriginListItem>;

  sortType?: SortType;
  sortOrder?: SortOrderType;
  lifecycles?: {
    afterSearch?: SearchEngineLifecycleFunctionType<OriginListItem>;
    afterSort?: (sortedList: OriginListItem[]) => void;
  };
}

export const useSearchEngine = <FlatListItem, OriginListItem>({
  data,
  searchString,
  getFlatList,
  options,
  getOriginListFromResult,
  sortFunctions,
  sortType = 'lastModified',
  sortOrder = 'ascending',
  lifecycles,
}: SearchEngineParams<FlatListItem, OriginListItem>) => {
  const flatList = useMemo(() => (data.length > 0 ? getFlatList(data) : []), [data, getFlatList]);

  const [searchEngine, setSearchEngine] = useState<Fuse<FlatListItem> | null>(null);
  const [filteredList, setFilteredList] = useState(data);

  useEffect(
    () => {
      if (data.length > 0) {
        let newFilteredList: OriginListItem[] = data;

        if (searchString && searchEngine) {
          const result = searchEngine.search(searchString);

          newFilteredList = getOriginListFromResult(result, data);
        }

        if (sortFunctions && sortFunctions[sortType]) {
          newFilteredList = [...newFilteredList].sort((a, b) => sortFunctions[sortType](a, b, sortOrder));
        }

        lifecycles?.afterSearch && lifecycles.afterSearch(newFilteredList, searchString);

        setFilteredList(newFilteredList);
      }

      if (!data?.length) setFilteredList([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchString, lifecycles?.afterSearch, searchEngine, getOriginListFromResult, sortType, sortOrder],
  );

  useEffect(() => {
    if (!searchEngine && flatList.length > 0) {
      const fuseEngine = new Fuse(flatList, options);
      setSearchEngine(fuseEngine);
    }
  }, [flatList, options, searchEngine]);

  return { filteredList };
};
