import { fraction } from 'mathjs';

import { Formatting, FormattingInterface, FormattingItem } from 'store/reducers/visualisations/types';

interface FormatDataParams
  extends Pick<FormattingItem, 'meta'>,
    Pick<FormattingInterface['formats'], 'numberOfZeros' | 'numeric'> {
  text: string;
}

export const chain: Record<Formatting, (params: FormatDataParams) => string> = {
  numerical: ({ text, numberOfZeros, numeric }) => {
    if (!isNumeric(Number(changeComaToDot(text)))) {
      return text;
    }

    const numberFromText = changeComaToDot(text).toFixed(numberOfZeros);
    const numericType = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: numberOfZeros }).format(Number(numberFromText));

    return numeric ? numericType : numberFromText;
  },
  percent: ({ text, numberOfZeros, numeric }) => {
    if (!isNumeric(Number(changeComaToDot(text)))) {
      return text;
    }

    const numberFromText = changeComaToDot(text);
    const numberPercent = Number(+numberFromText * 100).toFixed(numberOfZeros) + '%';

    const numericType = new Intl.NumberFormat('ru-RU', {
      style: 'percent',
      minimumFractionDigits: numberOfZeros,
    }).format(Number(numberFromText));

    return numeric ? numericType : numberPercent;
  },
  money: ({ text, numberOfZeros, meta }) => {
    if (!isNumeric(Number(changeComaToDot(text)))) {
      return text;
    }

    const numberFromText = changeComaToDot(text).toFixed(numberOfZeros);
    return new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      minimumFractionDigits: numberOfZeros,
      currency: meta?.money ? meta?.money : 'RUB',
    }).format(Number(numberFromText));
  },
  fraction: ({ text }) => {
    if (!isNumeric(Number(changeComaToDot(text)))) {
      return text;
    }

    const numberFromText = changeComaToDot(text);

    const fractionFormatting = fraction(numberFromText);
    const { s, d, n } = fractionFormatting;
    const fractionFormattingS = s === 1 ? '' : s + ' ';
    const fractionFormattingD = d === 1 ? '' : '/' + d;

    return fractionFormattingS + n + fractionFormattingD;
  },
  exponential: ({ text }) => {
    if (!isNumeric(Number(changeComaToDot(text)))) {
      return text;
    }

    const questionText = text.replace(/[^%]/g, '');
    const res = text.replace(/[^.\d]/g, '');

    return Number(res).toExponential() + questionText;
  },
};

export const changeComaToDot = (number: string) => {
  const changeCommaToDot = new RegExp('(\\d+)([,.]?)(\\d*)', 'g');

  return Number(
    number.replace(changeCommaToDot, (str, p1, p2, p3) => {
      if (p3 == '') return p1;
      return p1 + '.' + p3;
    }),
  );
};

export const numberOfSymbolsAfterComma = (number: string) => number?.toString().match(/\.(\d+)/)?.[1].length;

export const isNumeric = (n: number) => !isNaN(n);

export const fractional = (number: string) => {
  const trunc = Math.trunc(Number(number));
  const frac = !Number.isInteger(Number(number))
    ? Math.floor((Number(number) % 1) * Math.pow(10, Number(numberOfSymbolsAfterComma(number)))) +
      '/' +
      Math.pow(10, Number(numberOfSymbolsAfterComma(number))) +
      ''
    : '';

  return trunc + '' + frac;
};

export const chainFormatter = (formatters: FormattingInterface['formats'], text: string) => {
  text = chain[formatters.formattingItem.formattingType]({
    text,
    numberOfZeros: formatters.numberOfZeros,
    meta: formatters.formattingItem.meta,
    numeric: formatters.numeric,
  });
  const formattersText = formatters.editText.isActive ? formatters.editText.text : '';

  return text + (formattersText ? ' ' + formattersText : '');
};
