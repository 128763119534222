import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { FlexContainer } from 'styles/FlexContainer';
import { ReactChildrenType } from 'types/global';
import { BoxModal } from './styles';
import { HeaderSettingsAreaLayout, HeaderSettingsAreaLayoutProps } from 'modules/settingsContainer/FieldSettingsArea';
import { MODAL_UNIVERSAL } from 'modules/workspace/constans';

interface ModalUniversalProps extends Pick<HeaderSettingsAreaLayoutProps, 'headerText' | 'onClose'> {
  open: boolean;
  disabledOutsideClose?: boolean;
  children?: ReactChildrenType;
  width?: string;
  maxHeight?: string;
  height?: string;
  padding?: string;
  position?: 'relative' | 'absolute' | 'static';
  disableEscapeKeyDown?: boolean;
}

export const ModalUniversal: FC<ModalUniversalProps> = ({
  children,
  width,
  maxHeight,
  padding = '12px',
  position = 'relative',
  height,
  disabledOutsideClose = false,
  open,
  onClose,
  headerText,
  disableEscapeKeyDown,
}) => {
  const isClose = !disabledOutsideClose ? onClose : () => {};

  return (
    <>
      {/* TODO: MuiPaper-root is crutch, remove ZIndex */}
      {/* TODO: Delete MuiPaper-root and adding custom className for adding to isClickToItem array */}
      <Modal
        open={open}
        onClose={isClose}
        className={`MuiPaper-root ${MODAL_UNIVERSAL}`}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <BoxModal width={width} maxHeight={maxHeight} height={height}>
          <HeaderSettingsAreaLayout height="100%" maxHeight="100%" onClose={onClose} headerText={headerText}>
            <FlexContainer width="100%" flex="1 1 0" padding={padding} position={position}>
              {children}
            </FlexContainer>
          </HeaderSettingsAreaLayout>
        </BoxModal>
      </Modal>
    </>
  );
};
