import { HeaderMenuProps } from 'modules/ui/menu/HeaderMenu';
import { NoopType, NoopValueType } from 'types/global';
import {
  EditableMenuWrapper,
  EditButtonsWrapper,
  MenuItem,
  MenuItemsWrapper,
  MenuItemWrapper,
  StyledTextInput,
} from 'modules/ui/menu/EditableMenu/styles';
import { HeightInterface } from 'types/styles';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { AddIcon, DeleteIcon, EditIcon } from 'assets/icons/withContainer';
import React, { ChangeEvent, KeyboardEventHandler, MouseEventHandler, useState } from 'react';
import { HeaderItemInterface } from 'modules/ui/menu/HeaderMenu/types';
import CircularProgress from '@mui/material/CircularProgress';
import { FlexContainer } from 'styles/FlexContainer';

interface EditableMenuProps<T> extends HeaderMenuProps<T>, Partial<HeightInterface> {
  onAddClick: NoopType;
  onDeleteClick: NoopValueType<T>;
  onItemChange: NoopValueType<HeaderItemInterface<T>>;
  isLoading?: boolean;
  deletionDisabled?: boolean;
}

export const EditableMenu = <T extends string>({
  onAddClick,
  onDeleteClick,
  activeItemKey,
  items,
  onItemClick,
  onItemChange,
  height = '30px',
  isLoading = false,
  deletionDisabled = false,
}: EditableMenuProps<T>) => {
  const [editItem, setEditItem] = useState<HeaderItemInterface<T> | null>(null);

  const onEditClick: (key: T) => MouseEventHandler<HTMLDivElement> = (key) => (e) => {
    e.stopPropagation();
    const newEditItem = editItem?.key === key ? null : items.find((item) => item.key === key) || items[0];

    setEditItem(newEditItem);
  };

  const onEdit = (e: ChangeEvent<HTMLInputElement>) => {
    setEditItem((value) => (value ? { ...value, name: e.target.value } : null));
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter' && editItem) {
      editItem && onItemChange(editItem);
      setEditItem(null);
    }
  };

  const onDelete: (key: T) => MouseEventHandler<HTMLDivElement> = (key) => (e) => {
    e.stopPropagation();
    onDeleteClick(key);
  };

  const onMenuItemClick = (key: T) => () => {
    onItemClick(key);
    editItem && setEditItem(null);
  };

  return (
    <EditableMenuWrapper height={height}>
      {isLoading ? (
        <FlexContainer width="30px" height="100%" alignItems="center" justifyContent="center">
          <CircularProgress size="24px" />
        </FlexContainer>
      ) : (
        <MenuItemsWrapper>
          {items.map(({ name, key }) => (
            <MenuItemWrapper key={key} onClick={onMenuItemClick(key)} isActive={activeItemKey === key}>
              {editItem?.key === key ? (
                <StyledTextInput
                  autoFocus
                  name={`edit_${key}`}
                  width="100%"
                  value={editItem.name}
                  size={editItem.name.length || 1}
                  onChange={onEdit}
                  onKeyDown={onKeyDown}
                />
              ) : (
                <MenuItem>{name}</MenuItem>
              )}
              <EditButtonsWrapper>
                <IconWrapper
                  onClick={onEditClick(key)}
                  iconWidth="17px"
                  iconHeight="17px"
                  containerHeight="30px"
                  containerWidth="30px"
                  Icon={EditIcon}
                />
                <IconWrapper
                  onClick={deletionDisabled ? undefined : onDelete(key)}
                  iconWidth="17px"
                  iconHeight="17px"
                  containerHeight="30px"
                  containerWidth="30px"
                  Icon={DeleteIcon}
                />
              </EditButtonsWrapper>
            </MenuItemWrapper>
          ))}
        </MenuItemsWrapper>
      )}
      <IconWrapper
        onClick={isLoading ? undefined : onAddClick}
        iconWidth="17px"
        iconHeight="17px"
        containerHeight="100%"
        containerWidth="30px"
        Icon={AddIcon}
      />
    </EditableMenuWrapper>
  );
};
