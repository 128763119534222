import { TableDataSettings, VisualisationDataInterface, VisualisationValuesInterface } from 'store/reducers/visualisations/types';
import {
  Column,
  ColumnWidthInterface,
  DataTableInterface,
  MakeHyperLinksRecordType,
  OnSortChangeInterface,
  TotalValuesType,
} from 'modules/visualisations/Table/visualisation/types';
import {
  backgroundByValueAlias,
  backgroundValueAlias,
  colorValueAlias,
  colorValueByAlias,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import { getSumOfArrayValues } from 'utils/utils';
import { FormattingRecordType } from 'modules/visualisations/hooks/useFormat';
import { AST } from 'types/ast';
import { defaultSelectAST, generateBasicFunctionColumn, getSelectColumnsFromSqlString, sqlParser } from 'utils/SQL/genereteAst';
import { generateSqlSelectValue } from 'utils/SQL/generateSQL';
import { IndicatorAstType } from 'store/reducers/ast/types';
import { PropertiesRecordType } from 'modules/visualisations/hooks/useProperties';
import { v4 } from 'uuid';
import { onChangeOrderById } from 'modules/visualisations/common/onChangeFunctions';
import { ColorByConditionUtils } from '../../../../utils/visualisations';

export const loaderSize = '40px';
export const lastRowClass = 'last-row';

interface GetTableDataParams {
  allColumns: TableDataSettings;
  visualisationValues: VisualisationValuesInterface;
  columnWidth: ColumnWidthInterface;
  properties: PropertiesRecordType;
  formatting?: FormattingRecordType;
  makeHyperLinks: MakeHyperLinksRecordType;
  countIncisions: number;
  grouping: string[];
  excludeGroups?: string[];
  parentsChain?: string[];
}

export const getColumnData: (params: GetTableDataParams) => Column[] = ({
  allColumns,
  columnWidth,
  properties,
  makeHyperLinks,
  countIncisions,
  grouping,
}) => {
  const columns = [...allColumns.incisions, ...allColumns.indicators].map((column) =>
    column.settings.nameFromDatabase ? column.fieldName || '' : column.name,
  );

  return columns.reduce<Column[]>(
    (columns, indicatorName, index) =>
      ![colorValueAlias, backgroundValueAlias, colorValueByAlias, backgroundByValueAlias].includes(indicatorName)
        ? [
            ...columns,
            {
              Header: indicatorName,
              id: v4(),
              dataAccessor: indicatorName,
              isGroup: grouping.includes(indicatorName),
              isIncision: index < countIncisions,
              width: columnWidth[indicatorName],
              properties: properties[indicatorName],
              isHyperLink: !!makeHyperLinks[indicatorName],
            },
          ]
        : columns,
    [],
  );
};

export const getTableData = ({
  allColumns,
  visualisationValues,
  columnWidth,
  properties,
  formatting,
  makeHyperLinks,
  countIncisions,
  grouping,
  excludeGroups,
  parentsChain,
}: GetTableDataParams) => {
  const columns = getColumnData({
      allColumns,
      visualisationValues,
      columnWidth,
      properties,
      makeHyperLinks,
      countIncisions,
      grouping,
    }),
    accessors = columns.map(({ dataAccessor }) => dataAccessor),
    defaultColumnName = accessors[0],
    defaultVisualisationValues = visualisationValues[defaultColumnName] || [];

  const data = defaultVisualisationValues.map((_, index) =>
    accessors.reduce<DataTableInterface[]>((data, accessor, indexAccesor) => {
      const visualisationValuesOfColumn = visualisationValues[accessor] || [],
        { backgroundColorBy, fontColorBy } = properties[accessor],
        value = visualisationValuesOfColumn[index],
        formattingFunction = formatting && formatting[accessor],
        totalValue = formattingFunction && value ? formattingFunction(value) : value !== undefined ? String(value) : null,
        backgroundColor = ColorByConditionUtils({
          visualisationValues,
          colorBy: backgroundColorBy,
          alias: backgroundByValueAlias,
          index,
          indexAccesor,
        }),
        fontColor = ColorByConditionUtils({
          visualisationValues,
          colorBy: fontColorBy,
          alias: colorValueByAlias,
          index,
          indexAccesor,
        }),
        valueBackgroundColorByCondition = visualisationValues[backgroundValueAlias]?.[index] || null,
        valueFontColorByCondition = visualisationValues[colorValueAlias]?.[index] || null;

      return [
        ...data,
        {
          id: `${accessor}>${totalValue}`,
          accessorKey: accessor,
          value: excludeGroups?.includes(accessor) ? null : totalValue || null,
          valueFromBD: Number(value),
          isExpanded: false,
          countChildren: 0,
          backgroundColorBy: backgroundColor,
          fontColorBy: fontColor,
          valueBackgroundColorByCondition,
          valueFontColorByCondition,
          parentsChain,
          strParentsChain: parentsChain ? parentsChain.join('>') : 'BigDad',
          isTotalRow: false,
        },
      ];
    }, []),
  );

  return { columns, data };
};

interface GetTotalSqlRequestParams extends Pick<TableDataSettings, 'indicators'> {
  from: AST.FromFromParser;
  where: AST.WhereIn | AST.WhereBetween | AST.UnionAndExpressions | AST.WhereLike | null;
}

export const getTotalSqlRequest = ({ indicators, from, where }: GetTotalSqlRequestParams) => {
  const columns = indicators.reduce<IndicatorAstType[]>(
    (
      selectValues,
      {
        name,
        fieldName,
        customRequest,
        operationType,
        settings: {
          nameFromDatabase,
          totalSettings: { isShow, operationType: operationTypeManual, customRequest: totalCustomRequest, isAutoAggregation },
        },
      },
    ) => {
      if (isShow) {
        let selectValue: IndicatorAstType | null = null;
        const indicatorName = getVisualisationFieldName({ name, fieldName, nameFromDatabase }),
          allCustomRequest = isAutoAggregation ? customRequest : totalCustomRequest,
          totalOperationType = isAutoAggregation ? operationType : operationTypeManual;

        if (totalOperationType === 'other') {
          const selectQuery = generateSqlSelectValue(allCustomRequest, indicatorName);

          selectValue = getSelectColumnsFromSqlString(selectQuery)?.[0];
        } else if (fieldName) {
          selectValue = generateBasicFunctionColumn({ alias: indicatorName, fieldName, functionName: totalOperationType });
        }

        return selectValue ? [...selectValues, selectValue] : selectValues;
      }

      return selectValues;
    },
    [],
  );

  if (columns.length) {
    return sqlParser.sqlify({
      ...defaultSelectAST,
      columns,
      from,
      where,
    });
  }
};

interface GetCalculatedTotalValuesParams extends Pick<TableDataSettings, 'indicators'>, Required<VisualisationDataInterface> {}

export const getCalculatedTotalValues: (params: GetCalculatedTotalValuesParams) => TotalValuesType = ({
  indicators,
  visualisationValues,
}) => {
  return indicators.reduce(
    (
      result,
      {
        name,
        fieldName,
        settings: {
          nameFromDatabase,
          totalSettings: { isShow },
        },
      },
    ) => {
      if (isShow) {
        const indicatorName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

        const indicatorData = visualisationValues[indicatorName] || [];

        return { ...result, [indicatorName]: getSumOfArrayValues(indicatorData) };
      }
      return result;
    },
    {},
  );
};

export const transformLoadedValuesToTotalValues = (loadedValues: VisualisationValuesInterface) =>
  Object.keys(loadedValues).reduce<TotalValuesType>((result, key) => {
    const values = loadedValues[key],
      value = Array.isArray(values) ? values[0] : 0,
      mayBeNumber = typeof value === 'string' && !Number.isNaN(parseInt(value)) ? parseInt(value) : value || 0,
      normalizedValue = typeof mayBeNumber === 'string' ? 0 : mayBeNumber;

    return { ...result, [key]: normalizedValue };
  }, {});

export const onSortClick =
  ({ dataSettings, columnName, id, sortingColumn, isSorted }: OnSortChangeInterface) =>
  () => {
    const order = dataSettings.orderBy.filter((el) => el.columnName !== columnName);

    if (!isSorted) {
      return onChangeOrderById(
        dataSettings,
        [
          ...order,
          {
            columnName,
            type: 'DESC',
          },
        ],
        id,
      );
    }

    if (sortingColumn?.type === 'ASC') {
      return onChangeOrderById(dataSettings, order, id);
    }

    return onChangeOrderById(
      dataSettings,
      [
        ...order,
        {
          columnName,
          type: 'ASC',
        },
      ],
      id,
    );
  };
