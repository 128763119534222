import { store } from 'store';
import {
  updateDataSettingsAction,
  updateLineAndBarDataSettingsAction,
  updateLineAndBarViewSettingsAction,
} from 'store/reducers/visualisations/actions';
import {
  ColorByInterface,
  ElementSettingsInterface,
  LineAndBarDataSettings,
  LineAndBarIndicatorInterface,
  BarType,
  LineAndBarViewSettings,
  MinAndMaxInterface,
  ShowSumInterface,
  ShowValuePositionGeneric,
  ShowValueSettingsInterface,
} from 'store/reducers/visualisations/types';
import { TopAndBottomType } from 'types/styles';
import { OnChangeValue } from 'modules/visualisations/LineAndBar/settings/DataTab/types';
import { moveArrayItem, MoveToType } from 'utils/utils';
import { ColorValuesByThemeType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { PositionSettingType } from 'types/store';

const dispatch = store.dispatch;

export const onColorBySettingsChange = (colorBySettings: ColorByInterface) =>
  dispatch(updateLineAndBarDataSettingsAction({ colorBySettings }));

export const onColorByValueSettingsChange = (colorByValueSettings: ColorByInterface) =>
  dispatch(updateLineAndBarDataSettingsAction({ colorByValueSettings }));

export const onRotateChange = (rotateTo90: boolean, { axisIncisionSettings, axisIndicatorSettings }: LineAndBarViewSettings) => {
  dispatch(updateLineAndBarDataSettingsAction({ rotateTo90 }));
  dispatch(
    updateLineAndBarViewSettingsAction({
      axisIncisionSettings: { ...axisIncisionSettings, position: 'bottom' },
      axisIndicatorSettings: { ...axisIndicatorSettings, position: 'left' },
    }),
  );
};

export const onTypeChange = (type: BarType, indicators: LineAndBarIndicatorInterface[]) =>
  dispatch(
    updateLineAndBarDataSettingsAction({
      type,
      indicators:
        type === 'nominated'
          ? indicators.map((indicator) => ({
              ...indicator,
              settings: { ...indicator.settings, elementSettings: { ...indicator.settings.elementSettings, type: 'bar' } },
            }))
          : indicators,
    }),
  );

export const onShowSumChange = (showSum: ShowSumInterface) => dispatch(updateLineAndBarDataSettingsAction({ showSum }));

export const onMinAndMaxChange = (minAndMax: MinAndMaxInterface) => dispatch(updateLineAndBarDataSettingsAction({ minAndMax }));

export const onMinAndMaxAdditionalChange = (minAndMaxAdditional: MinAndMaxInterface) =>
  dispatch(updateLineAndBarDataSettingsAction({ minAndMaxAdditional }));

/* Incision change */

export const onColorIncisionChange: OnChangeValue<PaletteValuesByThemeType | null> = (dataSettings, colors, id) =>
  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, colors } : incision)),
      indicators: dataSettings.indicators.map((incision) =>
        incision.settings.elementSettings.type === 'bar' ? { ...incision, color: null } : incision,
      ),
    }),
  );

export const onMoveIncision = (dataSettings: LineAndBarDataSettings, incisionId: string, moveTo: MoveToType) => {
  const incisions = dataSettings.incisions,
    indexOfIncision = incisions.findIndex(({ id }) => id === incisionId),
    { newArray } = moveArrayItem(incisions, indexOfIncision, moveTo);

  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      incisions: newArray,
    }),
  );
};

/* Indicator change */

export const onChangeElementSettings: OnChangeValue<ElementSettingsInterface> = (dataSettings, elementSettings, id) => {
  const isLineType = elementSettings.type === 'line',
    position: PositionSettingType | TopAndBottomType = isLineType ? 'top' : 'flex-start';

  const hasColorsInIncisions = dataSettings.incisions.some(({ colors }) => colors);

  return dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id
          ? {
              ...indicator,
              color: !isLineType && hasColorsInIncisions ? null : indicator.color,
              settings: {
                ...indicator.settings,
                elementSettings: {
                  ...indicator.settings.elementSettings,
                  ...elementSettings,
                  type: dataSettings.type === 'nominated' ? 'bar' : elementSettings.type,
                },
                showValue:
                  indicator.settings.elementSettings.type !== elementSettings.type
                    ? { ...indicator.settings.showValue, position: position as ShowValuePositionGeneric }
                    : indicator.settings.showValue,
              },
            }
          : indicator,
      ),
    }),
  );
};

export const onChangeShowValue: OnChangeValue<ShowValueSettingsInterface> = (dataSettings, showValue, id) =>
  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, showValue } } : indicator,
      ),
    }),
  );

export const onColorIndicatorChange: OnChangeValue<ColorValuesByThemeType | null> = (dataSettings, color, id) => {
  const changedIndicator = dataSettings.indicators.find((indicator) => indicator.id === id);

  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((incision) => (id === incision.id ? { ...incision, color } : incision)),
      incisions: dataSettings.incisions.map((incision) =>
        changedIndicator?.settings.elementSettings.type === 'bar' ? { ...incision, colors: null } : incision,
      ),
    }),
  );
};

export const onMoveIndicator = (dataSettings: LineAndBarDataSettings, indicatorId: string, moveTo: MoveToType) => {
  const indicators = dataSettings.indicators,
    indexOfIndicators = indicators.findIndex(({ id }) => id === indicatorId),
    { newArray } = moveArrayItem(indicators, indexOfIndicators, moveTo);

  dispatch(
    updateLineAndBarDataSettingsAction({
      ...dataSettings,
      indicators: newArray,
    }),
  );
};

export const onChangeAdditionalIndicators: OnChangeValue<boolean> = (dataSettings, additionalIndicators, id) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, additionalIndicators } } : indicator,
      ),
    }),
  );
