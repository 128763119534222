import React, { ChangeEventHandler, FC } from 'react';
import styled, { css } from 'styled-components';
import { Checked } from '../../assets/icons/forDelete';
import { ColorVarsEnum } from '../../enums/ColorVarsEnum';

export type CheckTypeType = 'primary' | 'secondary';

interface CheckTypeInterface {
  checkType?: CheckTypeType;
}

interface ICheckbox extends CheckTypeInterface {
  value?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  name: string;
  id: string;
  label: string;
  padding?: string;
  preventDefault?: boolean;
  disabled?: boolean;
}

const Checkbox: FC<ICheckbox> = ({
  value,
  checked = false,
  onChange,
  name,
  id,
  label,
  checkType = 'primary',
  padding = '10px',
  preventDefault,
  disabled = false,
}) => {
  return (
    <LabelContainer
      disabled={disabled}
      key={id}
      padding={padding}
      onClick={
        preventDefault
          ? (e) => {
              e.preventDefault();
            }
          : undefined
      }
    >
      <CheckboxContainer>
        <HiddenCheckbox onChange={!disabled ? onChange : undefined} checked={checked} />
        <StyledCheckbox $checked={checked} checkType={checkType}>
          <StyledCheckboxIcon />
        </StyledCheckbox>
      </CheckboxContainer>
      {label && <SpanText $checked={checked}>{label}</SpanText>}
    </LabelContainer>
  );
};

export default Checkbox;

const LabelContainer = styled.label<Pick<ICheckbox, 'padding' | 'disabled'>>`
  padding: ${({ padding }) => padding};
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  background-color: red;
`;

interface IStatusCheckbox extends CheckTypeInterface {
  $checked: boolean;
}

const StyledCheckboxIcon = styled(Checked)`
  position: absolute;
  left: 1.5px;
  top: 3px;
  visibility: hidden;
  path {
    stroke: var(${ColorVarsEnum.Level_1});
  }
`;

const StyledCheckbox = styled.div<IStatusCheckbox>`
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid ${`var(${ColorVarsEnum.Level_4})`};
  box-sizing: border-box;
  border-radius: 3px;
  transition: all 150ms;
  position: relative;

  ${({ $checked }) =>
    $checked &&
    css`
      background: var(${ColorVarsEnum.Level_4});

      ${StyledCheckboxIcon} {
        visibility: visible;
      }
    `}

  ${({ checkType }) =>
    checkType === 'secondary' &&
    css`
      background: transparent;

      ${StyledCheckboxIcon} {
        path {
          stroke: var(${ColorVarsEnum.Level_2});
        }
      }
    `}
`;

const SpanText = styled.span<IStatusCheckbox>`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  color: ${(props) => (props.$checked ? `var(${ColorVarsEnum.Level_1})` : `var(${ColorVarsEnum.Level_2})`)};
  margin-left: 8px;
`;
