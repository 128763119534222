import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import styled, { css } from 'styled-components';

interface CellGridWrapperProps {
  columns: number;
}

const border = `1px solid var(${ColorVarsEnum.Level_5})`;

export const CellGridWrapper = styled.div<CellGridWrapperProps>`
  height: fit-content;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
  border-top: ${border};
  border-left: ${border};
`;

interface CellGridItemProps {
  active?: boolean;
}

const cellGridItemBefore = css`
  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid var(${ColorVarsEnum.Accent});
  }
`;

export const CellGridItem = styled.div<CellGridItemProps>`
  width: 100%;
  cursor: pointer;
  aspect-ratio: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  border-bottom: ${border};
  border-right: ${border};
  color: var(${ColorVarsEnum.Level_1});

  ${({ active }) =>
    active
      ? cellGridItemBefore
      : css`
          :hover {
            ${cellGridItemBefore}
          }
        `}
`;
