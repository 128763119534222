import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { OnValueChange } from 'types/global';
import { CellPaddingSettings as CellPaddingSettingsInterface } from 'types/store';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import React from 'react';
import { pxUnit } from 'constants/global';

type CellPaddingSettingsProps = OnValueChange<CellPaddingSettingsInterface>;

export const CellPaddingSettings = ({ onChange, value }: CellPaddingSettingsProps) => {
  const { horizontal, vertical } = value;

  return (
    <MainContainerSettings titleText="Внутренний отступ в ячейках">
      <GroupContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="По ширине"
            name="horizontal"
            value={horizontal}
            unit={pxUnit}
            onChange={(horizontal) => onChange({ ...value, horizontal })}
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="По высоте"
            name="vertical"
            value={vertical}
            unit={pxUnit}
            onChange={(vertical) => onChange({ ...value, vertical })}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
