import styled from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const FileInputWrapper = styled.div`
  position: relative;
  flex-direction: column;
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
`;

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const FileInputLabel = styled.label`
  position: relative;
  min-width: 55px;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: var(${ColorVarsEnum.Level_1});
  padding: 10px 9px;
  z-index: 0;
  transition: all 200ms;

  :after {
    content: '';
    position: absolute;
    opacity: 0.07;
    border-radius: 2px;

    background: var(${ColorVarsEnum.Level_1});
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }
  :hover {
    background-color: var(${ColorVarsEnum.Level_1_hover_btn});
  }
`;
