import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { NoopType, OnValueChange } from 'types/global';
import { DefaultDataSettingsInterface, DefaultPropertiesInterface } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { backgroundValueNameTitle, colorNameTitle, percentUnit, pxUnit } from 'constants/global';
import { PaddingsElementSettings } from 'modules/settingsContainer/common/PaddingsElementSettings';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { PrimaryTextSpan } from 'styles/TextsElements';
import Button from 'modules/ui/Button';
import React from 'react';
import { propertiesSettingsTitle } from 'modules/visualisations/Text/settings/ViewTab/constants';
import { alignmentHorizontalOptions, alignmentVerticalOptions, fontStyleIcons } from './constants';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { PositionSettingType } from 'types/store';
import { RadioSelected } from 'components/RadioSelected/RadioSelected';
import { getActiveRadio } from 'components/RadioSelected/constants';

interface ColorPickerItemProps {
  title: string;
  onChangeColors: (value: ColorValuesByThemeType | null) => void;
  colorsValue: ColorValuesByThemeType | null;
  defaultActivatorBackgroundColor: string;
}

export const ColorPickerItem = ({
  title,
  onChangeColors,
  colorsValue,
  defaultActivatorBackgroundColor,
}: ColorPickerItemProps) => {
  return (
    <ElementContainerSettings>
      <FlexContainer alignItems="center" gap="8px">
        <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Level_3})`}>
          {title}
        </PrimaryTextSpan>
        <FlexContainer gap="4px">
          <ColorPicker
            defaultActivatorBackgroundColor={defaultActivatorBackgroundColor}
            Activator={CircleActivator}
            closeOnClick
            type="color"
            onChange={onChangeColors}
            value={colorsValue}
          />
        </FlexContainer>
      </FlexContainer>
    </ElementContainerSettings>
  );
};

interface PropertiesProps<T extends DefaultPropertiesInterface>
  extends OnValueChange<T>,
    Partial<Pick<DefaultDataSettingsInterface, 'indicators'>> {
  title?: string;
  titleColorMainContainerText?: string;
  isMainContainerColorSettings?: boolean;
  isMainContainerSettings?: boolean;
  disabledComponentColorByValue?: boolean;
  disabledLineHeight?: boolean;
  disabledPadding?: boolean;
  disabledOpacity?: boolean;
  disabledFontColorBy?: boolean;
  disabledBackgroundColorBy?: boolean;
  disabledUnderline?: boolean;
  disabledLetterSpacing?: boolean;
  disabledFontColorByBlock?: boolean;
  rightPositionModal?: string;
  switcherColorBy?: {
    switcherChangeColorBy?: NoopType;
    switcherStateColorBy?: boolean;
  };
  switcherStyle?: {
    switcherChangeStyle?: NoopType;
    switcherStateStyle?: boolean;
  };
  alignment?: {
    horizontal: PositionSettingType;
    vertical: PositionSettingType;
  };
}

export const PropertiesSettings = <T extends DefaultPropertiesInterface>({
  value: properties,
  onChange,
  isMainContainerColorSettings,
  isMainContainerSettings,
  disabledComponentColorByValue,
  indicators,
  switcherColorBy,
  switcherStyle,
  title = propertiesSettingsTitle,
  disabledFontColorBy = false,
  disabledFontColorByBlock = false,
  disabledPadding = false,
  disabledOpacity = false,
  disabledBackgroundColorBy = false,
  disabledLineHeight = false,
  disabledUnderline = false,
  disabledLetterSpacing = false,
  titleColorMainContainerText,
  alignment,
}: PropertiesProps<T>) => {
  const { defaultThemePalette, activeThemeSchema } = useColorValues();

  const activePositionHorizontalValue = alignment && getActiveRadio(alignmentHorizontalOptions, alignment.horizontal);
  const activePositionVerticallyValue = alignment && getActiveRadio(alignmentVerticalOptions, alignment.vertical);
  const propertiesTop = () => (
    <>
      <ElementContainerSettings>
        <FlexContainer alignItems="center" gap="8px">
          <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Level_3})`}>
            Начертание
          </PrimaryTextSpan>
          <FlexContainer gap="4px">
            {fontStyleIcons(disabledUnderline).map(({ Icon, type }) => {
              const fontStyleValue = type && properties?.fontStyle[type];

              return (
                <Button
                  key={type}
                  needBackground={false}
                  iconSize="big"
                  heightSize="small"
                  onClick={() => onChange({ ...properties, fontStyle: { ...properties?.fontStyle, [type]: !fontStyleValue } })}
                  leftIcon={<Icon />}
                  selected={fontStyleValue}
                />
              );
            })}
          </FlexContainer>
        </FlexContainer>
      </ElementContainerSettings>
      <ElementContainerSettings>
        <NumberPropertiesBlock
          properties="Размер"
          name="fontSize"
          unit={pxUnit}
          value={properties?.fontSize}
          onChange={(fontSize) => onChange({ ...properties, fontSize })}
          heightSize={'small'}
        />
      </ElementContainerSettings>
      {!disabledBackgroundColorBy && (
        <>
          <ColorPickerItem
            title={backgroundValueNameTitle}
            defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_4_menu]}
            colorsValue={properties.backgroundColor}
            onChangeColors={(backgroundColor) => onChange({ ...properties, backgroundColor })}
          />
        </>
      )}
      {!disabledFontColorBy && (
        <ColorPickerItem
          title={colorNameTitle}
          defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_4_menu]}
          colorsValue={properties.fontColor}
          onChangeColors={(fontColor) => onChange({ ...properties, fontColor })}
        />
      )}
      {!disabledLineHeight && (
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Межстрочный интервал"
            name="lineHeight"
            unit={percentUnit}
            value={properties.lineHeight}
            onChange={(lineHeight) => onChange({ ...properties, lineHeight })}
            heightSize={'small'}
          />
        </ElementContainerSettings>
      )}
      {!disabledLetterSpacing && (
        <ElementContainerSettings>
          <NumberPropertiesBlock
            name="letterSpacing"
            properties="Межбуквенный интервал"
            unit={pxUnit}
            value={properties.letterSpacing}
            onChange={(letterSpacing) => onChange({ ...properties, letterSpacing })}
            heightSize={'small'}
          />
        </ElementContainerSettings>
      )}
      {!disabledOpacity && (
        <ElementContainerSettings>
          <NumberPropertiesBlock
            properties="Прозрачность"
            name="opacity"
            unit="%"
            value={properties.opacity}
            onChange={(opacity) => onChange({ ...properties, opacity })}
            heightSize={'small'}
          />
        </ElementContainerSettings>
      )}
      {alignment && activePositionVerticallyValue && activePositionHorizontalValue && (
        <ElementContainerSettings>
          <FlexContainer alignItems="center" gap="8px">
            <PrimaryTextSpan lineHeight="12px" color={`var(${ColorVarsEnum.Level_3})`}>
              Выравнивание
            </PrimaryTextSpan>
            <FlexContainer gap="4px">
              <ElementContainerSettings>
                <RadioSelected
                  onChange={(option) =>
                    onChange({
                      ...properties,
                      alignment: {
                        horizontal: option.value,
                        vertical: alignment?.vertical,
                      },
                    })
                  }
                  activeValue={activePositionHorizontalValue}
                  options={alignmentHorizontalOptions}
                />
              </ElementContainerSettings>
              <ElementContainerSettings>
                <RadioSelected
                  onChange={(option) =>
                    onChange({
                      ...properties,
                      alignment: {
                        horizontal: alignment?.horizontal,
                        vertical: option.value,
                      },
                    })
                  }
                  activeValue={activePositionVerticallyValue}
                  options={alignmentVerticalOptions}
                />
              </ElementContainerSettings>
            </FlexContainer>
          </FlexContainer>
        </ElementContainerSettings>
      )}
    </>
  );

  const propertiesCenter = () => (
    <>
      {!disabledPadding && (
        <PaddingsElementSettings value={properties.padding} onChange={(padding) => onChange({ ...properties, padding })} />
      )}
    </>
  );

  const propertiesBottomColor = () => (
    <>
      {!disabledFontColorByBlock && (
        <ColorBySettings
          titleMainContainerText={titleColorMainContainerText}
          disabledComponentByValue={disabledComponentColorByValue}
          isMainContainerSettings={isMainContainerColorSettings}
          switcherColorBy={switcherColorBy}
          value={properties?.fontColorBy}
          indicators={indicators}
          onChange={(fontColorBy) => onChange({ ...properties, fontColorBy })}
        >
          <ColorPicker
            Activator={CircleActivator}
            closeOnClick
            type="color"
            onChange={(fontColor) => onChange({ ...properties, fontColor })}
            value={properties.fontColor}
            defaultActivatorBackgroundColor={defaultThemePalette[0]}
          />
        </ColorBySettings>
      )}
    </>
  );

  return (
    <>
      {!isMainContainerSettings ? (
        <>
          <MainContainerSettings titleText={title}>
            <GroupContainerSettings
              switcherState={switcherStyle?.switcherStateStyle}
              switcherChange={switcherStyle?.switcherChangeStyle}
            >
              {propertiesTop()}
            </GroupContainerSettings>
            {!disabledPadding && <GroupContainerSettings>{propertiesCenter()}</GroupContainerSettings>}
            {propertiesBottomColor()}
          </MainContainerSettings>
        </>
      ) : (
        <>
          <GroupContainerSettings
            titleText={propertiesSettingsTitle}
            switcherState={switcherStyle?.switcherStateStyle}
            switcherChange={switcherStyle?.switcherChangeStyle}
          >
            {propertiesTop()}
            {propertiesCenter()}
          </GroupContainerSettings>
          {propertiesBottomColor()}
        </>
      )}
    </>
  );
};
