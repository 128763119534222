import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import {
  defaultSqlDataSettings,
  defaultTableDataSettings,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import { DefaultDataSettings } from 'modules/settingsContainer/common/data/DefaultDataSettings';
import React from 'react';
import { SqlSettings } from 'modules/settingsContainer/common/data/SqlSettings';
import {
  onBackgroundByValueSettingsChange,
  onChangeIncisionsLabelProperties,
  onChangeIndicatorLabelProperties,
  onChangeIndicatorMakeHyperLinks,
  onColorByValueSettingsChange,
  onColumnWidthIncisionsChange,
  onColumnWidthIndicatorChange,
  onDynamicsMarkerChange,
  onMoveIncision,
  onMoveIndicator,
  onSortChange,
  onStepIncisionClickWithGrouping,
  onTotalIndicatorChange,
} from 'modules/visualisations/Table/settings/DataTab/constants';
import {
  onAddNewIncision,
  onAddNewIndicator,
  onChangeEmptyValue,
  onChangeFictionalData,
  onChangeGroupingAllIncisions,
  onChangeGroupingTableRows,
  onChangeIncisionCustomRequest,
  onChangeIncisionFieldName,
  onChangeIncisionName,
  onChangeIncisionNameFromDataBase,
  onChangeIndicatorCustomRequest,
  onChangeIndicatorFormatting,
  onChangeIndicatorName,
  onChangeIndicatorNameFromDataBase,
  onDeleteIncision,
  onDeleteIndicator,
  onLimitChange,
  onModelIdChange,
  onSqlSettingsSave,
} from 'modules/visualisations/common/onChangeFunctions';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { FieldSettingsRenderType, MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { SettingsFieldEntry } from 'modules/settingsContainer/SettingsFieldEntry';
import { NameFromDatabaseSettings } from 'modules/settingsContainer/common/data/NameFromDatabaseSettings';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { FictionalMetricField } from 'modules/settingsContainer/common/FictionalMetricField';
import { IndicatorMetricField } from 'modules/settingsContainer/common/IndicatorMetricField';
import { TableIncisionInterface, TableIndicatorInterface } from 'store/reducers/visualisations/types';
import { ColumnWidthSettings } from 'modules/settingsContainer/common/ColumnWidthSettings';
import { SortByColumnSettings } from 'modules/settingsContainer/common/data/SortByColumnSettings';
import { TableTotalIndicatorSettings } from 'modules/settingsContainer/common/data/TableTotalIndicatorSettings';
import { DynamicsMarkerSettings } from 'modules/settingsContainer/common/data/DynamicsMarkerSettings';
import { getIncisionAndIndicatorsCompletions } from 'utils/sqlSettings';
import { FormattingSettings } from 'modules/settingsContainer/common/data/FormattingSettings';
import { MakeHyperLinksSettings } from 'modules/settingsContainer/common/data/MakeHyperLinks';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { GeneralSettingsSwitch } from 'components/shared/generalSettingsSwitch';
import { EmptyValuesSettings } from 'modules/settingsContainer/common/data/EmptyValuesSettings';
import { MainContainerSettings } from 'modules/settingsContainer';
import { ColorManaging } from 'components/ColorManaging/ColorManaging';
import { conditionIncision, conditionIndicator } from 'components/ColorManaging/constants';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { CommonSettingsInterface } from './types';
import { ColorBySettings } from 'modules/settingsContainer/common/ColorBySettings';
import { backgroundTableValueNameTitle, colorValueNameTitle } from 'constants/global';

export const DataTab = () => {
  const {
    dataSettings,
    codeEditorData,
    astOfVisualisation,
    id: visualisationId,
  } = useGetActiveVisualisationSettings({
    defaultData: defaultTableDataSettings,
    defaultSqlData: defaultSqlDataSettings,
  });

  const { isRealData, hasAllGroupIncision, modelId, incisions, indicators } = dataSettings;
  const { defaultThemePalette } = useColorValues();
  const hasGroupingIncision = !!incisions.filter((incision) => incision.settings.isGroup)[0];

  const { tableFields, controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected, modelMetaData } =
    useDataSettingsMetric(modelId);

  const CommonSettingsBackgroundColor = ({ properties, onChange, options, id }: CommonSettingsInterface) => {
    return (
      <ColorManaging
        titleText={'Изменить цвет заливки'}
        onChangeProperties={(value) =>
          onChange(
            dataSettings,
            {
              ...properties,
              changeFillColor: value,
            },
            id,
          )
        }
        changeFillColor={properties.changeFillColor}
        colorBy={properties?.backgroundColorBy}
        onChange={(value) => onChange(dataSettings, { ...properties, backgroundColorBy: value }, id)}
        options={options}
        isRealData={isRealData}
      >
        <ColorPicker
          Activator={CircleActivator}
          closeOnClick
          type="color"
          onChange={(backgroundColor) =>
            onChange(
              dataSettings,
              {
                ...properties,
                backgroundColor,
              },
              id,
            )
          }
          value={properties.backgroundColor}
          defaultActivatorBackgroundColor={defaultThemePalette[0]}
        />
      </ColorManaging>
    );
  };

  const incisionRender: MetricsRenderType<TableIncisionInterface> = ({ metrics }) => {
    const hasIncisionsGrouping = metrics.filter((metric) => metric.settings.isGroup);

    return (
      <>
        {metrics.map(({ fieldName, name, fictionalData, id, settings: { nameFromDatabase, customRequest, properties } }) => {
          const onUpClick = () => {
            if (hasIncisionsGrouping.length > 0) {
              return onStepIncisionClickWithGrouping({ dataSettings, hasIncisionsGrouping, id, moveTo: 'up' });
            }
            onMoveIncision(dataSettings, id, 'up');
          };
          const onDownClick = () => {
            if (hasIncisionsGrouping.length > 0) {
              return onStepIncisionClickWithGrouping({ dataSettings, hasIncisionsGrouping, id, moveTo: 'down' });
            }
            onMoveIncision(dataSettings, id, 'down');
          };
          const onChangeColors = (backgroundColor: ColorValuesByThemeType | null) => {
            onChangeIncisionsLabelProperties(
              dataSettings,
              {
                ...properties,
                changeFillColor: true,
                backgroundColor,
              },
              id,
            );
          };

          return (
            <SettingsFieldEntry
              setupIsOpen={setupIsOpen(id)}
              onSetupClick={onSetupClick(id)}
              onClick={onMetricClick(id)}
              isSelected={metricIsSelected(id)}
              fieldValue={getVisualisationFieldName({ nameFromDatabase, fieldName, name })}
              canChangeField={!nameFromDatabase}
              onFieldChange={(name) => onChangeIncisionName(dataSettings, name, id)}
              onDownClick={onDownClick}
              onUpClick={onUpClick}
              onChangeColors={onChangeColors}
              colorsValue={properties.backgroundColor}
              colorPickerType="color"
              colorByHand={properties.backgroundColorBy.type === 'default'}
              key={id}
            >
              <FictionalMetricField
                onCustomRequestChange={(customRequest) => onChangeIncisionCustomRequest(dataSettings, customRequest, id)}
                customRequest={customRequest || ''}
                isRealData={isRealData}
                options={tableFields}
                disabled={!!customRequest}
                value={{ fictionalData, fieldName }}
                onChange={({ fictionalData, fieldName }) => {
                  fieldName && onChangeIncisionFieldName(dataSettings, fieldName || '', id);
                  fictionalData && onChangeFictionalData(dataSettings, fictionalData, id);
                }}
                modelMetaData={modelMetaData}
              />
            </SettingsFieldEntry>
          );
        })}
      </>
    );
  };

  const incisionFieldSettingsRender: FieldSettingsRenderType<TableIncisionInterface> = ({ metric: incision, metrics }) => {
    const {
        id,
        name,
        fieldName,
        settings: { isEmptyValues, nameFromDatabase, columnWidthSettings, properties, isGroup },
      } = incision,
      columnName = nameFromDatabase ? fieldName || '' : name,
      sortItsColumn = dataSettings.orderBy.filter((el) => el.columnName === columnName)?.[0],
      indexMetric = metrics.map(({ id }) => id).indexOf(id),
      isPreviousMetricGrouped = indexMetric !== 0 ? metrics[indexMetric - 1]?.settings.isGroup : true,
      isNextMetricGrouped = metrics[indexMetric + 1]?.settings.isGroup,
      possibilityChangeGrouping = !isGroup || !isNextMetricGrouped || (!isNextMetricGrouped && isPreviousMetricGrouped);

    return (
      <>
        <EmptyValuesSettings
          onChange={(isEmptyValues: boolean) => onChangeEmptyValue(dataSettings, isEmptyValues, id)}
          value={isEmptyValues}
        />
        {!hasAllGroupIncision && isPreviousMetricGrouped && (
          <GeneralSettingsSwitch
            titleText="Группировать"
            onChange={(group: boolean) => {
              if (possibilityChangeGrouping) {
                onChangeGroupingTableRows({ dataSettings, isGroup: group, id });
              }
            }}
            value={!!isGroup}
          />
        )}
        <MainContainerSettings titleText={'Параметры текста'}>
          <PropertiesSettings
            isMainContainerColorSettings
            isMainContainerSettings
            disabledComponentColorByValue
            disabledFontColorByBlock
            disabledPadding
            value={properties}
            onChange={(value) => onChangeIncisionsLabelProperties(dataSettings, value, id)}
            disabledBackgroundColorBy
            disabledFontColorBy
            disabledOpacity
            //TODO настроить выравнивание в таблице после добавления общих настроек для разрезов и показателей
            // alignment={properties.alignment}
          />
          <ColorManaging
            titleText={'Цвет'}
            colorBy={properties?.fontColorBy}
            onChange={(value) => onChangeIncisionsLabelProperties(dataSettings, { ...properties, fontColorBy: value }, id)}
            options={conditionIncision}
            isRealData={isRealData}
          >
            <ColorPicker
              Activator={CircleActivator}
              closeOnClick
              type="color"
              onChange={(fontColor) =>
                onChangeIncisionsLabelProperties(
                  dataSettings,
                  {
                    ...properties,
                    fontColor,
                  },
                  id,
                )
              }
              value={properties.fontColor}
              defaultActivatorBackgroundColor={defaultThemePalette[0]}
            />
          </ColorManaging>
        </MainContainerSettings>
        <MainContainerSettings titleText={'Параметры ячейки'}>
          <CommonSettingsBackgroundColor
            properties={properties}
            onChange={onChangeIncisionsLabelProperties}
            options={conditionIncision}
            id={id}
          />
        </MainContainerSettings>

        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIncisionNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />
        <ColumnWidthSettings
          value={columnWidthSettings}
          onChange={(columnWidthSettings) => onColumnWidthIncisionsChange(dataSettings, columnWidthSettings, id)}
        />
        <SortByColumnSettings
          value={sortItsColumn}
          onChange={({ type, isActive }) =>
            onSortChange({
              dataSettings,
              columnName,
              type,
              sortItsColumn,
              isActive,
            })
          }
        />
      </>
    );
  };

  const indicatorRender: MetricsRenderType<TableIndicatorInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ id, fieldName, name, operationType, color, customRequest, settings: { nameFromDatabase, properties } }) => {
        const onUpClick = () => onMoveIndicator(dataSettings, id, 'up');
        const onDownClick = () => onMoveIndicator(dataSettings, id, 'down');
        const onChangeColors = (backgroundColor: ColorValuesByThemeType | null) => {
          onChangeIndicatorLabelProperties(
            dataSettings,
            {
              ...properties,
              changeFillColor: true,
              backgroundColor,
            },
            id,
          );
        };
        return (
          <SettingsFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            fieldValue={getVisualisationFieldName({ name, nameFromDatabase, fieldName })}
            canChangeField={!nameFromDatabase}
            onFieldChange={(name) => onChangeIndicatorName(dataSettings, name, id)}
            onUpClick={onUpClick}
            onDownClick={onDownClick}
            onChangeColors={onChangeColors}
            colorsValue={properties.backgroundColor}
            colorByHand={properties.backgroundColorBy.type === 'default'}
            key={id}
          >
            <IndicatorMetricField
              onCustomRequestChange={(customRequest) => onChangeIndicatorCustomRequest(dataSettings, customRequest, id)}
              customRequest={customRequest}
              options={tableFields}
              dataSettings={dataSettings}
              id={id}
              isRealData={isRealData}
              fieldName={fieldName}
              operationType={operationType}
              modelMetaData={modelMetaData}
            />
          </SettingsFieldEntry>
        );
      })}
    </>
  );

  const indicatorFieldSettingsRender: FieldSettingsRenderType<TableIndicatorInterface> = ({ metric: indicator }) => {
    const {
        id,
        name,
        fieldName,
        settings: {
          nameFromDatabase,
          columnWidthSettings,
          totalSettings,
          dynamicsMarkerSettings,
          formatting,
          makeHyperLinks,
          properties,
        },
      } = indicator,
      columnName = nameFromDatabase ? fieldName || '' : name,
      sortItsColumn = dataSettings.orderBy.filter((el) => el.columnName === columnName)?.[0];

    return (
      <>
        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIndicatorNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />
        <ColumnWidthSettings
          value={columnWidthSettings}
          onChange={(columnWidthSettings) => onColumnWidthIndicatorChange(dataSettings, columnWidthSettings, id)}
        />
        <MainContainerSettings titleText={'Параметры текста'}>
          <PropertiesSettings
            isMainContainerColorSettings
            isMainContainerSettings
            disabledComponentColorByValue
            disabledFontColorByBlock
            disabledPadding
            value={properties}
            onChange={(value) => onChangeIndicatorLabelProperties(dataSettings, value, id)}
            disabledBackgroundColorBy
            disabledFontColorBy
            disabledOpacity
            //TODO настроить выравнивание в таблице после добавления общих настроек для разрезов и показателей
            // alignment={properties.alignment}
          />
          <ColorManaging
            titleText={'Цвет'}
            colorBy={properties?.fontColorBy}
            onChange={(value) => onChangeIndicatorLabelProperties(dataSettings, { ...properties, fontColorBy: value }, id)}
            options={conditionIndicator}
            isRealData={isRealData}
          >
            <ColorPicker
              Activator={CircleActivator}
              closeOnClick
              type="color"
              onChange={(fontColor) =>
                onChangeIndicatorLabelProperties(
                  dataSettings,
                  {
                    ...properties,
                    fontColor,
                  },
                  id,
                )
              }
              value={properties.fontColor}
              defaultActivatorBackgroundColor={defaultThemePalette[0]}
            />
          </ColorManaging>
        </MainContainerSettings>
        <MainContainerSettings titleText={'Параметры ячейки'}>
          <CommonSettingsBackgroundColor
            properties={properties}
            onChange={onChangeIndicatorLabelProperties}
            options={conditionIndicator}
            id={id}
          />
        </MainContainerSettings>
        <MakeHyperLinksSettings
          value={makeHyperLinks}
          onChange={(makeHyperLinks) => onChangeIndicatorMakeHyperLinks(dataSettings, makeHyperLinks, id)}
        />
        <SortByColumnSettings
          value={sortItsColumn}
          onChange={({ type, isActive }) =>
            onSortChange({
              dataSettings,
              columnName,
              type,
              sortItsColumn,
              isActive,
            })
          }
        />
        <TableTotalIndicatorSettings
          value={totalSettings}
          onChange={(totalSettings) => onTotalIndicatorChange(dataSettings, totalSettings, id)}
          modelMetaData={modelMetaData}
        />
        <DynamicsMarkerSettings
          value={dynamicsMarkerSettings}
          onChange={(dynamicsMarkerSettings) => onDynamicsMarkerChange(dataSettings, dynamicsMarkerSettings, id)}
        />
        <FormattingSettings value={formatting} onChange={(value) => onChangeIndicatorFormatting(dataSettings, value, id)} />
      </>
    );
  };

  return (
    <>
      <DefaultDataSettings dataSettings={dataSettings} />
      <ModelSelectorSettings value={modelId} onChange={onModelIdChange} />
      <GeneralSettingsSwitch
        titleText="Группировать все разрезы"
        onChange={(groupIncision: boolean) => {
          onChangeGroupingAllIncisions(dataSettings, groupIncision);
        }}
        value={hasAllGroupIncision}
      />
      <MetricSettings
        titleText="Разрезы"
        addButtonText="Добавить разрез"
        fieldSettingsRender={incisionFieldSettingsRender}
        metricRender={incisionRender}
        metrics={incisions}
        onAdd={() => onAddNewIncision(dataSettings, 'table')}
        onDelete={(id) => id && onDeleteIncision(dataSettings, id)}
        controls={controls}
      />
      <MetricSettings
        titleText="Показатели"
        addButtonText="Добавить показатель"
        fieldSettingsRender={indicatorFieldSettingsRender}
        metricRender={indicatorRender}
        metrics={indicators}
        onAdd={() => onAddNewIndicator(dataSettings, 'table')}
        onDelete={(id) => id && onDeleteIndicator(dataSettings, id)}
        disableDeleteButton={false}
        controls={controls}
      />
      <SqlSettings
        astData={astOfVisualisation}
        sqlData={codeEditorData}
        adviceEditorIncision={getIncisionAndIndicatorsCompletions(incisions)}
        adviceEditorIndicator={getIncisionAndIndicatorsCompletions(indicators)}
        onSave={(sqlSettingsChanges) => onSqlSettingsSave(dataSettings, sqlSettingsChanges, 'table')}
        modelMetaData={modelMetaData}
      />
      <ColorBySettings
        title={colorValueNameTitle}
        indicators={indicators}
        disabledComponentByValue
        value={dataSettings.colorByValueSettings}
        onChange={onColorByValueSettingsChange}
      />
      <ColorBySettings
        title={backgroundTableValueNameTitle}
        indicators={indicators}
        disabledComponentByValue
        value={dataSettings.backgroundByValueSettings}
        onChange={onBackgroundByValueSettingsChange}
      />

      {(hasAllGroupIncision || hasGroupingIncision) && (
        <LimitSettings value={dataSettings.limit} onChange={onLimitChange} title="Лимит группировки" />
      )}
    </>
  );
};
