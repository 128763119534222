import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ColorVarsEnum } from '../../enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';

export const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" size={80} thickness={3} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: 80,
          height: 80,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_1})`} fontSize="14px" lineHeight="14px">{`${Math.round(
          props.value,
        )}%`}</PrimaryTextSpan>
      </Box>
    </Box>
  );
};
