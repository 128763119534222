import React from 'react';
import { FilterElementsSettings } from 'modules/settingsContainer/FilterElementsSettings';
import { DefaultFilterDataSettings } from 'modules/settingsContainer/common/data/DefaultFilterDataSettings';
import { useGetActiveFilter } from 'modules/filters/hooks/getActiveFilter';
import { onFilterElementsChange } from 'modules/settingsContainer/common/data/DefaultFilterDataSettings/constants';

export const DataTab = () => {
  const { data, modelIdValue, tableFields } = useGetActiveFilter({ type: 'date' });

  const { isRealData, nameSettings, fictionalData } = data;

  return (
    <>
      <DefaultFilterDataSettings modelIdValue={modelIdValue} data={data}>
        <FilterElementsSettings
          onChange={onFilterElementsChange}
          isRealData={isRealData}
          options={tableFields}
          value={{ fictionalData, nameSettings }}
        />
      </DefaultFilterDataSettings>
    </>
  );
};
