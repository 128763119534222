import { DefaultDataSettingsInterface } from 'store/reducers/visualisations/types';
import { RealDataSettings } from 'modules/settingsContainer/common/data/RealDataSettings';

interface DefaultDataSettingsProps {
  dataSettings: DefaultDataSettingsInterface;
}

export const DefaultDataSettings = ({ dataSettings }: DefaultDataSettingsProps) => (
  <>
    <RealDataSettings value={dataSettings.isRealData} />
  </>
);
