import styled from 'styled-components';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import React from 'react';
import { TOOLTIP } from 'modules/workspace/constans';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: `${className}  ${TOOLTIP}` }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `var(${ColorVarsEnum.Level_3_menu})`,
    borderRadius: '3px',
    border: `1px solid var(${ColorVarsEnum.Level_4})`,
    padding: '2px 4px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    color: `var(${ColorVarsEnum.Level_2})`,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
  },
}));
