import { useAppDispatch } from 'store';
import { getActiveUser } from 'store/reducers/adminUsers/getters';
import { loadUserGroupsAction, updateUserGroupAccessAction } from 'store/reducers/adminUsers/actions';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { Button, Select, TextField } from 'modules/ui';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { Form } from './styles';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { ModalComponentType } from 'store/reducers/modals/types';
import { AddUserGroupAccessFormType, SelectItemInterface } from 'components/admin/usersPage/elemets/Groups/types';
import { UpdateUserGroupAccessPayload } from 'store/reducers/adminUsers/types';

interface UserGroupAccessModalProps {
  selectData: SelectItemInterface[];
}

export const UserGroupAccessModal: ModalComponentType<UserGroupAccessModalProps> = ({ onClose, selectData }) => {
  const dispatch = useAppDispatch();

  const activeUser = useSelector(getActiveUser);
  const activeUserId = activeUser?.id || '';

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<AddUserGroupAccessFormType>({
    mode: 'onSubmit',
    defaultValues: {
      group: selectData[0].value,
    },
  });

  const onSubmit: SubmitHandler<AddUserGroupAccessFormType> = async ({ group }) => {
    try {
      if (!isDirty || !activeUserId || isSubmitting) {
        return null;
      }

      const params: UpdateUserGroupAccessPayload = {
        userId: activeUserId,
        groupId: group,
      };

      const response = await dispatch(updateUserGroupAccessAction(params));

      if (response) {
        dispatch(loadUserGroupsAction({ userId: activeUserId }));
      }

      onClose();
    } catch (e) {
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      {activeUser && (
        <TextField needBackground={false} name="login" label="Пользователь" value={activeUser.login} disabled width="100%" />
      )}

      <Controller
        render={({ field }) => (
          <Select title="В группу" needBackground={false} options={selectData} width="100%" {...field} value={field.value} />
        )}
        name="group"
        control={control}
      />

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
