import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { NoopValueType, ReactChildrenType } from 'types/global';
import { BoardPositionConfigInterface, BorderStyleSettingsInterface, ShadowSettingInterface } from 'types/store';
import { BackgroundSettings } from 'modules/settingsContainer/common/view/BackgroundSettings';
import { BorderSettings } from 'modules/settingsContainer/common/view/BorderSettings';
import { ShadowSettings } from 'modules/settingsContainer/common/view/ShadowSettings';
import {
  ContainerPositionSettings,
  ContainerPositionSettingsProps,
} from 'modules/settingsContainer/common/view/ContainerPositionSettings';

export interface ContainerSettingsProps
  extends Pick<ContainerPositionSettingsProps, 'disableDragging' | 'onExternalDisableDraggingChange'> {
  onExternalPositionChange?: NoopValueType<BoardPositionConfigInterface>;
  onExternalShowBackgroundChange?: NoopValueType<boolean>;
  positionConfig: BoardPositionConfigInterface;
  showBackground?: boolean;
  onExternalShadowSettingsChange?: NoopValueType<ShadowSettingInterface>;
  shadowSettings: ShadowSettingInterface;
  onExternalBorderSettingsChange?: NoopValueType<BorderStyleSettingsInterface>;
  borderSettings: BorderStyleSettingsInterface;
  ExternalBackgroundSettings?: ReactChildrenType;
}

export const ContainerSettings = ({
  positionConfig,
  disableDragging,
  showBackground,
  borderSettings,
  shadowSettings,
  onExternalBorderSettingsChange,
  onExternalShadowSettingsChange,
  onExternalPositionChange,
  onExternalDisableDraggingChange,
  onExternalShowBackgroundChange,
  ExternalBackgroundSettings,
}: ContainerSettingsProps) => {
  return (
    <MainContainerSettings titleText="Параметры контейнера">
      <ContainerPositionSettings
        value={positionConfig}
        onChange={onExternalPositionChange}
        disableDragging={disableDragging}
        onExternalDisableDraggingChange={onExternalDisableDraggingChange}
      />
      {ExternalBackgroundSettings ? (
        ExternalBackgroundSettings
      ) : (
        <BackgroundSettings value={!!showBackground} onChange={onExternalShowBackgroundChange} />
      )}
      <BorderSettings value={borderSettings} onChange={onExternalBorderSettingsChange} />
      <ShadowSettings value={shadowSettings} onChange={onExternalShadowSettingsChange} />
    </MainContainerSettings>
  );
};
