import {
  DefaultIncisionType,
  IncisionRadioSelector,
  IncisionRadioSelectorProps,
} from 'modules/visualisations/components/IncisionRadioSelector';
import { FlexContainer } from 'styles/FlexContainer';
import { ReactChildrenType } from 'types/global';
import { IncisionSelectorPositionInterface } from 'store/reducers/visualisations/types';

interface SingleIncisionLayoutProps<Incisions extends DefaultIncisionType>
  extends IncisionRadioSelectorProps<Incisions>,
    IncisionSelectorPositionInterface {
  children: ReactChildrenType;
}

export const SingleIncisionLayout = <Incisions extends DefaultIncisionType>({
  children,
  incisions,
  ...incisionRadioProps
}: SingleIncisionLayoutProps<Incisions>) => (
  <FlexContainer gap="10px" flexDirection="column" width="100%" height="100%">
    <IncisionRadioSelector incisions={incisions} {...incisionRadioProps} />
    {children}
  </FlexContainer>
);
