import { Box, Fade, Popover, Typography } from '@mui/material';
import ColorTheme from 'constants/ColorTheme';
import { Dispatch, RefObject, useState } from 'react';
import { Rnd } from 'react-rnd';

interface VisualizationErrorPopupProps {
  hasError: string | null | undefined;
  visibleError: boolean;
  setVisibleError: Dispatch<boolean>;
  isShowMode: boolean;
  boardRef: RefObject<Rnd>;
}

export const VisualizationErrorPopup = ({ hasError, visibleError, isShowMode, boardRef }: VisualizationErrorPopupProps) => {
  const [isVisibleDescription, setIsVisibleDescription] = useState(false);
  const parentRef = boardRef?.current?.resizableElement.current;

  if (!hasError) {
    return null;
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={parentRef}
      open={visibleError}
      TransitionComponent={Fade}
      TransitionProps={{
        enter: true,
        exit: false,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: ColorTheme.default.alert,
          borderRadius: '4px',
          padding: '4px 8px',
          boxShadow: '0px 2px 30px 0px #13111E26',
          zIndex: 100,
        }}
        onMouseEnter={() => setIsVisibleDescription(true)}
        onMouseLeave={() => setIsVisibleDescription(false)}
      >
        <Typography sx={{ color: '#FFFFFF', fontWeight: 'bold' }}>Ошибка данных</Typography>
        {isVisibleDescription && !isShowMode && (
          <Typography
            sx={{
              color: '#FFFFFF',
              whiteSpace: 'wrap',
              wordWrap: 'break-word',
              maxHeight: '300px',
              maxWidth: '500px',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {String(hasError)}
          </Typography>
        )}
      </Box>
    </Popover>
  );
};
