import { useSelector } from 'react-redux';
import { SqlVisualisationProps, useSqlVisualisation } from 'modules/visualisations/hooks/sqlVisualisation';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch } from 'store';
import { ECharts } from 'echarts';
import { ColorByParams, useColorBy } from 'modules/visualisations/hooks/colorBy';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import {
  DefaultDataSettingsInterface,
  EventsSettingsInterface,
  FormattingInterface,
  MinAndMaxInterface,
  SelectItemInterface,
} from 'store/reducers/visualisations/types';
import { disableFilterByIdAction, enableFilterAction, updateEnabledFilterAction } from 'store/reducers/filters/actions';
import { getEnabledFiltersByVisualisationId } from 'store/reducers/filters/getters';
import { useFormat } from 'modules/visualisations/hooks/useFormat';
import { SqlLimitInterface } from 'types/store';
import { useModelIdValue } from 'utils/hooks/visualisation/modelIdValue';
import { useVisualisationNormalizedValues } from 'modules/visualisations/hooks/visualisationNormalizedValues';

interface VisualisationProps
  extends Omit<SqlVisualisationProps, 'dispatch' | 'usingFilters' | 'serviceSelectValues' | 'limit' | 'modelId'>,
    Pick<ColorByParams, 'colorsBy'>,
    Pick<DefaultDataSettingsInterface, 'limit'> {
  events: EventsSettingsInterface;
  intervalRandomData?: MinAndMaxInterface;
  activeIncisionId?: string | null;
}

export const useVisualisation = ({
  events: {
    isReactingToFilter,
    filterSettings: { isFiltering, applyToAllPages, moreThanOne, filterInfluences },
  },
  colorsBy,
  variablesSelectValues = [],
  imagesSelectValues = [],
  limit,
  intervalRandomData,
  activeIncisionId,
  ...sqlParams
}: VisualisationProps) => {
  const dispatch = useAppDispatch();

  const { id, dataSettings } = sqlParams;

  const eChartRef = useRef<ECharts | null | undefined>(null);

  const { visualisationNormalizedValues } = useVisualisationNormalizedValues({
    id,
    isReactingToFilter,
    dataSettings,
    intervalRandomData,
    activeIncisionId,
  });

  /* Filtering logic */
  const enabledFilters = useSelector(getEnabledFiltersByVisualisationId(id));

  const modelIdValue = useModelIdValue(dataSettings.modelId);

  const updateFilter = useCallback(
    ({ selectedValue, fieldName }: { selectedValue?: string | null; fieldName?: string | null }) => {
      if (dataSettings.isRealData && selectedValue && fieldName && isFiltering) {
        const selectedValues = [selectedValue],
          nameSettings = {
            nameFromDatabase: false,
            name: '',
            fieldName,
          };

        if (moreThanOne || !enabledFilters?.length) {
          dispatch(
            enableFilterAction({
              type: 'single',
              isGlobal: applyToAllPages,
              link: 'visualisation',
              isRealData: true,
              nameSettings,
              linkId: id,
              selectedValues,
              modelId: modelIdValue,
              filterInfluences,
            }),
          );

          return;
        }

        enabledFilters &&
          enabledFilters.forEach(({ id }) => dispatch(updateEnabledFilterAction({ id, data: { nameSettings, selectedValues } })));
      }
    },
    [
      dataSettings.isRealData,
      isFiltering,
      moreThanOne,
      enabledFilters,
      dispatch,
      applyToAllPages,
      id,
      modelIdValue,
      filterInfluences,
    ],
  );

  useEffect(() => {
    if (enabledFilters?.length) {
      enabledFilters.forEach(({ id }) => dispatch(updateEnabledFilterAction({ id, data: { filterInfluences } })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInfluences]);

  useEffect(() => {
    if (enabledFilters?.length) {
      enabledFilters.forEach(({ id }) => dispatch(updateEnabledFilterAction({ id, data: { isGlobal: applyToAllPages } })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyToAllPages]);

  useEffect(() => {
    if (enabledFilters?.length && !isFiltering) {
      enabledFilters.forEach(({ id }) => dispatch(disableFilterByIdAction(id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFiltering]);

  useEffect(() => {
    if (enabledFilters?.length && enabledFilters?.length > 1 && !moreThanOne) {
      enabledFilters.forEach(({ id }) => dispatch(disableFilterByIdAction(id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moreThanOne]);

  /* For delete ')' from echarts-for-react library */

  useEffect(() => {
    const eChartsWrapper = eChartRef.current?.getDom()?.parentElement,
      [, text] = eChartsWrapper?.childNodes || [];

    if (eChartsWrapper && text?.textContent === ')') {
      eChartsWrapper.removeChild(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eChartRef.current]);

  /* SQL logic */

  const serviceSelectValues = useMemo(
    () =>
      (colorsBy || []).reduce<SelectItemInterface[]>((result, { type, byCondition: { sqlCondition, alias } }) => {
        return type === 'condition' && sqlCondition ? [...result, { alias, selectSql: sqlCondition }] : result;
      }, []),
    [colorsBy],
  );

  const sqlLimit = useMemo<SqlLimitInterface | undefined>(() => (limit.isActive ? { to: limit.value } : undefined), [limit]);

  const sqlReturnedValues = useSqlVisualisation({
    ...sqlParams,
    serviceSelectValues,
    variablesSelectValues,
    imagesSelectValues,
    dispatch,
    limit: sqlLimit,
    modelId: modelIdValue,
  });

  /* Color By logic*/

  const colorByParams = useColorBy({
    colorsBy,
    indicators: dataSettings.indicators,
    visualisationValues: visualisationNormalizedValues,
  });

  const formattingDataVariables = useMemo(
    () =>
      dataSettings.indicators.reduce<Record<string, FormattingInterface>>(
        (indicator, { name, fieldName, settings: { formatting, nameFromDatabase } }) => {
          const indicatorFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
          indicator[indicatorFieldName] = formatting;
          return { ...indicator, [name]: formatting };
        },
        {},
      ),
    [dataSettings.indicators],
  );

  const formattingParams = useFormat(formattingDataVariables);

  return {
    visualisationNormalizedValues,
    updateFilter,
    dispatch,
    eChartRef,
    formattingParams,
    modelId: modelIdValue,
    ...sqlReturnedValues,
    ...colorByParams,
  };
};
