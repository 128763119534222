import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  deleteByIdUserGroupAccessAction,
  deleteUserGroupAccessAction,
  loadNoUserGroupsAction,
  loadUserGroupsAction,
} from 'store/reducers/adminUsers/actions';
import {
  closeConfirmationModalAction,
  closeModalAction,
  openConfirmationModalAction,
  openModalTypedAction,
} from 'store/reducers/modals/actions';
import { useAppDispatch } from 'store';
import { TableWithSearch } from 'modules/ui/TableWithSearch';
import { UserGroupAccessModal } from './Modal/UserGroupAccessModal';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCell } from 'modules/ui/Table/TableCell';
import { getActiveUser, getUserGroups } from 'store/reducers/adminUsers/getters';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { addUserGroupAccess } from './constants';
import { UsersGroupListInterface } from 'store/reducers/adminUsers/types';

export const Groups: FC = () => {
  const dispatch = useAppDispatch();

  const activeUser = useSelector(getActiveUser);
  const activeUserId = activeUser?.id || '';
  const { loading, groupsList } = useSelector(getUserGroups);

  useEffect(() => {
    if (activeUserId) {
      dispatch(loadUserGroupsAction({ userId: activeUserId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUserId]);

  const onDelete = useCallback(
    async (group: UsersGroupListInterface) => {
      try {
        if (!activeUserId) {
          return;
        }

        const groupId = group.id;

        const response = await dispatch(deleteUserGroupAccessAction({ userId: activeUserId, groupId })).unwrap();

        if (response) {
          dispatch(deleteByIdUserGroupAccessAction(groupId));
        }
      } catch (error) {
        console.error('Error creating project version:', error);
      } finally {
        dispatch(closeConfirmationModalAction());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserId],
  );

  const onDeleteModal = useCallback(
    async (group: UsersGroupListInterface) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          description: `Действительно удалить доступ пользователя к группе «${group.title}»?`,
          onConfirm: () => onDelete(group),
          titleText: 'Удаление доступа',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete],
  );

  const onCloseAddUserGroupAccessModal = () => dispatch(closeModalAction(addUserGroupAccess));

  const onAddModal = useCallback(
    async () => {
      try {
        if (!activeUserId) {
          return;
        }

        const noUserGroups = await dispatch(loadNoUserGroupsAction({ userId: activeUserId })).unwrap();
        const selectData = noUserGroups.map((group) => ({ name: group.name, value: group.id }));

        dispatch(
          openModalTypedAction({
            Component: UserGroupAccessModal,
            componentProps: {
              onClose: onCloseAddUserGroupAccessModal,
              selectData,
            },
            modalSettings: {
              headerText: 'Добавление доступа',
            },
            name: addUserGroupAccess,
          }),
        );
      } catch (error) {
        console.error('Error creating project version:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseAddUserGroupAccessModal, activeUserId],
  );

  const columnHelper = createColumnHelper<UsersGroupListInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: 'Название группы',
        cell: (info) => (
          <TableCell
            text={info.getValue()}
            actions={{
              onRemove: async () => {
                await onDeleteModal(info.row.original);
              },
            }}
          />
        ),
      }),
    ],
    [columnHelper, onDeleteModal],
  );

  return (
    <FlexContainer position="relative" width="100%" padding="24px" maxWidth="800px">
      <TableWithSearch filterColumnName="title" columns={columns} rows={groupsList} onAddModal={onAddModal} loading={loading} />
    </FlexContainer>
  );
};
