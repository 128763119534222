import React, { FC } from 'react';
import { IconProps } from './types';

export const HorizontalCenter: FC<IconProps> = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.23828 6.16602H16.7621" stroke={isActive ? '#26A5ED' : '#9DA1AF'} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.3335 12L18.6668 12" stroke={isActive ? '#26A5ED' : '#9DA1AF'} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9.14355 17.832H14.8578" stroke={isActive ? '#26A5ED' : '#9DA1AF'} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
