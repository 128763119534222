import React, { FC } from 'react';
import { IconProps } from './types';

export const HorizontalEnd: FC<IconProps> = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.14307 6.16602H18.6668" stroke={isActive ? '#26A5ED' : '#9DA1AF'} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.3335 12L18.6667 12" stroke={isActive ? '#26A5ED' : '#9DA1AF'} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M12.9521 17.832H18.6664" stroke={isActive ? '#26A5ED' : '#9DA1AF'} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
