import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { HeatmapEChartsOption, SeriesDataType } from 'modules/visualisations/Heatmap/visualisation/types';
import { getAxisNameLocation, getNameIndicatorTextPadding } from 'modules/visualisations/common/constants';
import { ThemeColorsInterface } from 'store/reducers/themes/types';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import {
  AxisSettingsInterface,
  ExtendedSettingsInterface,
  GradientByInterfaceType,
  HeatmapDataSettings,
  HeatmapIndicatorInterface,
  PositionValueType,
  VisualisationValuesInterface,
  VisualMapSettingsInterface,
} from 'store/reducers/visualisations/types';
import { BarEChartsOption, ConfigWithGridDimensionsInterface, GridDimensionsInterface } from 'types/echarts';
import { PositionSettingType, StartAndEndInterface } from 'types/store';
import { AbsolutePositionType } from 'types/styles';

import { calculateGridDimension, getLengthOfGraphicString, initialDimensions } from 'utils/generateConfigGraphic';

export const getHeatmapData = (
  { incisions, indicators }: Pick<HeatmapDataSettings, 'indicators' | 'incisions'>,
  visualisationValues: VisualisationValuesInterface,
) => {
  const indicator = indicators?.[0];

  const [incisionXAxis, incisionYAxis] = incisions;

  if (!incisionXAxis || !incisionYAxis || !indicator) {
    return { data: {}, activeIncisionSum: {}, maxIndicatorSum: {}, minIndicatorSum: {} };
  }

  const {
      name: nameIndicator,
      fieldName: fieldNameIndicator,
      settings: { nameFromDatabase: nameFromDatabaseIndicator },
    } = indicator,
    {
      name: nameXAxis,
      fieldName: fieldNameXAxis,
      settings: { nameFromDatabase: nameFromDatabaseXAxis },
    } = incisionXAxis,
    {
      name: nameYAxis,
      fieldName: fieldNameYAxis,
      settings: { nameFromDatabase: nameFromDatabaseYAxis },
    } = incisionYAxis;

  const indicatorFieldName = getVisualisationFieldName({
      name: nameIndicator,
      fieldName: fieldNameIndicator,
      nameFromDatabase: nameFromDatabaseIndicator,
    }),
    incisionXAxisFieldName = getVisualisationFieldName({
      name: nameXAxis,
      fieldName: fieldNameXAxis,
      nameFromDatabase: nameFromDatabaseXAxis,
    }),
    incisionYAxisFieldName = getVisualisationFieldName({
      name: nameYAxis,
      fieldName: fieldNameYAxis,
      nameFromDatabase: nameFromDatabaseYAxis,
    });

  const indicatorsValues = visualisationValues[indicatorFieldName] || [],
    incisionXAxisValues = visualisationValues[incisionXAxisFieldName] || [],
    incisionYAxisValues = visualisationValues[incisionYAxisFieldName] || [];

  const uniqueIncisionXAxisValues = [...new Set(incisionXAxisValues as (string | number)[])];
  const uniqueIncisionYAxisValues = [...new Set(incisionYAxisValues as (string | number)[])];

  const incisionValue: VisualisationValuesInterface = {
    [incisionXAxisFieldName]: uniqueIncisionXAxisValues as string[] | number[],
    [incisionYAxisFieldName]: uniqueIncisionYAxisValues as string[] | number[],
  };

  const activeIncisionSum = (incisionValue[incisionYAxisFieldName] as string[])?.reduce<Record<string, number>>(
    (result, key, index) => {
      const value = indicatorsValues?.[index] || 0;

      return {
        ...result,
        [key]: typeof value === 'string' ? 1 : value,
      };
    },
    {},
  );

  const seriesData: SeriesDataType = [];
  let maxIndicatorSumValue = 0;
  let minIndicatorSumValue = 0;

  if (incisionXAxisValues.length > 0 && incisionYAxisValues.length > 0 && indicatorsValues.length > 0) {
    for (let i = 0; i < incisionXAxisValues.length; i++) {
      const incisionXAxisValue = incisionXAxisValues[i] as string | number;
      const incisionYAxisValue = incisionYAxisValues[i] as string | number;
      const xValue = incisionXAxisValue ?? uniqueIncisionXAxisValues?.indexOf(incisionXAxisValue);
      const yValue = incisionYAxisValue ?? uniqueIncisionYAxisValues?.indexOf(incisionYAxisValue);

      const value = indicatorsValues[i] as string | number;

      maxIndicatorSumValue = maxIndicatorSumValue > value ? maxIndicatorSumValue : Number(value);
      minIndicatorSumValue = minIndicatorSumValue < value ? minIndicatorSumValue : Number(value);

      seriesData.push([yValue, xValue, value]);
    }
  }

  const indicatorsValue: Record<string, SeriesDataType> = {
    [indicatorFieldName]: seriesData,
  };

  const maxIndicatorSum: Record<string, number> = {
    [indicatorFieldName]: maxIndicatorSumValue,
  };

  const minIndicatorSum: Record<string, number> = {
    [indicatorFieldName]: minIndicatorSumValue,
  };

  return { data: { ...incisionValue, ...indicatorsValue }, activeIncisionSum, maxIndicatorSum, minIndicatorSum };
};

interface AxisConfigIndicator {
  min?: number;
  max?: number;
  maxStringLength?: number;
}

interface GetIndicatorAxisConfigParams extends AxisConfigIndicator {
  axisIndicatorSettings: AxisSettingsInterface<'indicator'>;
  activeThemeSchema: ThemeColorsInterface;
  mainIndicator: AxisConfigIndicator;
}

export const getIndicatorAxisConfig: (params: GetIndicatorAxisConfigParams) => HeatmapEChartsOption['yAxis'] = ({
  axisIndicatorSettings,
  activeThemeSchema,
  mainIndicator,
}) => {
  const {
    isShow,
    showAxis,
    position,
    name,
    label: { isActive: isActiveLabel, value: labelValue, properties },
    stepSize,
    showGrid,
  } = axisIndicatorSettings;

  const { max, min, maxStringLength } = mainIndicator;

  return [
    {
      type: 'value',
      show: isShow,
      position,
      max,
      min,
      name: name.isShow ? name.text : undefined,
      nameLocation: getAxisNameLocation[name.position.type],
      nameTextStyle: {
        padding: getNameIndicatorTextPadding({
          isRotated: false,
          isAutoType: name.type === 'auto',
          positionValue: name.position.value,
          isLeft: position === 'left',
          maxStringLength,
        })[name.position.type],
        align: undefined,
      },
      splitNumber: stepSize.type === 'manual' ? stepSize.value : undefined,
      axisLine: {
        show: showAxis,
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
      axisLabel: {
        show: isActiveLabel,
        margin: isActiveLabel ? labelValue : undefined,
        textStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_2],
          fontSize: properties?.fontSize,
          fontWeight: properties?.fontStyle?.bold ? 'bold' : 'normal',
          fontStyle: properties?.fontStyle?.italic ? 'italic' : 'normal',
        },
      },
      splitLine: {
        show: showGrid,
        lineStyle: {
          type: 'dashed',
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
      axisTick: {
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
    },
  ];
};

export const getIndicatorAxisGridDimension: (params: GetIndicatorAxisConfigParams) => GridDimensionsInterface = ({
  axisIndicatorSettings,
  maxStringLength = 0,
}) => {
  const {
    isShow,
    position,
    name,
    label: { isActive: isActiveLabel },
  } = axisIndicatorSettings;

  if (!isShow) return initialDimensions;

  const nameGridShift = 20 + getLengthOfGraphicString(name.text),
    baseStringLength = 20;

  const baseGrid = {
    ...initialDimensions,
    [position]: baseStringLength + (isActiveLabel ? maxStringLength : 0),
  };

  const namePositionGridDimensions = {
    'flex-start': {
      ...initialDimensions,
      bottom: 20,
      left: name.position.type === 'flex-start' && name.position.type === 'flex-start' ? nameGridShift / 2 : nameGridShift,
      right: name.position.type === 'flex-start' && name.position.type === 'flex-start' ? nameGridShift : nameGridShift / 2,
    },
    center: position === 'left' ? { ...initialDimensions, left: 20 } : { ...initialDimensions, right: 10 },
    'flex-end': {
      ...initialDimensions,
      top: 20,
      left: name.position.type === 'flex-end' && name.position.type === 'flex-end' ? nameGridShift / 2 : nameGridShift,
      right: name.position.type === 'flex-end' && name.position.type === 'flex-end' ? nameGridShift / 2 : nameGridShift,
    },
  };

  const namePositionGrid = name.isShow ? namePositionGridDimensions[name.position.type] : initialDimensions;

  return calculateGridDimension([baseGrid, namePositionGrid]);
};

export const getIndicatorAxisWithGridDimensions: (
  params: GetIndicatorAxisConfigParams,
) => ConfigWithGridDimensionsInterface<HeatmapEChartsOption['yAxis']> = (params) => ({
  config: getIndicatorAxisConfig(params),
  gridDimensions: getIndicatorAxisGridDimension(params),
});

export const getBarGraphGridDimensions = (indicators: HeatmapIndicatorInterface[]) => {
  let isActiveGrid = false;

  indicators.forEach(
    ({
      settings: {
        showValue: { isShow, position },
      },
    }) => {
      if (!isActiveGrid) {
        isActiveGrid = isShow && position === 'outside';
      }
    },
  );

  const shiftPosition = 'top';

  return isActiveGrid ? { ...initialDimensions, [shiftPosition]: 20 } : initialDimensions;
};

export const defaultGridDimension: GridDimensionsInterface = {
  left: 70,
  right: 40,
  top: 20,
  bottom: 20,
};

interface MaxAndMinValueInterface {
  maxValue: number;
  minValue: number;
}

interface GetLegendConfigParams {
  visualMapSettings: VisualMapSettingsInterface;
  defaultColor: string;
  typeLegend: GradientByInterfaceType;
  startAndEnd: StartAndEndInterface;
  colors: string[];
  maxAndMinValue: MaxAndMinValueInterface;
}

export const getLegendConfigWithGridDimensions: (params: {
  typeLegend: GradientByInterfaceType;
  defaultColor: string;
  maxAndMinValue: MaxAndMinValueInterface;
  startAndEnd: StartAndEndInterface;
  colors: string[];
  visualMapSettings: ExtendedSettingsInterface;
}) => ConfigWithGridDimensionsInterface<BarEChartsOption['visualMap']> = ({
  defaultColor,
  maxAndMinValue,
  colors,
  startAndEnd,
  typeLegend,
  visualMapSettings,
}) => ({
  config: getLegendConfig({ visualMapSettings, maxAndMinValue, startAndEnd, typeLegend, colors, defaultColor }),
  gridDimensions: getLegendGridDimension(visualMapSettings),
});

export const getLegendConfig: (params: GetLegendConfigParams) => BarEChartsOption['visualMap'] = ({
  visualMapSettings: { type, isShow, location, position, width },
  maxAndMinValue,
  colors,
  startAndEnd,
  typeLegend,
  defaultColor,
}) => {
  const legendIsAuto = type === 'auto';
  const isHorizontalLocation = location.type === 'right' || location.type === 'left';
  const { maxValue, minValue } = maxAndMinValue;
  const { start, end } = startAndEnd;

  return {
    min: minValue,
    color: colors,
    range: [start, end],
    max: maxValue,
    type: typeLegend === 'valueSteps' ? 'piecewise' : 'continuous',
    splitNumber: colors?.length,
    calculable: true,
    show: isShow,
    textStyle: {
      color: defaultColor,
      fontSize: '14px',
      overflow: 'breakAll',
      width: isHorizontalLocation ? width - 32 : undefined,
    },
    orient: isHorizontalLocation ? 'vertical' : 'horizontal',
    ...getLegendLocation(legendIsAuto, location.value)[location.type],
    ...(isHorizontalLocation
      ? getVerticalLegendPosition(legendIsAuto, position)
      : getHorizontalLegendPosition(legendIsAuto, position))[position.type],
  };
};

export const getLegendGridDimension: (legendSettings: VisualMapSettingsInterface) => GridDimensionsInterface = ({
  location,
  width,
  isShow,
}) => {
  const dimensionByLocation = { left: width, right: width, bottom: 40, top: 40 };

  return { ...initialDimensions, ...(isShow ? { [location.type]: dimensionByLocation[location.type] } : {}) };
};

export const getLegendLocation: (
  legendIsAuto: boolean,
  location: number,
) => Record<AbsolutePositionType, BarEChartsOption['visualMap']> = (legendIsAuto, location) => ({
  top: {
    top: legendIsAuto ? 'top' : location,
  },
  bottom: {
    bottom: legendIsAuto ? 'bottom' : location,
  },
  right: {
    right: legendIsAuto ? 'right' : location,
  },
  left: {
    left: legendIsAuto ? 'left' : location,
  },
});

export const getVerticalLegendPosition: (
  legendIsAuto: boolean,
  position: PositionValueType,
) => Record<PositionSettingType, BarEChartsOption['visualMap']> = (legendIsAuto, position) =>
  legendIsAuto
    ? {
        'flex-start': {
          top: 'top',
        },
        center: {
          top: 'center',
        },
        'flex-end': {
          top: 'bottom',
        },
      }
    : {
        'flex-start': {
          top: position.type === 'flex-start' ? position.value : undefined,
        },
        center: {
          top: 'center',
        },
        'flex-end': {
          bottom: position.type === 'flex-end' ? position.value : undefined,
        },
      };

export const getHorizontalLegendPosition: (
  legendIsAuto: boolean,
  position: PositionValueType,
) => Record<PositionSettingType, BarEChartsOption['visualMap']> = (legendIsAuto, position) =>
  legendIsAuto
    ? {
        'flex-start': {
          left: 'left',
        },
        center: {
          left: 'center',
        },
        'flex-end': {
          left: 'right',
        },
      }
    : {
        'flex-start': {
          left: position.type === 'flex-start' ? position.value : undefined,
        },
        center: {
          left: 'center',
        },
        'flex-end': {
          right: position.type === 'flex-end' ? position.value : undefined,
        },
      };
