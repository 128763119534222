import { IncisionServiceTreeMapInterface, NestedDataType, TreeNode } from 'modules/visualisations/Tree/visualisation/types';
import {
  SignaturesInterface,
  TreeDataSettings,
  TreeIncisionInterface,
  VisualisationValuesInterface,
} from 'store/reducers/visualisations/types';
import { LabelOption } from 'echarts/types/src/util/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { TreemapSeriesLevelOption } from 'echarts/types/src/chart/treemap/TreemapSeries';
import { getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { PropertiesRecordType } from 'modules/visualisations/hooks/useProperties';
import { GetColorsFnType } from 'modules/settingsContainer/ColorPicker/hooks';
import { ThemeColorsInterface } from 'store/reducers/themes/types';

const getNestedData: (resultObject: NestedDataType, row: Array<string | undefined>, value: number | null) => NestedDataType = (
  resultObject,
  row,
  value,
) => {
  const [currentLevel, ...remainingLevels] = row;

  if (!currentLevel) {
    return resultObject;
  }

  const currentLevelValue = resultObject[currentLevel] || {};

  if (remainingLevels.length === 0) {
    return { ...resultObject, [currentLevel]: value };
  }

  const resultObjectValue = getNestedData(currentLevelValue as NestedDataType, remainingLevels, value);

  return { ...resultObject, [currentLevel]: { ...(currentLevelValue as NestedDataType), ...resultObjectValue } };
};

const getNestedObject = (data: VisualisationValuesInterface, incisions: IncisionServiceTreeMapInterface[], indicator: string) => {
  const indicatorData = (data[indicator] as Array<number | null>) || [];

  return indicatorData.reduce<NestedDataType>((result, indicatorValue, indicatorIndex) => {
    const row = incisions.map(({ path: incisionKey }) => data?.[incisionKey]?.[indicatorIndex] as string | undefined);

    const resObjValue = getNestedData(result, row, indicatorValue);

    return { ...result, ...resObjValue };
  }, {});
};

const getTreeMapVisualisationData: (
  nestedObject: NestedDataType,
  incisions: IncisionServiceTreeMapInterface[],
  level: number,
) => TreeNode[] = (nestedObject, incisions, level) => {
  const levelValues = Object.keys(nestedObject);

  const nestedValue = nestedObject[levelValues?.[0]];

  if (typeof nestedValue !== 'object' || nestedValue === undefined || nestedValue === null) {
    return levelValues.map((name) => {
      /*TODO: ClickHouse return string value for min() or max() fn, this solutions is workaround */
      const value = typeof nestedObject[name] !== 'number' ? 1 : nestedObject[name];

      return { name, path: incisions[level].path, tableColumn: incisions[level].tableColumn, value };
    }) as TreeNode[];
  }

  return levelValues.map((name) => {
    return {
      name,
      path: incisions[level].path,
      tableColumn: incisions[level].tableColumn,
      children: [...getTreeMapVisualisationData(nestedObject[name] as NestedDataType, incisions, level + 1)],
    };
  });
};

export const getTreeMapData: (dataSettings: TreeDataSettings, visualisationValues: VisualisationValuesInterface) => TreeNode[] = (
  dataSettings,
  visualisationValues,
) => {
  const indicator = dataSettings.indicators?.[0];

  if (!indicator) {
    return [];
  }

  const incisions: IncisionServiceTreeMapInterface[] = dataSettings.incisions.map(
      ({ name, fieldName, settings: { nameFromDatabase } }) => ({
        path: getVisualisationFieldName({ name, fieldName, nameFromDatabase }),
        tableColumn: fieldName,
      }),
    ),
    {
      name,
      fieldName,
      settings: { nameFromDatabase },
    } = indicator,
    indicatorFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

  const nestedObject = getNestedObject(
    visualisationValues,
    incisions.filter(({ path }) => Object.keys(visualisationValues).includes(path)),
    indicatorFieldName,
  );

  return getTreeMapVisualisationData(nestedObject, incisions, 0);
};

const locationMapper = {
  center: '',
  'flex-start': 'Bottom',
  'flex-end': 'Top',
};
const positionMapper = {
  center: '',
  'flex-start': 'Left',
  'flex-end': 'Right',
};

export const getLabelPosition = ({ location, position }: SignaturesInterface) =>
  `inside${locationMapper[location]}${positionMapper[position]}` as LabelOption['position'];

/* TODO: Need to fix it TypeScript */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getTreeMapLevels: (
  incisions: TreeIncisionInterface[],
  showBackground: boolean,
  activeThemeSchema: ThemeColorsInterface,
  propertiesIncisionName: PropertiesRecordType,
  propertiesIncisionValue: PropertiesRecordType,
  propertiesIncisionNameValue: PropertiesRecordType,
  getColorValues: GetColorsFnType,
) => TreemapSeriesLevelOption[] = (
  incisions,
  showBackground,
  activeThemeSchema,
  propertiesIncisionName,
  propertiesIncisionNameValue,
  propertiesIncisionValue,
  getColorValues,
) => {
  const backgroundNodeColor = showBackground
    ? activeThemeSchema[ColorVarsEnum.Level_4_menu]
    : activeThemeSchema[ColorVarsEnum.Level_5_application];

  return [
    {
      itemStyle: {
        borderColor: activeThemeSchema[ColorVarsEnum.Level_5],
        borderWidth: 1,
        gapWidth: 1,
      },
      upperLabel: {
        show: false,
      },
    },
    ...incisions.map((value) => {
      const name = propertiesIncisionName[value.name];
      const nameFromIncision = propertiesIncisionNameValue[value.name];
      const formattedValue = propertiesIncisionValue[value.name];

      return {
        itemStyle: {
          borderColor: backgroundNodeColor,
          gapWidth: 1,
          borderWidth: 4,
        },
        label: {
          position: 'insideBottomRight',
          rich: {
            name: {
              fontSize: name.fontSize,
              color: getColorValues(name.fontColor),
              lineHeight: name.fontSize,
              fontWeight: name.fontStyle.bold ? 'bold' : 'normal',
              fontStyle: name.fontStyle.italic ? 'italic' : 'normal',
            },
            nameFromIncision: {
              fontSize: nameFromIncision.fontSize,
              color: getColorValues(nameFromIncision.fontColor),
              lineHeight: nameFromIncision.fontSize,
              fontWeight: nameFromIncision.fontStyle.bold ? 'bold' : 'normal',
              fontStyle: nameFromIncision.fontStyle.italic ? 'italic' : 'normal',
            },

            formattedValue: {
              fontSize: formattedValue.fontSize,
              color: getColorValues(formattedValue.fontColor),
              lineHeight: formattedValue.fontSize,
              fontWeight: formattedValue.fontStyle.bold ? 'bold' : 'normal',
              fontStyle: formattedValue.fontStyle.italic ? 'italic' : 'normal',
            },
          },
        },
      };
    }),
  ];
};

export const defaultColors = (activeThemeSchema: ThemeColorsInterface) => [
  activeThemeSchema[ColorVarsEnum.Level_3],
  activeThemeSchema[ColorVarsEnum.Level_4],
  activeThemeSchema[ColorVarsEnum.Level_5],
];
