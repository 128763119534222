import { PieIndicatorDesignInterface } from 'store/reducers/visualisations/types';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React from 'react';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { degUnit, percentUnit, pxUnit } from 'constants/global';
import { BooleanElementSettings } from 'modules/settingsContainer/common/BooleanElementSettings';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { OnValueChange } from 'types/global';

type PieStyleSettingsProps = OnValueChange<PieIndicatorDesignInterface>;

export const PieStyleSettings = ({ value: styleProperties, onChange }: PieStyleSettingsProps) => {
  const { borderRadius, borderWidth, pieAnimationElement, radiusFirst, startAngle } = styleProperties;

  return (
    <MainContainerSettings titleText="Оформление">
      <GroupContainerSettings>
        <BooleanElementSettings
          titleText="Выдвигать доли при наведении"
          value={pieAnimationElement}
          onChange={() => onChange({ ...styleProperties, pieAnimationElement: !pieAnimationElement })}
        />
        <ElementContainerSettings>
          <NumberPropertiesBlock
            name="borderRadius"
            properties="Радиус скругления"
            unit={pxUnit}
            value={borderRadius}
            onChange={(borderRadius) => onChange({ ...styleProperties, borderRadius })}
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            name="borderWidth"
            properties="Расстояние между долями"
            unit={pxUnit}
            value={borderWidth}
            onChange={(borderWidth) => onChange({ ...styleProperties, borderWidth })}
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            name="radiusFirst"
            properties="Внутренний радиус"
            unit={percentUnit}
            value={radiusFirst}
            onChange={(radiusFirst) => onChange({ ...styleProperties, radiusFirst })}
          />
        </ElementContainerSettings>
        <ElementContainerSettings>
          <NumberPropertiesBlock
            name="startAngle"
            properties="Стартовый угол"
            unit={degUnit}
            value={startAngle}
            onChange={(startAngle) => onChange({ ...styleProperties, startAngle })}
          />
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
