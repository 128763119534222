import React from 'react';
import { TextSettingInterface } from 'store/reducers/visualisations/types';
import { updateViewSettingsAction } from 'store/reducers/visualisations/actions';
import { useAppDispatch } from 'store';
import { TextSettings } from 'modules/settingsContainer/common/TextSettings';
import { TextSettingsControlInterface } from 'modules/settingsContainer/common/TextSettings/types';

type TitleSettingsProps = TextSettingsControlInterface;

export const TitleSettings = ({ value, onChange: onExternalChange }: TitleSettingsProps) => {
  const dispatch = useAppDispatch();

  const onLocalChange = (header: TextSettingInterface) => {
    dispatch(updateViewSettingsAction({ header }));
  };

  const onChange = onExternalChange || onLocalChange;

  return <TextSettings value={value} onChange={onChange} titleText="Заголовок" />;
};
