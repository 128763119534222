import { GroupType, IdInterface, LoadingInterface, RoleType } from 'types/store';
import {
  FlowListInterface,
  FlowProjectHistoryVersionInterface,
  FlowProjectsInterface,
  FlowsListInterface,
  SortProject,
} from 'store/reducers/projectManager/types';

export interface UsersListInterface extends IdInterface {
  login: string;
}

export interface UsersInterface extends LoadingInterface {
  usersList: UsersListInterface[];
}

export interface UserFlowsListInterface extends IdInterface {
  name: string;
  flowGroupType: GroupType;
  groupName: string;
}

export interface UserFlowsInterface extends LoadingInterface {
  userFlowsList: UserFlowsListInterface[];
}

export interface UserSourcesListInterface extends IdInterface {
  name: string;
  sourceGroupType: GroupType;
  groupName: string;
}

export interface UserSourcesInterface extends LoadingInterface {
  userSourcesList: UserSourcesListInterface[];
}

export interface UsersGroupListInterface extends IdInterface {
  title: string;
}

export interface UsersGroupInterface extends LoadingInterface {
  groupsList: UsersGroupListInterface[];
}

export interface UsersSliceStateInterface {
  users: UsersInterface;
  userSources: UserSourcesInterface;
  activeUser: UsersListInterface | null;
  userFlows: UserFlowsInterface;
  userGroups: UsersGroupInterface;
}

export interface UserInfo extends IdInterface, UserInterface {}

export interface UserInterface {
  login: string;
  password: string;
  role: RoleType;
  isLicense: false;
}

export interface DraftsOfAllUsersInterface extends LoadingInterface {
  draftsOfAllUsersList: {
    id: string;
    name: string;
  }[];
}

export interface UsersSliceStateInterface {
  users: UsersInterface;
  activeUser: UsersListInterface | null;
  usersDraftProjects: FlowProjectsInterface;
  usersDraftProjectHistoryVersion: FlowProjectHistoryVersionInterface;
  activeUserDraftProjectId: string | null;
  shortUserDraftProject: SortProject;
  adminDraftInfo: FlowsListInterface | null;
  draftsOfAllUsers: DraftsOfAllUsersInterface;
}

/* Action Types */
export enum UsersActionsTypes {
  LOAD_USERS = 'LOAD_USERS',
  LOAD_USER_INFO = 'LOAD_USER_INFO',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  ACTIVE_USER_ID = 'ACTIVE_USER_ID',
  DELETE_USER = 'DELETE_USER',
  ADD_USER = 'ADD_USER',
  DELETE_BY_ID_USER = 'DELETE_BY_ID_USER',
  UPDATE_USERS = 'UPDATE_USERS',

  LOAD_DRAFT_PROJECTS = 'LOAD_DRAFT_PROJECTS',
  LOAD_SHORT_DRAFT_USER_PROJECTS = 'LOAD_SHORT_DRAFT_USER_PROJECTS',
  LOAD_DRAFT_OF_ALL_USERS = 'LOAD_DRAFT_OF_ALL_USERS',
  DELETE_USER_DRAFT_PROJECT = 'DELETE_USER_DRAFT_PROJECT',
  LOAD_USER_DRAFT_PROJECT_INFO = 'LOAD_USER_DRAFT_PROJECT_INFO',
  LOAD_USER_DRAFT_PROJECT_HISTORY_VERSION = 'LOAD_USER_DRAFT_PROJECT_HISTORY_VERSION',
  RESTORE_HISTORY_USER_DRAFT_PROJECT = 'RESTORE_HISTORY_USER_DRAFT_PROJECT',
  USER_DRAFT_PROTECT_PROJECT = 'USER_DRAFT_PROTECT_PROJECT',
  RENAME_DRAFT_USER_PROJECT = 'RENAME_DRAFT_USER_PROJECT',
  MOVE_DRAFT_USER_PROJECT = 'MOVE_DRAFT_USER_PROJECT',
  CREATE_USER_DRAFT_PROJECT_VERSION_ANOTHER = 'CREATE_USER_DRAFT_PROJECT_VERSION_ANOTHER',
  COPY_USER_DRAFT_PROJECT_VERSION_ANOTHER = 'COPY_USER_DRAFT_PROJECT_VERSION_ANOTHER',
  DELETE_BY_ID_USER_DRAFT_PROJECT = 'DELETE_BY_ID_USER_DRAFT_PROJECT',
  UPDATE_USER_DRAFT_PROJECT_BY_ID = 'UPDATE_USER_DRAFT_PROJECT_BY_ID',

  LOAD_USER_SOURCES = 'LOAD_USERS_SOURCES',
  LOAD_NO_USER_SOURCES = 'LOAD_NO_USERS_SOURCES',
  CREATE_USER_SOURCE = 'CREATE_USER_SOURCE',
  UPDATE_USER_SOURCE = 'UPDATE_USER_SOURCE',
  DELETE_USER_SOURCE = 'DELETE_USER_SOURCE',
  ADD_USER_SOURCE = 'ADD_USER_SOURCE',
  DELETE_BY_ID_USER_SOURCE = 'DELETE_BY_ID_USER_SOURCE',
  UPDATE_USER_SOURCES = 'UPDATE_USERS_SOURCES',

  LOAD_USER_GROUPS = 'LOAD_USER_GROUPS',
  LOAD_NO_USER_GROUPS = 'LOAD_NO_USER_GROUPS',
  DELETE_USER_GROUP_ACCESS = 'DELETE_USER_GROUP_ACCESS',
  DELETE_BY_ID_USER_GROUP_ACCESS = 'DELETE_BY_ID_USER_GROUP_ACCESS',
  UPDATE_USER_GROUP_ACCESS = 'UPDATE_USER_GROUP_ACCESS',
  UPDATE_USERS_GROUP_ACCESS = 'UPDATE_USERS_GROUP_ACCESS',

  LOAD_USER_FLOWS = 'LOAD_USER_FLOWS',
  LOAD_NO_USER_FLOWS = 'LOAD_NO_USER_FLOWS',
  CREATE_USER_FLOW = 'CREATE_USER_FLOW',
  UPDATE_USER_FLOW = 'UPDATE_USER_FLOW',
  DELETE_USER_FLOW = 'DELETE_USER_FLOW',
  ADD_USER_FLOW = 'ADD_USER_FLOW',
  DELETE_BY_ID_USER_FLOW = 'DELETE_BY_ID_USER_FLOW',
  UPDATE_USERS_FLOW = 'UPDATE_USERS_FLOW',
}

/* Payload Interfaces */

export type UserPayload = UserInterface;

export type UserUpdatePayload = {
  password?: string;
  role: RoleType;
  isLicense: false;
};

export type UserLoadDraftProjectsPayload = {
  userId: string;
};

export type UserDeleteDraftProjectsPayload = {
  projectId: string;
};

export type UpdateUserDraftProjectByIdPayload = {
  userDraftProject: FlowListInterface;
};

export type LoadUserSourcesPayload = {
  userId: string;
};
export type DeleteUserSourcePayload = LoadUserSourcesPayload & {
  sourceId: string;
};
export type CreateUserSourcePayload = DeleteUserSourcePayload & {
  type: GroupType;
};

export type LoadUserGroupsPayload = {
  userId: string;
};
export type DeleteUserGroupsPayload = LoadUserGroupsPayload & {
  groupId: string;
};

export type UpdateUserGroupAccessPayload = DeleteUserGroupsPayload;

export type LoadUserFlowsPayload = {
  userId: string;
};

export type DeleteUserFlowPayload = LoadUserFlowsPayload & {
  flowId: string;
};

export type CreateUserFlowPayload = DeleteUserFlowPayload & {
  type: GroupType;
};
